import DataService from './DataService'

const CopilotService = {
    // bot service
    chat: (payload, token) => {
        return DataService.post("/copilot/chat", payload, token)
    },
    feedback: payload => {
        return DataService.post("/copilot/feedback", payload)
    },
    selectAgent: (payload, token) => {
        return DataService.post("/copilot/selectagent", payload, token)
    },
    qna: (payload, token) => {
        return DataService.post("/copilot/qna", payload, token)
    },
    recommend: (payload) => {
        return DataService.post("/copilot/recommend", payload)
    },
    getSnapshotList: () => {
        return DataService.get("/copilot/snapshotlist")
    },
    // management service
    getManagementList: (type) => {
        return DataService.get("/copilotmanagement/list?type=" + type)
    },
    operateManagement: (operate, payload) => {
        return DataService.post("/copilotmanagement/" + operate, payload)
    },
    addManagement: (payload) => {
        return DataService.post("/copilotmanagement/add", payload)
    },
    editManagement: (payload) => {
        return DataService.post("/copilotmanagement/edit", payload)
    },
    deleteManagement: (payload) => {
        return DataService.post("/copilotmanagement/delete", payload)
    },
    // snapshot service
    publishSnapshot: (payload) => {
        return DataService.post("/copilotmanagement/publish", payload)
    },
    getScenarioList: () => {
        return DataService.get("/copilotmanagement/scenariolist")
    },
    getKnowledgeTypeList: () => {
        return DataService.get("/copilotmanagement/knowledgetypelist")
    },
    getSnapshot: (id) => {
        return DataService.get("/copilotmanagement/snapshot?id=" + id)
    },
}

export default CopilotService