import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faNetworkWired, faFlag, faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { replaceDate, getStandardizedUrl } from '../../Functions/UrlHelper';
import Chart from '../Chart';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { QualityAlertModal, GoalLineModal } from './ServiceQualityAlertModal';
import { ServiceMetricsDateRangePicker } from './ServiceMetricsDatepicker';
import DataService from '../../Dataservices/DataService';
import { addDaysToUTCDate } from '../../Functions/DateTimeHelper';
import { DashboardLoadingOverlay } from '../PublicComponents/HelperComponents';
import { transformLatencyDataToDate, fillInDates } from '../../Functions/ChartHelpers';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faBell, faNetworkWired, faFlag, faShareSquare);

class ServiceMetricsChart extends Component {
    constructor(props) {
        super(props)

        this.state = {
            StartDate: props.startDate?props.startDate:addDaysToUTCDate(new Date(), -30).setHours(0,0,0,0),
            EndDate: props.endDate?props.endDate:addDaysToUTCDate(new Date(), 0).setHours(0,0,0,0),
            showQualityAlertModal: false,
            showGoalLineModal: false,
            data: {
                title: "",
                series: [],
                samples: [],
            },
            timezone: "UTC",
            updatedTime: new Date().getTime(),
            isLoading: false,
            chart: props.chart,
            url: props.chart.url,
            breakdown: false,
            minY: 0,
            error: ''
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) { 
        if(nextProps.startDate !== prevState.StartDate || nextProps.endDate !== prevState.EndDate) {
            return {
                StartDate: nextProps.startDate,
                EndDate: nextProps.endDate
            }
        }
        return null
    } 

    componentDidUpdate(prevProps) {
        if(prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
            this.drawChart(this.state.breakdown, this.state.StartDate, this.state.EndDate);
        }
    }

    componentDidMount() {
        this.drawChart(this.state.breakdown, this.state.StartDate, this.state.EndDate);
    }

    selectDate = (datepicker) => {
        this.setState({
            StartDate: datepicker.hourlyStartDate, 
            EndDate: datepicker.hourlyEndDate
        });
        this.drawChart(this.state.breakdown, datepicker.hourlyStartDate, datepicker.hourlyEndDate);
    }

    drawChart = (breakdown, StartDate, EndDate) => {
        const { chart } = this.state

        let url = breakdown?chart.breakdownUrl:chart.url;
        this.setState({isLoading: true})

        DataService.post(chart.baseQuery?chart.baseQuery:url, {
            StartDate: StartDate,
            EndDate: EndDate,
            Url: getStandardizedUrl(url),
            IsHourly: false
        }).then(res => {
            if(res.data.IsSuccess) {
                let formatSeries = (series) => {
                    if(!series.Name)
                    {
                        series.Name = "(blank)";
                    }
                    if(!breakdown && series.Name != "All-Up"){
                        series.Hide = true;
                    }
                    return series;
                }
    
                res.data.AdditionalLatencyDataSeries?.map(formatSeries)
                res.data.LatencyDataSeries?.map(formatSeries)
                res.data.SampleCountDataSeries?.map(formatSeries)
    
                const data = {
                    title: this.props.title,
                    series: fillInDates(transformLatencyDataToDate(this.props.isLatency?res.data.LatencyDataSeries:res.data.AdditionalLatencyDataSeries), false, true, true),
                    samples: fillInDates(transformLatencyDataToDate(res.data.SampleCountDataSeries), false, true, true)
                }
                const minY = data.series.reduce((p, d) => (d.Data.reduce((pp, point) => ( point.y !== null?Math.min(pp, point.y):pp ), p)), 1e8)
                const maxY = data.series.reduce((p, d) => (d.Data.reduce((pp, point) => ( point.y !== null?Math.max(pp, point.y):pp ), p)), 0)
    
                this.setState({
                    data: data,
                    updatedTime: new Date().getTime(),
                    isLoading: false,
                    minY: Math.max(minY - (maxY - minY)/10, 0)
                })
            }else {
                this.setState({ isLoading: false,})
                this.setError(res.data.ErrorMessage)
            }
        }).catch(error => {
            this.setState({
                isLoading: false,
            })
            this.setError(error.message)
        });
    }

    onToggleQualityAlertModal = () => { this.setState(prevState => ({ showQualityAlertModal: !prevState.showQualityAlertModal })) }

    onToggleGoalLineModal = () => { this.setState(prevState => ({ showGoalLineModal: !prevState.showGoalLineModal })) }

    setError = (error) => { this.setState({error: error}); }

    onChangeToBreakdown = () => { 
        const { breakdown, StartDate, EndDate } = this.state
        this.setState({ breakdown : !breakdown})
        this.drawChart(!breakdown, StartDate, EndDate) 
    }

    openUrl = () => {
        const { breakdown, chart, openUrl } = this.props
        const { StartDate, EndDate } = this.state
        let url = breakdown?chart.breakdownUrl:chart.url
        if(chart.baseQuery)         {
            url = replaceDate(url, "sd", StartDate, false)
            url = replaceDate(url, "ed", EndDate, false)
        }
        window.open(openUrl?openUrl:url)
    }

    render() {
        const { showQualityAlertModal, showGoalLineModal, data, timezone, updatedTime, isLoading, minY, error, StartDate, EndDate } = this.state
        const { canOpenUrl, tab } = this.props

        const containerStyle = (isLoading || error) ? { height: '450px' } : {height: '100%'}
        const chartStyle ={ height: '425px', overflowY: 'auto' }
        return (
            <div style={containerStyle}>
                {error && <Alert variant='danger' className="dashboardAlert">{error}</Alert>}    
                {isLoading ? <DashboardLoadingOverlay/> : 
                <React.Fragment>
                    <ButtonToolbar className="chartToolbar">
                        <ButtonGroup aria-label="First group">
                            {canOpenUrl && 
                                <OverlayTrigger key={`overlay-details`} placement="top" overlay={ <Tooltip id={`tooltip-details`}> Details </Tooltip>}>
                                    <Button aria-label="View chart in new page" variant="light" onClick={this.openUrl}><FontAwesomeIcon icon={faShareSquare} /></Button> 
                                </OverlayTrigger>
                            }
                            <OverlayTrigger key={`overlay-breakdown`} placement="top" overlay={ <Tooltip id={`tooltip-breakdown`}> Breakdown </Tooltip>}>
                                <Button aria-label="Breakdown" variant="light" onClick={this.onChangeToBreakdown}> <FontAwesomeIcon icon={faNetworkWired} /> </Button>
                            </OverlayTrigger>
                            <OverlayTrigger key={`overlay-goallines`} placement="top" overlay={ <Tooltip id={`tooltip-goallines`}> Goalline </Tooltip>}>
                                <span> <Button style={{ pointerEvents: 'none' }} aria-label="Edit goallines" variant="light" disabled onClick={this.onToggleGoalLineModal}> <FontAwesomeIcon icon={faFlag} /> </Button> </span>
                            </OverlayTrigger>
                            <OverlayTrigger key={`overlay-alerts`} placement="top" overlay={ <Tooltip id={`tooltip-alerts`}> Alerts </Tooltip>}>
                                <span> <Button style={{ pointerEvents: 'none' }} aria-label="Edit alerts" variant="light" disabled onClick={this.onToggleQualityAlertModal}> <FontAwesomeIcon icon={faBell} /> </Button> </span>
                            </OverlayTrigger>
                            <OverlayTrigger key={`overlay-calendar`} placement="top" overlay={<Tooltip id={`tooltip-calendar`}> Calendar </Tooltip>}>
                                    <span><ServiceMetricsDateRangePicker startDate={StartDate} endDate={EndDate} selectDate={this.selectDate} /></span>
                            </OverlayTrigger>
                        </ButtonGroup>
                    </ButtonToolbar>

                    <div style={chartStyle}>
                        {(data.series && data.series.length > 0) && <Chart key={data.title}
                            chartInfo={{
                                includeWeekends: "true"
                            }}
                            options={{
                                serverData: data.series,
                                title: data.title,
                                chartType: 'Line',
                                xAxisType: 'datetime',
                                loading: false
                            }}
                            isHourly={false}
                            timezone={timezone}
                            isInDashboard={true}
                            updatedTime={updatedTime}
                            minY={minY}
                            tab={tab}
                            disableAnnotation={true}/>}
                        {(data.samples && data.samples.length > 0) && <Chart key={data.title + "samples"}
                            chartInfo={{
                                includeWeekends: "true"
                            }}
                            options={{
                                serverData: data.samples,
                                title: "Samples",
                                chartType: 'Line',
                                xAxisType: 'datetime',
                                loading: false
                            }}
                            isHourly={false}
                            timezone={timezone}
                            isInDashboard={true}
                            updatedTime={updatedTime}
                            tab={"samples"}
                            disableAnnotation={true}/>}
                    </div>

                    <QualityAlertModal 
                        showModal={showQualityAlertModal} 
                        toggleModal={this.onToggleQualityAlertModal}
                    />
                    <GoalLineModal 
                        showModal={showGoalLineModal}
                        toggleModal={this.onToggleGoalLineModal}
                    />
                </React.Fragment>}
            </div>
        )
    }
}

export default withRouter(ServiceMetricsChart)