import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import React, { Component } from 'react';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import DataService from '../../Dataservices/DataService';
import ChartService from '../../Dataservices/ChartService';
import { convertStringToUTCDate, addDaysToUTCDate } from '../../Functions/DateTimeHelper';
import Alert from 'react-bootstrap/Alert';
import "./Insights.css";
import ReactDatePicker from '../ReactDatepicker';

class InsightsModal extends Component {
    constructor(props) {
        super(props);


        this.state = {
            insightsDate: new Date(),
            selectCheckpoint: false,
            selectMetrics: false,
            checkpoint: '',
            dailyMetrics : ''
        }
    }

    componentDidMount() {
        if(this.props.cubeName !== "perfPanelPerf") {
            ChartService.postMaxDate(this.props.cubeName, null, null).then(res => {
                var maxDate = convertStringToUTCDate(JSON.parse(res.data).MaxDateNormalized)
                this.setState({ insightsDate: maxDate })
            }).catch(err => {
                console.error(err);
                this.setState({ insightsDate: addDaysToUTCDate(new Date(), -2) })
            })
        }
    }

    setDate = (e) => this.setState({ insightsDate: e })

    onselectMetricsChange = (e) => {
        let { dailyMetrics } = this.state;

        this.setState({
            selectMetrics: e.target.checked,
            dailyMetrics: e.target.checked === true ? dailyMetrics : '' 
        })
    }

    onselectCheckpointChange = (e) => {
        let { checkpoint } = this.state;

        this.setState({
            selectCheckpoint: e.target.checked,
            checkpoint: e.target.checked === true ? checkpoint : '' 
        })
    }

    setCheckpointValue = (e) => this.setState({ checkpoint: e ? e.value : '' })

    setDailyMetricsValue = (e) => this.setState({ dailyMetrics: e ? e.value : '' })

    handleAnalyzeButton = (e) => {
        e.preventDefault();

        const { checkpoint, dailyMetrics } = this.state;

        var chartInfo = {
            StartDate: this.state.insightsDate,
            EndDate: this.state.insightsDate,
            Url: window.location.href
        };

        if (this.props.tab === "checkpoint") {
            chartInfo.Legend = checkpoint
        }

        if (this.props.tab === "dailymetrics") {
            chartInfo.Legend = dailyMetrics
        }

        if (this.props.tab === "daily") {
            chartInfo.Legend = "All-Up"
        }

        DataService.post(this.props.cubeName + "/chartIdforUrl", chartInfo).then(res => {
            window.open(window.location.origin + "/Insights?chartID=" + res.data, "_blank");
            this.props.hideInsightsModal();
        }).catch(err => {
            console.log(err);
            this.setState({error: "Hit an issue getting Insights for this chart"});
        });
    }

    render() {
        let { hasError, showInsightsModal, hideInsightsModal, tab, checkpointList, metricsList } = this.props;
        let { selectCheckpoint, selectMetrics } = this.state

        return (
            <Modal show={showInsightsModal} onHide={hideInsightsModal}>
                <Modal.Header closeButton>
                    Get Insights
                </Modal.Header>

                <Modal.Body>
                    {this.state.error && <Alert variant='danger'>
                        {this.state.error}
                    </Alert>}
                    {hasError && <div>Multiple trend lines detected. Please make sure all pivots are rolled up and single percentile is provided</div>}
                    {!hasError && <Form.Group>
                        <div className="expertInsightsDatepicker">
                            Choose a date&emsp;
                            <ReactDatePicker
                                value={this.state.insightsDate}
                                onChange={date => this.setDate(date)}
                                className="gray-border medium-width"
                            />
                        </div>
                    </Form.Group>}
                    {!hasError && tab === "dailymetrics" && <Form.Group>
                        <Form.Check type="checkbox" label="Specify a metrics value for RCA" checked={selectMetrics} onChange={this.onselectMetricsChange} />
                    </Form.Group>}
                    {selectMetrics && <Form.Group>
                        <div className="expertInsightsMetricspicker">
                            Choose a Metrics
                            <Select
                                name="dailyMetrics"
                                options={metricsList}
                                value={metricsList.find(item => item.value === this.state.dailyMetrics)}
                                onChange={this.setDailyMetricsValue}
                            />
                        </div>
                    </Form.Group>}
                    {!hasError && tab === "checkpoint" && <Form.Group>
                        <Form.Check type="checkbox" label="Specify a checkpoint value for RCA" checked={selectCheckpoint} onChange={this.onselectCheckpointChange} />
                     </Form.Group>}
                    {selectCheckpoint && <Form.Group>
                        <div className="expertInsightsCheckpointpicker">
                            Choose a Checkpoint
                            <Select
                                name="checkpoint"
                                options={checkpointList}
                                value={checkpointList.find(item => item.value === this.state.checkpoint)}
                                onChange={this.setCheckpointValue}
                            />
                        </div>
                    </Form.Group>}
                </Modal.Body>               
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideInsightsModal}>Close</Button>
                    {!hasError && <Button variant="primary" onClick={this.handleAnalyzeButton}>Analyze</Button>}
                </Modal.Footer>

            </Modal>
        )
    }
}

export default InsightsModal;