import DataService from './DataService'

const MSRService = {
    getMSRList: () => {
        return DataService.get('MSR/list')
    },
    getMSRInfo: (id) => {
        return DataService.get('MSR/getMSR?MSRId=' + id)
    },
    editScenario: payload => {
        return DataService.post('MSR/editScenario', payload)
    },
    reorderScenario: payload => {
        return DataService.post('MSR/reorderScenarios', payload)
    },
    deleteScenario: payload => {
        return DataService.post('MSR/editScenario', payload)
    },
    addScenario: payload => {
        return DataService.post('MSR/addScenario', payload)
    },
    addMSR: payload => {
        return DataService.post('MSR/add', payload)
    },
    deleteMSR: payload => {
        return DataService.post('MSR/editMSR', payload)
    },
    editMSR: payload => {
        return DataService.post('MSR/editMSR', payload)
    },
    addMSRCallout: payload => {
        return DataService.post('MSR/addMSRCallout', payload)
    },
    editMSRCallout: payload => {
        return DataService.post('MSR/editMSRCallout', payload)
    },
    deleteMSRCallout: payload => {
        return DataService.post('MSR/editMSRCallout', payload)
    }
}

export default MSRService