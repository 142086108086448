import React, { useContext, useState, useEffect } from 'react'
import { Form, Accordion, Card, Col, Row, Container, useAccordionToggle, AccordionContext, OverlayTrigger, Popover } from 'react-bootstrap'
import PivotDropdownList from './PivotDropdownList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTape, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import './PivotBodyPane.css';
import { handleClick } from '../../Dataservices/EventTrackingService'
import { library } from "@fortawesome/fontawesome-svg-core";
import Tooltip from 'react-bootstrap/Tooltip'
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

library.add(faTimes, faQuestionCircle);

library.add(faTape, faCaretDown, faCaretUp );

function CustomToggle({ props, children, eventKey, groupName, callback }) {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
        () => {
            callback && callback(eventKey);
            props.handleClick(groupName, "pivotBodyPane", "analysis");
        },
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <button
            type="button"
        style={groupHeaderButtonStyle}
            onClick={decoratedOnClick}
            aria-expanded={isCurrentEventKey ? "true" : "false"}
      > <FontAwesomeIcon icon={isCurrentEventKey ? faCaretUp : faCaretDown }/>&ensp;
        {children}
      </button>
    );
  }

  
  function PivotRow (props) {
    const [pivotValueList, setPivotValueList] = useState([]);
    const [lightPivot, setLightPivot] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    let { defaultSelectedValues, defaultNotFlag, defaultRollUp } = props.getDefaultValues(props.pivot);

    useEffect(() => {
        let array = []
        if (props.pivots) {
            for (let key in props.pivots) {
                array = array.concat(props.pivots[key].map(e => {
                    return {
                        "label": (!e) ? "(blank)" : e, 
                        "value": e
                    }
                }))
            }
            setLightPivot(false);
        } else {
            setLightPivot(true);
        }
        setPivotValueList(array)
    }, [props.pivots])

    const tooltipContent = props.pivot.toolTip ? <>{props.pivot.pivotFriendlyName} : {props.pivot.toolTip} </>: <>Find more pivot meaning from our <a href="https://perfpanel.azurewebsites.net/Mapping">pivot mapping</a> page.</>;
    const renderTooltip = (props) => (
        <Tooltip  id={`tooltip-pivotmapping-content`} {...props} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
            {tooltipContent}
        </Tooltip>
    );
            
    return (
        <Row className="pivotSelectPivotRow">
            <Col xs={4} className="pivotLabels">
                <span>{props.pivot.pivotFriendlyName}</span>
                
                {
        <OverlayTrigger
            placement="top"
            overlay={renderTooltip}
            show={showTooltip}
            trigger="manual"
        >
        <FontAwesomeIcon
            tabIndex={0}
            icon={faQuestionCircle}
            className="pivotSelectionButton"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        />
    </OverlayTrigger>
                }
            </Col>
            <Col xs={7} className="pivotValues" onClick={() => { handleClick(props.pivot.pivotFriendlyName, "pivotBodyPane/" + props.groupName, "analysis") }}>
                <PivotDropdownList
                    pivotFriendlyName={props.pivot.pivotFriendlyName}
                    pivotName={props.pivot.pivotName}
                    cubeName={props.cubeName}
                    options={pivotValueList}
                    defaultValues={defaultSelectedValues}
                    onChange={e => props.onSelectChange(props.pivot.serverName, e)}
                    supportPrefix={props.pivot.selectionType !== "single" && props.supportPrefix}
                    isMulti={props.pivot.selectionType !== "single"}
                    enableSearch={props.pivot.enableSearch}
                    tooltip={props.pivot.toolTip}
                    addPivots={props.addPivots}
                    lightPivot={lightPivot}
                />
            </Col>
            { props.supportPrefix && <Col className="pivotCheckbox"><Form.Check aria-label="Exclude selected pivot values" type="checkbox" onChange={e => props.notChange(props.pivot.serverName, e.target.checked)} defaultChecked={defaultNotFlag} /></Col>}
            {
                props.pivot.rollUp &&
                <Col className="pivotCheckbox"><Form.Check type="checkbox" aria-label="Roll up selected pivot values" onChange={e => props.rollupChange(props.pivot.rollUp?.serverName, e.target.checked)} defaultChecked={defaultRollUp} /></Col>
            }
        </Row>
    );
}

export default function PivotBodyPane (props) {

    if (props.mapping && props.mapping.groupsList && props.urlDefaultParams) {
        return <Container style={{maxHeight: "68vh", overflowY: "scroll"}}>
                {props.mapping.groupsList.filter(group => group.groupName !== "None").map((group) => {
                    let anyValueHasRollup = group.pivots.some((pivot) => { return pivot.rollUp ? true : false });
                    return <Row xs={12} key={"pivotGroup" + group.groupName} style={{ marginTop: "10px" }}>
                        <Accordion defaultActiveKey="0" transition="false" style={{ width: "100%" }}>
                            <Card style={{ overflow: "visible" }} border="light">
                                <Card.Header style={groupHeaderStyle}>
                                <CustomToggle props={props} eventKey={group.isCollapsed ? "1" : "0"} groupName={group.groupName} >
                                    <span style={pivotSelectorHeader}>{group.groupName}</span>
                                </CustomToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey={group.isCollapsed ? "1" : "0"}>
                                    <Card.Body>
                                        <Row style={{ flexWrap: "noWrap", marginBottom: "10px" }}>
                                            <Col xs={4} className="pivotLabels"><strong>Pivot</strong></Col>
                                            <Col xs={7} className="pivotValues"><strong>Filter</strong></Col>
                                            {
                                                props.supportPrefix && 
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Popover id="popover-basic">
                                                            <Popover.Content>
                                                                If checked, values selected for this pivot will be excluded from the query.
                                                            </Popover.Content>
                                                        </Popover>
                                                }>
                                                    <Col className="pivotCheckbox">
                                                        <strong>!</strong>
                                                    </Col>
                                                </OverlayTrigger>
                                            }
                                            {
                                                anyValueHasRollup &&
                                                <Col className="pivotCheckbox">  
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Popover id="popover-basic">
                                                            <Popover.Content>
                                                                Unroll this pivot. Each individual value will display a separate line on the chart.
                                                            </Popover.Content>
                                                        </Popover>}
                                                >
                                                    <FontAwesomeIcon icon={faTape}/>
                                                </OverlayTrigger>
                                                </Col>
                                            }
                                        </Row>
                                        {
                                            group.pivots.map((pivot) => 
                                                <PivotRow 
                                                    pivot={pivot}
                                                    key={pivot.pivotName}
                                                    pivots={props.pivots ? props.pivots[pivot.pivotName] : null}
                                                    cubeName={props.cubeName}
                                                    supportPrefix={props.supportPrefix}
                                                    getDefaultValues={props.getDefaultValues}
                                                    onSelectChange={props.onSelectChange}
                                                    notChange={props.notChange}
                                                    rollupChange={props.rollupChange}
                                                    addPivots={props.addPivots}
                                                    groupName={group.groupName}
                                                />)
                                        }
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Row>
                })}
                </Container>
        };

    return (<Row></Row>);
}


const groupHeaderStyle = {
    background: "white",
    borderStyle: "none",
    borderBottom: "1px solid #e5e5e5"
}
const groupHeaderButtonStyle = {
    background: "white",
    borderStyle: "none",
    color: "#337ab7"
}
const pivotSelectorHeader = {
    fontSize: "1.3em",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "10px",
    color: "#337ab7"
}