import DataService from './DataService'

const ApplicationPageService = {
    getTranslation: () => {
        return DataService.get('Application/gettranslations')
    },
    saveTranslation: payload => {
        return DataService.post('Application/savetranslations', payload)
    }
}

export default ApplicationPageService