import DataService from './DataService'

const AnnotationDataService = {

    postAnnotationsForChart: (postAnnotationRequest) => {
        return DataService.post('/Annotations/AnnotationsForChart', postAnnotationRequest)
    },

    postAddAnnotationsForChart: (postAddAnnotationRequest) => {
        return DataService.post('/Annotations/add', postAddAnnotationRequest)
    },

    postEditAnnotationsForChart: (postEditAnnotationRequest) => {
        return DataService.post('/Annotations/edit', postEditAnnotationRequest)
    },

    getAnnotationList: () => {
        return DataService.get('/Annotations/Annotations?dateToQuery=none')
    }
}

export default AnnotationDataService