import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import DataService from '../../Dataservices/DataService';
import './GetRawLogs.css';
import { formatBrowserTZDateAsString } from '../../Functions/DateTimeHelper';
import ReactDatePicker from '../ReactDatepicker';

class GetRawLogs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            logList: {},
            rowNumber: 1000,
            description: "GetRawLogs",
            logListLoaded: false,
            submitDisabled: false,
            defaultDate: new Date(),
            rawLogsDate: new Date(),
            alertShow: false,
            alertMessage: "danger"
        }
    }

    // receive props
    // this function will be called when props change
    // props: current props
    // state: current state
    // return value: new state values of this.state
    static getDerivedStateFromProps(props, state) {
        if (props.defaultDate || props.logList) {
            let updateObj = {};

            if(props.defaultDate) {
                let newDefaultDate = new Date(props.defaultDate);
                if(newDefaultDate.getTime() !== state.defaultDate.getTime()) {
                    updateObj.defaultDate = newDefaultDate;
                    updateObj.rawLogsDate = newDefaultDate;
                }
            }

            if(props.logList && !state.logListLoaded) {
                let logList = {};
                props.logList.forEach(log => {
                    logList[log.name] = log.defaultVal;
                    return log;
                });
                updateObj.logList = logList;
                updateObj.logListLoaded = true;
            }

            return updateObj;
        } else {
            return null;
        }
    }

    setDate = (e) => {
        this.setState({rawLogsDate: e})
    }

    handleRowNumberChange = (e) => this.setState({rowNumber: e.target.value});
    handleDescriptionChange = (e) => this.setState({description: e.target.value});

    handleSubmitRawLogs = (e) => {
        e.preventDefault();
        this.setState({
            alertShow: false
        });

        // todo: add validation on the submit button as well
        let date = formatBrowserTZDateAsString(this.state.rawLogsDate, this.props.isHourly);

        var request = {};
        request.Description = this.state.description;
        request.Request = this.props.request;
        request.ExtraLogsChosen = this.state.logList;
        request.Cube = this.props.cubeName;
        request.Url = this.props.url;
        request.Request.StartDate = date;
        request.Request.EndDate = date;
        request.Request.TopN = this.state.rowNumber.toString();
       
        request.Request.Tab = "daily";
        request.Request.AppendPercentile = "True";
        request.Request.IncludeWeekends = "true";
        request.Request.IsWithCheckpoint = "false";
        request.Request.IsWithMetric = "false";
        request.Request.ChartType = "latency";
        request.Request.getRawLogs = true;

        if(request.Description === "")
        {
            request.Description = "GetRawLogs";
        }

        if(request.Request.TopN < 1)
        {
            this.setState({
                alertShow: true,
                alertMessage: "The number of rows must be greater than 0."
            })
            return;
        }

        this.setState({ submitDisabled: true });

        DataService.post(this.props.cubeName + "/rawDataQuery", request).then(res => {
            this.onSubmit();
            this.setState({ submitDisabled: false });
        }).catch(err => {
            this.setState({
                submitDisabled: false,
                alertShow: true,
                alertMessage: err.response.data
            });
        });
    }

    onSubmit = () => {
        window.open("/RawLogQueryStatus", "_blank");
        this.props.hideGetRawLogs();
    }

    handleLogListChange = (e) => {
        let logList = this.state.logList;
        logList[e.target.id]= e.target.checked;
        this.setState({logList: logList});
    }

    render() {
        let { showGetRawLogs, hideGetRawLogs, url, logList, isHourly } = this.props;
        return(
            <Modal className="getRawLogBox" show={showGetRawLogs} onHide={hideGetRawLogs}>
                {this.state.alertShow && <Alert variant='danger' onClose={() => this.setState({alertShow: false})} dismissible>
                    {this.state.alertMessage}
                </Alert>}
                <Form onSubmit={this.handleSubmitRawLogs}>
                    <Modal.Header closeButton>
                        <Modal.Title>Get Raw Logs</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group as={Row}>
                            <Col sm="4">
                                <Form.Label>Url</Form.Label>
                            </Col>
                            <Col sm="8">
                                <p style={{wordBreak: 'break-all',whiteSpace: 'normal'}}>{url}</p>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm="4">
                                <Form.Label>Date (UTC) </Form.Label>
                            </Col>
                            <Col sm="8">
                                <ReactDatePicker                                  
                                    value={this.state.rawLogsDate}
                                    showTime={isHourly}
                                    onChange={date => this.setDate(date)}
                                />                            
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="rawLogsForm.description">
                            <Col sm="4">
                                <Form.Label>Job Description</Form.Label>
                            </Col>
                            <Col sm="8">
                                <Form.Control type="text" onChange={this.handleDescriptionChange} defaultValue="GetRawLogs"></Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="rawLogsForm.rowNumber">
                            <Col sm="4">
                                <Form.Label>Number of Rows</Form.Label>
                            </Col>
                            <Col sm="8">
                                <Form.Control type="number" defaultValue={this.state.rowNumber} onChange={this.handleRowNumberChange}></Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="rawLogsForm.logs">
                            <Col sm="4">
                                <Form.Label>Choose Logs</Form.Label>
                            </Col>
                            <Col sm="8">
                                {logList && logList.map(log => (
                                    <Form.Check type="checkbox" name="extraLogList" key={log.name} onChange={this.handleLogListChange} id={log.name} label={log.friendlyName} defaultChecked={log.defaultVal} />
                                ))} 
                            </Col>
                        </Form.Group>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={hideGetRawLogs}>Close</Button>
                        <Button variant="primary" type="submit" disabled={this.state.submitDisabled}>{this.state.submitDisabled?'Request Pending...':'Submit'}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }
}

export default GetRawLogs;