
export const getOtherPivotNameAlias = (pivot) => {
    if (!pivot) return ['', ''];
    const nameAliasDelimiter = ' AS ';
    if (pivot.indexOf(nameAliasDelimiter) === -1) return [pivot, pivot];

    let splitted = pivot.split(nameAliasDelimiter);

    if (splitted.length === 1) {
        if (pivot.startsWith(nameAliasDelimiter)) {
            return ['', splitted[0]];
        } else {
            return [splitted[0], ''];
        }
    }

    return splitted;
};
