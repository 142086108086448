import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'  
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faCaretDown, faCaretUp );  

export var SortOrderEnum = {
    Ascending: 'Ascending',
    Descending: 'Descending',
    None: 'None'
}

export const ellipsis = 0;

export function filterObject(obj, searchText, keys)  {
    if (!searchText) return true

    let fields = keys
    if (!keys || keys.length === 0) {
        fields = Object.keys(obj)
    }

    return fields.some(key => {
        let value = obj[key]
        if (value === null || value === undefined) {
            return false
        }

        let valueType = typeof(value)
        if (valueType === "object") {
            if (value instanceof Array) {
                return value.some(item => {
                    return filterObject(item, searchText, [])
                })
            }
            return filterObject(value, searchText, [])
        }

        let string_value = String(value).toLowerCase()
        return string_value.indexOf(searchText.toLowerCase()) !== -1
    })
}

export function filterObjectWithArray(obj, filters, keys) {
    if (!filters) return true

    let fields = keys
    if (!keys || keys.length === 0) {
        fields = Object.keys(obj)
    }

    return fields.some(key => {
        let value = obj[key]
        if (value === null || value === undefined) {
            return false
        }

        return filters.some(filter => filter === value)
    })
}

export function compareString(str1, str2, isAscending, ignoreCase) {
    if (!str1) {
        str1 = ''
    }

    if (!str2) {
        str2 = ''
    }

    if (ignoreCase) {
        str1 = str1.toLowerCase()
        str2 = str2.toLowerCase()
    }

    if (str1 < str2) {
        return isAscending ? -1 : 1
    } else if (str1 > str2) {
        return isAscending ? 1 : -1
    } else {
        return 0
    }
}

export function arrayEquals(arr1, arr2) {
    if (arr1 === arr2) return true
    if (arr1 == null || arr2 == null) return false
    if (arr1.length !== arr2.length) return false

    for (let i = 0; i < arr1.length; ++i) {
        if (arr1[i] !== arr2[i]) return false
    }
    return true
}

export function getNextSortOrder(prevOrder, isNewKey, isDoubleSwitch) {
    if (isNewKey) return SortOrderEnum.Ascending

    switch (prevOrder) {
        case SortOrderEnum.None:
            return SortOrderEnum.Ascending
        case SortOrderEnum.Ascending:
            return SortOrderEnum.Descending
        case SortOrderEnum.Descending:
            return isDoubleSwitch ? SortOrderEnum.Ascending : SortOrderEnum.None
        default:
            return SortOrderEnum.None
    }
}

export function renderSortMark(sortKey, sortOrder, tableKey) {
    if (sortKey === tableKey && sortOrder === SortOrderEnum.Ascending) {
        return <span><FontAwesomeIcon icon={faCaretUp}/>&ensp;</span>
    } else if (sortKey === tableKey && sortOrder === SortOrderEnum.Descending) {
        return <span><FontAwesomeIcon icon={faCaretDown}/>&ensp;</span>
    } else {
        return null
    }
}

export function getCurrentPageList(list, num) {
    return list.slice((num - 1) * 15, Math.min(list.length, num * 15))
}

export function getPageNumbersToDisplay(numClicked, numTotalPages, pagesToDisplay = 24)
{
    //Always make the number of displayed pages odd regardless of what's passed for symmetry
    //Displayed pages limit needs to be at least 5 (first, last, 2 ellipses, currently clicked page)
    let numPagesToDisplay = pagesToDisplay > 5 ? pagesToDisplay : 5;
    numPagesToDisplay = numPagesToDisplay % 2 > 0 ? numPagesToDisplay : numPagesToDisplay + 1;
    let halfPagesToDisplay = Math.ceil(numPagesToDisplay/2);

    if (numClicked > numTotalPages) {
        numClicked = 1;
    }

    let pages = [1];

    if (numTotalPages <= numPagesToDisplay) {
        for (let i = 2; i <= numTotalPages; i++) {
            pages.push(i);
        }
    } else if (numClicked <= halfPagesToDisplay) {
        for (let i = 2; i < numPagesToDisplay - 1; i++) {
            pages.push(i);
        }
        pages.push(ellipsis);
        pages.push(numTotalPages);
    } else if (numClicked > halfPagesToDisplay && numClicked + halfPagesToDisplay > numTotalPages) {
        pages.push(ellipsis)
        for (let i = numTotalPages - numPagesToDisplay + 3; i <= numTotalPages; i++) {
            pages.push(i)
        }
    } else if (numClicked > halfPagesToDisplay && numClicked + halfPagesToDisplay <= numTotalPages) {
        pages.push(ellipsis)
        let halfPageRange = (numPagesToDisplay - 5)/2;
        for (let i = numClicked - halfPageRange; i <= numClicked + halfPageRange; i++) {
            pages.push(i)
        }
        pages.push(ellipsis)
        pages.push(numTotalPages)
    }

    return pages
}

function addThousandSeparator(num) {
    let part = String(num).split('.');

    let str = '';
    let count = 0;
    for(let i = part[0].length - 1; i >= 0; i--) {
        if(count === 3) {
            str = ',' + str;
            count = 0;
        }

        str = part[0][i] + str;
        count++;
    }

    return part.length === 2 ? str + '.' + part[1] : str;
}

export function flattenPercentiles(percentiles){
    let results = [];
    for(let i =0; i < percentiles.length; i++) {
         let key = percentiles[i];
         var stepDelimiterIndex = key.indexOf(":")
         var rangeDelimiterIndex = key.indexOf("-")
         if(stepDelimiterIndex >0 && rangeDelimiterIndex >0) {
            var rangeStart = parseFloat(key.substring(0, rangeDelimiterIndex))
            var rangeEnd = parseFloat(key.substring(rangeDelimiterIndex+1, stepDelimiterIndex))
            var stepInc = parseFloat(key.substring(stepDelimiterIndex + 1))
            if (!isNaN(rangeStart) && !isNaN(rangeEnd) && !isNaN(stepInc))
            {
                var percentile = rangeStart;
                results.push(percentile.toFixed(2).toString());
                while (rangeEnd > percentile)
                {
                    percentile = percentile + stepInc;
                    results.push(percentile.toFixed(2).toString());
                }
            }
         }
         else {
             results.push(key);
         }
    }

    let uniqueArr = [...new Set(results)];
    return uniqueArr
}

export function getPercentileTableData(data, percentiles) {

    var latencyDataSeries = data.LatencyDataSeries
    var sampleCountDataSeries = data.SampleCountDataSeries

    var table = latencyDataSeries.map(row => {
        var sample = sampleCountDataSeries.filter(sam => sam.Name === row.Name)[0]
        let latency = []
        for(let i in percentiles) {
            let key = percentiles[i]
            let result = row.Data.filter(col => col.name === key);
            if(result.length > 0 && result[0].y != null) {
                latency.push(addThousandSeparator(result[0].y.toFixed(2)))
            } else {
                latency.push("")
            }
        }
        return {
            legend: row.Name,
            latency: latency,
            samples: sample.Data.map(data => addThousandSeparator(data.y))
        }
    })

    return table
}

export function compareObject(key, isAscending) {
    return (obj1, obj2) => {
        return compareString(obj1[key], obj2[key], isAscending)
    }
}

export function sortList (list, key, order) {
    if (!list) {
        return;
    }

    if (order === SortOrderEnum.None) {
        return list.sort(compareObject("DefaultKey", true))
    } else {
        return list.sort(compareObject(key, order === SortOrderEnum.Ascending))
    }
}