import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import MSRService from '../../Dataservices/MSRService'

class MSRCallOutModal extends Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState(props.currCalloutData);
        this.textareaRef = React.createRef();
      }
    
    getInitialState = (currCalloutData) => {
        if (currCalloutData !== undefined) {
            return {
                Owner: currCalloutData.Owner,
                MSRWeek: currCalloutData.MSRWeek,
                Callout: currCalloutData.Callout,
            };
        } else {
            return {
                Owner: '',
                MSRWeek: '',
                Callout: '',
            };
        }
    };
    
    componentDidUpdate(prevProps) {
        if (prevProps.currCalloutData !== this.props.currCalloutData) {
            this.setState(this.getInitialState(this.props.currCalloutData));
        }
    }

    onOwnerChange = e => { this.setState({ Owner: e.target.value }) }

    onMSRWeekChange = e => { this.setState({ MSRWeek: e.target.value }) }

    onCallOutChange = e => { this.setState({ Callout: e.target.value }, () => {
        this.textareaRef.current.style.height = 'auto';
        this.textareaRef.current.style.height = `${this.textareaRef.current.scrollHeight}px`;
      });
    }

    onCancel = () => {
        const { toggleModal } = this.props
        toggleModal()
    }

    onSave = () => {
        const { Scenario, toggleModal, setError, currCalloutData, onSave } = this.props
        const { Owner, MSRWeek, Callout } = this.state

        toggleModal()

        const payload = {
            Owner: Owner,
            MSRWeek: MSRWeek,
            Callout: Callout
        }

        if (currCalloutData === undefined) {
            payload.ScenarioId = Scenario.id
            MSRService.addMSRCallout(payload).then(res => {
                console.log(res)
                this.setState({
                    ScenarioId: '',
                    Owner: '',
                    MSRWeek: '',
                    Callout: ''
                })
            }).catch(err => {
                console.error(err);
                setError(err.message);
            })
        } else {
            payload.Id = currCalloutData.Id
            payload.ScenarioId = currCalloutData.ScenarioId
            onSave(payload)
            MSRService.editMSRCallout(payload).then().catch(err => {
                console.error(err);
                setError(err.message);
            })
        }
    }

    onDelete = MSRCallOutId => {
        const { toggleModal, onDelete } = this.props
        toggleModal()
        onDelete(MSRCallOutId)
    }

    render () {
        const { showModalorNot } = this.props
        const { Owner, MSRWeek, Callout } = this.state
        return (
            <Modal show={showModalorNot} onHide={this.onCancel}>
                <Modal.Header>
                    <Modal.Title>CallOut for Scenario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} controlId="title">
                            <Form.Label column sm="4">Scenario Owner</Form.Label>
                            <Col sm="7">
                                <Form.Control type="text" value={Owner} onChange={this.onOwnerChange}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="title">
                            <Form.Label column sm="4">MSR Week</Form.Label>
                                <Col sm="7">
                                    <Form.Control type="text" value={MSRWeek} onChange={this.onMSRWeekChange} />
                                </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="title">
                            <Form.Label column sm="4">Call Out</Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    as="textarea"
                                    value={Callout}
                                    onChange={this.onCallOutChange}
                                    ref={this.textareaRef}
                                    style={{ overflow: 'hidden' }}
                                />
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {this.props.currCalloutData !== undefined && <div className="alertClearBtn">
                        <Button variant="danger" onClick={this.onDelete.bind(this, this.props.currCalloutData.Id)}>Delete</Button>
                    </div>}
                    <Button variant="primary" id="saveBtn" onClick={this.onSave}>
                        Save
                    </Button>
                    <Button variant="warning" id="cancelBtn" onClick={this.onCancel}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default MSRCallOutModal