import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import uuid from 'react-uuid';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { OverallItem } from './ServiceQualityPage';
import Chart from '../Chart';
import PerfPanelStatsService from '../../Dataservices/PerfPanelStatsService';
import { convertStringToUTCDate, addDaysToUTCDate, formatUTCDateAsString } from '../../Functions/DateTimeHelper';
import './ServiceMetricsPage.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import ReactDatePicker from '../ReactDatepicker';

library.add(faPen);

class UsagePage extends Component {

    constructor(props) {
        super(props)

        let startDate = addDaysToUTCDate(new Date(), -63);
        let endDate = addDaysToUTCDate(new Date(), -1);

        let params = new URLSearchParams(this.props.location.search);
        const paramEndDate = params.get('endDate');
        if(paramEndDate) {
            endDate = convertStringToUTCDate(paramEndDate);
            startDate = addDaysToUTCDate(endDate, -63);
        }

        this.state = {
            startDate: startDate,
            endDate: endDate,
            data: [],
            isLoading: false,
            MAU: { value: "", trend: "" },
            WAU: { value: "", trend: "" },
            DAU: { value: "", trend: "" },
        }
    }

    componentDidMount() {
        this.getUsage();
    }

    setEndDate = (date) => {
        this.setState({endDate: date});
        let params = new URLSearchParams(this.props.location.search);
        params.set('endDate', formatUTCDateAsString(date));
        this.props.history.push(window.location.pathname + "?" + params.toString());
    }

    getUsage = () => {
        this.setState({
            isLoading: true
        })

        PerfPanelStatsService.getUsage({
            StartDate: this.state.startDate,
            EndDate: this.state.endDate
        }).then(res => {
            const usage = res.data.map(d => (d? {
                title: d.type,
                series: [{
                    Data: d.dataSet.map(point => ({
                        date: point.date,
                        name: point.date,
                        build: point.build,
                        y: point.numUsers,
                        x: convertStringToUTCDate(point.sortDate)
                    })), 
                    Description: "",
                    ID: "0",
                    LinkedTo: "-1",
                    Name: d.type,
                }]
            }: null))
            let MAU = { name: "MAU", value: "", trend: "" }
            let filres = res.data.filter(x => (x && x.type == "MAU"))
            if(filres.length > 0 && filres[0].dataSet.length > 2) {
                filres = filres[0].dataSet
                MAU = { name: "MAU", value: filres[filres.length-2].numUsers, trend: filres[filres.length-2].numUsers - filres[filres.length-3].numUsers }
            }
            let WAU = { name: "WAU", value: "", trend: "" }
            filres = res.data.filter(x => (x && x.type == "WAU"))
            if(filres.length > 0 && filres[0].dataSet.length > 2) {
                filres = filres[0].dataSet
                WAU = { name: "WAU", value: filres[filres.length-2].numUsers, trend: filres[filres.length-2].numUsers - filres[filres.length-3].numUsers }
            }
            let DAU = { name: "DAU", value: "", trend: "" }
            filres = res.data.filter(x => (x && x.type == "DAU"))
            if(filres.length > 0 && filres[0].dataSet.length > 2) {
                filres = filres[0].dataSet
                DAU = { name: "DAU", value: filres[filres.length-2].numUsers, trend: filres[filres.length-2].numUsers - filres[filres.length-3].numUsers }
            }
            this.setState({
                data: usage,
                isLoading: false,
                MAU: MAU,
                WAU: WAU,
                DAU: DAU,
            })
        })
        document.getElementById('usage-graph-it').innerHTML = "Graph it invoked, charts " + (this.state.isLoading ? "loading" : "loaded");
    }

    render(){
        const {startDate, endDate, isLoading, data, WAU, MAU, DAU} = this.state

        return (
            <React.Fragment>
                <Row className="usageHeader">
                    <OverallItem 
                        key="DAU"
                        overall={DAU}
                        increaseGreen={true}
                        unit=""/>
                    <OverallItem 
                        key="WAU"
                        overall={WAU}
                        increaseGreen={true}
                        unit=""/>
                    <OverallItem 
                        key="MAU"
                        overall={MAU}
                        increaseGreen={true}
                        unit=""/>
                </Row>

                <Row className="usageHeader">
                    <Col xs={1} className="usageHeaderLabel"><strong >Start</strong></Col>
                    <Col xs={1} className="usageItem">
                    <ReactDatePicker
                        value={startDate}
                        onChange={date => this.setState({ startDate: date })}
                    /></Col>
                    <Col xs={1} className="usageHeaderLabel"><strong>End</strong></Col>
                    <Col xs={1} className="usageItem">
                    <ReactDatePicker                    
                        style={{ width: "100%", padding: "0px" }}
                        value={endDate}
                        onChange={date => this.setEndDate(date)}
                    /></Col>
                    <Col xs={2} className="usageItem"><Button variant="primary" onClick={this.getUsage}>Graph it&emsp;<FontAwesomeIcon icon={faPen} /></Button></Col>
                    <div id="usage-graph-it" role="status" class="sr-only"></div>
                </Row>

                <Row>
                    <Col>
                        {isLoading ? <div className="overlay"><img alt="Loading" className="center" src="..\gears.gif"/></div> : data.map(usage => (usage?
                            <Chart key={usage.title}
                                chartInfo={{
                                    includeWeekends: "true"
                                }}
                                options={{
                                    serverData: usage.series,
                                    title: usage.title,
                                    chartType: 'Line',
                                    xAxisType: 'datetime',
                                    loading: false
                                }}
                                disableAnnotation={true}/>
                            : null
                        ))}
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default withRouter(UsagePage);