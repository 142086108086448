import React, { Component } from 'react';
import { Col, Row, Button, Modal} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import CentralService from '../../Dataservices/CentralService';

class IssueCommandPane extends Component {
    constructor(props) {
        super(props);
        
        this.SourcePathKey = "SourcePath"

        this.state = {
            isLoading: true,
            registeredCommands: [],
            serverList: [],
            serviceList: [],
            commandList: [],
            zipPathList: [],
            originalZipPathList: [],
            data: props.serviceList,
            selectedServer: "All",
            selectedService: '',
            selectedCommand: {
                CommandName: "Start",
                TaskName: "Start",
                Description: "Start the service"
            },
            selectedZipPath: {FilePath: '', CommitMessage: ''},
            parameterObject: null,
            reason: "",
            modalShow: false,
            error: null
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.serviceAndServerRefreshCount !== this.props.serviceAndServerRefreshCount) {
            let commandList = this.getCommandList(this.state.registeredCommands, this.props.serviceName)
            this.setState({
                selectedServer: this.props.serverName,
                selectedService: this.props.serviceName,
                commandList: commandList,
                selectedCommand: commandList[0]
            });
        }
    }

    componentDidMount() {
        CentralService.getServiceList().then(res => {
            let rawServiceList = res.data;
            CentralService.getRegisteredCommand().then(result => {
                let registeredCommands = result.data;

                let serviceList = [];

                let defaultService = ''
                for (let i in rawServiceList) {
                    let service = rawServiceList[i].ServiceName;
                    if(!serviceList.includes(service)) {
                        serviceList.push(service);
                        if (defaultService === '') {
                            defaultService = service;
                        }
                    }
                }

                let commandList = this.getCommandList(registeredCommands, defaultService);
                let serverList = this.getServerList(rawServiceList, defaultService);

                CentralService.getServiceZipPath().then(zipRes=>{
                    this.setState({
                        originalZipPathList: zipRes.data,
                        registeredCommands: registeredCommands,
                        serverList: serverList,
                        serviceList: serviceList,
                        commandList: commandList,
                        data: rawServiceList,
                        selectedServer: "All",
                        selectedService: defaultService,
                        selectedCommand: commandList[0],
                        parameterObject: commandList[0].ParameterSample,
                    })
                }).catch(err => {
                    this.setState({error: err.message});
                })
            }).catch(err => {
                this.setState({error: err.message});
            })
        }).catch(err => {
            this.setState({error: err.message});
        })
    }

    getServerList = (serviceList, service) => {
        let result = ["All"];
        for (let i in serviceList) {
            let item = serviceList[i]
            if (item.ServiceName === service && !result.includes(item.ServerName)) {
                result.push(item.ServerName)
            }
        }
        return result;
    }

    getCommandList = (registeredCommands, service) => {
        let commandsList = [];
        if (service === "PerfCentralService") {
            commandsList = [
                {
                    CommandName: "Refresh",
                    TaskName: "Refresh",
                    Description: "Default command. Refresh central service, get service package list from Azure blob."
                },
                {
                    CommandName: "Update",
                    TaskName: "Update",
                    Description: "Default command. Update the service using binaries from Azure Blob.",
                    ParameterSample: {
                        SourcePath: this.SourcePathKey,
                    }
                }
            ]
        } else if (service !== "WindowsUpdateServiceClient") {
            commandsList = [
                {
                    CommandName: "Start",
                    TaskName: "Start",
                    Description: "Default command. Start the service (sc start)"
                },
                {
                    CommandName: "Stop",
                    TaskName: "Stop",
                    Description: "Default command. Stop the service (sc stop)"
                },
                {
                    CommandName: "Restart",
                    TaskName: "Restart",
                    Description: "Default command. Restart the service (sc stop & sc start)"
                },
                {
                    CommandName: "Update",
                    TaskName: "Update",
                    Description: "Default command. Update the service using binaries from Azure Blob.",
                    ParameterSample: {
                        SourcePath: this.SourcePathKey,
                    }
                }
            ]
        }
        for (let i in registeredCommands) {
            let cmd = registeredCommands[i];
            if (cmd.ServiceName === service) {
                cmd.TaskName = "Control";
                commandsList.push(cmd);
            }
        }
        // temporary fix
        // ban Restart command in Staging
        // will be removed after the bug in staging is fixed
        if (service === 'PerfCubeStaging') {
            commandsList = commandsList.filter(x=>x.TaskName !== 'Restart')
        }
        return commandsList;
    }

    onChangeServer = item => {
        this.setState({selectedServer: item.value})
    }
    
    onChangeService = item => {
        let commandList = this.getCommandList(this.state.registeredCommands, item.value);
        let serverList = this.getServerList(this.state.data, item.value);
        this.setState({
            commandList: commandList,
            serverList: serverList,
            selectedServer: "All",
            selectedCommand: commandList[0],
            parameterObject: commandList[0].ParameterSample,
            selectedService: item.value
        })
    }

    onChangeCommand = item => {
        let {originalZipPathList, selectedService} = this.state;
        let zipPathList = [];
        let selectedZipPath = {FilePath: '', CommitMessage: ''};
        let parameterObject = item.value.ParameterSample;
        if (parameterObject && this.SourcePathKey in parameterObject) {
            for (var i in originalZipPathList) {
                var zip = originalZipPathList[i];
                if (zip.ServiceName === selectedService) {
                    zipPathList.push(zip)
                }
            }
        }
        zipPathList.sort((a,b) => {
            if (a.FilePath === b.FilePath) {
                return 0;
            } else if (a.FilePath < b.FilePath) {
                return 1;
            } else {
                return -1;
            }
        });
        if (zipPathList.length > 0) {
            selectedZipPath = zipPathList[0];
            parameterObject[this.SourcePathKey] = selectedZipPath.FilePath;
        }
        this.setState({
            zipPathList: zipPathList,
            selectedCommand: item.value,
            parameterObject: parameterObject,
            selectedZipPath: selectedZipPath
        })
    }

    onChangeZipPath = item => {
        let {parameterObject} = this.state;
        if (this.SourcePathKey in parameterObject) {
            parameterObject[this.SourcePathKey] = item.label;
        }
        this.setState({
            parameterObject: parameterObject,
            selectedZipPath: item.value,
        })
    }

    onChangeParameter = (key,value) => {
        let {parameterObject} = this.state;
        if (key in parameterObject) {
            parameterObject[key] = value;
        }
        this.setState(parameterObject);
    }

    onIssueCommand = () => {
        this.setState({modalShow: true});
    }

    onSubmit = () => {
        let {parameterObject, selectedServer, selectedService, selectedCommand, reason} = this.state;
        let parameter = {
            ServiceName: selectedService,
            CommandName: selectedCommand.CommandName,
        }
        if (parameterObject != null) {
            for (let k in parameterObject) {
                parameter[k] = parameterObject[k];
            }
        }
        let data = {
            ServerName: selectedServer,
            ServiceName: selectedService,
            TaskName: selectedCommand.TaskName,
            Reason: reason,
            Parameters: JSON.stringify(parameter)
        };
        CentralService.postCommand(data).then(()=>{
            this.props.onIssueCommand();
        }).catch(()=>{
            this.props.onIssueCommand();
        })
        this.setState({
            modalShow: false
        })
    }

    renderParameter(parameterName, sampleValue) {
        if (parameterName === this.SourcePathKey) {
            let {selectedZipPath, zipPathList} = this.state;
            return (
                <Form.Group key={parameterName} style={{margin: 10, marginTop:15}} as={Row}>
                    <Form.Label column sm="1" style={{textAlign: 'left'}}>{parameterName}</Form.Label>
                    <Col sm="5">
                        <Select
                            value={{label:selectedZipPath.FilePath, value:selectedZipPath}}
                            options={zipPathList.map(e=>{
                                return {label:e.FilePath, value:e}
                            })}
                            onChange={this.onChangeZipPath}
                        />
                        <div style={{paddingTop: 5, textAlign: 'left'}}>
                            {selectedZipPath.CommitMessage.split('\n').slice(0, 5).map(e=>{
                                if (e.trim().length !== 0) {
                                    return (<span>{e}<br></br></span>)
                                } else {
                                    return <span></span>
                                }
                            })}
                        </div>
                    </Col>
                </Form.Group>
            )
        } else {
            return (
                <Form.Group key={parameterName} style={{margin: 10, marginTop:15}} as={Row}>
                    <Form.Label column sm="1" style={{textAlign: 'left'}}>{parameterName}</Form.Label>
                    <Col sm="5">
                        <Form.Control type="text" value={sampleValue} onChange={event=>{
                            this.onChangeParameter(parameterName, event.target.value)
                        }}>
                        </Form.Control>
                    </Col>
                </Form.Group>
            )
        }
    }

    render() {
        let {modalShow, reason, serverList, commandList, serviceList, selectedServer, selectedService, selectedCommand, parameterObject } = this.state;
        let parameterPairs = [];
        for(let k in parameterObject){
            parameterPairs.push([k, parameterObject[k]])
        }
        return (
            <div>
                {this.state.error && <Alert variant='danger'>{this.state.error}</Alert>}
                {this.state.serviceList.length !== 0 &&
                <div>
                    <Form>
                        <Form.Group style={{margin: 10}} as={Row}>
                            <Form.Label column sm="1" style={{textAlign: 'left'}}>Service</Form.Label>
                            <Col sm="2">
                                <Select
                                    aria-label="Select service"
                                    value={{label:selectedService, value:selectedService}}
                                    options={serviceList.map(e=>{return {label:e, value:e}})}
                                    onChange={this.onChangeService}
                                />
                            </Col>
                            <Form.Label style={{marginLeft: 20, marginTop:10}}>Server</Form.Label>
                            <Col sm="2">
                                <Select
                                    aria-label="Select server"
                                    value={{label:selectedServer, value:selectedServer}}
                                    options={serverList.map(e=>{return {label:e, value:e}})}
                                    onChange={this.onChangeServer}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group style={{margin: 10}} as={Row}>
                            <Form.Label column sm="1" style={{textAlign: 'left'}}>Command</Form.Label>
                            <Col sm="2">
                                <Select
                                    aria-label="Select command"
                                    value={{label:selectedCommand.CommandName, value:selectedCommand.CommandName}}
                                    options={commandList.map(e=>{return {label:e.CommandName, value:e}})}
                                    onChange={this.onChangeCommand}
                                />
                            </Col>
                            <Form.Label style={{marginLeft: 20, marginTop:10}}>Description: {selectedCommand.Description} </Form.Label>
                        </Form.Group>
                        {
                            parameterPairs.map(pair=>{
                                return this.renderParameter(pair[0], pair[1])
                            })
                        }
                        <Form.Group style={{margin: 10, marginTop:15}} as={Row}>
                            <Form.Label column sm="1" style={{textAlign: 'left'}}>Reason</Form.Label>
                            <Col sm="5">
                                <Form.Control aria-label="Reason" as="textarea" value={reason} rows={3} onChange={event=>this.setState({reason: event.target.value})}/>
                            </Col>
                        </Form.Group>
                        <Row>
                            <Col style={{textAlign:'left', margin: 20}}>
                                <Button variant="primary" onClick={this.onIssueCommand}>Submit</Button>
                            </Col>
                        </Row>
                    </Form>
                    <Modal show={modalShow} onHide={()=>{this.setState({modalShow: false})}}>
                        <Modal.Header closeButton>
                        <Modal.Title>Please confirm your parameters</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to trigger "{selectedService}.{selectedCommand.CommandName}" on "{selectedServer}" server?</Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{this.setState({modalShow: false})}}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.onSubmit}>
                            Submit
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>}
            </div>
        )
    }
}


export default IssueCommandPane;