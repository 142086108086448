import React, { Component } from 'react';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import CentralServiceMonitor from './CentralServiceMonitor';
import IssueCommandPane from './IssueCommandPane';
import CommandHistory from './CommandHistory';
import CentralService from '../../Dataservices/CentralService';

class CentralServiceTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            serviceName: null,
            serverName: null,
            serviceAndServerRefreshCount: 0,
            refreshCounter: 0
        }
    }
    
    componentDidMount() {
        CentralService.getServiceList().then(res => {
            this.setState({
                serviceList: res.data,
                isLoading: false
            })
        }).catch(err => {
            console.error(err)
            this.setState({error: err.message})
        })

        CentralService.getRegisteredCommand().then(res => {
            let registeredCommands = [];
            for (let i in res.data) {
                let cmd = res.data[i];
                try {
                    cmd.ParameterSample = JSON.parse(cmd.ParameterSample);
                } catch(e) {
                    cmd.ParameterSample = null;
                }
                registeredCommands.push(cmd);
            }
            this.setState({
                registeredCommands: registeredCommands,
                isLoading: false
            })
        }).catch(err => {
            console.error(err)
            this.setState({error: err.message})
        })

        CentralService.getCommands().then(res => {
            this.setState({
                commands: res.data,
                isLoading: false
            })
        }).catch(err => {
            console.error(err)
            this.setState({error: err.message})
        })
    }

    onIssueCommand = () => {
        this.setState({refreshCounter: this.state.refreshCounter+1})
    }

    selectServiceAndServer = (service, server) => {
        this.setState({
            serverName: server,
            serviceName: service,
            serviceAndServerRefreshCount: this.state.serviceAndServerRefreshCount+1
        })
    }

    render() {
        return (
            <div>
                <div>
                    <Row>
                        <Col className="Label">
                            <h2>Service Status</h2>
                        </Col>
                    </Row>
                    <CentralServiceMonitor selectServiceAndServer={this.selectServiceAndServer}/>
                    <hr/>
                    <Row>
                        <Col className="Label">
                            <h2>Issue Command</h2>
                        </Col>
                    </Row>
                    {
                        <IssueCommandPane onIssueCommand={this.onIssueCommand}
                                      serverName={this.state.serverName}
                                      serviceName={this.state.serviceName}
                                      serviceAndServerRefreshCount={this.state.serviceAndServerRefreshCount}
                        />
                    }
                    <hr/>
                    <Row>
                        <Col className="Label">
                            <h2>Command History</h2>
                        </Col>
                        <CommandHistory refreshCounter={this.state.refreshCounter}/>
                    </Row>
                </div>
            </div>
        )
    }
}

export default CentralServiceTab;
