
export function getColor(text) {
    const colorPalette = [
        '#737373','#505050','#D2D2D2','#949494','#5C2D91','#84D984','#32145A','#E37FBD',
        '#7FE3D2','#E87B7D','#107C10','#E81123','#BAD80A','#D83B01','#004B50','#008272',
        '#FFF980','#FFB900','#FF8C00','#004B1C','#FFF100','#5C005C','#B4A0FF','#B4009E',
        '#A80000','#E3008C','#FFC073','#00188F','#00B294','#002050','#00BCF2','#0078D7'
    ];

    var hash = 0, i, chr, len;
    for (i = 0, len = text.length; i < len; i++) {
        chr   = text.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
    }

    return colorPalette[(hash & (colorPalette.length - 1))];
}