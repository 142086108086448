import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import ApplicationPageService from '../../Dataservices/ApplicationPageService'

class ApplicationPageTableRow extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isEditing: false,
            identity: props.identity,
            name: props.name
        }
    }

    onSave = () => {
        const { identity, name } = this.state
        this.setState({ 
            isEditing: false
        });

        const payload = {
            AppId: identity,
            Name: name
        }
        
        ApplicationPageService.saveTranslation(payload).then(res =>{
            
        }).catch(err => {
            console.error(err)
            this.props.setError(err.message);
        })
    }

    onEdit = () => {
        this.setState({ isEditing: true});
    }

    onChangeName = e => {
        this.setState({ name: e.target.value});
    }

    render() {
        const { isEditing, identity, name } = this.state

        return (
            <tr>
                <td>{identity}</td>
                <td>{isEditing ? <InputGroup className="mb-3"><Form.Control label="Application Name" value={name} onChange={this.onChangeName} name="editName" /></InputGroup> : name}</td>
                <td><Button variant="primary" onClick={isEditing ? this.onSave : this.onEdit}>{isEditing ? 'Save' : 'Edit'}</Button></td>
            </tr>
        );
    }
}

export default ApplicationPageTableRow