import React, { useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import uuid from 'react-uuid'
import './ChartComponents.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faExternalLinkAlt);

export default function ChartCardRow(props) {
    const {tab, chartDisplayType, point, description, percentageDisplay, yDisplay, isSpecificSeriesExpertInsightsSupported, launchSpecificDailyExpertInsights} = props.options;
    const [showInsightsButton, setShowInsightsButton] = useState(false);

    let BuildLabel = (props) => {
        return <>|<span style={{color: "red"}}>{props.build}</span></>
    }
    
    let DescriptionOverlay = ({pointName, description, children}) => {
        if (description && description.length){
            return <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-${pointName}`}>
                        {description}
                    </Tooltip>
                }>
                {React.cloneElement(children)} 
            </OverlayTrigger>
        }
        else return children;
    }

    let handleExpertInsights = (e) => {
        e.preventDefault();
        launchSpecificDailyExpertInsights(point.name);
    }

    if (tab === 'checkpoint' || tab === 'checkpointPercentiles'|| chartDisplayType === 'StackedArea' || chartDisplayType === '100PercentArea') {
        return (
            <React.Fragment  key={uuid()}>
                <span style={{color: point.color}}>{'\u25CF'}</span>:
                <DescriptionOverlay pointName={point.name} description={description}>
                    {point.percentage >= 1 ? <span  style={{color: 'red', fontWeight: 'bold'}}>{point.name}</span> : <span>{point.name}</span>} 
                </DescriptionOverlay>
                <span>: {yDisplay} ({percentageDisplay}%){point.build ? <BuildLabel build={point.build}/> : null}</span>
            </React.Fragment>
        )
    } else {
        return (
            <div onMouseEnter={e => {
                setShowInsightsButton(true);
            }}
            onMouseLeave={e => {
                setShowInsightsButton(false);
            }}>
                <span style={{color: point.color}}>{'\u25CF'}</span>:
                <DescriptionOverlay pointName={point.name} description={description}><span>{point.name}</span></DescriptionOverlay>
                <span>: {yDisplay}{point.build ? <BuildLabel build={point.build}/> : null}</span>
                {isSpecificSeriesExpertInsightsSupported && showInsightsButton && 
                    <FontAwesomeIcon onClick={handleExpertInsights} icon={faExternalLinkAlt} className="rowBulb"/>}
            </div>
        )
    }
}
