export const debounce = (func, delay) => {
  let debounceHandler;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceHandler);
    debounceHandler = setTimeout(() => func.apply(context, args), delay);
  };
};

export const getErrorMessage = (err, isComplete) => {
    let error;

    const concatKeys = (o) => {
        if (typeof o == 'string' || typeof o == 'number') return o;

        let result = '';
        Object.keys(o).forEach((key) => {
            if (o[key] && (typeof o[key] === 'string' || typeof o[key] == 'number')) {
                result = result + ' ' + o[key];
            }
        });

        return result;
    };

    if (err.response && err.response.data) {
        error = concatKeys(err.response.data);
    } else if (err.message) {
        error = concatKeys(err.message);
    } else {
        error = concatKeys(err);
    }

    if (!isComplete && error.length > 200) {
        error = error.slice(0, 200) + '...(Please see the complete error in console)';
    }

    return error;
};
