import { getPercentileTableData } from '../Functions/TableHelper'
import React from 'react';
import { addIncrementToUTCDate, cloneDate, convertStringToUTCDate, formatUTCDateAsString, getTimeDifference, addDaysToUTCDate } from './DateTimeHelper';

export const Tabs = [
    { dbName: 'daily', friendlyName: 'Daily' },
    { dbName: 'daily7Rolling', friendlyName: 'Daily Rolling 7' },
    { dbName: 'usage', friendlyName: 'Usage'},
    { dbName: 'checkpoint', friendlyName: 'Checkpoint'},
    { dbName: 'pivottable', friendlyName: 'Table'},
    { dbName: 'dailymetrics', friendlyName: 'Metrics'},
    { dbName: 'percentiles', friendlyName: 'Percentiles'},
    { dbName: 'checkpointPercentiles', friendlyName: 'Checkpoint Percentiles'},
    { dbName: 'metricPercentiles', friendlyName: 'Metric Percentiles'},
    { dbName: 'cumulativeHistogram', friendlyName: 'Cumulative Histogram'},
    { dbName: 'histogram', friendlyName: 'Histogram'},
    { dbName: 'ratio', friendlyName: 'Ratio'}
]

export const TabXValueMap = {
    daily: 'Date',
    daily7Rolling: 'Date',
    usage: 'Date',
    checkpoint: 'Date',
    checkpointPercentiles: 'Percentiles',
    dailymetricslatency: 'Date',
    dailymetricscount: 'Date',
    dailymetricsratio: 'Date',
    dailymetricsother: 'Date',
    metricspercentileslatency: 'Percentiles',
    metricspercentilescount: 'Percentiles',
    metricspercentilesratio: 'Percentiles',
    metricspercentilesother: 'Percentiles',
    percentiles: 'Percentile',
    cumulativeHistogram: 'Latency Bucket Ceiling',
    histogram: 'Latency Bucket Ceiling',
    dailyHistogram: 'Latency Bucket Ceiling',
    ratio: 'Date'
}

export const TabYValueMap = {
    daily: 'Latency(ms)',
    daily7Rolling: 'Latency(ms)',
    usage: '# of Samples',
    checkpoint: 'Latency(ms)',
    checkpointPercentiles: 'Latency(ms)',
    dailymetricslatency: 'Latency(ms)',
    dailymetricscount: 'Count',
    dailymetricsratio: 'Ratio of Samples',
    dailymetricsother: 'Other',
    metricspercentileslatency: 'Latency(ms)',
    metricspercentilescount: 'Count',
    metricspercentilesratio: 'Ratio of Samples',
    metricspercentilesother: 'Other',
    percentiles: 'Latency(ms)',
    cumulativeHistogram: 'Values',
    histogram: '# of Samples',
    dailyHistogram: '# of Samples',
    ratio: 'Ratio of Samples',
    percentage: '%'
}

export const TabInitTimeInterval = {
    daily: 1,
    usage: 1,
    checkpoint: 1,
    dailymetrics: 1,
    ratio: 1
}

export const TabMaxTimeInterval = {
    daily: 35,
    usage: 35,
    checkpoint: 14,
    dailymetrics: 14,
    ratio: 35
}

export function legendFormatFunction() {
    var legendItem = this.name;
    var formattedItem = '';
    var numChars = window.self !== window.top ? 20 : 30;

    formattedItem += legendItem[0];
    for (var i = 1; i < legendItem.length; i++) {
        if (i % numChars === 0) {
            formattedItem += "<br>";
        }
        formattedItem += legendItem[i];
    }

    return formattedItem;
};

export function handleLegendItemClick(e) {
    var ctrlKeyPressed = e.browserEvent.metaKey || e.browserEvent.ctrlKey;
    if (!ctrlKeyPressed) {
        var seriesIndex = this.index;
        var series = this.chart.series;

        for (var i = 0; i < series.length; i++) {
            // rather than calling 'show()' and 'hide()' on the series', we use setVisible and then
            // call chart.redraw --- this is significantly faster since it involves fewer chart redraws
            if (series[i].index === seriesIndex) {
                series[i].setVisible(true, false);
            }
            else {
                series[i].setVisible(false, false);
            }
        }
        this.chart.redraw();
        return false;
    }
}

export function transformLatencyDataToDate(data) {
    if(!data) return data;
    for (var j = 0; j < data.length; j++) {
        var series = data[j].Data;

        for (var i = 0; i < series.length; i++) {
            series[i].x = convertStringToUTCDate(series[i].name);
        }

    }
    return data;
}

export function fillInDates(data, isHourly, includeWeekends, isLatencySeries) {
    if (!data) return data;
    for (var j = 0; j < data.length; j++) {
        data[j].Data = fillInDatesHelper(data[j].Data, isHourly, includeWeekends, isLatencySeries);
    }
    return data;
}

export function fillInDatesHelper(data, isHourly, includeWeekends, isLatencySeries) {
    if (!data || data.length < 2) {
        return data;
    }

    data.sort((a, b) => a.x < b.x ? a : b);
    const minDate = data[0].x, maxDate = data[data.length - 1].x;
    let filledArray = new Array(getTimeDifference(minDate, maxDate, isHourly) + 1);
    filledArray[0] = data[0];
    let dataPtr = 1, filledArrPtr = 0;
    while (dataPtr < data.length) {
        const currentDate = filledArray[filledArrPtr].x;
        let i = 1, timeDifference = getTimeDifference(currentDate, data[dataPtr].x, isHourly);
        while (i < timeDifference) {
            const nextDate = addIncrementToUTCDate(cloneDate(currentDate), i, isHourly);
            if (includeWeekends === "true" || ((nextDate.getUTCDay() % 6) !== 0)) {
                filledArrPtr += 1;
                filledArray[filledArrPtr] = { name: formatUTCDateAsString(nextDate, isHourly), x: nextDate, y: isLatencySeries ? null : 0, build: '', date: null };
            }
            i++;
        }
        filledArrPtr += 1;
        filledArray[filledArrPtr] = data[dataPtr];
        dataPtr += 1;
    }
    filledArray.length = filledArrPtr + 1;
    return filledArray;
}

export function transformLatencyDataToCumulative(data) {
    for (var j = 0; j < data.length; j++) {
        var series = data[j].Data;

        for (var i = 1; i < series.length; i++) {
            series[i].y += series[i - 1].y;
        }

        var maxium = series[series.length - 1].y;
        for (i = 0; i < series.length; i++) {
            series[i].y = series[i].y * 100 / maxium;
        }
    }

    return data
}

export function isZoomSupported(tab) {
    return tab === "checkpointPercentiles" || tab === "percentiles" || tab === "metricspercentileslatency" || tab === "metricspercentilesratio" || tab === "metricspercentilesother" || tab === "metricspercentilescount";
}

export function serverDataToChartData(tab, metrics, serverResponseData, includeWeekends) {
    let data = [];

    let formatSeries = (series) => {
        if (!series.Name) {
            series.Name = "(blank)";
        }
        if (metrics && !metrics.includes(series.Name)) {
            series.Hide = true;
        }
        return series;
    }

    serverResponseData.SampleCountDataSeries?.map(formatSeries);
    serverResponseData.LatencyDataSeries?.map(formatSeries);
    serverResponseData.AdditionalLatencyDataSeries?.map(formatSeries);

    switch (tab) {
        case 'checkpoint':
        case 'checkpointPercentiles':
            if (serverResponseData.AdditionalLatencyDataSeries) {
                data = [
                    {
                        serverData: [...serverResponseData.AdditionalLatencyDataSeries].sort((a, b)=>(parseInt(b.ID) - parseInt(a.ID))),
                        chartType: 'StackedArea',
                        xAxisType: tab === 'checkpoint' ? 'datetime' : 'category'
                    }
                ]
            } else {
                data = [
                    {
                        serverData: [],
                        chartType: 'StackedArea',
                        xAxisType: tab === 'checkpoint' ? 'datetime' : 'category'
                    }
                ]
            }
            break
        case 'dailymetrics' || 'metricPercentiles':
            data = [
                {
                    serverData: serverResponseData.LatencyDataSeries.filter(value => {
                        return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() === (".T");
                    }),
                    chartType: 'Line',
                    xAxisType: tab === 'dailymetrics' ? "datetime" : "category"
                },
                {
                    serverData: serverResponseData.LatencyDataSeries.filter(value => {
                        return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() === (".C");
                    }),
                    chartType: 'Line',
                    xAxisType: tab === 'dailymetrics' ? "datetime" : "category"
                },
                {
                    serverData: serverResponseData.LatencyDataSeries.filter(value => {
                        return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() === (".R");
                    }),
                    chartType: 'Line',
                    xAxisType: tab === 'dailymetrics' ? "datetime" : "category"
                },
                {
                    serverData: serverResponseData.LatencyDataSeries.filter(value => {
                        return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() !== (".R") && value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() !== (".C") && value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() !== (".T");
                    }),
                    chartType: 'Line',
                    xAxisType: tab === 'dailymetrics' ? "datetime" : "category"
                }
            ]
            break
        case 'cumulativeHistogram':
            data = [
                {
                    serverData: transformLatencyDataToCumulative(serverResponseData.LatencyDataSeries),
                    chartType: 'Line',
                    xAxisType: "category"
                }
            ]
            break
        case 'table':
        case 'pivottable':
            break
        case 'percentiles':
        case 'histogram':
            data = [
                {
                    serverData: serverResponseData.LatencyDataSeries.concat(serverResponseData.AdditionalLatencyDataSeries).filter(arr => arr !== null),
                    chartType: 'Line',
                    xAxisType: "category"
                }
            ]
            break
        default:
            data = [
                {
                    serverData: serverResponseData.LatencyDataSeries.concat(serverResponseData.AdditionalLatencyDataSeries).filter(arr => arr !== null),
                    chartType: 'Line',
                    xAxisType: "datetime"
                }
            ]
            break
    }

    data.forEach((e) => {
        if (e.xAxisType === "datetime") {
            fillInDates(transformLatencyDataToDate(e.serverData), false, includeWeekends, tab !== 'usage')
        }
    })

    return data
}

export function serverDataToTableData(serverResponseData, percentilesForTableView) {
    let tableData = {}

    let cols = percentilesForTableView.map(e => {
        if (e === "AVG") {
            return "-1"
        } else {
            return e.trim()
        }
    })
    let latencyHeader = percentilesForTableView.map(e => {
        let p = parseInt(e)
        if (isNaN(p)) {
            return e
        } else {
            return e + "%"
        }
    })
    let table = getPercentileTableData(serverResponseData, cols)
    tableData.table = table
    tableData.latencyHeader = latencyHeader

    return tableData
}

export function pointStorageSuggestion(tab, seriesPoint) {

    const storageSuggestion = "Consider switching from HDD to SSD to reduce latency";

    if (tab && tab.startsWith('dailymetrics') && seriesPoint.series.name.indexOf('HDD') !== -1) {
        return (
            <React.Fragment>
                <br />
                <span style={{ color: 'grey' }}>{storageSuggestion}</span>
                <a className="accessibleLink" href='https://docs.substrate.microsoft.net/docs/Performance/Latency/Get-started-with-SDS-Fast-Storage.html'> Learn more</a>
            </React.Fragment>
        )
    } else {
        return null;
    }
};

export function segmentSeriesForDate(series, date) { //format cached chart data to query middle tier for rule analysis
    if (!series) { return {}; }
    let seriesCopy = JSON.parse(JSON.stringify(series));;
    for (const [layerIndex, layerData] of Object.entries(seriesCopy)) { //for each layer
        let dateData = {};
        let dataPoints = layerData.Data;
        for (let j = 0; j < dataPoints.length; j++) { //for each date in layer
            if (dataPoints[j]['name'] === date) { //pull out the date we're looking for
                dateData = dataPoints[j];
                break;
            }
        }
        seriesCopy[layerIndex]["data"] = [dateData];
    }
    return seriesCopy;
}

export function getDateFromName(name) {
    let date = name
    if (date.indexOf('|') !== -1) {
        date = date.split('|')[0]
    }

    return date
}

export function isThisDayIncluded(date, includeWeekends) {
    return (includeWeekends === "true") || (date.getUTCDay() % 6 !== 0);
}

// todo : hourly data 
export function emptyData(startDate, endDate, includeWeekends) {
    let data = [];
    for (let date = startDate; date <= endDate; date = addDaysToUTCDate(date, 1)) {
        if (!isThisDayIncluded(date, includeWeekends)) continue;
        data.push({
            name: formatUTCDateAsString(date),
            y: null,
            build: "",
            date: null,
            x: date,
        })
    }
    return data;
}

export function emptyLatencySeries(startDate, endDate, includeWeekends) {
    let latencySeries = [];
    let series = {
        Data: [],
        ID: "0",
        Name: "Loading...",
        Description: "",
    };
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    series.Data = emptyData(startDate, endDate, includeWeekends);
    latencySeries.push(series);
    return latencySeries
}

export function concatData(data, new_data, startDate, endDate, isHourly, includeWeekends, isLatencySeries) {
    new_data.forEach(series => {
        let relatedSeries = data.find(x => { return x.Name.toLowerCase() === series.Name.toLowerCase() });
        if (!relatedSeries) {
            relatedSeries = { ...series, ID: data.length + "" }
            relatedSeries.Data = fillInDatesHelper(emptyData(startDate, endDate, includeWeekends), isHourly, includeWeekends, isLatencySeries);
            data.push(relatedSeries);
        }
        fillInData(relatedSeries, series);
    })
    return data;
}

export function mergeCheckpointData(data, new_data, startDate, endDate, isHourly, includeWeekends, isLatencySeries) {
    let posToStartSearching = data.length - 1;
    new_data.reverse().forEach((series) => {
        let relatedSeriesIndex = data.findIndex((x) => { return x.Name.toLowerCase() === series.Name.toLowerCase() });
        if (relatedSeriesIndex < 0) { // If not found, it's a new checkpoint. Insert it based on the previous checkpoint place
            let relatedSeries = { ...series, ID: data.length + "" }
            relatedSeries.Data = fillInDatesHelper(emptyData(startDate, endDate, includeWeekends), isHourly, includeWeekends, isLatencySeries);
            relatedSeriesIndex = posToStartSearching + 1;
            data.splice(relatedSeriesIndex, 0, relatedSeries);
        }else if (relatedSeriesIndex > posToStartSearching) { // If found, but not in the right place, move it to the right place
            let series = data[relatedSeriesIndex];
            data.splice(relatedSeriesIndex, 1);
            relatedSeriesIndex = posToStartSearching + 1;
            data.splice(relatedSeriesIndex, 0, series);
        }
        fillInData(data[relatedSeriesIndex], series);
        posToStartSearching = Math.max(relatedSeriesIndex - 1, 0);
    })
    return data;
}

export function fillInData(series, new_series) {
    new_series.Data.forEach(value => {
        let relatedy = series.Data.find(x => { return x.name === value.name })
        if (relatedy && value.y !== undefined) {
            relatedy.y = value.y;
            relatedy.build = value.build;
            relatedy.date = value.date;
        }
    })
    return series;
}

export function getStartDate(data) {
    let newStartDate = null;
    data.forEach(series => {
        series.Data.forEach(point => {
            if (point.y != null && (point.x < newStartDate || newStartDate === null)) newStartDate = point.x;
        })
    })
    return newStartDate;
}

export function getEndDate(data) {
    let newEndDate = null;
    data.forEach(series => {
        series.Data.forEach(point => {
            if (point.y != null && (point.x > newEndDate || newEndDate === null)) newEndDate = point.x;
        })
    })
    return newEndDate;
}

export function fillInEmptySamples(data) {
    data.forEach(series => {
        series.Data.forEach(point => {
            if (point.y == null) point.y = 0;
        })
    })
    return data
}

export function cutDataWithTimeRange(data, startDate, endDate) {
    data.forEach(series => {
        series.Data = series.Data.filter(value => (value && value.x >= startDate && value.x <= endDate));
    })
    data = data.filter(series => (series && series.Data.filter(value => (value.y != null)).length > 0));
    return data
}