/*
    PLEASE READ THIS COMMENT BEFORE CHANGING OR USING ANYTHING INVOLVING DATE/DATETIME IN THIS COMPONENT.
    Throughout PerfPanel, we handle dates with the assumption that the the given date is in UTC.
    However, the datepicker we use will always display the given date in the user's timezone.
    Ex: given date is "2021-01-01 00:00". Assume it is UTC.
        If the user is in Redmond, browser will display the date as "2020-12-31 17:00"
        If the user is in Suzhou, browser will display the date as "2021-01-01 8:00"
    Therefore, if we want to display the date in the datepicker as a timezone other than the user's, 
    we must add an offset equivalent to offset between the user's timezone and UTC,
    plus the offset between UTC and the target timezone.
    **THIS RESULTS IN A LOGICALLY INCORRECT TIME**,
    so you must convert the date back to the intended UTC time before using.
    There is a helper function already written that will do this for you, getDisplayDateAsLogicalDate.
    See safeChangeDateRange for an example of how to use it.
    For the opposite behavior (UTC-date-to-displayed-date instead of displayed-date-to-UTC),
    use getLogicalDateAsDisplayDate.
    See fromDate's initialization for an example of its use.
    PLEASE KEEP THIS BEHAVIOR ISOLATED TO THIS COMPONENT and do any needed conversions in this component,
    not elsewhere.
*/

import React, { useState, useEffect, useCallback } from 'react';
import { Dropdown, DropdownButton, InputGroup, Row } from 'react-bootstrap';
import { addDaysToUTCDate, convertStringToUTCDate, convertTimezone, formatUTCDateAsString, getDisplayDateAsLogicalDate, getLogicalDateAsDisplayDate,  timezones } from '../../Functions/DateTimeHelper';
import { handleClick } from '../../Dataservices/EventTrackingService';
import ReactDatePicker from '../ReactDatepicker';

function PivotDatepicker(props){

    // Constants and helper functions
    const timezoneOptions = Object.keys(timezones);
    const FromDateServerName = "StartDate";
    const ToDateServerName = "EndDate";
    const TimezoneServerName = "timezone";
    const DefaultTimezone = "UTC";
    const getDefaultStartDate = (isHourly) => isHourly ? getLogicalDateAsDisplayDate(addDaysToUTCDate(new Date(), -2)): addDaysToUTCDate(new Date(), -30);
    const getDefaultEndDate = (isHourly) => isHourly ?  getLogicalDateAsDisplayDate(addDaysToUTCDate(new Date(), 0)) : addDaysToUTCDate(new Date(), 0);

    // State
    const [fromDate, setFromDate] = useState(getDefaultStartDate(props.isHourly));
    const [toDate, setToDate] = useState(getDefaultEndDate(props.isHourly));
    const [timezone, setTimezone] = useState(DefaultTimezone);
    
    // Need to explicitly destructure function props to avoid an ESLint error
    const {changeChartingPaneDateRange, changeChartingPaneTimezone} = props;
    
    //  Please read header comment for full context on this function
    // useCallback is needed to avoid unnecessary triggers of the refresh effect
    const safeChangeChartingPaneDateRange = useCallback((key, date, newTimezone) => {
        changeChartingPaneDateRange(key, formatUTCDateAsString(getDisplayDateAsLogicalDate(date, newTimezone), props.isHourly));
    }, [changeChartingPaneDateRange, props.isHourly]);

    // Refresh when the PivotHeadPane receives new options
    useEffect(() => {
        if(props.urlDefaultParams){
            let newTimezone = props.urlDefaultParams[TimezoneServerName];
            newTimezone = newTimezone != null ? newTimezone : DefaultTimezone;
            setTimezone(newTimezone);
            changeChartingPaneTimezone(newTimezone);

            let newStartDate =  props.urlDefaultParams[FromDateServerName];
            newStartDate =  newStartDate != null ? getLogicalDateAsDisplayDate(convertStringToUTCDate(newStartDate)) : getDefaultStartDate(props.isHourly);
            setFromDate(newStartDate);
            safeChangeChartingPaneDateRange(FromDateServerName, newStartDate, newTimezone);

            let newEndDate =  props.urlDefaultParams[ToDateServerName];
            newEndDate =  newEndDate != null ? getLogicalDateAsDisplayDate(convertStringToUTCDate(newEndDate)) : getDefaultEndDate(props.isHourly);
            setToDate(newEndDate);
            safeChangeChartingPaneDateRange(ToDateServerName, newEndDate, newTimezone);
        }
    }, [props.refresh, safeChangeChartingPaneDateRange, changeChartingPaneTimezone, props.isHourly, props.urlDefaultParams]);

    let changeFromDate = date => {
        setFromDate(date);
        safeChangeChartingPaneDateRange(FromDateServerName, date, timezone);
    }

    let changeToDate = date => {
        setToDate(date);
        safeChangeChartingPaneDateRange(ToDateServerName, date, timezone);
    }

    let changeTimezone = newTimezone => {
        convertTimezone(fromDate, timezone, newTimezone);
        convertTimezone(toDate, timezone, newTimezone);
        
        safeChangeChartingPaneDateRange(FromDateServerName, fromDate, newTimezone);
        safeChangeChartingPaneDateRange(ToDateServerName, toDate, newTimezone);
        setTimezone(newTimezone);
        changeChartingPaneTimezone(newTimezone);
    }

    function DatepickerRow(label, isHourly, value, onChange) {
        return (<Row>
                <div className="chartingPanePivotLabel">{label}</div>
                <div className="chartingPanePivotValue" aria-label={label} onClick={() => { handleClick(label, "pivotHeadPane", "analysis") }}>       
                <ReactDatePicker
                        value={value}
                        showTime={isHourly}
                        onChange={(date) => onChange(date)}
                    />
                </div>
            </Row>);
    }

    return <>
        {DatepickerRow("Start", props.isHourly, fromDate, changeFromDate)}
        {DatepickerRow("End", props.isHourly, toDate, changeToDate)}
        {props.isHourly ?
            <Row>
              <div className="chartingPanePivotLabel">Timezone</div>
              <div className="chartingPanePivotValue">
                  <DropdownButton
                      as={InputGroup.Append}
                      variant="outline-secondary"
                      title={timezone}
                      className="dropDownButton"
                  >
                      {
                          timezoneOptions?.map((e,i)=>(<Dropdown.Item key={i} onClick={() => changeTimezone(e)}>{e}</Dropdown.Item>))
                      }
                  </DropdownButton>
              </div>
          </Row>
        : null} 
      </>;
}


export default React.memo(PivotDatepicker);