import React from 'react'
import { ChromePicker } from 'react-color'
import './ColorPicker.css'

class ColorPicker extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            displayColorPicker: false
        }
    }
  
    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    }

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    }

    render() {
        const { color, onChange } = this.props
        return (
            <div>
                <div className="swatch" onClick={ this.handleClick }>
                <div style={{ background: color }} className="color"/>
                </div>
                { this.state.displayColorPicker && 
                    <div className="pop">
                        <div className="cover" onClick={ this.handleClose }/>
                        <ChromePicker color={color} onChange={onChange} disableAlpha />
                    </div> }
            </div>
        )
    }
}

export default ColorPicker