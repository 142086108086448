import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Alert from 'react-bootstrap/Alert'
import DashboardEditChartModal from './DashboardEditChartModal'
import DashboardMoveChartModal from './DashboardMoveChartModal'
import DataSuiteIndividualAlertModal from '../AlertingComponents/DataSuiteIndividualAlertModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faSync, faShareSquare, faArrowsAlt, faChevronUp, faChevronDown, faEdit, faConciergeBell } from '@fortawesome/free-solid-svg-icons'
import { getTabFromUrl, getCubeFromURL, updateGoalLineInUrl, getStandardizedUrl} from '../../Functions/UrlHelper'
import AlertingService from '../../Dataservices/AlertingService'
import { library } from "@fortawesome/fontawesome-svg-core";
import { ScenarioTags } from '../../Dataservices/ChartService'
import ProgressiveChart from '../ChartComponents/ProgressiveChart'
import VisibilitySensor from 'react-visibility-sensor'

library.add(faBell, faSync, faShareSquare, faArrowsAlt, faChevronUp, faChevronDown, faEdit, faConciergeBell);

class DashboardChart extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dailyStartDate: props.dailyStartDate,
            dailyEndDate: props.dailyEndDate,
            hourlyStartDate: props.hourlyStartDate,
            hourlyEndDate: props.hourlyEndDate,
            isSaving: false,
            cubeName: '',
            showEditChartModal: false,
            showMoveChartModal: false,
            showIndividualAlertModal: false,
            tab: null,
            tableError: '',
            chartError: [],
            error: null,
            updatedTime: new Date().getTime(),
            updatedGoallineTime: new Date().getTime(),
            updatedAlertTime: new Date().getTime(),
            refreshTime: new Date().getTime(),
            chart: props.chart,
            isAllAlertsValid: true,
            missingDates: -1,
            visibility: false,
        }

        this.data = {
            charts: [{
                serverData: [],
                rawData: [],
                chartType: 'Line',
                errorMessage: ''
            }],
            isTable: false,
            isHourly: false,
            timezone: "UTC",
            tableData: {},
            renderStartTime: null,
            renderEndTime: null,
            startDate: null,
            endDate: null,
        }
    }

    componentDidMount() {
        const { chart } = this.state;
        const standardizeUrl = getStandardizedUrl(chart.url);
        this.setState({ tab: getTabFromUrl(standardizeUrl), appName: getCubeFromURL(standardizeUrl) });
    }

    onToggleEditChartModal = () => {
        this.setState(prevState => {
            return {
                showEditChartModal: !prevState.showEditChartModal
            }
        })
    }

    onToggleMoveChartModal = () => {
        this.setState(prevState => {
            return {
                showMoveChartModal: !prevState.showMoveChartModal
            }
        })
    }

    onToggleIndividualAlertModal = () => {
        this.setState(prevState => {
            return {
                showIndividualAlertModal: !prevState.showIndividualAlertModal
            }
        })
    }

    openDashboardUrl = () => {
        const { chart } = this.props
        const url = updateGoalLineInUrl(chart.url, (chart.alertList) ? chart.alertList.GoalLines : [])
        window.open(url)
    }

    onChartRefresh = () => {
        this.setState({ refreshTime: new Date().getTime() })
        document.getElementById('dashboard-chart-refresh').innerHTML = this.props.chart.title + " refreshed";
    }

    onEditChartSave = (chart) => {
        const { onEditChartSave } = this.props
        onEditChartSave(chart)
        this.setState({ updatedGoallineTime: new Date().getTime(), })
    }

    onMoveChart = (dashboardId, oldIndex, newIndex) => {
        const { onMoveChart } = this.props
        this.onToggleMoveChartModal()
        onMoveChart(dashboardId, oldIndex, newIndex)
    }

    onChangeIsAllAlertsValid = (valid) => {
        this.setState({ isAllAlertsValid: valid })
    }

    onChangeVisibiilty = (isVisible) => {
        if(isVisible) this.setState({ visibility: isVisible })
    }

    validateAlert = () => {
        let { chart, onEditChartSave } = this.props
        const { charts } = this.data
        const { tab } = this.state
        let legendList = Array()
        let isAllAlertsValid = true
        for (let chart of charts) {
            for (let series of chart.serverData) {
                legendList.push(series.Name)
            }
        }

        if (!legendList.includes("Loading...")) {

            // Validate alert list and set alert to disable when it's invalid.
            // 1. Contains no existing legend.
            // 2. Contains global alert in metrics tab.
            const validateThreshold = function (t) {
                if ((t.Legend !== null && !legendList.includes(t.Legend)) ||
                    (tab === "dailymetrics" && t.Legend === null)) {
                    isAllAlertsValid = false
                    if (t.IsAlertEnabled) t.IsAlertEnabled = false
                }
            }

            if (chart && chart.alertList) {
                chart.alertList.DynamicAlerts.map((alert) => validateThreshold(alert.DynamicThreshold))
                chart.alertList.StaticAlerts.map((alert) => validateThreshold(alert.StaticThreshold))
            }
            if (!isAllAlertsValid) {
                onEditChartSave(chart)
                this.setState({ updatedAlertTime: new Date().getTime(), })
                AlertingService.changeAlert(chart.alertList).then().catch(err => {
                    console.error(err)
                    this.setState({ error: err.message })
                })
            }
        }

        this.setState({
            isAllAlertsValid: isAllAlertsValid
        })
    }

    setErrorMessage = (error) => {
        this.setState({ error: error })
    }

    setData = (data) => {
        this.data = data;
        this.validateAlert();
    }

    renderDashboardOptions = () => {
        const { chart, onMoveChart, totalChartNum, isOwner } = this.props
        const { order } = chart
        const { tab, isAllAlertsValid } = this.state
        const countEnabledAlert = (chart) => {
            if (chart && chart.alertList) {
                return chart.alertList.DynamicAlerts.filter(alert => (alert.DynamicThreshold.IsAlertEnabled)).length +
                    chart.alertList.StaticAlerts.filter(alert => (alert.StaticThreshold.IsAlertEnabled)).length +
                    chart.alertList.GoalLines.filter(alert => (alert.GoalLine.IsAlertEnabled || alert.GoalLine.IsGoalLineEnabled)).length
            } else {
                return 0
            }
        }

        return (
            <ButtonToolbar className="chartToolbar">
                <ButtonGroup className="mr-2" aria-label="First group">
                    {!this.data.isHourly && (tab === 'daily' || tab === 'usage' || tab === 'checkpoint' || tab === 'ratio' || tab === 'dailymetrics') && isOwner &&
                        <Button aria-label="Edit alerts" variant="light" onClick={this.onToggleIndividualAlertModal}>
                            <FontAwesomeIcon icon={faBell} style={isAllAlertsValid ? ((countEnabledAlert(chart) > 0) ? null : { color: '#aaaaaa' }) : { color: "#8B0000" }} />
                        </Button>}
                    <Button aria-label="Refresh chart" variant="light" onClick={this.onChartRefresh}><FontAwesomeIcon icon={faSync} /></Button>
                    <Button aria-label="View chart in new page" variant="light" onClick={this.openDashboardUrl}><FontAwesomeIcon icon={faShareSquare} /></Button>
                    {isOwner && <Button aria-label="Edit chart" variant="light" onClick={this.onToggleEditChartModal}><FontAwesomeIcon icon={faEdit} /></Button>}
                    {order !== 0 && <Button aria-label="Move chart up" variant="light" onClick={onMoveChart.bind(this, order, order - 1)}><FontAwesomeIcon icon={faChevronUp} /></Button>}
                    {order !== totalChartNum - 1 && <Button aria-label="Move chart down" variant="light" onClick={onMoveChart.bind(this, order, order + 1)}><FontAwesomeIcon icon={faChevronDown} /></Button>}
                    {totalChartNum > 1 && <Button aria-label="Set chart position" variant="light" onClick={this.onToggleMoveChartModal}><FontAwesomeIcon icon={faArrowsAlt} /></Button>}
                </ButtonGroup>
            </ButtonToolbar>
        )
    }

    render() {
        const { showIndividualAlertModal, showEditChartModal, showMoveChartModal, tab, error, updatedAlertTime, refreshTime, visibility } = this.state
        const { onEditChartDelete, totalChartNum, chart, cubes, evoServices, evoServicesMapping, dailyStartDate, dailyEndDate, hourlyStartDate, hourlyEndDate, loginUser, refreshChartUrl, setCsvData, dashboardInfo, icmServices, setIcmServices, originator } = this.props
        const { charts, isHourly } = this.data

        const containerStyle = !error ? { height: '450px' } : { height: '100%' }
        return (
            <VisibilitySensor onChange={this.onChangeVisibiilty}>
                <div style={containerStyle}>
                    {error && <Alert variant='danger' className="dashboardAlert">{error}</Alert>}
                    <div style={{ display: 'flex' }}>
                        {this.renderDashboardOptions()}
                    </div>
                    <div id="dashboard-chart-refresh" role="status" class="sr-only"></div>
                    <DashboardEditChartModal
                        cubes={cubes}
                        showModal={showEditChartModal}
                        toggleModal={this.onToggleEditChartModal}
                        chart={chart}
                        onSave={this.onEditChartSave}
                        onDelete={onEditChartDelete}
                        isHourly={isHourly}
                        setError={this.setErrorMessage} />
                    <DashboardMoveChartModal
                        showModal={showMoveChartModal}
                        toggleModal={this.onToggleMoveChartModal}
                        onMoveChart={this.onMoveChart}
                        dashboardId={chart.dataSuiteId}
                        orginalOrder={chart.order}
                        maxOrder={totalChartNum - 1} />
                    <DataSuiteIndividualAlertModal
                        showModal={showIndividualAlertModal}
                        toggleModal={this.onToggleIndividualAlertModal}
                        dashboardInfo={dashboardInfo}
                        charts={charts}
                        chart={chart}
                        tab={tab}
                        updatedTime={updatedAlertTime}
                        onSave={this.onEditChartSave}
                        originator={originator}
                        onChangeValidation={this.onChangeIsAllAlertsValid}
                        icmServices={icmServices}
                        setIcmServices={setIcmServices} />
                    { visibility ?
                    <ProgressiveChart
                        cubes={cubes}
                        evoServices={evoServices}
                        evoServicesMapping={evoServicesMapping}
                        chart={chart}
                        dailyStartDate={dailyStartDate}
                        dailyEndDate={dailyEndDate}
                        hourlyStartDate={hourlyStartDate}
                        hourlyEndDate={hourlyEndDate}
                        loginUser={loginUser}
                        setErrorMessage={this.setErrorMessage}
                        setData={this.setData}
                        refreshChartUrl={refreshChartUrl}
                        refreshTime={refreshTime}
                        isInDashboard={true}
                        setCsvData={setCsvData}
                        scenarioTags={ScenarioTags.Dashboard}
                    />:
                    <h3>{chart.title}</h3>}
                </div>
            </VisibilitySensor>
        );
    }

}

export default DashboardChart