import DataService from './DataService'

export const ScenarioTags = {
    Empty: "Empty",
    Analysis: "Analysis",
    Dashboard: "Dashboard",
    WeeklyMSR: "WeeklyMSR",
    DailyMSR: "DailyMSR",
    Insights: "Insights",
    Copilot: "Copilot",
}

const ChartService = {
    postDataQuery: (cubeName, request, token, scenarioTag) => {
        request.CacheOnly = false
        return DataService.post(cubeName + "/dataQuery", request, token, scenarioTag)
    },

    postDataCacheQuery: (cubeName, request, token, scenarioTag) => {
        request.CacheOnly = true
        return DataService.post(cubeName + "/dataQuery", request, token, scenarioTag)
    },

    postMaxDate: (cubeName, request, token) => {
        return DataService.post(cubeName + "/MaxDate", request, token)
    }
}

export default ChartService