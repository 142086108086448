import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import EvoDataService from '../../Dataservices/EvoDataService'
import './CosmicMappings.css';

class CommonMappingTableRow extends Component {
    constructor(props) {
        super(props)

        this.state = {
            item: this.props.item,
            isEditing: false
        }
    }

    onSave = payload => {
        this.setState({ 
            item: payload,
            isEditing: false
        });
        
        EvoDataService.editCommonMapping(payload).catch(err => {
            console.error(err);
            this.props.setError(err.message);
        })
    }

    onEdit = () => {
        this.setState({ isEditing: true});
    }

    onChangeLegend = (value, item) => {
        item.legend = value;
        this.setState({ item: item});
    }

    onChangeDescription = (value, item) => {
        item.description = value;
        this.setState({ item: item});
    }

    render() {
        const { isEditing, item } = this.state

        return (
            <tr key={item.keyName + item.keyType}>
                <td className="limitMinWidth">{item.keyName}</td>
                <td className="limitMinWidth">{item.keyType}</td>
                <td>{isEditing ? <InputGroup label="Legend" className="mb-3"><Form.Control value={item.legend} onChange={(event) => this.onChangeLegend(event.target.value, item)}/></InputGroup> : item.legend}</td>
                <td>{isEditing ? <InputGroup label="Description" className="mb-3"><Form.Control value={item.description} onChange={(event) => this.onChangeDescription(event.target.value, item)}/></InputGroup> : item.description}</td>
                <td><Button variant="primary" onClick={isEditing ? this.onSave.bind(this, item) : this.onEdit.bind(this, item)}>{isEditing ? 'Save' : 'Edit'}</Button></td>
            </tr>
        );
    }
}

export default CommonMappingTableRow