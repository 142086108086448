import React, {Component} from 'react'
import uuid from 'react-uuid'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faRedo} from '@fortawesome/free-solid-svg-icons'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import DataService from '../../Dataservices/DataService'
import './RawLogQueryStatus.css'
import titles from '../../Store/titles.js'
import {getPageTitle} from '../../Functions/PageTitleHelper'
import {convertStringToUTCDate, formatUTCDateAsString} from '../../Functions/DateTimeHelper'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faRedo);

class RawLogQueryStatus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showAllUserJobs: false,
            refreshDisabled: false,
            queryStatusList: [],
            jobError: '',
            showJobErrorModal: false,
            isLoadingList: false,
            error: null
        }

        this.data = {
            startTime: new Date().getTime()
        }
    }

    componentDidMount() {
        this.getQueryStatusList();
        document.title = getPageTitle(titles.RAW_LOG_QUERY_STATUS, null);
    }

    onChangeShowAllUserJobs = () => {   
        let initial = this.state.showAllUserJobs;
        this.setState(initialState => ({
           showAllUserJobs: !initialState.showAllUserJobs
        }), this.getQueryStatusList)       
        document.getElementById('show-all-user-jobs').innerHTML = (!initial ? "Show all user jobs " : "Do not show all user jobs") + (this.state.isLoadingList ? "loading" : "loaded");         
    } 

    onJobErrorModalClose = () => {
        this.setState({
            showJobErrorModal: false
        })
    }

    getQueryStatusList = () => {
        this.setState({
            refreshDisabled: true,
            isLoadingList: true           
        })        
        DataService.get('RawDataQuery/listJobs?showAllUsers=' + this.state.showAllUserJobs+'&skip=0&objectsPerPage=100').then(res => {
            this.setState({
                queryStatusList: res.data,
                refreshDisabled: false,
                isLoadingList: false
            })
            window.localStorage.setItem("loadTime", new Date().getTime() - this.data.startTime);
        }).catch(err => {
            console.log(err);
            this.setState({error : err.message});
        });       
    }

    getJobError = streamId => {
        DataService.post('RawDataQuery/getError', { StreamID: streamId }).then(res => {
            this.setState({
                jobError: res.data,
                showJobErrorModal: true
            })
        }).catch(err => {;
            console.log(err)
            this.setState({error : err.message});
        })
    }

    onRefresh = () => {
        this.getQueryStatusList();
        document.getElementById('raw-log-query-refresh').innerHTML = "Raw Log Query Status refreshed " + (this.state.isLoadingList ? "loading" : "loaded");
    }

    render() {
        return (
            <Container fluid className="rawLogQueryStatusContainer">
                {this.state.error && <Alert variant='danger'>{this.state.error}</Alert>}             
                <Modal show={this.state.showJobErrorModal} className="jobErrorModal">
                    <Modal.Header closeButton onHide={this.onJobErrorModalClose}>
                        <Modal.Title>Job Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="rawLogQueryStatusJobErrorModalBody">{this.state.jobError}</p>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.onJobErrorModalClose}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>

                <Row>
                    <Col className="rawLogQueryStatusTopChoice">
                        <Button variant="primary" onClick={this.onRefresh} disabled={this.state.refreshDisabled}>
                            { this.state.isLoadingList && <span><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />&ensp;</span> }
                            Refresh&ensp;
                            <FontAwesomeIcon icon={faRedo}/>
                        </Button>
                        <div id="raw-log-query-refresh" role="status" class="sr-only"></div>
                        <Form.Check type="checkbox" name="showAllUserJobs" label="Show All User Jobs" aria-label="Show All User Jobs" onChange={this.onChangeShowAllUserJobs} checked={this.state.showAllUserJobs} />                
                        <div id="show-all-user-jobs" role="status" class="sr-only"></div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3 style={{textAlign: 'start'}}>Your Query Status</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th scope="col">User name</th>
                                        <th scope="col">Description (Url Embedded)</th>
                                        <th scope="col">Raw Data Date</th>
                                        <th scope="col">Job Status</th>
                                        <th scope="col">Job Finish Date</th>
                                        <th scope="col">ADLA Job ID</th>
                                        <th scope="col">File Path</th>
                                        <th scope="col">Job Error</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.queryStatusList.map(status => {
                                        const url = new URL(status.url);

                                        return <tr key={uuid()}>
                                            <td>{status.userName}</td>
                                            <td><a className="accessibleLink" href={url.pathname + url.search} target="_blank" rel="noreferrer">{status.description}</a></td>
                                            <td>{status && status.fullStartDate ? formatUTCDateAsString(convertStringToUTCDate(status.fullStartDate), true) : ''}</td>
                                            <td>
                                                {status.cosmosJobs.map(job => (
                                                    <p className="rawLogQueryStatusTableP" key={uuid()}>{job.jobStatus}</p>
                                                ))}
                                            </td>
                                            <td>
                                                {status.cosmosJobs.map(job => (
                                                    <p className="rawLogQueryStatusTableP" key={uuid()}>{job.finishDate}</p>
                                                ))}
                                            </td>
                                            <td>
                                                {status.cosmosJobs.map(job => (
                                                    <p className="rawLogQueryStatusTableP" key={uuid()}>{job.cosmosJobId && job.jobStatus != 'Expired'? <a className="accessibleLink" href={'https://exchange-storage-prod-c14.azuredatalakeanalytics.net/jobLink/' + job.cosmosJobId} target="_blank">{job.cosmosJobId}</a>: null}</p>
                                                ))}
                                            </td>
                                            <td>
                                                {status.cosmosJobs.map(job => (
                                                    <p className="rawLogQueryStatusTableP" key={uuid()}><a className="accessibleLink" href={'https://aad.cosmos14.osdinfra.net/cosmos/office.adhoc/shares/exchange.storage.prod' + job.dataPath + '?property=info'} target="_blank">{job.dataName}</a></p>
                                                ))}
                                            </td>
                                            <td>
                                                {status.cosmosJobs.map(job => (
                                                    <p className="rawLogQueryStatusTableP" key={uuid()}>{job.hasError === "True" ? <a className="accessibleLink" tabIndex={0} onClick={this.getJobError.bind(this, job.streamId)}>Job Error</a> : ''}</p>
                                                ))}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default RawLogQueryStatus;