import React from 'react'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'

function ValidationInput(props) {
    const { name, isRequired, value, error, onChange, placeholder, disabled, width } = props
    
    return (
        <InputGroup hasValidation onClick={e => e.stopPropagation()} style={{ width: width ? width : 'auto' }}>
            <Form.Control type="text"
                name={name}
                required={isRequired} 
                isInvalid={error} 
                value={value} 
                placeholder={placeholder} 
                onChange={onChange} 
                disabled={disabled}
                onClick={e => e.stopPropagation()}/>
            <Form.Control.Feedback type="invalid" style={{ textAlign: "start" }}>
                {error}
            </Form.Control.Feedback>
        </InputGroup>
    )
}

export default ValidationInput