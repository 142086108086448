import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {useState} from 'react'
import Table from 'react-bootstrap/Table'
import uuid from 'react-uuid'
import NoDataContainer from '../ChartComponents/NoDataContainer'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faCaretDown, faCaretUp);

export function PercentileTable(props) {

        const [sortKey, setSortKey] = useState(null);
        const [reversed, setReversed] = useState(false);

        let compare = (a,b) => {
            if(sortKey === null) return 0;
            let comparision = 0; 
            if (sortKey === "legend"){
                comparision = a.legend > b.legend ? 1 : -1;
            }
            else{
                let itemA = sortKey === "samples" ? a.samples[0] : a.latency[sortKey];
                let itemB = sortKey === "samples" ? b.samples[0] : b.latency[sortKey];
                comparision = parseFloat(itemA.replace(/,/g, '')) > parseFloat(itemB.replace(/,/g, '')) ? -1 : 1 ;
            }
            return reversed ? -comparision : comparision;
        }
    
        let resetSortType = (sortKey) => {
            setSortKey(sortKey);
            setReversed(false);
        }

        let renderSortMark = () => {
            return <span><FontAwesomeIcon icon={ reversed ? faCaretUp : faCaretDown }/>&ensp;</span>;
        } 

        if (props.tableData.updatedTime !== null && !props.errorMessage){
            return (
                <div style={{ overflow: 'auto', width: '100%', height: '100%' }} aria-live="polite" aria-atomic="true">
                    <h3>{props.title}</h3>
                    <Table striped bordered hover style={{marginBottom: '0'}}>
                        <thead>
                            <tr>
                                <th onClick={() => sortKey === "legend" ? setReversed(!reversed) : resetSortType("legend")}>{sortKey === "legend" && renderSortMark()}Legend</th>
                                {props.tableData.latencyHeader?.map((e,i)=>(<th key={uuid()} onClick={() => sortKey === i ? setReversed(!reversed) : resetSortType(i)}>{sortKey === i && renderSortMark()}{e}</th>))}
                                <th onClick={() => sortKey === "samples" ? setReversed(!reversed) : resetSortType("samples")}>{sortKey === "samples" && renderSortMark()}Samples</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.tableData.table?.sort(compare).map(data => (
                                <tr key={uuid()}>
                                    <td>{data.legend}</td>
                                    {
                                        data.latency.map(e=>(<td key={uuid()}>{e}</td>))
                                    }
                                    <td>{data.samples[0]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            );
        }
        else {
            return <NoDataContainer title={props.title} errorMessage={props.errorMessage} defaultMessage="Click Graph it to see results"/>
        }
}

export default PercentileTable