import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import EvoDataService from '../../Dataservices/EvoDataService';
import ServiceLevelMappings from './ServiceLevelMappings';
import CommonMappings from './CommonMappings';
import Alert from 'react-bootstrap/Alert';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './CosmicMappings.css';

function CosmicMappings()
{
    let [tab, setTab] = useState('');
    let [error, setError] = useState('');
    let [services, setServices] = useState([]);
    let [selectedService, setSelectedService] = useState('');
    let [mappingPromoted, setMappingPromoted] = useState(false);

    useEffect(() => {
        EvoDataService.getAllServicesWithMapping().then(res => {
                setServices(res.data);
            }).catch(err => {
                console.error(err);
                setError(err);
            })
        }, [])
    
    useEffect(() => {
        if (selectedService !== '' && selectedService !== null) {
            setMappingPromoted(false);
        }
    }, [selectedService])

    return (
        <Container fluid className="CosmicMappingsContainer">
            {error && <Alert variant = "danger">{error}</Alert>}
            <Nav variant="tabs">
                <Nav.Item onClick={() => {setTab("commonLevel"); setSelectedService("");}}>
                    <Nav.Link eventKey="common">Common Mappings</Nav.Link>
                </Nav.Item>
                <NavDropdown title={selectedService ==='' || selectedService === null ? "Select service level mappings" : selectedService}>
                    {
                        services.map(service => (
                            <NavDropdown.Item eventKey={service} onClick={() => {setTab("serviceLevel"); setSelectedService(service);}}>{service}</NavDropdown.Item>
                        ))
                    }
                </NavDropdown>
            </Nav>
            {tab === "commonLevel" && <CommonMappings setError={setError} mappingPromoted={mappingPromoted}/>}
            {tab === "serviceLevel" && selectedService !== "" && selectedService !== null && <ServiceLevelMappings service={selectedService} setError={setError}  mappingPromoted={mappingPromoted} setMappingPromoted={setMappingPromoted}/>}
        </Container>
    )
}

export default CosmicMappings;
