import React, { useState, useEffect, useRef } from 'react'
import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Pagination from 'react-bootstrap/Pagination'
import Select from 'react-select'
import AnnotationService from '../../Dataservices/AnnotationDataService'
import AnnotationManagementTableRow from './AnnotationManagementTableRow'
import { SortOrderEnum, filterObject, filterObjectWithArray, compareString, getNextSortOrder, getCurrentPageList, getPageNumbersToDisplay, renderSortMark } from '../../Functions/TableHelper'
import { Tabs } from '../../Functions/ChartHelpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import titles from '../../Store/titles.js';
import { getPageTitle } from '../../Functions/PageTitleHelper'
import { library } from "@fortawesome/fontawesome-svg-core";
import ReactDatePicker from '../ReactDatepicker';

library.add(faTimes);

export function AnnotationManagement (props) {
    const { loginUser, cubes } = props

    useEffect(() => {
        document.title = getPageTitle(titles.ANNOTATION_MANAGEMENT, null);
    }, [])

    const headerDbNames = {
        none: 'None',
        date: 'date',
        annotationText: 'annotation1',
        cube: 'cube',
        tab: 'tab',
        legend: 'legend',
        user: 'user',
        lastModifiedDate: 'lastModifiedDate',
        lastModifiedBy: 'lastModifiedBy',
        link: 'url',
        isDeleted: 'isDeleted'
    }
    const annotationHeaders = [
        {friendlyName: "Date", dbName: headerDbNames.date, isSortable: true},
        {friendlyName: "Annotation", dbName: headerDbNames.annotationText, isSortable: true},
        {friendlyName: "Cube", dbName: headerDbNames.cube, isSortable: true},
        {friendlyName: "Tab", dbName: headerDbNames.tab, isSortable: true},
        {friendlyName: "Legend", dbName: headerDbNames.legend, isSortable: true},
        {friendlyName: "Added By", dbName: headerDbNames.user, isSortable: true},
        {friendlyName: "Last Modified", dbName: headerDbNames.lastModifiedDate, isSortable: true},
        {friendlyName: "Last Modified By", dbName: headerDbNames.lastModifiedBy, isSortable: true},
        {friendlyName: "Link to Chart", dbName: headerDbNames.link, isSortable: false},
        {friendlyName: "Is Deleted", dbName: headerDbNames.isDeleted, isSortable: false}
    ]

    const [displayAnnotationList, setDisplayAnnotationList] = useState([]);
    const [rawAnnotationList, setRawAnnotationList] = useState([]);
    const [alteredAnnotationList, setAlteredAnnotationList] = useState([]);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [sortOrder, setSortOrder] = useState(SortOrderEnum.Descending);
    const [sortKey, setSortKey] = useState(headerDbNames.date);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [pageButtons, setPageButtons] = useState([]);
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [annotationFilter, setAnnotationFilter] = useState('')
    const [cubeFilter, setCubeFilter] = useState([])
    const [tabFilter, setTabFilter] = useState([])
    const [legendFilter, setLegendFilter] = useState('')
    const [addedByFilter, setAddedByFilter] = useState('')
    const [isDeletedFilter, setIsDeletedFilter] = useState([])
    const [cubeOptions, setCubeOptions] = useState([])


    const compareObject = (key, isAscending) => {
        return (obj1, obj2) => {
            return compareString(obj1[key], obj2[key], isAscending, true)
        }
    }

    const onSort = (key) => {
        const nextOrder = getNextSortOrder(sortOrder, key !== sortKey, true)
        setSortKey(key)
        setSortOrder(nextOrder)
    }

    const onClickPagination = (clickedNum) => {
        if(clickedNum > numberOfPages) {
            clickedNum = 1
        }

        const pages = getPageNumbersToDisplay(clickedNum, numberOfPages)
        setDisplayAnnotationList(getCurrentPageList(alteredAnnotationList, clickedNum));
        setCurrentPage(clickedNum);
        setPageButtons(pages);
    }

    const getAnnotationList = () => {
        let isUnmount = false
        AnnotationService.getAnnotationList().then(res => {
            if (!isUnmount) {
                setRawAnnotationList(res.data)
                setIsLoading(false)
            }
        }).catch(err => {
            console.error(err);
            if (!isUnmount) {
                setError(err.message);
                setIsLoading(false);
            }
        })

        return () => isUnmount = true
    }

    useEffect(getAnnotationList, []); // pass an empty array so this behaves like componentDidMount

    const updateCubeName = () => {
        if (cubes.length > 0) {
            setCubeOptions(cubes.filter(cube => cube.CubeStatusCode !== 4 || loginUser.isAdmin).map(cube => {
                return {
                    value: cube.AppName,
                    label: cube.DecorateDisplayName
                }
            }))

            if (rawAnnotationList.length > 0) {
                let allAnnotations = rawAnnotationList.filter(item => {
                    const cubeObj = cubes.find(c => c.AppName.toLowerCase() === item.cube.toLowerCase())
                    return loginUser.isAdmin || (!item.isDeleted && cubeObj && cubeObj.CubeStatusCode !== 4)
                }).map(item => {
                    const cubeObj = cubes.find(c => c.AppName.toLowerCase() === item.cube.toLowerCase())
                    const cube = cubeObj ? cubeObj.DecorateDisplayName : item.cube
                    item.cube = cube
                    return item
                })
                setRawAnnotationList(allAnnotations)
                setAlteredAnnotationList([...allAnnotations])
            }
        }
    }

    useEffect(updateCubeName, [isLoading, cubes])

    const updateAnnotationLists = () => {
        let annotationListCopy = [...rawAnnotationList]

        // filters
        if (startDate) {
            annotationListCopy = annotationListCopy.filter(anno => new Date(anno.date) >= startDate)
        }

        if (endDate) {
            annotationListCopy = annotationListCopy.filter(anno => new Date(anno.date) <= endDate)
        }

        if (annotationFilter) {
            annotationListCopy = annotationListCopy.filter(anno => filterObject(anno, annotationFilter, [headerDbNames.annotationText]))
        }

        if (cubeFilter && cubeFilter.length > 0) {
            annotationListCopy = annotationListCopy.filter(anno => filterObjectWithArray(anno, cubeFilter, [headerDbNames.cube]))
        }

        if (tabFilter && tabFilter.length > 0) {
            annotationListCopy = annotationListCopy.filter(anno => filterObjectWithArray(anno, tabFilter, [headerDbNames.tab]))
        }

        if (legendFilter) {
            annotationListCopy = annotationListCopy.filter(anno => filterObject(anno, legendFilter, [headerDbNames.legend]))
        }

        if (addedByFilter) {
            annotationListCopy = annotationListCopy.filter(anno => filterObject(anno, addedByFilter, [headerDbNames.user]))
        }

        if (isDeletedFilter && isDeletedFilter.length > 0) {
            annotationListCopy = annotationListCopy.filter(anno => filterObjectWithArray(anno, isDeletedFilter, [headerDbNames.isDeleted]))
        }

        //sort
        if (sortKey && sortKey !== headerDbNames.none && sortOrder !== SortOrderEnum.None) {
            annotationListCopy.sort(compareObject(sortKey, sortOrder === SortOrderEnum.Ascending))
        }

        setAlteredAnnotationList(annotationListCopy)

        //pagination
        const totalPage =  Math.ceil(annotationListCopy.length / 15)
        setNumberOfPages(totalPage)
        const newCurrentPage = currentPage > totalPage ? 1 : currentPage
        setDisplayAnnotationList(getCurrentPageList(annotationListCopy, newCurrentPage))
        const pages = getPageNumbersToDisplay(newCurrentPage, totalPage)
        setPageButtons(pages)
    }

    useEffect(updateAnnotationLists, [rawAnnotationList, startDate, endDate, sortKey, sortOrder, annotationFilter, cubeFilter, tabFilter, legendFilter, addedByFilter, isDeletedFilter])

    const onStartDateChangeFilter = date => {
        setStartDate(date)
    }

    const onEndDateChangeFilter = date => {
        setEndDate(date)
    }

    const onChangeAnnotationFilter = e => {
        setAnnotationFilter(e.target.value)
    }

    const onChangeCubeFilter = item => {
        setCubeFilter(item ? item.map(i => i.label) : [])
    }

    const onChangeTabFilter = item => {
        setTabFilter(item ? item.map(i => i.value) : [])
    }

    const onChangeLegendFilter = e => {
        setLegendFilter(e.target.value)
    }

    const onChangeAddedByFilter = e => {
        setAddedByFilter(e.target.value)
    }

    const onChangeIsDeletedFilter = item => {
        setIsDeletedFilter(item ? item.map(i => i.value) : [])
    }

    return (
        <Container fluid className="annoManagement">
            {error && <Row><Alert variant='danger' style={{width: '100%'}}>
                    {error}    
                </Alert></Row>
            }
            <Row>
                <Col>
                    <Form>
                        <Row>
                            <Col>
                                <h3 className="filterHeader">Add filters</h3>
                            </Col>
                        </Row>
                        <Row className="mb-3">                            
                            <Form.Group as={Col} controlId="filterStartDate" className="filterWrap">
                                <ReactDatePicker 
                                    value={startDate}
                                    placeholder="Start date"
                                    onChange={onStartDateChangeFilter}
                                    className="gray-border flex-width"
                                 />     
                            </Form.Group>
                            <Form.Group as={Col} controlId="filterEndDate" className="filterWrap">
                                <ReactDatePicker  
                                      value={endDate}
                                      placeholder="End date"
                                      onChange={onEndDateChangeFilter}   
                                      className="gray-border flex-width"
                                  />
                            </Form.Group>

                            <Form.Group as={Col} controlId="filterAnnotation">
                                <Form.Control type="text" className="textFilter" placeholder="Filter Annotation" onChange={onChangeAnnotationFilter}/>
                            </Form.Group>

                            <Form.Group as={Col} controlId="filterCube">
                                <Select options={cubeOptions}
                                    placeholder="Filter Cube"
                                    aria-label="Filter Cube"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    onChange={onChangeCubeFilter}/>
                            </Form.Group>

                            <Form.Group as={Col} controlId="filterTab">
                                <Select options={Tabs.map(tab => { 
                                        return {value: tab.dbName, label: tab.friendlyName }
                                    })}
                                    placeholder="Filter Tab"
                                    aria-label="Filter Tab"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    onChange={onChangeTabFilter}/>
                            </Form.Group>

                            <Form.Group as={Col} controlId="filterLegend">
                                <Form.Control type="text" className="textFilter" placeholder="Filter Legend" onChange={onChangeLegendFilter}/>
                            </Form.Group>

                            <Form.Group as={Col} controlId="filterAddedBy">
                                <Form.Control type="text" className="textFilter" placeholder="Filter Added By" onChange={onChangeAddedByFilter}/>
                            </Form.Group>

                            {loginUser.isAdmin && <Form.Group as={Col} controlId="filterIsDeleted">
                                    <Select options={[{label: 'True', value: true}, {label: 'False', value: false}]}
                                    placeholder="Filter IsDeleted"
                                    aria-label="Filter IsDeleted"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    onChange={onChangeIsDeletedFilter}/>
                            </Form.Group>}
                        </Row>
                    </Form>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div style={{overflowX: 'auto'}}>
                        <Table striped bordered hover size="sm" style={{textAlign: 'left'}}>
                            <thead>
                                <tr>
                                    {annotationHeaders.map(header => (
                                        (loginUser.isAdmin || header.dbName !== headerDbNames.isDeleted) ? <th onClick={header.isSortable ? () => onSort(header.dbName) : null} className="tableHeader" key={header.dbName}>
                                            {renderSortMark(sortKey, sortOrder, header.dbName)}
                                            {header.friendlyName}
                                        </th> : null
                                    ))}
                                </tr>
                            </thead>
                            
                            <tbody>
                                {displayAnnotationList.map((item) => <AnnotationManagementTableRow key={item.ID} annotation={item} isAdmin={loginUser.isAdmin}/>)}
                            </tbody>
                        </Table>
                    </div>
                    {isLoading && <img alt="" src="../gears.gif"/>}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Pagination style={{overflowX: 'auto'}}>
                        <Pagination.First disabled={currentPage === 1 || currentPage === 0 ? true : false} onClick={() => onClickPagination(1)}/>
                        <Pagination.Prev disabled={currentPage === 1 || currentPage === 0 ? true : false} onClick={() => onClickPagination(currentPage - 1)} />
                        {pageButtons.map(num => num === 0 ? <Pagination.Ellipsis disabled key={num}/> : <Pagination.Item key={num} onClick={() => onClickPagination(num)} active={currentPage === num ? true : false}>{num}</Pagination.Item>)}
                        <Pagination.Next disabled={currentPage === numberOfPages ? true : false} onClick={() => onClickPagination(currentPage + 1)}  />
                        <Pagination.Last disabled={currentPage === numberOfPages ? true : false} onClick={() => onClickPagination(numberOfPages)}/>
                    </Pagination>
                </Col>
            </Row>
        </Container>
    )
}

export default AnnotationManagement