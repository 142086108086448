import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

class BannerManagementModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            subjectInput: props.subject,
            detailInput: props.detail
        }
    }

    formatTime = time => {
        return new Date(time.toLocaleString() + ' UTC').toLocaleString()
    }

    onCancel = () => {
        const { subject, detail, onBannerCancel } = this.props
        this.setState({ 
            detailInput: detail,
            subjectInput: subject
        })

        onBannerCancel()
    }

    onSubjectChange = e => {
        this.setState({ subjectInput: e.target.value })
    }

    onDetailChange = e => {
        this.setState({ detailInput: e.target.value })
    }

    onClear = () => {
        this.setState({
            detailInput: '',
            subjectInput: ''
        })
    }

    render() {
        const { showModal, user, time, onBannerSave } = this.props
        const { detailInput, subjectInput } = this.state
        return (
            <Modal show={showModal} onHide={this.onCancel}>
                    <Modal.Header>
                        <Modal.Title>Edit PerfPanel Status Banner</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group as={Row} controlId="subject">
                                <Form.Label column sm="2">
                                    Subject:
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control type="text" value={subjectInput} onChange={this.onSubjectChange}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="detail">
                                <Form.Label column sm="2">
                                    Detail:
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control as="textarea" rows="10" value={detailInput} onChange={this.onDetailChange}/>
                                </Col>
                            </Form.Group>

                            <Form.Control plaintext readOnly defaultValue={'Last edited by: ' + user + ' at ' + this.formatTime(time)} />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="bannerClearBtn">
                            <Button variant="danger" onClick={this.onClear} id="clearBtn">
                                Clear
                            </Button>
                        </div>
                        <Button variant="primary" onClick={onBannerSave.bind(this, subjectInput, detailInput)} id="saveBtn">
                            Save
                        </Button>
                        <Button variant="warning" onClick={this.onCancel} id="cancelBtn">
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
        )
    }
}

export default BannerManagementModal