import DataService from './DataService';
import { loadUserInfo } from './AuthUtils';
import config from '../config';

const pool = ["userInfo", "firstLoadingTime", "interactionTime"]

// Filter out data query and graph part since this part takes a too long time 
// We will monitor it through cube loading time, and this latency record focuses on other component of pages
const filterResourcePath = ["dataQuery", "dataQueryOverrides", "data", "AnnotationsForChart", "gears.gif"]

/*********************************************************************************
 * function: log the performance entries for debug usage.
 * input: none
 * output: none
 * ******************************************************************************/
function printPerformanceEntries() {
    // Use getEntries() to get a list of all performance entries
    let p = performance.getEntries();
    for (let i = 0; i < p.length; i++) {
        console.log("PerformanceEntry[" + i + "]");
        printPerformanceEntry(p[i]);
    }
}

function printPerformanceEntry(perfEntry) {
    let properties = ["name", "entryType", "startTime", "duration"];
    for (let i = 0; i < properties.length; i++) {
        let supported = properties[i] in perfEntry;
        if (supported) {
            let value = perfEntry[properties[i]];
            console.log("... " + properties[i] + " = " + value);
        } else {
            console.log("... " + properties[i] + " is NOT supported");
        }
    }
}

/*******************************************************************************
 * function: calculate the certain loading time of the navigation part
 * input: none
 * output: navigation time list
 ******************************************************************************/
function getNavigationTime() {
    // Use getEntriesByType("navigation") to get a list of navigation performance entries
    const timing = window.performance.getEntriesByType("navigation")[0];
    let naviTime = {};
    if (timing) {
        // Total time from start to load
        naviTime.loadTime = timing.duration;
        // Time spent constructing the DOM tree
        naviTime.domReadyTime = timing.domComplete - timing.domInteractive;
        // Time consumed preparing the new page
        naviTime.readyStart = timing.fetchStart - timing.redirectEnd;
        // Time spent during redirection
        naviTime.redirectTime = timing.redirectEnd - timing.redirectStart;
        // AppCache
        naviTime.appcacheTime = timing.domainLookupStart - timing.fetchStart;
        // DNS query time
        naviTime.lookupDomainTime = timing.domainLookupEnd - timing.domainLookupStart;
        // TCP connection time
        naviTime.connectTime = timing.connectEnd - timing.connectStart;
        // Time spent during the request
        naviTime.requestTime = timing.responseEnd - timing.requestStart;
        // Request to completion of the DOM loading
        naviTime.initDomTreeTime = timing.domInteractive - timing.responseEnd;
        // Load event time
        naviTime.loadEventTime = timing.loadEventEnd - timing.loadEventStart;

        return naviTime;
    }
}

/*******************************************************************************
 * function: record the loading time of the resources and components
 * input: none
 * output: component loading time list
 ******************************************************************************/
function getComponentLoadingTime() {
    const entries = window.performance.getEntriesByType("resource");

    let componentTime = [];
    entries.map(entry => {
        const url = new URL(entry.name);
        let pathname = url.pathname.split("/");
        pathname = pathname[pathname.length-1];
        if (url.hostname === window.location.hostname && !filterResourcePath.includes(pathname)) {
            const resourceInfo = {
                name: url.pathname,
                entryType: entry.entryType,
                startTime: entry.startTime,
                duration: entry.duration,
            }
            componentTime.push(resourceInfo)
        }
    })
    return componentTime;
}

/*******************************************************************************
 * function: record the loading time of the resources and components
 * input: none
 * output: component loading time list
 ******************************************************************************/
function getTotalLatency() {
    const navigation = window.performance.getEntriesByType("navigation")[0];
    const entries = window.performance.getEntriesByType("resource");

    // We take the last loading resource finished time as the total page finished time. 
    // Filter out query related request since it takes a long time and we will monitor it using PerfPanelPerf cube
    let totalLatency = navigation.loadEventEnd;
    entries.map(entry => {
        const url = new URL(entry.name);
        let pathname = url.pathname.split("/");
        pathname = pathname[pathname.length-1];
        if (url.hostname === window.location.hostname && !filterResourcePath.includes(pathname)) {
            if(entry.startTime <= totalLatency) {
                totalLatency = totalLatency > entry.responseEnd ? totalLatency : entry.responseEnd;
            } 
        }
    })
    return totalLatency;
}

/*******************************************************************************
 * purpose: record the loading time of the resource and component loading part
 * input: none
 * output: component loading time list
 ******************************************************************************/
export function handleFirstLoadingTime() {
    // show the whole entries in the console
    // printPerformanceEntries();

    if (config.enableLatencyRecording && window.localStorage.getItem("firstLoadingTime") === null) {
        // Use the url path as controlID
        let controlID = window.location.pathname.toLowerCase();

        // prepare 3 parts of first loading time
        const totalLatency = getTotalLatency();
        const navigationTime = getNavigationTime();
        const componentLoadingTime = getComponentLoadingTime();

        // add those data into the set
        let firstLoadingTime = {
            "date": new Date().toLocaleString(),
            "controlID": controlID,
            "eventID": 1006,
            "detail": JSON.stringify({
                "totalLatency": totalLatency,
                "navigationTime": navigationTime,
                "componentLoadingTime": componentLoadingTime,
            }),
        };

        window.localStorage.setItem("firstLoadingTime", JSON.stringify(firstLoadingTime));
    }
}

/*******************************************************************************
 * purpose: record the interaction loading time for the users' re-render events
 * input: elementName, tab, page, functionTime, queryTime, renderTime,totalLatency, chartTitle, dashboardInfo
 * output: interaction loading time list
 ******************************************************************************/
export function handleInteractionLatency(elementName, tab, page, functionTime, queryTime, renderTime, totalLatency, chartTitle = null, dashboardInfo = null) {
    if (config.enableLatencyRecording) {

        // page is thought to be finished loading when user start to interact with it
        handleFirstLoadingTime();

        // get previous state of InteractionLatency
        let preState = JSON.parse(window.localStorage.getItem("interactionTime")) === null ? [] : JSON.parse(window.localStorage.getItem("interactionTime"));

        // prepare controlID
        let controlID = "/" + page + "/" + elementName + "/" + tab;

        // if it happens at dashboard page
        let curInteractionLatency = {}
        if (page === "dashboard") {
            curInteractionLatency = {
                "date": new Date().toLocaleString(),
                "controlID": controlID,
                "eventID": 1005,
                "detail": JSON.stringify({
                    "charTitle": chartTitle,
                    "cubeName": elementName,
                    "functionTime": functionTime,
                    "queryTime": queryTime,
                    "renderTime": renderTime,
                    "totalLatency": totalLatency,
                    "tab": tab,
                    "page": page,
                    "dashboardInfo": dashboardInfo
                }),
            };
            //if it happens at analysis page
        } else {
            curInteractionLatency = {
                "date": new Date().toLocaleString(),
                "controlID": controlID,
                "eventID": 1005,
                "detail": JSON.stringify({
                    "cubeName": elementName,
                    "functionTime": functionTime,
                    "queryTime": queryTime,
                    "renderTime": renderTime,
                    "totalLatency": totalLatency,
                    "tab": tab,
                    "page": page,
                })
            };
        }

        //add current cube data into "curInteractionLatency" collections
        preState.push(curInteractionLatency);
        window.localStorage.setItem("interactionTime", JSON.stringify(preState));
    }
}

/*******************************************************************************
 * purpose: sent a post request to the back end for saving the data.
 * input: none
 * output: none
 ******************************************************************************/
function saveLatencyData() {
    //if configure enableDataSaving == true, event data will be stored in backend db, then clear localStorage'
    if (config.enableLatencyRecording) {
        let request = {
            userInfo: {},
            saveData: [],
        };

        // load user authorization info
        loadUserInfo().then(() => {
            //calculate first loading time
            handleFirstLoadingTime();

            //collect event data in the localStorage and create a request
            for (let i in pool) {
                let key = pool[i];
                if (window.localStorage.getItem(key) !== null) {
                    if (key === "userInfo") {
                        request[key] = JSON.parse(window.localStorage.getItem(key));
                    } else if (key === "firstLoadingTime") {
                        const data = JSON.parse(window.localStorage.getItem(key));
                        request.saveData.push(data);
                    } else {
                        let data = JSON.parse(window.localStorage.getItem(key))
                        for (let item in data) {
                            request.saveData.push(data[item]);
                        }
                    }
                }
            }
            DataService.post("latencyrecord/addUserLatency", request, false).then(res => {
            }).catch(error => {
                console.log("Failed to save user data");
                console.log(error);
            });
        })
    }
}

//If directly call LatenctyRecording_function() in App.js, removeEventListener() won't work,
//listener will accumulates as user enters more pages and won't be removed.
//By wrapping it with a helper function LatenctyRecording(), such scenario could be avoided.
export var LatencyRecording = function () {
    LatencyRecording_function();
}

export function LatencyRecording_function() {
    if (config.enableLatencyRecording) {
        // create a listener, this listener will be triggered each time the window is "beforeunload"
        const listener = ev => {
            if (config.enableDataSaving) {
                saveLatencyData();
                // Data should be removed immediately since this request is sent in before unload event, page will be unload soon
                for (let i in pool) {
                    if (pool[i] !== "userInfo") {
                        window.localStorage.removeItem(pool[i]);
                    }
                }
            }
        };

        window.addEventListener('beforeunload', listener);
        return () => {
            window.removeEventListener('beforeunload', listener)
        }
    }
}
