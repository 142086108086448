import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompress, faExpand, faPlus } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import AddAnnotationModal from './AddAnnotationModal'
import { convertStringToUTCDate, formatUTCDateAsString } from '../../Functions/DateTimeHelper'
import './Annotation.css'
import AnnotationRow from './AnnotationRow';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faCompress, faExpand, faPlus);

class Annotation extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isExpanding: false,
            showDeleteAnnotationModal: false,
            showEditAnnotationModal: false,
            showAddAnnotationModal: false
        }
    }

    toggleExpanding = (e) => {
        if (e) {
            e.stopPropagation();
        }
        
        this.setState(prevState => {
            return {
                isExpanding: !prevState.isExpanding
            }
        })
    }

    toggleAddAnnotationModal = (e) => {
        if (e) {
            e.stopPropagation();
        }
        
        this.props.toggleShowAnyModal()
        this.setState(prevState => {
            return {
                showAddAnnotationModal: !prevState.showAddAnnotationModal
            }
        })
    }

    deleteAnnotation = (id) => {
        const { onDeleteAnnotation } = this.props
        onDeleteAnnotation(id)
    }

    editAnnotation = (id, newText) => {
        const { onEditAnnotation } = this.props
        onEditAnnotation(id, newText)
    }

    render() {
        const { annotation, loginUser, tab, cube, currentPoint, onAddAnnotation, toggleShowAnyModal, url } = this.props
        const { x, y, color, date, legend, content } = annotation
        const { isExpanding, showAddAnnotationModal } = this.state

        const style = { position: 'absolute', left: x + 'px', top: y + 'px', zIndex: 100 }
        const iconStyle = { position: 'absolute', left: x + 'px', top: (y - 16) + 'px', zIndex: 99, backgroundColor: color }

        return (
            <React.Fragment>
                {isExpanding ? 
                <div style={style} className="annotation">
                    <div className="annoHeader">
                        <div className="annoFirstLine">
                                {date && <span><span className="annoFooterKey">Date:</span> {formatUTCDateAsString(convertStringToUTCDate(date))}<br/></span>}
                            {legend && <span><span className="annoFooterKey">Legend:</span> {legend}</span>}
                        </div>
                        <ButtonGroup>
                            <Button variant="outline-light" size="sm" onClick={this.toggleAddAnnotationModal}><FontAwesomeIcon icon={faPlus}/></Button>
                            <Button variant="outline-light" size="sm" onClick={this.toggleExpanding}><FontAwesomeIcon icon={faCompress}/></Button>
                        </ButtonGroup>
                        <AddAnnotationModal 
                            tab={tab} 
                            cube={cube} 
                            url={url}
                            currentPoint={currentPoint} 
                            onAddAnnotation={onAddAnnotation} 
                            toggleModal={this.toggleAddAnnotationModal} 
                            showModal={showAddAnnotationModal}/>
                    </div>
                    <div className="annoContent">
                        {content.map((anno, index) => (
                            <AnnotationRow 
                                key={anno.id}
                                deleteAnnotation={this.deleteAnnotation}
                                editAnnotation={this.editAnnotation}
                                annotation={anno}
                                index={index}
                                loginUser={loginUser}
                                toggleShowAnyModal={toggleShowAnyModal}
                                date={date}/>
                        ))}
                    </div>
                </div> : 
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${date}`}>
                            Click to see the annotation here
                        </Tooltip>
                    }>
                    <button style={iconStyle} className="annoCompress" size="sm" onClick={this.toggleExpanding}><FontAwesomeIcon icon={faExpand} /></button>
                </OverlayTrigger>}
            </React.Fragment>
        );
    }
}


export default Annotation;