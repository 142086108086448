import React, { Component } from 'react';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import { SortOrderEnum, compareString, getNextSortOrder, renderSortMark, filterObject } from '../../Functions/TableHelper'
import {timeIntervalToString} from '../../Functions/DateTimeHelper';
import './PipelineStatusPage.css'

class MaintenanceJobMonitor extends Component {

    constructor(props) {
        super(props);

        this.data = props.data;

        this.statusObjectKeys = {
            None: 'None',
            StartTime: 'StartTime',
            ServerName: 'ServerName',
            RetryTimes: 'RetryTimes',
            JobState: 'JobState',
            JobName: 'JobName',
            JobID: 'JobID',
            JobDescription: 'JobDescription',
            HeartbeatTime: 'HeartbeatTime',
            TimeElapsed: 'TimeElapsed',
            HeartbeatInterval: 'HeartbeatInterval'
        }

        this.tableColumns = [
            ["Server Name", this.statusObjectKeys.ServerName], 
            ["Job Name", this.statusObjectKeys.JobName],
            ["Job State", this.statusObjectKeys.JobState],
            ["Job Description", this.statusObjectKeys.JobDescription],
            ["Retry Times", this.statusObjectKeys.RetryTimes],
            ["Time Elapsed", this.statusObjectKeys.TimeElapsed],
            ["Heart Beat", this.statusObjectKeys.HeartbeatInterval]
        ]

        let jobMonitorTable = props.data;
        for (let i in jobMonitorTable) {
            let startTime = new Date(jobMonitorTable[i].StartTime+'Z')
            let heartBeat = new Date(jobMonitorTable[i].HeartbeatTime+'Z')
            let now = Date.now()
            jobMonitorTable[i].DefaultKey = i;
            jobMonitorTable[i].TimeElapsed = timeIntervalToString(now - startTime);
            jobMonitorTable[i].HeartbeatInterval = timeIntervalToString(now - heartBeat);
        }

        this.state = {
            searchText: '',
            table: jobMonitorTable,
            tableSort: {
                key: this.statusObjectKeys.None,
                order: this.statusObjectKeys.None
            }
        }
    }

    compareObject = (key, isAscending) => {
        return (obj1, obj2) => {
            if (key === this.statusObjectKeys.RetryTimes) {
                return isAscending ? obj1[key] - obj2[key] : obj2[key] - obj1[key]
            } else if (key === this.statusObjectKeys.TimeElapsed || key === this.statusObjectKeys.HeartbeatInterval) {
                if (obj1[key].length !== obj2[key].length) {
                    return isAscending ? obj1[key].length - obj2[key].length : obj2[key].length - obj1[key].length
                } else {
                    return compareString(obj1[key], obj2[key], isAscending)
                }
            } else {
                // compare strings
                return compareString(obj1[key], obj2[key], isAscending)
            }
        }
    }

    sortList = (list, key, order) => {
        if (order === SortOrderEnum.None) {
            return list.sort(this.compareObject("DefaultKey", true))
        } else {
            return list.sort(this.compareObject(key, order === SortOrderEnum.Ascending))
        }
    }

    onChangeSearchText = e => {
        this.setState({
            searchText: e.target.value
        }, this.onSearch)
    }

    onSearch = () => {
        const {searchText} = this.state;
        let table = [];
        if (searchText.trim() === '') {
            table = this.data;
        } else {
            table = this.data.filter(x => filterObject(x, searchText, [
                this.statusObjectKeys.ServerName,
                this.statusObjectKeys.JobName,
                this.statusObjectKeys.JobDescription,
                this.statusObjectKeys.JobState
            ]))
        }
        this.setState({
            table: table,
        })
    }

    onSort = (key) => {
        let { tableSort, table }  = this.state
        let nextOrder = getNextSortOrder(tableSort.order, tableSort.key !== key)
    
        tableSort = {
            key: key,
            order: nextOrder
        };
        
        let sortedList = this.sortList(table, key, nextOrder)
        this.setState({
            table: sortedList,
            tableSort: tableSort
        })
    }

    onChangeSearchText = e => {
        this.setState({
            searchText: e.target.value
        }, this.onSearch)
    }

    render() {
        let { tableSort, table } = this.state
        let columns = this.tableColumns;
        return (
            <div>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group className="Label" controlId="maintenanceJobMonitorSearch">
                                <Form.Control style={{marginTop: 20}} type="text" placeholder="Search" onChange={this.onChangeSearchText}/>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col className="Label">
                        <div style={{ overflowX: 'auto', width: '100%' }} aria-live="polite" aria-atomic="true" aria-label={"Job Monitor, " + (table ? table.length + " results found" : "")}>
                            <Table striped bordered hover className="statusTable">
                                <thead>
                                    <tr>
                                        {
                                            columns.map(item=>{
                                                let friendly_name = item[0];
                                                let key_name = item[1];
                                                return (
                                                    <th key={key_name} onClick={this.onSort.bind(this, key_name)} className="tableHeader" scope="col">
                                                        {renderSortMark(tableSort.key, tableSort.order, key_name)}
                                                        {friendly_name}
                                                    </th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {table && table.map(status => (
                                        <tr key={status.DefaultKey}>
                                            {
                                                columns.map(item=>{
                                                    let key_name = item[1];
                                                    return (
                                                        <td key={key_name}>{status[key_name]}</td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default MaintenanceJobMonitor;
