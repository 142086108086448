import React, { useState, useCallback, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import Highcharts from 'highcharts';
import ExportingFactory from "highcharts/modules/exporting";
import ExportingDataFactory from "highcharts/modules/export-data";
import BrokenAxis from "highcharts/modules/broken-axis";
import Accessibility from "highcharts/modules/accessibility";
import HighchartsReact from 'highcharts-react-official';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";
import AddAnnotationModal from './AnnotationComponents/AddAnnotationModal';
import ChartCard from './ChartComponents/ChartCard';
import AnnotationDataService from '../Dataservices/AnnotationDataService';
import { getColor } from '../Functions/ColorHelper'
import { TabXValueMap, TabYValueMap, isZoomSupported, segmentSeriesForDate, getDateFromName } from '../Functions/ChartHelpers'
import { getStartDateFromUrl, getAnalyzeDateFromUrl, getIncludeWeekendsFromUrl } from '../Functions/UrlHelper'
import { msInHr, msInDay, convertStringToUTCDate, convertFromUTCToTimezone, formatUTCDateAsString } from '../Functions/DateTimeHelper.js';
import Legend from './ChartComponents/ChartLegend'
import ChartSetYModal from './ChartComponents/ChartSetYModal'
import DataTableModal from './ChartComponents/DataTableModal'
import NoDataContainer from './ChartComponents/NoDataContainer'
import ChartToolbar from './ChartComponents/ChartToolbar'
import ExpertInsightsDataService from '../Dataservices/ExpertInsightsDataService';
import './Chart.css'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Annotation from './AnnotationComponents/Annotation';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from 'react-bootstrap/Tooltip'

ExportingFactory(Highcharts);
ExportingDataFactory(Highcharts);
BrokenAxis(Highcharts);
Accessibility(Highcharts);

export function Chart(props) {

    library.add(faEye, faEyeSlash);

    //#region Initialization
    let { chartInfo, tab, cube, errorMessage, liftedShowRules, liftedProcessedRules, loginUser, isInExpertInsight, isInDashboard, isHourly, timezone, onChangeChartType, isChartingPaneHidden } = props;
    let hoverTimeout = null;
    const hoverTimeoutDuration = 1000;

    const { serverData, title, loading, chartType, xAxisType } = props.options;
    const [mouseCoords, setMouseCoords] = useState({
        X: 0,
        Y: 0
    });
    const [cardOffset, setCardOffset] = useState({
        X: 0,
        Y: 0
    });
    const [isCardPinned, setIsCardPinned] = useState(false);
    const [showSetY, setShowSetY] = useState(false);
    const [showDataTable, setShowDataTable] = useState(false);
    const [showChartCard, setShowChartCard] = useState(false);
    const [showCurrentPoint, setShowCurrentPoint] = useState(false);
    const [showAnnotations, setShowAnnotations] = useState(true);
    const [showAnyModal, setShowAnyModal] = useState(false);
    const [currentPoint, setCurrentPoint] = useState(null);
    const [showAddAnnotation, setShowAddAnnotation] = useState();
    const [updatedTime, setUpdatedTime] = useState(null);
    const [prevTimezone, setPrevTimezone] = useState("UTC");
    const [updatedGoallineTime, setUpdatedGoallineTime] = useState(null);
    const [legendDescriptionMapping, setLegendDescriptionMapping] = useState({});
    const [chartDisplayType, setChartDisplayType] = useState(null);
    const [dataTable, setDataTable] = useState([]);
    const [legendList, setLegendList] = useState([]);
    const [defaultShowRules, defaultSetShowRules] = useState(new Proxy({}, {
        get: (target, name) => name in target ? target[name] : false
    }));
    const [defaultProcessedRules, defaultSetProcessedRules] = useState({});
    const [hasBuildDict, setHasBuildDict] = useState({});

    let showRules, setShowRules;
    let processedRules, setProcessedRules;

    if (liftedShowRules && liftedShowRules.length === 2 && liftedProcessedRules && liftedProcessedRules.length === 2) {
        [showRules, setShowRules] = liftedShowRules;
        [processedRules, setProcessedRules] = liftedProcessedRules;
    } else {
        [showRules, setShowRules] = [defaultShowRules, defaultSetShowRules];
        [processedRules, setProcessedRules] = [defaultProcessedRules, defaultSetProcessedRules];

    }

    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'line',
            zoomType: isZoomSupported(tab) ? 'x' : '',
            events: {
                load: (e) => { setMinY(e.target.yAxis[0].min); setMaxY(e.target.yAxis[0].max) }
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            line: {
                marker: {
                    symbol: 'circle',
                    radius: 2,
                    enabled: false
                }
            },
            area: {
                stacking: chartType === "100PercentArea" ? 'percent' : 'normal',
                marker: {
                    symbol: 'circle',
                    radius: 2,
                    enabled: false
                }
            },
            column: {
                stacking: 'normal',
            },
            series: {
                cursor: 'pointer',
                point: {
                    events: {
                        mouseOver: function (point) {
                            setShowChartCard(true);
                            if (point.target && point.target.series) {
                                setCurrentPoint(point);
                                //hoverTimeout = setTimeout(() => processRules(point), hoverTimeoutDuration);
                                //when point is hovered, run in-chart on-demand expert insights
                                //disabled until bug is fixed
                            }
                        },

                        mouseOut: function () {
                            setShowChartCard(false);
                            if (hoverTimeout) {
                                clearTimeout(hoverTimeout);
                            }
                        }

                    }
                }
            }
        },
        exporting: {
            enabled: false
        },
        accessibility: {
            enabled: false
        },
        tooltip: {
            enabled: false
        },
        subtitle: {
            text: ''
        },
        series: [],
        title: { // Add white ghost title so there's room for the actual title
            text: '',
            style: { color: "#FFFFFF", fill: "#FFFFFF" }
        },
        yAxis: {
            plotLines: [],
            type: 'linear',
            min: (props.minY && props.minY > 0) ? props.minY : 0,
            max: (tab === "cumulativeHistogram" || tab === "percentage") ? 100 : null,
            labels: tab === "cumulativeHistogram" ? { format: '{value}%' } : {},
            events: {
                afterSetExtremes: (e) => { setMaxY(e.max); setMinY(e.min); }
            }
        },
        xAxis: {
            type: xAxisType,
            tickmarkPlacement: 'on',
            tickLength: 0,
            labels: {
                rotation: 315,
                x: 9,
                formatter: function () {
                    if (hasBuildDict[this.value] && hasBuildDict[this.value] === true) {
                        return '<span style="fill: red;">' + this.value + '</span>';
                    }
                    else return this.value;
                }
            }
        }
    });

    const [minY, setMinY] = useState(null);
    const [maxY, setMaxY] = useState(null);
    const [annotations, setAnnotations] = useState([]);
    const [annotationArr, setAnnotationArr] = useState([]);
    const annotationLabel = useRef("");

    const chartRef = useRef(null);
    const chartWrapper = useRef();

    /*
        Without useCallback(), new function props will be passed to Chart's children
        every time Chart rerenders
    */
    const toggleAllSeriesCallback = useCallback(toggleAllSeries, [annotations]);
    const toggleSingleSeriesCallback = useCallback(toggleSingleSeries, [annotations]);
    const onHideSetYCallback = useCallback(pinned => {
        if (!pinned) {
            setShowChartCard(false)
        }
        setShowSetY(false)
    }, []);
    const setMinMaxYCallback = useCallback(setMinMaxY, []);
    const onHideDataTableCallback = useCallback(pinned => {
        if (!pinned) {
            setShowChartCard(false)
        }
        setShowDataTable(false)
    }, []);
    //#endregion

    //#region Accessibility
    function setAccessibility(enableAccessibility) {
        setChartOptions((prevState) => {
            return ({
                ...prevState,
                accessibility: { enabled: enableAccessibility }
            })
        });
    }
    //#endregion

    //#region Toggling
    function toggleAllSeries(selectAll, redraw) {
        if (chartRef.current.chart) {
            var series = chartRef.current.chart.series;

            for (var i = 0; i < series.length; i++) {
                series[i].setVisible(selectAll, false);
            }

            setChartOptions((prevState) => {
                return ({
                    ...prevState,
                    series: prevState.series.map((x) => {
                        return { ...x, visible: selectAll };
                    })
                })
            });

            if (redraw) chartRef.current.chart.redraw();

            let annoList = annotations.map(anno => {
                const series = anno.point.series
                if (series) {
                    series.visible = selectAll
                }
                return anno
            })
            setAnnotations(annoList)
        }
    }

    function toggleSingleSeries(seriesName) {
        var series = chartRef.current.chart.series;
        for (var i = 0; i < series.length; i++) {
            // rather than calling 'show()' and 'hide()' on the series', we use setVisible and then
            // call chart.redraw --- this is significantly faster since it involves fewer chart redraws
            if (series[i].name === seriesName) {
                series[i].setVisible();
            }
        }
        setChartOptions((prevState) => {
            return ({
                ...prevState,
                series: prevState.series.map((x) => {
                    return { ...x, visible: x.name === seriesName ? !x.visible : x.visible };
                })
            })
        });

        let annoList = annotations.map(anno => {
            const series = anno.point.series
            if (series) {
                series.visible = series.name === seriesName ? !series.visible : series.visible
            }
            return anno
        })

        setAnnotations(annoList)
    }
    //#endregion

    //#region Annotations

    function getAnnotations() {
        if (serverData && serverData.length > 0 && chartRef.current && chartRef.current.chart.series[0] && !isInExpertInsight && !props.disableAnnotation) {
            let chartData = chartRef.current.chart;

            let postAnnotationRequest = {};
            postAnnotationRequest.Date = getStartDateFromUrl(chartInfo?.url || props.location.search)
            postAnnotationRequest.Cube = props.cube;
            postAnnotationRequest.Tab = props.tab;

            const newAnnotationLabel = postAnnotationRequest.Cube + "_" + postAnnotationRequest.Tab + "_" + postAnnotationRequest.Date;
            if (serverData && serverData[0] && chartData.series && chartData.series[0]) {
                if (newAnnotationLabel != annotationLabel.current) {
                    annotationLabel.current = newAnnotationLabel
                    AnnotationDataService.postAnnotationsForChart(postAnnotationRequest).then(res => {
                        setAnnotationArr(res.data)
                        updateAnnotationFromSet()
                    })
                } else {
                    updateAnnotationFromSet()
                }
            }
        }
    }

    function updateAnnotationFromSet() {
        if (serverData && serverData.length > 0 && chartRef.current && chartRef.current.chart.series[0] && !isInExpertInsight) {
            let chartData = chartRef.current.chart;
            let annos = []
            for (let i = 0; i < annotationArr.length; i++) {
                const annotation = annotationArr[i]
                let point;

                let legendIndex = -1
                serverData.forEach((series, index) => {
                    if (series.Name === annotation.legend) {
                        legendIndex = index
                        return
                    }
                })

                if (legendIndex >= 0) {
                    for (let j = 0; j < serverData[legendIndex].Data.length; j++) {
                        if (Date.parse(getDateFromName(serverData[legendIndex].Data[j].name)) === Date.parse(getDateFromName(annotation.date))) {
                            point = chartData.series[legendIndex].data[j];
                        }
                    }

                    updateAnnotationList(annos, annotation, point)
                }
            }
            setAnnotations(annos)
        }
    }

    function updateAnnotationList(prevList, annotation, point) {
        const target = point || (currentPoint && currentPoint.target)
        if (target && chartRef.current && chartRef.current.chart) {
            const chartData = chartRef.current.chart
            let isAdded = false

            for (let i = 0; i < prevList.length; i++) {
                let anno = prevList[i]
                if (Date.parse(getDateFromName(annotation.date)) === Date.parse(getDateFromName(anno.date)) && annotation.legend === anno.legend) {
                    const sameAnno = anno.content.filter(each => each.id === annotation.ID)
                    if (sameAnno.length === 0) {
                        anno.content.push({
                            id: annotation.ID,
                            text: annotation.annotation1,
                            user: annotation.user,
                        })
                    }
                    isAdded = true
                }
            }

            if (!isAdded) {
                prevList.push({
                    x: target.plotX + chartData.plotLeft,
                    y: target.plotY + chartData.plotTop,
                    color: target.series.color,
                    date: annotation.date,
                    legend: annotation.legend,
                    point: Object.assign({}, point),
                    content: [{
                        id: annotation.ID,
                        text: annotation.annotation1,
                        user: annotation.user
                    }]
                })
            }
        }
    }

    function addAnnotation(toAdd, point) {
        const list = annotations.slice(0)
        updateAnnotationList(list, toAdd, point)
        setAnnotations(list)
    }

    function toggleAnnotations(e) {
        e.stopPropagation()
        setShowAnnotations(!showAnnotations);
    }

    function deleteAnnotation(id) {
        const list = annotations.map(anno => {
            anno.content = anno.content.filter(each => each.id !== id)
            return anno
        }).filter(anno => {
            return anno.content.length !== 0
        })
        setAnnotations(list)
    }

    function editAnnotation(id, newText) {
        const list = annotations.map(anno => {
            anno.content = anno.content.map(each => {
                if (each.id === id) {
                    each.text = newText
                }
                return each
            })
            return anno
        })
        setAnnotations(list)
    }

    function toggleAddAnnotationModal(e) {
        if (e) {
            e.stopPropagation()
        }
        setShowAddAnnotation(!showAddAnnotation)
    }
   // useEffect(getAnnotations, [props.updatedTime]);
    //#endregion

    function handleContainerResize() {
        if (chartRef && chartRef.current && chartRef.current.chart)
            chartRef.current.chart.reflow()
    }
    useEffect(handleContainerResize, [isChartingPaneHidden]);

    //#region In-chart rule recommendations
    function processRules(point) {
        let currentDate = point.target.name;
        let isCheckpoints = props.tab === "checkpoint";
        let isMetrics = props.tab.includes("dailymetrics");

        if (isCheckpoints || isMetrics) { //only process rules for checkpoints and metrics
            if (!(currentDate in processedRules)) {
                let prevState = processedRules;
                prevState[currentDate] = [];
                setProcessedRules(prevState);

                let prevShowRules = showRules;
                prevShowRules[currentDate] = true;
                setShowRules(prevShowRules);

                //request body
                let seriesForDate = segmentSeriesForDate(props.options.rawData, currentDate);
                let requestBody = {};
                if (isCheckpoints) {
                    requestBody["checkpoints"] = { "AdditionalLatencyDataSeries": seriesForDate };
                } else {
                    requestBody["metrics"] = { "LatencyDataSeries": seriesForDate };
                }

                //query middle tier for rule processsing
                ExpertInsightsDataService.ruleResultForInChartRules(requestBody, props.cube).then(res => { //in-chart rules processing query
                    prevState[currentDate] = res.data;
                    console.log(prevState);
                    setProcessedRules(prevState);
                }).catch(rulesErr => {
                    console.log("RULES ERR");
                    console.log(rulesErr);
                    setProcessedRules(prevState);
                })
            }
        }

    }
    //#endregion

    //#region Chart display
    let changeChartDisplayType = (chartDisplayType) => {
        setChartOptions((prevState) => {
            return ({
                ...prevState,
                plotOptions: {
                    ...prevState.plotOptions,
                    area: {
                        ...prevState.plotOptions.area,
                        stacking: chartDisplayType === "100PercentArea" ? 'percent' : 'normal'
                    }
                },
                series: prevState.series.map((x) => {
                    return { ...x, type: chartDisplayType === "Line" ? 'line' : 'area' };
                })
            })
        })
        setChartDisplayType(chartDisplayType);
        if (onChangeChartType) onChangeChartType(chartDisplayType);
    };

    function setMinMaxY(min, max) {
        chartRef.current.chart.yAxis[0].update(
            {
                startOnTick: false,
                min: parseFloat(min),
                endOnTick: false,
                max: parseFloat(max)
            }
        )
    }
    //#endregion

    //#region Chart card
    function handleMouseMove(e) {
        e.stopPropagation();
        if (!isCardPinned) {
            setMouseCoords({ X: e.clientX, Y: e.clientY });
        }
        if (!showCurrentPoint) {
            setShowCurrentPoint(true);
        }
    }

    function handleMouseLeave(e) {
        e.stopPropagation();
        if (!isCardPinned) {
            setShowChartCard(false);
        }
        setShowCurrentPoint(false);
    }

    let pinCard = (e) => {
        e.stopPropagation();
        setCardOffset(calculateChartCardPosition({ X: e.clientX, Y: e.clientY }));
        setIsCardPinned(true);
    }

    function calculateChartCardPosition(mouse) {
        if (chartWrapper.current) {
            const boundingRect = chartWrapper.current.getBoundingClientRect();
            const left = mouse.X - boundingRect.left - 20;
            const top = mouse.Y - boundingRect.top;
            return {
                X: left,
                Y: top
            }
        } else {
            return {
                X: 0,
                Y: 0
            }
        }
    }
    //#endregion

    //#region Goal line

    let getGoalLine = (index) => {
        let shouldShowGoalLine = false
        if (chartInfo && chartInfo.alertList && chartInfo.alertList.GoalLines && (chartInfo.alertList.GoalLines.length > 0)) {
            for (let goallineAlert of chartInfo.alertList.GoalLines) {
                if (goallineAlert.GoalLine.IsAlertEnabled || goallineAlert.GoalLine.IsGoalLineEnabled) {
                    shouldShowGoalLine = true
                }
            }
        }
        let goalCount = 0
        if (shouldShowGoalLine) {
            let seriesList = [];
            for (let goallineAlert of chartInfo.alertList.GoalLines) {
                const goalLine = goallineAlert.GoalLine
                const axis = TabYValueMap[tab]
                if (!axis.includes(goalLine.Axis)) continue
                if (goalLine.IsAlertEnabled || goalLine.IsGoalLineEnabled) {
                    let goal = [];
                    const series = serverData[0];
                    if (series) {
                        series.Data.forEach(data => {
                            goal.push({
                                date: data.date,
                                name: data.name,
                                build: data.build,
                                y: goalLine.Goal,
                                ...(xAxisType === 'datetime' && { x: convertStringToUTCDate(data.name) })
                            })
                        });
                        seriesList.push({
                            type: 'line',
                            dashStyle: goalLine.LineType,
                            data: goal,
                            stack: '',
                            index: 'goalline' + index + goalCount,
                            turboThreshold: 0,
                            name: 'Goal line',
                            color: goalLine.Color,
                            visible: true,
                            id: 'goalline' +  + (index + goalCount),
                            linkedTo: undefined,
                            accessibility: { enabled: false, keyboardNavigation: false }, // goal line should not be interactive
                            marker: { enabled: false }
                        });
                        goalCount = goalCount + 1;
                    }
                }
            }
            return seriesList;
        }
        else {
            return [];
        }
    }

    //#endregion
    if (props.updatedTime === null) {
        return (
            <div>
                <NoDataContainer title={title} errorMessage={errorMessage} defaultMessage="Click Graph it to see results" />
            </div>
        )
    }
    else if (serverData && serverData.length > 0) {
        let index = 0;
        if (props.updatedTime !== updatedTime || props.timezone !== prevTimezone) {
            let legendDescriptionMapping = {};
            let seriesList = [];
            let dataTable = [];
            let legends = [];
            let newHasBuildDict = {}
            let analyzeIndex = -1;
            let analyzeDate = null;
            if (chartInfo) {
                analyzeDate = getAnalyzeDateFromUrl(chartInfo.url)
                analyzeDate = analyzeDate ? formatUTCDateAsString(convertStringToUTCDate(analyzeDate), isHourly) : null;
            }
            for (; index < serverData.length; index++) {
                const series = serverData[index];
                const preSeries = chartOptions.series.find(x => (x.name === series.Name));
                seriesList.push({
                    type: (chartType === "Column" ? 'column' : (chartType === "Line" ? 'line' : 'area')),
                    data: series.Data,
                    stack: series.Stack,
                    dashStyle: 'solid',
                    index: index,
                    turboThreshold: 0,
                    name: series.Name,
                    color: getColor(series.Name),
                    visible: series.Hide ? false : (preSeries ? preSeries.visible : true),
                    id: parseInt(series.ID) > -1 ? series.ID : undefined,
                    linkedTo: parseInt(series.LinkedTo) > -1 ? series.LinkedTo : undefined
                });
                legendDescriptionMapping[series.Name] = series.Description; //sets the key and descriptions of each point on the graph

                if (index === 0 || series.Data.length > dataTable.length) {
                    series.Data.forEach((point, index) => {
                        if(dataTable.filter(x => x[0] === point.name).length > 0) return;
                        dataTable.push([point.name])
                        if (analyzeDate) {
                            analyzeIndex = index
                        }
                    })
                }
                series.Data.forEach((point, i) => {
                    if ((xAxisType === 'datetime' || series.Data.length < 2) && (i === 0 || !series.Data[i - 1].y) && (i === series.Data.length - 1 || !series.Data[i + 1].y) && series.Data[i].y !== null) {
                        series.Data[i].marker = { enabled: true };
                    } else {
                        series.Data[i].marker = { enabled: false };
                    }

                    if (point.build) {
                        newHasBuildDict[xAxisType === "datetime" ? point.x.getTime() : point.x] = true;
                    }
                    const dataTableRow = dataTable.filter(x => x[0] === point.name);
                    if(dataTableRow.length > 0) {
                        dataTableRow[0].push(point.y)
                    }
                });
                legends.push(series.Name);
            }
            setDataTable(dataTable);
            setLegendList(legends);
            setHasBuildDict(newHasBuildDict);
            setChartOptions((prevState) => ({
                ...prevState,
                plotOptions: {
                    ...prevState.plotOptions,
                    line: {
                        ...prevState.plotOptions.line
                    },
                    area: {
                        ...prevState.plotOptions.area,
                        stacking: chartType === "100PercentArea" ? 'percent' : 'normal'
                    }
                },
                series: seriesList,
                title: { ...prevState.title, text: title },
                yAxis: {
                    ...prevState.yAxis,
                    title: { ...prevState.yAxis.title, text: TabYValueMap[tab] }
                },
                xAxis: {
                    ...prevState.xAxis,
                    plotLines: [{
                        color: 'red', // Color value
                        dashStyle: 'solid', // Style of the plot line. Default to solid
                        zIndex: 100,
                        width: 2, // Width of the line
                        value: analyzeIndex
                    }],
                    type: xAxisType,
                    tickInterval: xAxisType === 'datetime' ? (isHourly ? msInHr : msInDay) : 1,
                    title: { ...prevState.xAxis.title, text: xAxisType === "datetime" ? (isHourly ? "Date (" + timezone + ")" : "Date") : TabXValueMap[tab] },
                    labels: {
                        ...prevState.xAxis.labels,
                        formatter: function () {
                            let value = this.value;
                            if (xAxisType === "datetime") {
                                const time = new Highcharts.Time();
                                if (isHourly) {
                                    let date = new Date(this.value);
                                    convertFromUTCToTimezone(date, timezone);
                                    value = time.dateFormat('%m/%d/%Y %H:%M', date);
                                }
                                else {
                                    value = time.dateFormat('%m/%d/%Y', this.value);
                                }
                            }
                            if (newHasBuildDict[this.value] && newHasBuildDict[this.value] === true) {
                                return '<span style="fill: red;">' + value + '</span>';
                            }
                            else return value;
                        }
                    },
                    ...((getIncludeWeekendsFromUrl(chartInfo.url) !== "true" || (chartInfo.includeWeekends && chartInfo.includeWeekends.toLowerCase() !== "true")) ? {
                        breaks: [{
                            breakSize: isHourly ? msInHr : msInDay,
                            from: Date.UTC(1999, 11, 31, isHourly ? 23 : 0),
                            to: Date.UTC(2000, 0, 3),
                            repeat: 7 * 24 * 36e5
                        }]
                    } : {
                        breaks: []
                    })
                }
            }));
            setLegendDescriptionMapping(legendDescriptionMapping);
            setUpdatedTime(props.updatedTime);
            setPrevTimezone(props.timezone);
        }
        if (updatedGoallineTime !== props.updatedGoallineTime) {
            setChartOptions((prevState) => {
                let dataSeries = prevState.series.filter(x => { return x.name !== 'Goal line' });
                return ({
                    ...prevState,
                    series: dataSeries.concat(...getGoalLine(dataSeries.length))
                })
            });

            setUpdatedGoallineTime(props.updatedGoallineTime)
        }

        let showCard = showChartCard
        if (!isCardPinned) {
            showCard = showChartCard && !showDataTable && !showSetY && !showAnyModal
        }
        let enabled = true; //Set to the prop instead when logic is fixed
        return (          
            <Container className="chart" fluid style={{ position: 'relative' }}>
                <Row>
                    <Col sm={4} xl={2} style={{ textAlign: "left", paddingLeft: isInDashboard ? '15px' : '30px', borderRight: "1px solid #e5e5e5" }}>
                        <Legend data={chartOptions.series} updatedTime={updatedTime} alternateSortData={props.alternateSortData} tab={props.tab} isInDashboard={isInDashboard} toggleAll={toggleAllSeriesCallback} toggleSingle={toggleSingleSeriesCallback} />
                    </Col>
                    <Col sm={8} xl={10} className="chartWrapper" ref={chartWrapper} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} onClick={(e) => { if (!isCardPinned && !showDataTable && !showSetY && !showAnyModal) pinCard(e) }} >
                        {showAddAnnotation && <AddAnnotationModal
                            tab={tab}
                            cube={cube}
                            url={isInDashboard ? chartInfo.url : window.location.href}
                            currentPoint={currentPoint.target}
                            onAddAnnotation={addAnnotation}
                            toggleModal={toggleAddAnnotationModal}
                            showModal={showAddAnnotation} />}
                        <ChartCard
                            showAddAnnotationForm={toggleAddAnnotationModal}
                            unpinCard={() => setIsCardPinned(false)}
                            currentPoint={currentPoint}
                            series={chartOptions.series}
                            processedRules={processedRules}
                            isInDashboard={isInDashboard}
                            showRules={showRules}
                            title={title}
                            cube={cube}
                            showChartCard={showCard}
                            showCurrentPoint={showCurrentPoint}
                            isPinned={isCardPinned}
                            isSpecificSeriesExpertInsightsSupported={!enabled}
                            tab={tab}
                            mouseCoords={mouseCoords}
                            cardOffset={cardOffset}
                            order={chartInfo && chartInfo.order ? chartInfo.order : 1}
                            legendDescriptionMapping={legendDescriptionMapping}
                            chartDisplayType={chartDisplayType}
                            isHourly={isHourly}
                            isAccessibilityEnabled={chartOptions.accessibility.enabled} />
                        {showAnnotations && annotations.map(anno => (
                            <React.Fragment key={anno.id} >
                                {anno.point.series && anno.point.series.visible ? <Annotation
                                    url={isInDashboard ? chartInfo.url : window.location.href}
                                    loginUser={loginUser}
                                    annotation={anno}
                                    toggleShowAnyModal={() => setShowAnyModal(!showAnyModal)}
                                    onDeleteAnnotation={deleteAnnotation}
                                    onEditAnnotation={editAnnotation}
                                    toggleAddAnnotationModal={toggleAddAnnotationModal}
                                    tab={tab}
                                    cube={cube}
                                    currentPoint={anno.point}
                                    onAddAnnotation={addAnnotation}
                                    showAddAnnotation={showAddAnnotation} /> : null}
                            </React.Fragment>
                        ))}

                        <div className="chartBar">
                            <div className="left">
                                {annotations.length > 0 && <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id={`tooltip-toggleAnnotation`}>
                                            {showAnnotations ? 'Hide annotations' : 'Show annotations'}
                                        </Tooltip>
                                    }>
                                    <Button variant="light" size="sm" className="hideAnnotationsButton" onClick={toggleAnnotations}><FontAwesomeIcon icon={showAnnotations ? faEyeSlash : faEye} /></Button>
                                </OverlayTrigger>}
                                {Object.keys(hasBuildDict).length !== 0 && <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Popover id="popover-basic">
                                            <Popover.Content>
                                                Red indicates a new dominant build on that date for at least one series. Hover over a point from that day to find the build number in the card.
                                            </Popover.Content>
                                        </Popover>}>
                                    <FontAwesomeIcon color="red" icon={faInfoCircle} />
                                </OverlayTrigger>}
                            </div>
                            <div className="chartTitle">
                                {(isInDashboard || isInExpertInsight) && props.missingDates && props.missingDates != 0 ?
                                    <div>
                                        {title} &nbsp;
                                        <Spinner animation="border" size="sm" style={{ "color": "gray" }}></Spinner> &nbsp;
                                        {props.missingDates > 0 && <p style={{ "fontSize": "12px", "color": "gray" }}>{props.missingDates} day{props.missingDates > 1 ? 's' : ''} data remaining…</p>}
                                    </div>
                                    : title}
                            </div>
                            <ChartToolbar
                                changeChartDisplayType={changeChartDisplayType}
                                setMinMaxY={() => setShowSetY(true)}
                                chart={(chartRef && chartRef.current && chartRef.current.chart) ? chartRef.current.chart : null}
                                showDataTable={() => setShowDataTable(!showDataTable)}
                                setAccessibility={setAccessibility}
                                isInDashboard={isInDashboard}
                            />
                        </div>

                        <HighchartsReact tabIndex={0} highcharts={Highcharts} options={chartOptions} ref={chartRef} />
                        {(minY !== null && maxY !== null) && <ChartSetYModal show={showSetY} onHide={onHideSetYCallback.bind(this, isCardPinned)} minY={minY} maxY={maxY} setMinMaxY={setMinMaxYCallback} />}
                        {showDataTable && <DataTableModal show={showDataTable}
                            onHide={onHideDataTableCallback.bind(this, isCardPinned)}
                            legends={legendList}
                            dataTable={dataTable}
                            xAxis={TabXValueMap[tab]} />}
                    </Col>
                </Row>
            </Container>
        )
    } else if (!serverData || (!loading && (serverData.length === 0))) {
        return (
            <div>
                <NoDataContainer title={title} errorMessage={errorMessage} defaultMessage="No data returned from server" />
            </div>
        )
    }

    return null;
}

/*
These are expensive functions that seem to have no effect on chart behavior.
*/

Highcharts.wrap(
    Highcharts.Series.prototype,
    "setState", empty
);

Highcharts.wrap(
    Highcharts.Pointer.prototype,
    "onContainerMouseLeave", empty
);

function empty() {

}

export default withRouter(Chart)