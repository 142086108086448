import React from 'react';
import AnnotationEditorModal from './AnnotationEditorModal'
import AnnotationDataService from '../../Dataservices/AnnotationDataService'

function EditAnnotationModal(props) {
    const { showModal, toggleModal, annotation, onEditAnnotation, date } = props
    const { id } = annotation

    const editAnnotation = (html) => {
        onEditAnnotation(id, html)
        return AnnotationDataService.postEditAnnotationsForChart({
            Id: id,
            AnnotationText: html,
            Date: date,
            IsDeleted: false
        })
    }

    return (
        <AnnotationEditorModal title={'Edit the Note'} text={annotation.text} showModal={showModal} toggleModal={toggleModal} onSave={editAnnotation} />
    )
}


export default EditAnnotationModal