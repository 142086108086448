import DataService from './DataService'

const RCADataService = {

    getRCAStatus: (id, isAlert) => {
        return DataService.get('/RCA/getRCAStatus?id=' + id + '&isAlert=' + isAlert)
    },

    triggerRCA: (request) => {
        return DataService.post('/RCA/addRCARequest', request)
    },

    postFeedback: (request) => {
        return DataService.post('/RCA/postFeedback', request)
    }

}

export default RCADataService