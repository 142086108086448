import React, { Component } from 'react';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import GetTrimSqlModal from './GetTrimSqlModal'
import MaintenanceService from '../../Dataservices/MaintenanceService';
import { SortOrderEnum, filterObject, compareString, getNextSortOrder, renderSortMark } from '../../Functions/TableHelper'
import './PipelineStatusPage.css'
import { LoadingGif } from '../PublicComponents/HelperComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import './TrimmingMonitor.css';

class TrimmingMonitor extends Component {

    constructor(props) {
        super(props);

        this.statusObjectKeys = {
            None: 'None',
            ServerName: 'ServerName',
            CubeName: 'CubeName',
            CubeDatabaseName: 'CubeDatabaseName',
            CubeStatus: 'CubeStatus',
            DatabaseState: 'DatabaseState',
            FactTableType: 'FactTableType',
            Id: 'Id',
            BackupEnabled: 'BackupEnabled',
            TrimmingEnabled: 'TrimmingEnabled',
            TrainingMode: 'TrainingMode',
            TrimmingTimeConfig: 'TrimmingTimeConfig',
            StagingDaysBuff: 'StagingDaysBuff',
            FactTableBuff: 'FactTableBuff',
            TrimmingSizeConfig: 'TrimmingSizeConfig',
            EarliestFactMainTable: 'EarliestFactMainTable',
            LatestFactMainTable: 'LatestFactMainTable',
            FactMainTableCount: 'FactMainTableCount',
            DataFileSize: 'DataFileSize',
            DataFileMaxSize: 'DataFileMaxSize',
            CurrentUsedSpace: 'CurrentUsedSpace',
            TableName: 'TableName',
            TrimmingJobDate: 'TrimmingJobDate',
            JobCompleted: 'JobCompleted',
            UpdateTime: 'UpdateTime',
            SQLStatement: 'SQLStatement'
        }

        this.trimmingConfigTableKeys = [
            this.statusObjectKeys.ServerName, 
            this.statusObjectKeys.CubeName, 
            this.statusObjectKeys.CubeStatus,
            this.statusObjectKeys.CubeDatabaseName,
            this.statusObjectKeys.DatabaseState, 
            this.statusObjectKeys.FactTableType, 
            this.statusObjectKeys.BackupEnabled,
            this.statusObjectKeys.TrimmingEnabled,
            this.statusObjectKeys.TrainingMode, 
            this.statusObjectKeys.TrimmingTimeConfig,
            this.statusObjectKeys.StagingDaysBuff, 
            this.statusObjectKeys.FactTableBuff, 
            this.statusObjectKeys.TrimmingSizeConfig,
            this.statusObjectKeys.EarliestFactMainTable,
            this.statusObjectKeys.LatestFactMainTable,
            this.statusObjectKeys.FactMainTableCount,
            this.statusObjectKeys.DataFileSize,
            this.statusObjectKeys.CurrentUsedSpace,
            this.statusObjectKeys.DataFileMaxSize,
            this.statusObjectKeys.UpdateTime
        ]
        
        this.serverTrimmingTableKeys = [
            this.statusObjectKeys.ServerName,
            this.statusObjectKeys.CubeName, 
            this.statusObjectKeys.CubeDatabaseName, 
            this.statusObjectKeys.TableName, 
            this.statusObjectKeys.TrimmingJobDate, 
            this.statusObjectKeys.JobCompleted, 
            this.statusObjectKeys.TrainingMode,
            this.statusObjectKeys.SQLStatement
        ]

        this.defaultDate = new Date().toISOString()

        this.state = {
            isLoading: true,
            allConfigs: [],
            configs:[],
            configsSort: { key: this.statusObjectKeys.None, order: this.statusObjectKeys.None},
            allList: [],
            searchText: '',
            tableList: [],
            initTableList:[],
            tableTitles: [],
            tableSort: [],
            showSqlModal: false,
            selectedStatus: null,
            error : null
        }
    }

    componentDidMount() {
        Promise.all([
            MaintenanceService.getTrimmingTableList(),
            MaintenanceService.getTrimmingConfig(),
        ])
        .then(([res1, res2]) => {
            this.setState({
                isLoading: false,
                allList: res1.data,
                allConfigs: res2.data
            }, this.init)
        })
        .catch(err => {
            console.error(err)
            this.setState({error: err.message})
        })
    }

    init = () => {
        let { allList, allConfigs } = this.state

        let servers = allList.map(status => status.ServerName)

        let tableTitle = [...new Set(servers)]
    
        let sort = []

        sort = tableTitle.map(() => ({
            key: this.statusObjectKeys.None,
            order: this.statusObjectKeys.None
        }));

        const today = new Date().toLocaleDateString();
        let serverList = tableTitle.map(server =>
        allList.filter(
            status =>
            status.ServerName === server &&
            (status.TrimmingJobDate === null || new Date(status.TrimmingJobDate).toLocaleDateString() === today)
        ));
        
        this.setState({
            tableTitles: tableTitle,
            tableList: serverList,
            initTableList: serverList,
            tableSort: sort,
            configs: allConfigs
        })
    }

    getSeperateList = () => {   
        let { allList, tableTitles, allConfigs, initTableList, searchText, tableSort, configsSort } = this.state

        let serverList = []
        if (searchText === '') {
            serverList = initTableList
        }
        else {
            serverList = tableTitles.map(server => allList.filter(status => status.ServerName === server && filterObject(status, searchText, this.serverTrimmingTableKeys)))
        }

        let configList = allConfigs.filter(config => filterObject(config, searchText, this.trimmingConfigTableKeys))

        tableSort.forEach((sort, index) => {
            if (sort.key !== this.statusObjectKeys.None && sort.order !== SortOrderEnum.None) {
                if (serverList[index].hasOwnProperty(sort.key)) {
                    serverList[index].sort(this.compareObject(sort.key, sort.order === SortOrderEnum.Ascending))
                }
            }
        })

        if (configsSort.key !== this.statusObjectKeys.None && configsSort.order !== SortOrderEnum.None) {
            if (configList.hasOwnProperty(configsSort.key)) {
                configList.sort(this.compareConfigObject(configsSort.key, configsSort.order === SortOrderEnum.Ascending))
            }
        }

        this.setState({
            tableList: serverList,
            configs: configList
        })
    }

    onChangeSearchText = e => {
        this.setState({
            searchText: e.target.value
        }, this.getSeperateList)
    }

    compareConfigObject = (key, isAscending) => {
        return (obj1, obj2) => {
            if (key === this.statusObjectKeys.UpdateTime) {
                // compare dates  "12/9/2020"  "12/11/2020 12:18:51 AM"
                let date1 =Date.parse(obj1[key])
                let date2 = Date.parse(obj2[key])

                // Date values may contain 'None'. Make None values always show last when sorting
                if (isNaN(date1)) return 1
                if (isNaN(date2)) return -1

                return isAscending ? date1 - date2 : date2 - date1
            } else if (key === this.statusObjectKeys.TrimmingTimeConfig || key === this.statusObjectKeys.StagingDaysBuff || key == this.statusObjectKeys.FactTableBuff || key === this.statusObjectKeys.TrimmingSizeConfig || key === this.statusObjectKeys.DataFileMaxSize || key === this.statusObjectKeys.CurrentUsedSpace) {
                // compare numbers
                return isAscending ? obj1[key] - obj2[key] : obj2[key] - obj1[key]
            } else {
                // compare strings
                return compareString(obj1[key], obj2[key], isAscending)
            }
        }
    }

    compareObject = (key, isAscending) => {
        return (obj1, obj2) => {
            if (key === this.statusObjectKeys.TrimmingJobDate) {
                // compare dates  "12/9/2020"  "12/11/2020 12:18:51 AM"
                let date1 =Date.parse(obj1[key])
                let date2 = Date.parse(obj2[key])

                // Date values may contain 'None'. Make None values always show last when sorting
                if (isNaN(date1)) return 1
                if (isNaN(date2)) return -1

                return isAscending ? date1 - date2 : date2 - date1
            } else {
                // compare strings
                return compareString(obj1[key], obj2[key], isAscending)
            }
        }
    }

    sortList = (list, key, order, tableIndex) => {
        if (order === SortOrderEnum.None) {
            let { allList, searchText, tableTitles, initTableList} = this.state
            let originList = []
            
            if (searchText === '') {
                originList = initTableList[tableIndex]
            }
            else {
                originList = allList.filter(status => status.ServerName === tableTitles[tableIndex] && filterObject(status, searchText, this.serverTrimmingTableKeys))
            }

            return originList
        } else {
            return list.sort(this.compareObject(key, order === SortOrderEnum.Ascending))
        }
    }

    onSort = (key, tableIndex) => {
        let { tableSort, tableList }  = this.state
        let nextOrder = getNextSortOrder(tableSort[tableIndex].order, tableSort[tableIndex].key !== key)

        tableSort.splice(tableIndex, 1, {
            key: key,
            order: nextOrder
        })

        let sortedList = this.sortList(tableList[tableIndex], key, nextOrder, tableIndex)

        tableList.splice(tableIndex, 1, sortedList)

        this.setState({
            tableList: tableList,
            tableSort: tableSort
        })
    }

    sortConfigList = (list, key, order) => {
        if (order === SortOrderEnum.None) {
            let { configs, searchText } = this.state
            let originList = []

            originList = configs.filter(config => filterObject(config, searchText, this.trimmingConfigTableKeys))
            
            return originList
        } else {
            return list.sort(this.compareConfigObject(key, order === SortOrderEnum.Ascending))
        }
    }

    onConfigSort = (key) => {
        let { configs, configsSort }  = this.state
        let nextOrder = getNextSortOrder(configsSort.order, configsSort.key !== key)

        configsSort = { key: key, order: nextOrder }
        
        let sortedList = this.sortConfigList(configs, key, nextOrder)

        this.setState({
            configs: sortedList,
            configsSort: configsSort
        })
    }

    handleSqlButtonClick = (status) => {
        this.setState({ showSqlModal: true, selectedStatus : status});
    };

    handleCloseSqlButton = () => this.setState({ showSqlModal: false});

    renderTrimmingConfigTable = () => {
        let { configsSort } = this.state
        return (
            <div>
                <hr/>
                <Row>
                    <Col className="Label">
                        <h2>Trimming Configs</h2>
                        <div className="trimmingContainer" aria-live="polite" aria-atomic="true" aria-label={"Trimming Configs, " + (this.state.configs ? this.state.configs.length + " results found" : "")}>
                            <Table striped bordered hover className="trimmingConfigTable">
                                <thead>
                                    <tr>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.ServerName)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.ServerName)}
                                            Server
                                        </th>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.CubeName)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.CubeName)}
                                            CubeName
                                        </th>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.CubeDatabaseName)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.CubeDatabaseName)}
                                            Cube Database
                                        </th>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.CubeStatus)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.CubeStatus)}
                                            Cube Status
                                        </th>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.DatabaseState)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.DatabaseState)}
                                            Database State
                                        </th>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.FactTableType)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.FactTableType)}
                                            Fact Table Type
                                        </th>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.BackupEnabled)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.BackupEnabled)}
                                            Backup Enabled
                                        </th>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.TrimmingEnabled)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.TrimmingEnabled)}
                                            Trimming Enabled
                                        </th>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.TrainingMode)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.TrainingMode)}
                                            Training Mode
                                        </th>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.TrimmingTimeConfig)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.TrimmingTimeConfig)}
                                            Time Config (Month)
                                        </th>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.EarliestFactMainTable)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.EarliestFactMainTable)}
                                            Earliest Fact_Main
                                        </th>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.LatestFactMainTable)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.LatestFactMainTable)}
                                            Latest Fact_Main
                                        </th>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.FactMainTableCount)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.FactMainTableCount)}
                                            Fact_Main Count
                                        </th>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.StagingDaysBuff)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.StagingDaysBuff)}
                                            Staging Tables Buffer
                                        </th>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.FactTableBuff)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.FactTableBuff)}
                                            Fact Tables Buffer
                                        </th>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.TrimmingSizeConfig)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.TrimmingSizeConfig)}
                                            Size Config (MB)
                                        </th>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.DataFileMaxSize)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.DataFileMaxSize)}
                                            Mdf Max Size (MB)
                                        </th>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.CurrentUsedSpace)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.CurrentUsedSpace)}
                                            Used Space (MB)
                                        </th>
                                        <th onClick={this.onConfigSort.bind(this, this.statusObjectKeys.UpdateTime)} className="tableHeader" scope="col">
                                            {renderSortMark(configsSort.key, configsSort.order, this.statusObjectKeys.UpdateTime)}
                                            Update Time
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.configs && this.state.configs.map(status => (
                                        <tr key={status.Id}>
                                            <td>{status.ServerName}</td>
                                            <td>{status.CubeName}</td>
                                            <td>{status.CubeDatabaseName}</td>
                                            <td>{status.CubeStatus}</td>
                                            <td>{status.DatabaseState}</td>
                                            <td>{status.FactTableType}</td>
                                            <td>{status.BackupEnabled}</td>
                                            <td>{status.TrimmingEnabled}</td>
                                            <td>{status.TrainingMode}</td>
                                            <td>{status.TrimmingTimeConfig}</td>
                                            <td>{status.EarliestFactMainTable}</td>
                                            <td>{status.LatestFactMainTable}</td>
                                            <td>{status.FactMainTableCount === -1 ? 'NaN' : status.FactMainTableCount}</td>
                                            <td>{status.StagingDaysBuff}</td>
                                            <td>{status.FactTableBuff}</td>
                                            <td>{status.TrimmingSizeConfig === 'Unlimited' ? 'Unlimited' : (status.TrimmingSizeConfig ? Number(status.TrimmingSizeConfig).toLocaleString() : '')}</td>
                                            <td>{status.DataFileMaxSize === 'Unlimited' || status.DataFileMaxSize === 'Unknown' ? status.DataFileMaxSize : (status.DataFileMaxSize ? Number(status.DataFileMaxSize).toLocaleString() : '')}</td>
                                            <td>{status.CurrentUsedSpace === -1 ? 'NaN' : (status.CurrentUsedSpace ? status.CurrentUsedSpace.toLocaleString() : '')}</td>
                                            <td>{status.UpdateTime}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    renderServerTrimmingTable = (title, tableIndex) => {
        let { tableSort, tableList} = this.state
        return tableIndex < tableList.length && tableList[tableIndex].length > 0 ? (
            <div>
                <hr/>
                <Row>
                    <Col className="Label">
                        <h2>{title}</h2>
                        <div className="trimmingContainer" aria-live="polite" aria-atomic="true" aria-label={title + " " + (this.state.tableList[tableIndex] ? this.state.tableList[tableIndex].length + " results found" : "")}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.ServerName, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.ServerName)}
                                            ServerName
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.CubeName, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.CubeName)}
                                            Cube
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.CubeDatabaseName, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.CubeDatabaseName)}
                                            Cube Database
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.TableName, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.TableName)}
                                            Table
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.TrimmingJobDate, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.TrimmingJobDate)}
                                            Trimming Job Date
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.JobCompleted, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.JobCompleted)}
                                            Job Completed
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.TrainingMode, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.TrainingMode)}
                                            Training Mode
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.SQLStatement, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.SQLStatement)}
                                            SQL
                                            <OverlayTrigger
                                                trigger="hover"
                                                placement="top"
                                                overlay={
                                                <Popover id="sql-popover">
                                                    <Popover.Content>
                                                    Clicking SQL button will show the SQL statement of updating Master_Fact_Table and Fact_Union_View for the cube at the TrimmingJobDate, and the deleting table statement for the table in the selected row. 
                                                    </Popover.Content>
                                                </Popover>
                                                }
                                            >
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </OverlayTrigger>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tableList[tableIndex].map(status => (
                                        <tr key={status.Id}>
                                            <td>{status.ServerName}</td>
                                            <td>{status.CubeName}</td>
                                            <td>{status.CubeDatabaseName}</td>
                                            <td>{status.TableName}</td>
                                            <td>{status.TrimmingJobDate}</td>
                                            <td>{status.JobCompleted}</td>
                                            <td>{status.TrainingMode}</td>
                                            <td>
                                                <Button onClick={() => this.handleSqlButtonClick(status)}>SQL</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </div>
        ) : null
    }

    render() {
        return (
            <div>
                {this.state.isLoading 
                    ? <LoadingGif/>
                    : <div>
                        {this.state.error && <Alert variant='danger'>{this.state.error}</Alert>}
                        <Row>
                            <Col>
                                <Form>
                                    <Form.Group className="Label" controlId="trimmingCubeSearch">
                                        <h2>Cube Trimming Status</h2>
                                        <Form.Control type="text" placeholder="Search Cube" onChange={this.onChangeSearchText}/>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        {this.renderTrimmingConfigTable()}
                        {this.state.tableTitles.map((title, index) => <div key={title}>{this.renderServerTrimmingTable('Trimming on ' + title, index)}</div>)}
                        <GetTrimSqlModal
                            hideGetSql={this.handleCloseSqlButton} 
                            showGetSql={this.state.showSqlModal}
                            ServerName={this.state.selectedStatus?.ServerName}
                            CubeDatabaseName={this.state.selectedStatus?.CubeDatabaseName}
                            CubeName={this.state.selectedStatus?.CubeName}
                            TableName={this.state.selectedStatus?.TableName}
                            TrimmingDate={this.state.selectedStatus?.TrimmingJobDate??this.defaultDate}
                        />
                    </div>}
            </div>
        )
    }
}

export default TrimmingMonitor;
