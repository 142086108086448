import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import AnnotationDataService from '../../Dataservices/AnnotationDataService'

class DeleteAnnotationModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            error: ''
        }
    }

    deleteAnnotation = () => {
        const { annotation, onDeleteAnnotation, date } = this.props
        const { id, text } = annotation

        onDeleteAnnotation(id)
        AnnotationDataService.postEditAnnotationsForChart({
            Id: id,
            AnnotationText: text,
            Date: date,
            IsDeleted: true
        }).then().catch(err => {
            this.setState({error: err.message})
            console.error(err)
        })
    }

    render() {
        const { showModal, toggleModal } = this.props
        const { error } = this.state

        return (
            <Modal show={showModal} onHide={toggleModal}>
                <Modal.Header closeButton>
                    Are you sure you want to delete this annotation?
                </Modal.Header>
                {error && <Modal.Body>
                    <Alert variant='danger'>
                        {error}
                    </Alert>
                </Modal.Body>}
                <Modal.Footer>
                    <Button variant="primary" onClick={this.deleteAnnotation}>Yes</Button>
                    <Button variant="secondary" onClick={toggleModal}>No</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default DeleteAnnotationModal