import React, { useState, useRef, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Select, { components } from 'react-select';
import { ValidationWarning } from '../PublicComponents/HelperComponents';
import DataService from '../../Dataservices/DataService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './PivotDropdownList.css';
import { debounce } from '../../Functions/CommonHelper';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faTimes);
const limit = 100;
const searchAfterTyping = 500; // When user stop typing for 500ms then send search request

// inputValue is the value in the input box
// listOfKeys is the list of pivot values
// optionDict is the dictionary of all options. optionDict[value] = {value: value, label: label} 
// searchKeys will return a list of pivot values that match the inputValue
const searchKeys = (inputValue, listOfKeys, optionDict) => {
    let filteredListOfKeys = [];
    const emptyInput = inputValue === "";
    for (let i in listOfKeys) {
        let key = listOfKeys[i];
        let item = optionDict[key];
        if (item.value === null) continue;
        if (emptyInput || item.value.toLowerCase().includes(inputValue)) {
            filteredListOfKeys.push(item);
        }
    }
    return filteredListOfKeys.sort((a, b) => {
        if (a.value.length !== b.value.length) {
            return a.value.length - b.value.length;
        } else {
            var la = a.value.toLowerCase();
            var lb = b.value.toLowerCase();
            return la < lb ? -1 : (la > lb ? 1 : 0);
        }
    });
}

// Add prefix item if the cube supports prefix and 
// add new pivot item if the enableSearch of pivot is true
const prefixAndNewPivot = (inputValue, supportPrefix, enableSearch, optionDict) => {
    let array = [];

    if (supportPrefix) {
        if (inputValue !== "") {
            let prefixValue = inputValue + "*";
            if (!(prefixValue in optionDict)) {
                array.push({ value: prefixValue, label: prefixValue + "\t(prefix matching)", color: "#0052CC" });
            }
        }
    }
    if (enableSearch) {
        if (!(inputValue in optionDict)) {
            array.push({ value: inputValue, label: inputValue + "\t(add additional values)", color: "#0052CC" });
        }
    }

    return array
}

export default function PivotDropdownList(props) {

    const [inputValue, setInputValue] = useState("");
    const [selectedValues, setSelectedValues] = useState(props.defaultValues);
    const [options, setOptions] = useState([]);
    const [originalOptions, setOriginalOptions] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showMenu, setShowMenu] = useState(false);
    const [listening, setListening] = useState(false);

    const menuRef = useRef(null);
    const pivotTimestamp = useRef(new Date().getTime());

    useEffect(() => {
        setOriginalOptions(props.options);
        setOptions(searchOptionsLocal("", props.options, props.defaultValues, props.supportPrefix, props.enableSearch));
        setIsLoading(false);
    }, [props.options, props.defaultValues, props.supportPrefix, props.enableSearch]);

    // Close on outside click
    useEffect(() => {
        if (listening || !menuRef.current) return;
        setListening(true);
        [`click`, `touchstart`].forEach((type) => {
            document.addEventListener(type, (evt) => {
                if (!menuRef.current || menuRef.current.contains(evt.target)) return;
                setShowMenu(false);
            });
        });
    }, [listening]);


    const selectStyles = {
        option: (styles, state) => ({
            ...styles,
            color: state.data.color,
            backgroundColor: state.isSelected ? '#e0e0e0' : 'white',
            fontSize: "14px",
        }),

        control: provided => ({ ...provided, margin: 8, fontSize: "14px", minHeight: 25, borderColor: 'gray', boxShadow: "none" }),

        menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
    }

    // change selected values
    const onSelectChange = (inputValue) => {
        let newValue = inputValue ? (props.isMulti ? inputValue : [inputValue]) : [];
        if (props.enableSearch && newValue.length === 1 && newValue[0].label.indexOf("(add additional values)") > -1) {
            let requestData = { PivotName: props.pivotName, Value: newValue[0].value };
            DataService.post(props.cubeName + "/resolveExtraPivots", requestData).then(res => {
                if (res.data.ErrorMessage) {
                    setError(res.data.ErrorMessage);
                }
                else {
                    setError(null);
                    if (requestData.Value.indexOf('*') > -1) clearInput();
                    props.addPivots(props.pivotName, res.data.ExtraValues);
                }
            }).catch(error => {
                console.error("Could not resolve pivots");
                console.error(error);
            });
        }
        else {
            setSelectedValues(newValue);
            setOptions(searchOptionsLocal('', options, newValue.filter((value) => value.label.indexOf("(add additional values)" < 0)), props.supportPrefix, props.enableSearch));
            props.onChange(newValue);
        }
    }

    const onSelectAll = () => {
        onSelectChange(options);
    }

    const onClearAll = () => {
        onSelectChange([]);
    }
    
    // Send request to backend to search for pivots. This function is used when the pivot is light
    const searchOptionsRemote = (inputValue, selectedValues) => {
        const timestamp = new Date().getTime();
        pivotTimestamp.current = timestamp;
        if (inputValue == null) {
            return [];
        }

        let optionDict = {};
        let selectedKeys = [];
        let otherKeys = [];
        // union selectedValues and options
        selectedValues?.forEach(element => {
            optionDict[element.value] = element;
            selectedKeys.push(element.value);
        });
        // search from backend
        const requestData = { pivot: props.pivotName, key: inputValue, size: limit };
        DataService.post(props.cubeName + "/pivotsSearch", requestData).then(res => {
            if (timestamp !== pivotTimestamp.current) return;
            let pivotOptions;
            if (res.data.ErrorMessage) {
                setError(res.data.ErrorMessage);
                pivotOptions = [];
            }
            else {
                pivotOptions = res.data.pivots.map((e => ({ "label": (!e) ? "(blank)" : e, "value": e })));
            }
            pivotOptions?.forEach(element => {
                if (!(element.value in optionDict)) {
                    optionDict[element.value] = element;
                    otherKeys.push(element.value);
                }
            });

            const array = prefixAndNewPivot(inputValue, props.supportPrefix, props.enableSearch, optionDict);
            const inputValueLow = Array.isArray(inputValue) && inputValue.length === 0 ? "" : inputValue.toLowerCase();
            setOptions(array.concat(searchKeys(inputValueLow, selectedKeys, optionDict)).concat(searchKeys(inputValueLow, otherKeys, optionDict)));
            setIsLoading(false);
        }).catch(error => {
            console.error("Could not search pivots");
            console.error(error);
            setError("Could not search pivots, please try again later.");
            setOptions([]);
            setIsLoading(false);
        });
    }

    // Search for pivots in frontend locally. This function is used when the pivot is not light.
    const searchOptionsLocal = (inputValue, options, selectedValues, supportPrefix, enableSearch) => {
        if (inputValue == null) {
            return [];
        }

        let optionDict = {};
        let selectedKeys = [];
        let otherKeys = [];
        // union selectedValues and options
        selectedValues?.forEach(element => {
            optionDict[element.value] = element;
            selectedKeys.push(element.value);
        });
        options?.forEach(element => {
            if (!(element.value in optionDict)) {
                optionDict[element.value] = element;
                otherKeys.push(element.value);
            }
        });

        const array = prefixAndNewPivot(inputValue, supportPrefix, enableSearch, optionDict);
        const inputValueLow = Array.isArray(inputValue) && inputValue.length === 0 ? "" : inputValue.toLowerCase();
        return array.concat(searchKeys(inputValueLow, selectedKeys, optionDict)).concat(searchKeys(inputValueLow, otherKeys, optionDict));
    }

    const debouncedSearch = useRef(debounce(searchOptionsRemote, searchAfterTyping));

    // Set loading and only after user stop typing for searchAfterTypeing time then search pivot from backend. This function is used when the pivot is light
    const loadingAndSearchRemote = (inputValue) => {
        setIsLoading(true);
        debouncedSearch.current(inputValue, selectedValues);
    }

    // avoid search text to be cleared after selection
    const onInputChange = (inputValue, { action }) => {
        if (action === 'input-change') {
            setError(null);
            setInputValue(inputValue);
            if (props.lightPivot) {
                loadingAndSearchRemote(inputValue);
            } else {
                setOptions(searchOptionsLocal(inputValue, originalOptions, selectedValues, props.supportPrefix, props.enableSearch));
            }
        }
    }

    const clearInput = () => {
        setError(null);
        setInputValue('');
        if (props.lightPivot) {
            loadingAndSearchRemote('');
        } else {
            setOptions(searchOptionsLocal('', originalOptions, selectedValues, props.supportPrefix, props.enableSearch));
        }
    }

    const getDisplayedValue = () => {
        if (selectedValues.length === 0) {
            return "Select..."
        } else {
            const concatValues = (accumulator, currentValue) => accumulator + currentValue.label + ",";
            let valuesAsString = selectedValues.reduce(concatValues, "").slice(0, -1);
            return selectedValues.length === 1 ? valuesAsString : `(${selectedValues.length}) ${valuesAsString}`;
        }
    }

    return (
        <div ref={menuRef} style={{ width: "100%" }}>
            <Button className="selectButton" aria-label={props.pivotFriendlyName + " " + getDisplayedValue()} onClick={() => setShowMenu(!showMenu)} variant="secondary">{getDisplayedValue()}</Button>
            {showMenu &&
                <div className="pivotDropdown">
                    {props.isMulti && <Button variant="light" tabIndex={0} className="pivotSelectionButton" onClick={onSelectAll}>Select All</Button>}
                    {props.isMulti && <Button variant="light" tabIndex={0} className="pivotSelectionButton" onClick={onClearAll}>Clear All</Button>}
                    <Select
                        autoFocus
                        backspaceRemovesValue={false}
                        error={error}
                        components={{
                            DropdownIndicator: () => (<div></div>),
                            IndicatorSeparator: null,
                            NoOptionsMessage: (base) => (<components.NoOptionsMessage {...{...base, children: props.lightPivot?"type to search":"no options"}} />)
                        }}
                        controlShouldRenderValue={false}
                        hideSelectedOptions={false}
                        isClearable={false}
                        menuIsOpen={!isLoading}
                        isLoading={isLoading}
                        placeholder="Search..."
                        tabSelectsValue={false}
                        isMulti={props.isMulti}
                        closeMenuOnSelect={false}
                        value={selectedValues}
                        options={options.slice(0, limit)}
                        styles={selectStyles}
                        inputValue={inputValue}
                        onInputChange={onInputChange}
                        onChange={onSelectChange}
                        classNamePrefix="react-select"
                        aria-label="Select pivot values"
                    />
                    {!isLoading && inputValue && <button tabIndex={0} className="pivotSelectionClear" title="Clear pivot search" onClick={clearInput}><FontAwesomeIcon icon={faTimes} /></button>}
                    {error && <span className="pivotSelectionButton"><ValidationWarning text={error} /></span>}
                    {!isLoading && options.length > limit && <p className="limitInfoIndicator">Only showing top {limit} items</p>}
                </div>
            }
        </div>
    );
}