import DataService from './DataService'

const LayoutService = {
    getBanner: () => {
        return DataService.get('StatusBanner/getBanner')
    },
    saveBanner: banner => {
        return DataService.post('StatusBanner/editBanner', banner)
    }
}

export default LayoutService