import React, {Component} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Collapse from 'react-bootstrap/Collapse'
import Badge from 'react-bootstrap/Badge'
import ValidationInput from './ValidationInput'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class OwnersModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            owners: props.owners.join(";"),
            ownersError: '',
            dataUpdateTime: props.dataUpdateTime
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.dataUpdateTime !== prevState.dataUpdateTime) {
            return {
                owners: nextProps.owners.join(";"),
                ownersError: '',
                dataUpdateTime: nextProps.dataUpdateTime
            }
        }

        return null
    }

    validateOwners = (owner) => {
        if (owner && owner.indexOf(' ') !== -1) { return 'Owners cannot contain spaces!'} 
        return ''
    } 

    onOwnersChange = (e) => { this.setState({ owners: e.target.value, ownersError: this.validateOwners(e.target.value) }) }

    changeOwners = () => {
        const { owners } = this.state
        const { toggleModal, change } = this.props
        change(owners)
        toggleModal()
    }

    render() {
        const { toggleModal, showModal } = this.props
        const { owners, ownersError } = this.state

        return (
            <Modal show={showModal} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Cube CSV Owner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">Owners</Form.Label>
                            <Col sm="9">
                                <ValidationInput
                                    type="page"
                                    columnkey="owners"
                                    value={owners} 
                                    error={ownersError}
                                    onChange={this.onOwnersChange}/>
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={toggleModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={this.changeOwners}>
                        Change
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default OwnersModal