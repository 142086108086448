import { classType, columnTableSchema, RoleList, ClassWithRoleList } from "./CubeCSVConst"

const dateReg = new RegExp('[Dd]ate')

const ringReg = new RegExp('.*[Rr]ing.*')

const buildReg = new RegExp('.*[Bb]uild.*')

const dataErrorReg = new RegExp('data_error')

const pathReg = new RegExp('.*[Pp]ath.*')

const bucketFloorReg = new RegExp('.*bucket_floor.*')

const bucketCeilingReg = new RegExp('.*bucket_ceiling.*')

const checkpointKeyReg = new RegExp('(.+)[Cc]heckpoint_*[Kk]ey')

const checkpointValueReg = new RegExp('(.+)[Cc]heckpoint_*[Vv]alue')

const metricKeyReg = new RegExp('(.+)[Mm]etric_*[Kk]ey')

const metricValueReg = new RegExp('(.+)[Mm]etric_*[Vv]alue')

const prevString = new RegExp('(([A-Z]|[a-z])([a-z]|[0-9])*)_?', 'g')
// while(xArray = myRegexp.exec(myString)) console.log(xArray);

const sampleReg = new RegExp('[Ss]amples?')

const latencyReg = new RegExp('.*[Ll]atency.*')

const pivothashReg = new RegExp('.*[Pp]ivot_?[Hh]ash.*')

const primaryLookupReg = new RegExp('.*(([Rr]equest)|([Ccorrelation]))_?[Ii]d')

const lookupReg = new RegExp('[Tt]race_?[Ii]d')

const hourReg = new RegExp('[Hh]our')

export function ClassTypeGuess(name) {
    if(dateReg.exec(name)) return classType.Date
    else if(ringReg.exec(name)) return classType.Ring
    else if(buildReg.exec(name)) return classType.Build
    else if(dataErrorReg.exec(name)) return classType.DataAnomaly
    else if(pathReg.exec(name)) return classType.Path
    else if(bucketFloorReg.exec(name)) return classType.LatencyBucketFloor
    else if(bucketCeilingReg.exec(name)) return classType.LatencyBucketCeiling
    else if(checkpointKeyReg.exec(name)) return classType.CheckpointKey
    else if(checkpointValueReg.exec(name)) return classType.CheckpointValue
    else if(metricKeyReg.exec(name)) return classType.MetricKey
    else if(metricValueReg.exec(name)) return classType.MetricValue
    else if(sampleReg.exec(name)) return classType.Samples
    else if(latencyReg.exec(name)) return classType.Latency
    else if(pivothashReg.exec(name)) return classType.PivotHash
    else if(primaryLookupReg.exec(name)) return classType.PivotHashPrimaryLookup
    else if(lookupReg.exec(name)) return classType.PivotHashLookup
    else if(hourReg.exec(name)) return classType.Hour
    else return classType.Pivot
}

export function AutoFill(row, dataHeader, headerIndex, isFlat) {
    let className = row[headerIndex[columnTableSchema.Class]]
    if(!className && row[headerIndex[columnTableSchema.ColumnInCosmos]]){
        className = ClassTypeGuess(row[headerIndex[columnTableSchema.ColumnInCosmos]])
    }
    if(!className && row[headerIndex[columnTableSchema.SqlColumnName]]) {
        className = ClassTypeGuess(row[headerIndex[columnTableSchema.SqlColumnName]])
    }
    if(!className) return null 
    let baseStringList = []
    if(row[headerIndex[columnTableSchema.ColumnInCosmos]]) {
        const myStr = row[headerIndex[columnTableSchema.ColumnInCosmos]]
        let xArray
        while(xArray = prevString.exec(myStr)) {baseStringList.push(xArray[1]) }
    }
    if(!baseStringList.length && row[headerIndex[columnTableSchema.SqlColumnName]]) {
        const myStr = row[headerIndex[columnTableSchema.SqlColumnName]]
        let xArray
        while(xArray = prevString.exec(myStr)) { baseStringList.push(xArray[1]) }
    }
    let result = []
    for(let i = 0; i < row.length; i++) {
        if(!row[i] && columnAutoFillMap[dataHeader[i].toLowerCase()]) {
            result.push(columnAutoFillMap[dataHeader[i].toLowerCase()](baseStringList, className, headerIndex, isFlat))
        }else {
            result.push(row[i])
        }
    }
    return result
}

export function AutoFillColumnInCosmos(baseStringList, className, headerIndex, isFlat) {
    const tmpStringList = baseStringList.map((item) => (item.toLowerCase()))
    return tmpStringList.join('_')
}

export function AutoFillCosmosDataType(baseStringList, className, headerIndex, isFlat) {
    if(className === classType.LatencyBucketFloor || className === classType.LatencyBucketCeiling) return "int"
    else if(className === classType.Samples || className === classType.Latency || className === classType.PivotHash) return "long"
    else return "string"
}

export function AutoFillClass(baseStringList, className, headerIndex, isFlat) {
    return className
}

export function AutoFillPresentInView(baseStringList, className, headerIndex, isFlat) {
    if(className === classType.Samples || className === classType.PivotHash) return "FALSE"
    else return "TRUE"
}

export function AutoFillIsDataFromCosmos(baseStringList, className, headerIndex, isFlat) {
    if(className === classType.Samples) return "FALSE"
    else return "TRUE"
}

export function AutoFillDimMap(baseStringList, className, headerIndex, isFlat) {
    return ""
}

export function AutoFillRole(baseStringList, className, headerIndex, isFlat) {
    if(!ClassWithRoleList.includes(className)) {
        return ""
    }
    if(className === classType.Build || className === classType.Ring) return RoleList.BE.name
    else {
        const tmpStringList = baseStringList.slice(0, -2).map((item) => (item.toLowerCase())).join("_")
        for(let key in RoleList) {
            if(RoleList[key].regString && RoleList[key].regString.exec(tmpStringList)) return RoleList[key].name
        }
    }
    return ""
}

export function AutoFillSQLTable(baseStringList, className, headerIndex, isFlat) {
    if(className === classType.Hour || className === classType.PivotHashLookup || className === classType.PivotHashPrimaryLookup ) return ""
    if(isFlat || className === classType.CheckpointValue || className === classType.MetricValue) return "[dbo].[FactTable]"
    if(!isFlat && className === classType.CheckpointKey) return "[dbo].[Dim_CheckpointKey]"
    if(!isFlat && className === classType.MetricKey) return "[dbo].[Dim_MetricKey]"
    if(!isFlat && (className === classType.LatencyBucketCeiling || className === classType.LatencyBucketFloor) ) return "[dbo].[Dim_LatencyBucket]"
    if(!isFlat && (className === classType.Pivot || className === classType.Build || className === classType.Ring) ) {
        const tmpStringList = baseStringList.map(item => {let tmp = item[0].toUpperCase()+item.slice(1); return tmp})
        return "[dbo].[Dim_" + tmpStringList.join('') + "]"
    }
    return ""
}

export function AutoFillSqlColumnName(baseStringList, className, headerIndex, isFlat) {
    const tmpStringList = baseStringList.map(item => {let tmp = item[0].toUpperCase()+item.slice(1); return tmp})
    return tmpStringList.join('')
}

export function AutoFillSqlDataType(baseStringList, className, headerIndex, isFlat) {
    if(className === classType.Latency || className === classType.Samples) return "bigint"
    if(className === classType.LatencyBucketCeiling || className === classType.LatencyBucketFloor) return "int"
    if(className === classType.CheckpointKey || className === classType.CheckpointValue || className === classType.MetricValue) return "varchar(1024)"
    if(className === classType.MetricKey) return "varchar(1500)"
    return ""
}

export function AutoFillFriendlyName(baseStringList, className, headerIndex, isFlat) {
    if(className === classType.Pivot || className === classType.Build || className === classType.Ring) {
        const tmpStringList = baseStringList.map(item => {let tmp = item[0].toUpperCase()+item.slice(1); return tmp})
        return tmpStringList.join(' ')
    }else if(className === classType.CheckpointKey || className === classType.CheckpointValue || className === classType.MetricKey || className === classType.MetricValue){
        const tmpStringList = baseStringList.slice(0, -2).map((item) => (item.toLowerCase())).join("_")
        for(let key in RoleList) {
            if(RoleList[key].regString && RoleList[key].regString.exec(tmpStringList)) return RoleList[key].friendlyName
        }
        return tmpStringList
    }
}

export function AutoFillDimshortname(baseStringList, className, headerIndex, isFlat) {
    if(isFlat) return ""
    if(className === classType.Pivot || className === classType.Build || className === classType.Ring || className === classType.CheckpointKey || className === classType.MetricKey) {
        const tmpStringList = baseStringList.map((item) => (item.toLowerCase()))
        return tmpStringList.join('_')
    }
    return ""
}

export function AutoFillFactTableColumnId(baseStringList, className, headerIndex, isFlat) {
    if(isFlat) return ""
    if(className === classType.Pivot || className === classType.Build || className === classType.Ring || className === classType.CheckpointKey || className === classType.MetricKey) {
        const tmpStringList = baseStringList.map(item => {let tmp = item[0].toUpperCase()+item.slice(1); return tmp})
        return tmpStringList.join('')+"Id"
    }
    return ""
}

export function AutoFillIdcolsize(baseStringList, className, headerIndex, isFlat) { return "" }

export function AutoFillSnowFlake(baseStringList, className, headerIndex, isFlat) { return "" }

export function AutoFillSnowFlakeId(baseStringList, className, headerIndex, isFlat) { return "" }

export function AutoFillSnowFlakeAlias(baseStringList, className, headerIndex, isFlat) { return "" }

export function AutoFillSnowFlakeDimTableId(baseStringList, className, headerIndex, isFlat) { return "" }

export function AutoFillGroupName(baseStringList, className, headerIndex, isFlat) { return "" }

export function AutoFillIsGroupCollapsed(baseStringList, className, headerIndex, isFlat) { return "" }

export function AutoFillDefaultValueInUI(baseStringList, className, headerIndex, isFlat) { return "" }

export function AutoFillIsHiddenInUI(baseStringList, className, headerIndex, isFlat) { return "FALSE" }

export function AutoFillIsCalculatedColumn(baseStringList, className, headerIndex, isFlat) { return "FALSE" }

export function AutoFillCalculationFunction(baseStringList, className, headerIndex, isFlat) { return "" }

export function AutoFillAdditionalDetails(baseStringList, className, headerIndex, isFlat) { return "" }

export function AutoFillGroupNum(baseStringList, className, headerIndex, isFlat) { return "" }

export function AutoFillRowNumber(baseStringList, className, headerIndex, isFlat) { return "" }

export function AutoFillRoleGapName(baseStringList, className, headerIndex, isFlat) { return "" }

export function AutoFillRTTColumn(baseStringList, className, headerIndex, isFlat) { return "" }

export function AutoFillUrlName(baseStringList, className, headerIndex, isFlat) { 
    if(className === classType.Pivot){
        const tmpStringList = baseStringList.map((item) => (item[0].toLowerCase()))
        return tmpStringList.join('')
    }
    return "" 
}

export function AutoFillRollupName(baseStringList, className, headerIndex, isFlat) {
    if(className === classType.Pivot){
        const tmpStringList = baseStringList.map((item) => (item[0].toLowerCase()))
        return "r" + tmpStringList.join('')
    }
    return "" 
}
export function AutoFillValidatorRegexName(baseStringList, className, headerIndex, isFlat) { return "" }
export function AutoFillValidatorRegexValue(baseStringList, className, headerIndex, isFlat) { return "" }

export function AutoFillRoleRole1(baseStringList, className, headerIndex, isFlat) {}
export function AutoFillRoleRole2(baseStringList, className, headerIndex, isFlat) {}
export function AutoFillRoleAlignment1(baseStringList, className, headerIndex, isFlat) {}
export function AutoFillRoleAlignment2(baseStringList, className, headerIndex, isFlat) {}
export function AutoFillRoleFallback(baseStringList, className, headerIndex, isFlat) {}

export function AutoFillLogName(baseStringList, className, headerIndex, isFlat) {}
export function AutoFillLogFriendlyName(baseStringList, className, headerIndex, isFlat) {}
export function AutoFillLogDefaultVal(baseStringList, className, headerIndex, isFlat) {}

export const columnAutoFillMap = {
    [columnTableSchema.ColumnInCosmos]: AutoFillColumnInCosmos,
    [columnTableSchema.CosmosDataType]: AutoFillCosmosDataType,
    [columnTableSchema.Class]: AutoFillClass,
    [columnTableSchema.PresentInView]: AutoFillPresentInView,
    [columnTableSchema.IsDataFromCosmos]: AutoFillIsDataFromCosmos,
    [columnTableSchema.DimMap]: AutoFillDimMap,
    [columnTableSchema.Role]: AutoFillRole,
    [columnTableSchema.SQLTable]: AutoFillSQLTable,
    [columnTableSchema.SqlColumnName]: AutoFillSqlColumnName,
    [columnTableSchema.SqlDataType]: AutoFillSqlDataType,
    [columnTableSchema.FriendlyName]: AutoFillFriendlyName,
    [columnTableSchema.Dimshortname]: AutoFillDimshortname,
    [columnTableSchema.FactTableColumnId]: AutoFillFactTableColumnId,
    [columnTableSchema.Idcolsize]: AutoFillIdcolsize,
    [columnTableSchema.SnowFlake]: AutoFillSnowFlake, 
    [columnTableSchema.SnowFlakeId]: AutoFillSnowFlakeId, 
    [columnTableSchema.SnowFlakeAlias]: AutoFillSnowFlakeAlias,
    [columnTableSchema.SnowFlakeDimTableId]: AutoFillSnowFlakeDimTableId,
    [columnTableSchema.GroupName]: AutoFillGroupName,
    [columnTableSchema.IsGroupCollapsed]: AutoFillIsGroupCollapsed,
    [columnTableSchema.DefaultValueInUI]: AutoFillDefaultValueInUI,
    [columnTableSchema.IsHiddenInUI]: AutoFillIsHiddenInUI,
    [columnTableSchema.IsCalculatedColumn]: AutoFillIsCalculatedColumn,
    [columnTableSchema.CalculationFunction]: AutoFillCalculationFunction,
    [columnTableSchema.AdditionalDetails]: AutoFillAdditionalDetails,
    [columnTableSchema.GroupNum]: AutoFillGroupNum,
    [columnTableSchema.RowNumber]: AutoFillRowNumber,
    [columnTableSchema.RoleGapName]: AutoFillRoleGapName,
    [columnTableSchema.RTTcolumn]: AutoFillRTTColumn,
    [columnTableSchema.UrlName]: AutoFillUrlName,
    [columnTableSchema.RollupUrlName]: AutoFillRollupName,
    [columnTableSchema.ValidatorRegexName]: AutoFillValidatorRegexName,
    [columnTableSchema.ValidatorRegexValue]: AutoFillValidatorRegexValue
}