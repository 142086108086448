import DataService from '../Dataservices/DataService';
import config from '../config';
import { loadUserInfo } from './AuthUtils';

export var pool = ["userInfo", "clickEvent", "graphEvent", "visitData", "loadTime", "scrollDepth"]

export function handleClick(elementName, position, page) {
    if (config.enableEventTracking) {

        //get previous state of "clickEvent"
        var preState = JSON.parse(window.localStorage.getItem("clickEvent")) === null ?
            {} : JSON.parse(window.localStorage.getItem("clickEvent"));

        var clickCount;
        var controlID = "/" + page + "/" + position + "/" + elementName;

        //if sid not in localStorage, load first
        loadUserInfo().then(()=>{
            var sid = JSON.parse(window.localStorage.getItem("userInfo")) === null ? "" : JSON.parse(window.localStorage.getItem("userInfo"))["sid"];

            //if current button first appears, create a new clickevent and add into "clickEvent"
            //if it appears not first time, just update click count
            if (controlID in preState) {
                var clickCount = preState[controlID]["detail"]["clickCount"];
                clickCount += 1;
                preState[controlID]["detail"]["clickCount"] = clickCount;
            } else {
                clickCount = 1;
                var curClickEvent = {
                    "date": new Date().toLocaleString(),
                    "sid": sid,
                    "eventID": 1001,
                    "controlID": controlID,
                    "detail": {
                        "buttonName": elementName,
                        "clickCount": clickCount,
                        "page": "analysis",
                        "position": position
                    }
                };
                preState[controlID] = curClickEvent;
            }

            window.localStorage.setItem("clickEvent", JSON.stringify(preState));
        })
        
    } else {
        //if enableEventTrack is false, simply do nothing.
    }

}

export function handleGraph(elementName, tab, page, queryTime, renderTime, chartTitle = null, dashboardInfo = null) {

    if (config.enableEventTracking) {
        //get previous state of graphEvent
        var preState = JSON.parse(window.localStorage.getItem("graphEvent")) === null ? [] : JSON.parse(window.localStorage.getItem("graphEvent"));
        var controlID = "/" + page + "/" + elementName + "/" + tab;
        
        //if sid not in localStorage, load first
        loadUserInfo().then(() => {
            var sid = JSON.parse(window.localStorage.getItem("userInfo")) === null ? "" : JSON.parse(window.localStorage.getItem("userInfo"))["sid"];

            //if cube graph event happens at dashboard page
            if (page === "dashboard") {
                var curGraphEvent = {
                    "date": new Date().toLocaleString(),
                    "controlID": controlID,
                    "sid": sid,
                    "eventID": 1003,
                    "detail": {
                        "charTitle": chartTitle,
                        "cubeName": elementName,
                        "queryTime": queryTime,
                        "renderTime": renderTime,
                        "tab": tab,
                        "source": page,
                        "dashboardInfo": dashboardInfo
                    },
                };
            //if cube graph event happens at analysis page
            } else {
                var curGraphEvent = {
                    "date": new Date().toLocaleString(),
                    "sid": sid,
                    "controlID": controlID,
                    "eventID": 1003,
                    "detail": {
                        "cubeName": elementName,
                        "queryTime": queryTime,
                        "renderTime": renderTime,
                        "tab": tab,
                        "source": page,
                    }
                };
            }
            console.log("curGraphEvent  ", curGraphEvent);
            //add current cube graph event into "graphEvent" collections
            preState.push(curGraphEvent);

            console.log("Graphtime--Data query part: ", queryTime);
            console.log("Graphtime--Render part: ", renderTime);

            window.localStorage.setItem("graphEvent", JSON.stringify(preState));
        })
    } else {

    }
}

function saveEventTrackingData() {
    if (config.enableDataSaving) {
        var request = {};

        // load user authorization info
        loadUserInfo().then(() => {
            //collect event data in the localStorage and create a request
            for (var i in pool) {
                var key = pool[i];
                if (window.localStorage.getItem(key) !== null) {
                    request[key] = window.localStorage.getItem(key);
                }
            }

            DataService.post("/eventtracking/addUserEvent", request).then(res => {
                console.log(res);
            }).catch(error => {
                console.log("Failed to save user data");
                console.log(error);
            });
        });
    } else {

    }
}

//If directly call handlePageEvent2() in App.js, removeEventListener() won't work,
//listener will accumulates as user enters more pages and won't be removed.
//By wrapping it with a helper function handlePageEvent(), such scenario could be avoided.

export var handlePageEvent = function () {
    handlePageEvent2();
}

function handlePageEvent2() {

    if (config.enableEventTracking) {
        // create a listener, this listener will be triggered each time the window is "beforeunload"
        const listener = ev => {

            var loadTime = window.localStorage.getItem("loadTime") === null ? 0 : window.localStorage.getItem("loadTime");
            var scrollDepth = window.localStorage.getItem("scrollDepth") === null ? 0 : window.localStorage.getItem("scrollDepth");

            var leaveTime = new Date().getTime();

            //total time that a user stay at a page
            var timeDuration = leaveTime - startTime;
            console.log("Total visit period, ", timeDuration);

            var currentURL = window.location.href;
            console.log("Current url, ", window.location.href);

            loadUserInfo().then(()=>{
                var sid = JSON.parse(window.localStorage.getItem("userInfo")) === null ? "" : JSON.parse(window.localStorage.getItem("userInfo"))["sid"];

                //simpilify the URL, exclude the parameter part, which is after "?"
                var controlID = currentURL.substring(0, currentURL.indexOf('?') === -1 ? currentURL.length : currentURL.indexOf('?'));

                var referer = document.referrer;
                referer = referer.substring(0, referer.indexOf('?') === -1 ? referer.length : referer.indexOf('?'));
                console.log("modifiedURL is ", controlID);
                window.localStorage.removeItem("visitData");

                var controlID = "/" + controlID;
                var curVisitEvent = {
                    "controlID": controlID,
                    "eventID": 1002,
                    "date": new Date().toLocaleString(),
                    "sid": sid,
                    "detail": {
                        "url": currentURL,
                        "timeDuration": timeDuration,
                        "source": referer,
                        "loadTime": parseInt(loadTime),
                        "scrollDepth": parseInt(scrollDepth),
                    },
                };
                console.log("curVisitEvent", curVisitEvent);

                visitData.push(curVisitEvent);
                console.log("curVisitData", visitData);

                window.localStorage.setItem("visitData", JSON.stringify(visitData));

                var referer = document.referrer;
                console.log("previous referer is ", referer);

                if (config.enableDataSaving) {
                    //if configure enableDataSaving == true, event data will be stored in backend db, then clear localStorage
                    saveEventTrackingData();
                    for (var i in pool) {
                        if (pool[i] !== "userInfo") {
                            window.localStorage.removeItem(pool[i]);
                        }
                    }
                }
            })
        };

        var visitData = JSON.parse(window.localStorage.getItem("visitData")) === null ? [] : JSON.parse(window.localStorage.getItem("visitData"));
        var startTime = new Date().getTime();
        var maxPercent = 0;

        //record the scroll depth
        //take it out if it affects user experience
        document.body.onscroll = () => {
            var percentDistance = Math.round((document.documentElement.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight) * 100);
            if (percentDistance > maxPercent) {
                maxPercent = percentDistance;
                window.localStorage.setItem("scrollDepth", maxPercent);
            }
        }
        
        window.addEventListener('beforeunload', listener);

        return () => {
            window.removeEventListener('beforeunload', listener)
        }
    } else {

    }
}

