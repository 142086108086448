import React, { useState } from 'react';
import { Form, ListGroup, InputGroup } from 'react-bootstrap';
import { useComponentShown } from '../../Functions/DropdownListHelper'
import './EditableSelect.css';

function EditableSelect (props) {
    const { value, onChange, options, onChangePayload, className, isMultiSelect, disableValueChange, disableSelectChange, isRequired } = props;

    const [showDropdown, setShowDropdown] = useState(false);
    const { ref, isComponentShown } = useComponentShown(false, showDropdown);

    const onTextChange = e => {
        if (disableValueChange) {
            return;
        }

        onChange(e.target.value, onChangePayload);
    };

    const onSelectChange = (option, index) => {
        if (disableSelectChange) {
            return;
        }

        if (isMultiSelect && value && value.length > 0) {
            if (value.indexOf(option) > -1) {
                return;
            } else {
                onChange(value + ',' + option, onChangePayload, index);
            }
        } else {
            onChange(option, onChangePayload, index);
        }

        if (!isMultiSelect) {
            setShowDropdown(false);
        }
    };

    const onInputFocus = () => {
        setShowDropdown(true);
    };

    return (
        <div className={"editable-select-container " + className} ref={ref}>
            <InputGroup>
                <Form.Control
                    className="editable-select-input"
                    type="text"
                    aria-required={isRequired}
                    disabled={disableValueChange && disableSelectChange}
                    value={value} 
                    onChange={onTextChange} 
                    onFocus={onInputFocus}
                    style={{caretColor: disableValueChange ? 'transparent' : 'auto', cursor: disableValueChange ? 'pointer' : 'auto'}}
                    />
            </InputGroup>
            {isComponentShown && showDropdown && options && options.length > 0 && !disableSelectChange &&
            <ListGroup className="editable-select-dropdown">
                {options.map((option, index) => 
                    <button 
                        role="listitem"
                        key={option} 
                        onClick={onSelectChange.bind(this, option, index)} 
                        className="option"
                        >
                            {option}
                    </button>)}
            </ListGroup>}
        </div>
    );
};

export default EditableSelect;