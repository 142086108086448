import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component } from 'react';
import 'antd/dist/antd.css';
import MaintenanceService from '../../Dataservices/MaintenanceService';

class GetTrimSqlModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            MasterFactTableSql: "",
            FactUnionViewSql: "",
            DeleteTableSql: "",
        }
    }
   
    handleGetSqlStatement = () => {
        const { ServerName, CubeDatabaseName, TableName, TrimmingDate } = this.props;

        this.setState({ loading: true, error: null });

        let request = {
            ServerName: ServerName,
            CubeDatabaseName: CubeDatabaseName,
            TableName: TableName,
            TrimmingDate: TrimmingDate
        };

        MaintenanceService.getTrimmingSqlStatement(request).then((res) => {
            this.setState({ MasterFactTableSql: res.data.MasterTableUpdate, FactUnionViewSql: res.data.FactUnionViewUpdate, DeleteTableSql: res.data.TableDeletion, loading: false });
        })
        .catch((error) => {
            this.setState({ error: error.message, loading: false });
        });
    };

    componentDidMount() {
        this.handleGetSqlStatement();
    }

    componentDidUpdate(prevProps) {
        if (this.props.ServerName != prevProps.ServerName || this.props.CubeDatabaseName != prevProps.CubeDatabaseName || this.props.TableName != prevProps.TableName) {
            this.handleGetSqlStatement();
        }
    }
    
    render() {
        const { hideGetSql, showGetSql, ServerName, CubeDatabaseName, CubeName, TableName, TrimmingDate } = this.props;
        const { MasterFactTableSql, FactUnionViewSql, DeleteTableSql, loading, error } = this.state;
    return (
       <Modal className="TrimmingSQLStatement" show={showGetSql} onHide={hideGetSql}>
         <Modal.Header closeButton>
            <Modal.Title>Get Trimming SQL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Server Name: {ServerName}</p>
            <p>Cube Database Name: {CubeDatabaseName}</p>
            <p>Cube Name: {CubeName}</p>
            <p>Table Name: {TableName}</p>
            <p>Trimming Job Date: {TrimmingDate}</p>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                <div>
                    <p className="sql-statement">{MasterFactTableSql}</p>
                    <p className="sql-statement">{FactUnionViewSql}</p>
                    <p className="sql-statement">{DeleteTableSql}</p>
                </div>
            )}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={hideGetSql}>Close</Button>
        </Modal.Footer>
        </Modal>
    )}
}
    
export default GetTrimSqlModal;
