
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

function SectionStatus(props) {
    const { type, text } = props;

    return (
        <Form.Text className="section-status">
            {type === 'info' && <span role="status" aria-atomic="true">{text}</span>}
            {type === 'error' && <span style={{color: 'red'}} role="alert" aria-atomic="true"><FontAwesomeIcon icon={faExclamationCircle}/>&ensp;{text}</span>}
            {type === 'success' && <span style={{color: 'green'}} role="status" aria-atomic="true"><FontAwesomeIcon icon={faCheckCircle}/>&ensp;{text}</span>}
        </Form.Text>
    );
};

export default SectionStatus;