import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faBook } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import CopilotService from '../../Dataservices/CopilotService';
import "./CopilotComponents.css";

export default class SendBox extends Component {
    constructor(props) {
        super(props)

        this.state = {
            message: '',
            error: '',
            snapshotList: [],
            selectedSnapshot: null,
            preMessage: '',
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.updateMessageTime !== this.props.updateMessageTime) {
            this.setState({
                message: this.state.message + this.props.updateMessage,
            })
        }
    }

    componentDidMount() {
        CopilotService.getSnapshotList().then((res) => {
            this.setState({
                snapshotList: res.data
            })
        })
    }

    changeMessage = (event) => {
        this.setState({
            message: event.target.value
        })
    }

    validateMessage = (message) => {
        if (message.length === 0) {
            this.setState({
                error: 'Please enter a message'
            })
            return false;
        } else if (message.length > 500) {
            this.setState({
                error: 'Message is too long'
            })
            return false;
        }
        this.setState({
            error: ''
        })
        return true;
    }

    setMessage = (message) => {
        this.setState({
            message: message
        })
    }

    sendMessage = () => {
        const { message } = this.state;
        const { sendMessage } = this.props;

        if (this.validateMessage(message)) {
            sendMessage(message);
            this.setState({
                message: '',
                preMessage: message
            })
        }
    }

    changeSnapshot = (snapshot) => {
        this.setState({
            selectedSnapshot: snapshot
        })
        this.props.changeSnapshot(snapshot);
    }

    render() {
        const { message, error, snapshotList, selectedSnapshot, preMessage } = this.state;
        const { debug, loginUser, switchDebug, recommend, refreshRecommend } = this.props;

        return (
            <Form className='sendbox'>
                <Form.Row>
                    {recommend.map((item) => (
                        <Button variant="outline-dark" style={{"border": "1px solid #B0BEFF", 
                        "padding": "4px, 8px, 4px, 8px",
                        "border-radius": "8px",
                        "gap": "4px",
                        }} onClick={this.setMessage.bind(this, item)}>{item}</Button>
                    ))}
                    <Button variant="outline-dark" className='sendbutton' aria-label="re fetch recommendation query" onClick={() => { refreshRecommend(preMessage) }}>
                        <FontAwesomeIcon icon={faBook} />
                    </Button>
                </Form.Row>
                <Form.Row>
                    <Form.Control as="textarea" rows={2} value={message} onChange={this.changeMessage} placeholder="Input your question here" />
                </Form.Row>
                <Form.Row>
                    <Form.Text id="messageLength">
                        {message.length}/500
                    </Form.Text>
                    <Form.Control.Feedback type="invalid" style={{ "width": "auto", "display": "auto", "margin-left": "1rem" }}>
                        {error}
                    </Form.Control.Feedback>
                    <div className='senddebugrow'>
                        <Button className='sendbutton' variant="outline-dark" aria-label="send message" onClick={this.sendMessage}>
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </Button>
                        {loginUser.isAdmin && <Form.Check type="switch" id="debug-switch" check={debug} onClick={switchDebug} label="Debug" />}
                        {debug &&
                            <Dropdown className='snapshotlist'>
                                <Dropdown.Toggle variant="outline-dark" size="sm">
                                    {selectedSnapshot ?? "Select Snapshot"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {snapshotList.map((snapshot) => (
                                        <Dropdown.Item key={snapshot} onClick={this.changeSnapshot.bind(this, snapshot)}>{snapshot}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    </div>
                </Form.Row>
            </Form>
        )
    }
}
