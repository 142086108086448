import React, { Component } from 'react';
import Row from 'react-bootstrap/Row'
import Select from 'react-select';
import './PipelineStatusPage.css'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

class DiskMonitor extends Component {
    
    constructor(props) {
        super(props);

        let data = this.props.data;
        let {diskGeneralKeys, diskGeneralSeries, diskGeneralMap} = this.getDiskStatistics(data);
        let defaultKey = diskGeneralKeys[0];

        this.state = {
            diskGeneralOption: {
                chart: {
                    type: 'column'
                },
                title: {
                    text: 'Disk Usage'
                },
                xAxis: {
                    categories: diskGeneralKeys
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Size'
                    },
                    labels: {
                        formatter: function() {
                            return this.value/1024 + ' TB';
                        }
                    },
                    tickInterval: 5120
                },
                
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: {point.y} GB'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal'
                    }
                },
                series: diskGeneralSeries
            },

            diskDetailOption: {
                chart: {
                    type: 'column'
                },
                xAxis: {
                    categories: diskGeneralMap[defaultKey].XAxis,
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Size'
                    },
                    labels: {
                        formatter: function() {
                            return this.value/1024 + ' TB';
                        }
                    },
                    tickInterval: 512
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                    }
                },
                title: {
                    text: 'Cube disk usage on ' + defaultKey
                },
                tooltip: {
                    pointFormat: 'Size: {point.displaySize}'
                },
                series: [
                    {
                        name: defaultKey, 
                        data: diskGeneralMap[defaultKey].Series,
                        colorByPoint: true
                    }
                ]
            },

            diskGeneralMap: diskGeneralMap,
            diskGeneralKeys: diskGeneralKeys,

            detailKey: defaultKey,
        }
    }

    displaySize = sizeInMB => {
        let GB = sizeInMB/1024;
        let displayValue = GB<1024 ? `${Math.floor(GB)} GB` : `${Math.floor(GB*10/1024)/10} TB`
        return displayValue
    }

    getDiskStatistics = data => {
        let diskGeneralSeries = [];
        let diskGeneralMap = {};
        
        let diskCharSet = {};
        let serverSet = {};

        data.sort((a,b)=>b.Size-a.Size);

        // aggregate
        for (let i in data) {
            let shortServerName = data[i].ServerName.split('.')[0];
            let key = shortServerName + '-' + data[i].DiskChar;
            diskCharSet[data[i].DiskChar] = 0;
            serverSet[shortServerName] = 0;

            if (diskGeneralMap[key] == null) {
                diskGeneralMap[key] = {
                    Series: [],
                    XAxis: [],
                    Used: 0,
                    Free: 0,
                    Delta: 0
                };
            }

            // get general series
            if (data[i].DatabaseName === 'Free') {
                diskGeneralMap[key].Free = data[i].Size;
            } else {
                diskGeneralMap[key].Used += data[i].Size;
                diskGeneralMap[key].Delta += data[i].DeltaSize;
            }

            diskGeneralMap[key].Series.push({
                y: data[i].Size/1024,
                displaySize: this.displaySize(data[i].Size)
            });
            diskGeneralMap[key].XAxis.push(data[i].DatabaseName);
        }

        let names = [ 'Delta', 'Free', 'Used'];
        let colors = ['#F7A35C', '#90ED7D', '#7CB5EC'];
        let diskGeneralKeys = [];
        for (let key in diskGeneralMap) {
            if (diskGeneralMap[key].Used !== 0) {
                diskGeneralKeys.push(key);
            }
        }
        diskGeneralKeys.sort();
        
        for (let i in names) {
            let legend = names[i];
            if (legend === 'Delta') {
                legend = 'Delta size in 7 days'
            }
            diskGeneralSeries.push({
                name: legend,
                data: diskGeneralKeys.map(key => {
                    let value = Math.floor(diskGeneralMap[key][names[i]]*10 / 1024) / 10
                    return value ? value : 0
                }),
                color: colors[i]
            })
        }

        return {diskGeneralKeys, diskGeneralSeries, diskGeneralMap}
    }

    onChangeDisk = disk => {
        let {diskGeneralMap} = this.state;
        let detailKey = disk.value;
        this.setState({
            detailKey: detailKey,
            diskDetailOption: {
                title: {
                    text: 'Cube disk usage on ' + detailKey
                },
                xAxis: {
                    categories: diskGeneralMap[detailKey].XAxis
                },
                series: [
                    {
                        name: detailKey, 
                        data: diskGeneralMap[detailKey].Series,
                        colorByPoint: true
                    }
                ]
            }
        });
    }

    render(){
        const {diskGeneralOption, diskDetailOption, diskGeneralKeys, detailKey} = this.state;
        return (
            <div aria-live="polite">
                <div>
                    <div id="DiskGeneralChart" style={{ width: "100%" }}>
                        <HighchartsReact highcharts={Highcharts} options={diskGeneralOption}></HighchartsReact>
                    </div>
                    <hr/>
                    <div>
                        <Row>
                            <div style={{"fontSize":17, "padding":8}}>Choose disk: </div>
                            <div style={{width: 300}}>
                                <Select
                                    value={{label:detailKey, value:detailKey}}
                                    options={diskGeneralKeys.map(e=>{return {label:e, value:e}})}
                                    onChange={this.onChangeDisk}
                                />
                            </div>
                        </Row>
                    </div>
                    <div id="DiskDetailChart" style={{ width: "100%", "overflowX": "auto" }}>
                        <HighchartsReact highcharts={Highcharts} options={diskDetailOption}></HighchartsReact>
                    </div>
                </div>
            </div>
        )
    }
}

export default DiskMonitor;
