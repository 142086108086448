import { columnTableSchema, roleTableSchema, logTableScheme, CosmosDataTypeList, ClassList, BooleanList, ClassWithRoleList, GroupClass,
         ClassWithNullRoleList, SQLDataTypeList, CpAndMetricClass, SQLKeyword, classType, shortNameList } from "./CubeCSVConst"

export const columnValidatorMap = {
    [columnTableSchema.ColumnInCosmos]: ValidateColumnInCosmos,
    [columnTableSchema.CosmosDataType]: ValidateCosmosDataType,
    [columnTableSchema.Class]: ValidateClass,
    [columnTableSchema.PresentInView]: ValidatePresentInView,
    [columnTableSchema.IsDataFromCosmos]: ValidateIsDataFromCosmos,
    [columnTableSchema.DimMap]: ValidateDimMap,
    [columnTableSchema.Role]: ValidateRole,
    [columnTableSchema.SQLTable]: ValidateSQLTable,
    [columnTableSchema.SqlColumnName]: ValidateSqlColumnName,
    [columnTableSchema.SqlDataType]: ValidateSqlDataType,
    [columnTableSchema.FriendlyName]: ValidateFriendlyName,
    [columnTableSchema.Dimshortname]: ValidateDimshortname,
    [columnTableSchema.FactTableColumnId]: ValidateFactTableColumnId,
    [columnTableSchema.Idcolsize]: ValidateIdcolsize,
    [columnTableSchema.SnowFlake]: ValidateSnowFlake, 
    [columnTableSchema.SnowFlakeId]: ValidateSnowFlakeId, 
    [columnTableSchema.SnowFlakeAlias]: ValidateSnowFlakeAlias,
    [columnTableSchema.SnowFlakeDimTableId]: ValidateSnowFlakeDimTableId,
    [columnTableSchema.GroupName]: ValidateGroupName,
    [columnTableSchema.IsGroupCollapsed]: ValidateIsGroupCollapsed,
    [columnTableSchema.DefaultValueInUI]: ValidateDefaultValueInUI,
    [columnTableSchema.IsHiddenInUI]: ValidateIsHiddenInUI,
    [columnTableSchema.IsCalculatedColumn]: ValidateIsCalculatedColumn,
    [columnTableSchema.CalculationFunction]: ValidateCalculationFunction,
    [columnTableSchema.AdditionalDetails]: ValidateAdditionalDetails,
    [columnTableSchema.GroupNum]: ValidateGroupNum,
    [columnTableSchema.RowNumber]: ValidateRowNumber,
    [columnTableSchema.RoleGapName]: ValidateRoleGapName,
    [columnTableSchema.RTTcolumn]: ValidateRTTColumn,
    [columnTableSchema.UrlName]: ValidateUrlName,
    [columnTableSchema.RollupUrlName]: ValidateRollupName,
    [columnTableSchema.ValidatorRegexName]: ValidateValidatorRegexName,
    [columnTableSchema.ValidatorRegexValue]: ValidateValidatorRegexValue
}

export const roleValidatorMap = {
    [roleTableSchema.Role1]: ValidateRoleRole1,
    [roleTableSchema.Role2]: ValidateRoleRole2,
    [roleTableSchema.Alignment1]: ValidateRoleAlignment1,
    [roleTableSchema.Alignment2]: ValidateRoleAlignment2,
    [roleTableSchema.Fallback]: ValidateRoleFallback
}

export const logValidatorMap = {
    [logTableScheme.Name]: ValidateLogName,
    [logTableScheme.FriendlyName]: ValidateLogFriendlyName,
    [logTableScheme.DefaultVal]: ValidateLogDefaultVal
}

export function ValidateColumnInCosmos(columnInCosmos, headerIndex, rowData) {
    if(!columnInCosmos){
        return {
            isValid: false, 
            errorMsg: "'#Column in Cosmos' can not be null."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateCosmosDataType(cosmosDataType, headerIndex, rowData) {
    if(!cosmosDataType){
        return {
            isValid: false, 
            errorMsg: "'CosmosDataType' can not be null."
        }
    }
    if(!CosmosDataTypeList.includes(cosmosDataType)) {
        return {
            isValid: false, 
            errorMsg: "CosmosDataType '" + cosmosDataType + "' is illegal."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateClass(rowclass, headerIndex, rowData) {
    if(!rowclass){
        return {
            isValid: false, 
            errorMsg: "'Class' can not be null."
        }
    }
    if(!ClassList.includes(rowclass)) {
        return {
            isValid: false, 
            errorMsg: "'Class:" + rowclass + "' is illegal."
        }
    }
    return {
        isValid: true
    }
}

export function ValidatePresentInView(presentInView, headerIndex, rowData) {
    if(!presentInView){
        return {
            isValid: false, 
            errorMsg: "'PresentInView' can not be null."
        }
    }
    if(!BooleanList.includes(presentInView)) {
        return {
            isValid: false, 
            errorMsg: "'PresentInView' should be 'TRUE' or 'FALSE'."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateIsDataFromCosmos(isDataFromCosmos, headerIndex, rowData) {
    if(!isDataFromCosmos){
        return {
            isValid: false, 
            errorMsg: "'IsDataFromCosmos' can not be null."
        }
    }
    if(!BooleanList.includes(isDataFromCosmos)) {
        return {
            isValid: false, 
            errorMsg: "'IsDataFromCosmos' should be 'TRUE' or 'FALSE'."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateDimMap(dimMap, headerIndex, rowData) {
    return {
        isValid: true
    }
}

export function ValidateRole(role, headerIndex, rowData) {
    const rowclass = rowData[headerIndex[columnTableSchema.Class]]
    if(ClassWithRoleList.includes(rowclass) && !role) {
        return {
            isValid: false, 
            errorMsg: "When 'Class' is " + rowclass + ", 'Role' should not be null."
        }
    } else if(ClassWithNullRoleList.includes(rowclass) && role) {
        return {
            isValid: false, 
            errorMsg: "When 'Class' is " + rowclass + ", 'Role' should be null."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateSQLTable(SQLTable, headerIndex, rowData) {
    return {
        isValid: true
    }
}

export function ValidateSqlColumnName(sqlColumnName, headerIndex, rowData) {
    return {
        isValid: true
    }
}

export function ValidateSqlDataType(sqlDataType, headerIndex, rowData) {
    const rowclass = rowData[headerIndex[columnTableSchema.Class]]
    if(!sqlDataType && rowclass === classType.Hour) {
        return {
            isValid: true
        }
    }
    if(!sqlDataType) {
        return {
            isValid: false, 
            errorMsg: "'SqlDataType' should not be null."
        }
    }
    const dataType = sqlDataType.split('(')
    if(!SQLDataTypeList.includes(dataType[0])) {
        return {
            isValid: false, 
            errorMsg: "'SqlDataType' is illegal."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateFriendlyName(friendlyName, headerIndex, rowData) {
    const rowclass = rowData[headerIndex[columnTableSchema.Class]]
    if(CpAndMetricClass.includes(rowclass) && !(friendlyName === friendlyName.toLowerCase())) {
        return {
            isValid: false, 
            errorMsg: "When the class is checkpoint or correlated metrics, 'FriendlyName' should be all lowercase."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateDimshortname(dimshortname, headerIndex, rowData) {
    const dimTable = rowData[headerIndex[columnTableSchema.SQLTable]]
    if(!dimTable && dimshortname) {
        return {
            isValid: false,
            errorMsg: "'Dimshortname' can not be used when 'SQLTable' is null."
        }
    }
    if(SQLKeyword.includes(dimshortname.toUpperCase())) {
        return {
            isValid: false,
            errorMsg: "'Dimshortname' " + dimshortname + " can not be used since it's a keyword in sql."
        }
    }
    if(shortNameList.includes(dimshortname.toLowerCase())) {
        return {
            isValid: false,
            errorMsg: "'Dimshortname' " + dimshortname + "can not be used since it's used in default setting."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateFactTableColumnId(factTableColumnId, headerIndex, rowData) {
    const dimTable = rowData[headerIndex[columnTableSchema.SQLTable]]
    if(!dimTable && factTableColumnId) {
        return {
            isValid: false,
            errorMsg: "'FactTableColumnId' can not be used when 'SQLTable' is null."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateIdcolsize(idcolsize, headerIndex, rowData) {
    const dimTable = rowData[headerIndex[columnTableSchema.SQLTable]]
    if(!dimTable && idcolsize) {
        return {
            isValid: false,
            errorMsg: "'Idcolsize' can not be used when 'DimMap' is null."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateSnowFlake(snowflake, headerIndex, rowData) {
    return {
        isValid: true
    }
} 

export function ValidateSnowFlakeId(snowflakeId, headerIndex, rowData) {
    return {
        isValid: true
    }
} 

export function ValidateSnowFlakeAlias(snowFlakeAlias, headerIndex, rowData) {
    return {
        isValid: true
    }
}

export function ValidateSnowFlakeDimTableId(snowFlakeDimTableId, headerIndex, rowData) {
    return {
        isValid: true
    }
}

export function ValidateGroupName(groupName, headerIndex, rowData) {
    const rowclass = rowData[headerIndex[columnTableSchema.Class]]
    const isHiddenInUI = rowData[headerIndex[columnTableSchema.IsHiddenInUI]]
    if(!GroupClass.includes(rowclass)) {
        return {
            isValid : true
        }
    }
    if(!groupName && isHiddenInUI !== "TRUE") {
        return {
            isValid: false,
            errorMsg: "'GroupName' should not be null when 'Class' is " + rowclass + "."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateIsGroupCollapsed(isGroupCollapsed, headerIndex, rowData) {
    const rowclass = rowData[headerIndex[columnTableSchema.Class]]
    const isHiddenInUI = rowData[headerIndex[columnTableSchema.IsHiddenInUI]]
    if(!BooleanList.includes(isGroupCollapsed)) {
        return {
            isValid: false, 
            errorMsg: "'IsGroupCollapsed' should be 'TRUE' or 'FALSE'."
        }
    }
    if(!GroupClass.includes(rowclass)) {
        return {
            isValid : true
        }
    }
    if(!isGroupCollapsed && isHiddenInUI !== "TRUE") {
        return {
            isValid: false,
            errorMsg: "'IsGroupCollapsed' should not be null when 'Class' is " + rowclass + "."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateDefaultValueInUI(defaultValueInUI, headerIndex, rowData) {
    if(!defaultValueInUI) {
        return {
            isValid: true
        }
    }
    let brackets = 0
    let quotation = 0
    for(var i = 0; i < defaultValueInUI.length; i++) {
        if(defaultValueInUI[i] === '"') quotation += 1;
        else if(defaultValueInUI[i] === '{') brackets += 1;
        else if(defaultValueInUI[i] === '}') {
            if(brackets <= 0) {
                return {isValid: false, errorMsg: "Error format. Miss match of brackets."}
            }else{
                brackets -= 1;
            }
        } 
    }
    if(brackets !== 0) {
        return {isValid: false, errorMsg: "Error format. Miss match of brackets."}
    }
    if(quotation%2 !== 0) {
        return {isValid: false, errorMsg: "Error format. Miss match of quotations."}
    }
    // const valueArray = defaultValueInUI.split("|")
    // for(var i = 0; i < valueArray.length; i++) {
    //     valueArray[i] = valueArray[i].trim()
    //     if((valueArray[i][0] !== "\"") || (valueArray[i][valueArray[i].length-1] !== "\"")) {
    //         return {
    //             isValid: false, 
    //             errorMsg: "'DefaultValueInUI' format is incorrect. The format should be \"200\"|\"201\"|\"202\"."
    //         }
    //     } 
    // }
    return {
        isValid: true
    }
}

export function ValidateIsHiddenInUI(isHiddenInUI, headerIndex, rowData) {
    // if(!isHiddenInUI){
    //     return {
    //         isValid: false, 
    //         errorMsg: "'IsHiddenInUI' can not be null."
    //     }
    // }
    if(!BooleanList.includes(isHiddenInUI)) {
        return {
            isValid: false, 
            errorMsg: "'IsHiddenInUI' should be 'TRUE' or 'FALSE'."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateIsCalculatedColumn(isCalculatedColumn, headerIndex, rowData) {
    // if(!isCalculatedColumn){
    //     return {
    //         isValid: false, 
    //         errorMsg: "'IsCalculatedColumn' can not be null."
    //     }
    // }
    if(!BooleanList.includes(isCalculatedColumn)) {
        return {
            isValid: false, 
            errorMsg: "'IsCalculatedColumn' should be 'TRUE' or 'FALSE'."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateCalculationFunction(calculationFunction, headerIndex, rowData) {
    const isCalculatedColumn = rowData[headerIndex[columnTableSchema.IsCalculatedColumn]]
    if(isCalculatedColumn === "TRUE" && !calculationFunction) {
        return {
            isValid: false,
            errorMsg: "'CalculationFunction' should be set when 'IsCalculatedColumn' is 'TRUE'."
        }
    }
    if(isCalculatedColumn === "FALSE" && calculationFunction) {
        return {
            isValid: false,
            errorMsg: "'CalculationFunction' should not be used when 'IsCalculatedColumn' is 'FALSE'."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateAdditionalDetails(additionDetail, headerIndex, rowData) {
    return {
        isValid: true
    }
}

export function ValidateGroupNum(groupNum, headerIndex, rowData) {
    const rowclass = rowData[headerIndex[columnTableSchema.Class]]
    const isHiddenInUI = rowData[headerIndex[columnTableSchema.IsHiddenInUI]]
    if(!GroupClass.includes(rowclass)) {
        return {
            isValid : true
        }
    }
    if(!groupNum && isHiddenInUI !== "TRUE") {
        return {
            isValid: false,
            errorMsg: "'GroupNum' should not be null when 'Class' is " + rowclass + "."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateRowNumber(rowNumber, headerIndex, rowData) {
    const rowclass = rowData[headerIndex[columnTableSchema.Class]]
    const isHiddenInUI = rowData[headerIndex[columnTableSchema.IsHiddenInUI]]
    if(!GroupClass.includes(rowclass)) {
        return {
            isValid : true
        }
    }
    if(!rowNumber && isHiddenInUI !== "TRUE") {
        return {
            isValid: false,
            errorMsg: "'RowNumber' should not be null when 'Class' is " + rowclass + "."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateRoleGapName(roleGapName, headerIndex, rowData) {
    return {
        isValid: true
    }
}

export function ValidateRTTColumn(RTTColumn, headerIndex, rowData) {
    return {
        isValid: true
    }
}

export function ValidateUrlName(urlName, headerIndex, rowData) {
    const rowclass = rowData[headerIndex[columnTableSchema.Class]]
    const isHiddenInUI = rowData[headerIndex[columnTableSchema.IsHiddenInUI]]
    if(!GroupClass.includes(rowclass)) {
        return {
            isValid : true
        }
    }
    if(!urlName && isHiddenInUI !== "TRUE") {
        return {
            isValid: false,
            errorMsg: "'UrlName' should not be null when 'Class' is " + rowclass + "."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateRollupName(rollupName, headerIndex, rowData) {
    const urlName = rowData[headerIndex[columnTableSchema.UrlName]]
    if((urlName && !rollupName) || (!urlName && rollupName)) {
        return {
            isValid: false,
            errorMsg: "'UrlName' and 'RollupName' should have values at the same time."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateValidatorRegexName(validatorRegexName, headerIndex, rowData) {
    return {
        isValid: true
    }
}

export function ValidateValidatorRegexValue(validatorRegexValue, headerIndex, rowData){
    return {
        isValid: true
    }
}

// log table 

export function ValidateLogName(name, headerIndex, rowData) {
    if(!name){
        return {
            isValid: false, 
            errorMsg: "'#Name' can not be null."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateLogFriendlyName(friendlyName, headerIndex, rowData) {
    if(!friendlyName){
        return {
            isValid: false, 
            errorMsg: "'FriendlyName' can not be null."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateLogDefaultVal(defaultVal, headerIndex, rowData) {
    console.log("defaultVal", defaultVal, defaultVal.length, BooleanList, BooleanList.includes(defaultVal))
    if(!BooleanList.includes(defaultVal)) {
        return {
            isValid: false, 
            errorMsg: "'DefaultVal' should be 'TRUE' or 'FALSE'."
        }
    }
    return {
        isValid: true
    }
}

// role table 

export function ValidateRoleRole1(role1, headerIndex, rowData) {
    if(!role1){
        return {
            isValid: false, 
            errorMsg: "'Role1' can not be null."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateRoleRole2(role2, headerIndex, rowData) {
    if(!role2){
        return {
            isValid: false, 
            errorMsg: "'Role2' can not be null."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateRoleAlignment1(alignment1, headerIndex, rowData) {
    if(!alignment1){
        return {
            isValid: false, 
            errorMsg: "'Alignment1' can not be null."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateRoleAlignment2(alignment2, headerIndex, rowData) {
    if(!alignment2){
        return {
            isValid: false, 
            errorMsg: "'Alignment2' can not be null."
        }
    }
    return {
        isValid: true
    }
}

export function ValidateRoleFallback(fallback, headerIndex, rowData) {
    return {
        isValid: true
    }
}

export function getValidator(type, key) {
    key = key.toLowerCase()
    let validator = null
    if(type === 'column') {
        validator = columnValidatorMap[key]
    }else if (type === 'role') {
        validator = roleValidatorMap[key]
    }else if (type === 'log') {
        validator = logValidatorMap[key]
    }
    return validator
}

export function validateWholeTable(dataHeader, dataContent, headerIndex, error) {
    let groupNameDict = {}, groupNumDict = {}, rowNumDict = {}, checkpointKeyList = [], metricKeyList = [], SQLTableDict = {}
    let result = true
    let ringnumber = 0, buildnumber = 0
    for(let i = 0; i < dataContent.length; i++) {
        const groupName = dataContent[i][headerIndex[columnTableSchema.GroupName]]
        const groupNum = dataContent[i][headerIndex[columnTableSchema.GroupNum]]
        const rowNum = dataContent[i][headerIndex[columnTableSchema.RowNumber]]
        const classType = dataContent[i][headerIndex[columnTableSchema.Class]]
        const isHiddenInUI = dataContent[i][headerIndex[columnTableSchema.IsHiddenInUI]]
        const SQLTable = dataContent[i][headerIndex[columnTableSchema.SQLTable]]
        const FactTableColumnId = dataContent[i][headerIndex[columnTableSchema.FactTableColumnId]]
        const Role = dataContent[i][headerIndex[columnTableSchema.Role]]
        if(!CpAndMetricClass.includes(classType)) {
            if(!FactTableColumnId || SQLTable === '[dbo].[FactTable]' || SQLTable === 'FactTable') continue
            if(!SQLTableDict[SQLTable]) {
                SQLTableDict[SQLTable] = FactTableColumnId
            }else if(SQLTableDict[SQLTable] && SQLTableDict[SQLTable] !== FactTableColumnId) {
                result = false
                error[i][headerIndex[columnTableSchema.FactTableColumnId]] = "Has different FactTableColumnId from other rows with the same SQLTable."
            }
        }
        if(isHiddenInUI === "FALSE" && groupName && !CpAndMetricClass.includes(classType)) {
            // Validate group name and num
            if(!groupNameDict[groupName] && !groupNumDict[groupNum]) {
                groupNameDict[groupName] = groupNum 
                groupNumDict[groupNum] = groupName
            }
            else if(groupNameDict[groupName] && groupNameDict[groupName] !== groupNum) {
                result = false
                error[i][headerIndex[columnTableSchema.GroupNum]] = "Has different group number from other rows with the same group name."
            }
            else if(groupNumDict[groupNum] && groupNumDict[groupNum] !== groupName) {
                result = false
                error[i][headerIndex[columnTableSchema.GroupNum]] = "Has different group name from other rows with the same group number."
            }
            // Validate row number
            if(!rowNumDict[groupName + rowNum]) {
                rowNumDict[groupName + rowNum] = true
            }else {
                result = false
                error[i][headerIndex[columnTableSchema.RowNumber]] = "This row numbder has been used by other row in the same group."
            }
        }
        // Validate pair key/value
        if(classType === "CheckpointKey") {
            checkpointKeyList.push(i)
        }else if(classType === "MetricKey") {
            metricKeyList.push(i)
        }else if(classType === "CheckpointValue") {
            checkpointKeyList.splice(0, 1)
        }else if(classType === "MetricValue") {
            metricKeyList.splice(0, 1)
        }
        // Validate multiple rings and builds
        else if(classType === "Ring") {
            ringnumber += 1
            if(ringnumber > 1 && !Role) {
                result = false
                error[i][headerIndex[columnTableSchema.Role]] = "Role is required when there are more than one ring."
            }
        }else if(classType === "Build") {
            buildnumber += 1
            if(buildnumber > 1 && !Role) {
                result = false
                error[i][headerIndex[columnTableSchema.Role]] = "Role is required when there are more than one build."
            }
        }
    }

    for(let i = 0; i < checkpointKeyList.length; i++) {
        result = false
        error[i][headerIndex[columnTableSchema.Class]] = "CheckpointKey need a corresponding CheckpointValue row"
    }
    for(let i = 0; i < metricKeyList.length; i++) {
        result = false
        error[i][headerIndex[columnTableSchema.Class]] = "MetricKey need a corresponding MetricValue row"
    }

    if(result) {
        return {
            isValid: true
        }
    }else {
        return {
            isValid: false
        }
    }
}