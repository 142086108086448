import React, {Component} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { CubeSetting } from './ValidationInput'

class CloneCsvModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // Cube properties
            cubeName: '',
            cubeError: '',
            owners: props.loginUser.userName+';',
            ownersError: '',

            // Show setting
            collapse: false,
        }
    }

    initState = () => {
        this.setState({
            // Cube properties
            cubeName: '',
            cubeError: '',
            owners: this.props.loginUser.userName+';',
            ownersError: '',

            // Show setting
            collapse: false,
        })
    }

    validateCubeName = (cubeName) => {
        const { validateCube, isFlat } = this.props
        let err = validateCube(cubeName, isFlat)
        if(err) return err
        return this.validateTitle(cubeName)
    }

    validateTitle = (title) => {
        if (title && title.indexOf(' ') !== -1) { return 'File title cannot contain spaces!' }
        return ''
    }

    validateOwners = (owner) => {
        if (owner && owner.indexOf(' ') !== -1) { return 'Owners cannot contain spaces!' } 
        return ''
    } 

    createEmptySelectedList = (len) => { return Array(len).fill(false) }

    onCubeNameChange = (e) => { this.setState({  cubeName: e.target.value, cubeError: this.validateCubeName(e.target.value) }) }

    onOwnersChange = (e) => { this.setState({ owners: e.target.value, ownersError: this.validateOwners(e.target.value) }) }

    onCreate= () => {
        const { cubeName, cubeError, owners, ownersError } = this.state
        const { onCreateCsv, toggleModal } = this.props
        const fileNameEmptyError = 'File name cannot be empty!'

        // Validate properties
        if (cubeError || ownersError)  return 
        if (!cubeName) { this.setState({ cubeError: fileNameEmptyError }) }
        if (!owners) { this.setState({ ownersError: fileNameEmptyError }) }
        if (!cubeName || !owners) return 

        toggleModal()
        onCreateCsv(cubeName, owners)
        this.initState()
    }

    showCollapse = () => { this.setState({ collapse: !this.state.collapse }) }

    render() {
        const { showModal, toggleModal } = this.props
        const { cubeName, cubeError, collapse, owners, ownersError } = this.state

        return (
            <Modal show={showModal} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Cube CSV</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <CubeSetting cubeName={cubeName} cubeError={cubeError} onCubeNameChange={this.onCubeNameChange} owners={owners} ownersError={ownersError} onOwnersChange={this.onOwnersChange} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={toggleModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={this.onCreate}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default CloneCsvModal