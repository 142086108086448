import React, {Component} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

class MessageModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            initialValue: props.initialValue,
            value: props.initialValue,
            title: props.title,
            message: props.message
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.initialValue !== prevState.initialValue) {
            return {
                initialValue: nextProps.initialValue,
                value: nextProps.initialValue
            }
        }
        return null
    }

    setMessage = (title, message) => { this.setState({ title: title, message: message }) }

    render() {
        const { showModal, toggleModal } = this.props
        const { message, title } = this.state

        return (
            <Modal show={showModal} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title> {title} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Text> {message} </Form.Text>
                </Modal.Body>
                <Button onClick={toggleModal}> OK </Button>
            </Modal>
        )
    }
}

export default MessageModal