import './PipelineStatusPage.css'

import React, { Component } from 'react';
import { SortOrderEnum, compareString, filterObject, getNextSortOrder, renderSortMark } from '../../Functions/TableHelper'

import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import CentralService from '../../Dataservices/CentralService';
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Form from 'react-bootstrap/Form'
import { LoadingGif } from '../PublicComponents/HelperComponents';
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import {formatTime} from '../../Functions/DateTimeHelper';
import {timeIntervalToString} from '../../Functions/DateTimeHelper';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faRedo);

class CentralServiceMonitor extends Component {

    constructor(props) {
        super(props);

        this.data = []

        this.statusObjectKeys = {
            None: 'None',
            BinaryName: 'BinaryName',
            ID: 'ID',
            ServerName: 'ServerName',
            ServiceName: 'ServiceName',
            ServicePath: 'ServicePath',
            Status: 'Status',
            UpdateTime: 'UpdateTime',
            HeartbeatTime: 'HeartbeatTime',
            HeartbeatInterval: 'HeartbeatInterval',
            Version: 'Version',
        }

        this.tableColumns = [
            ["Server Name", this.statusObjectKeys.ServerName], 
            ["Service Name", this.statusObjectKeys.ServiceName],
            ["Status", this.statusObjectKeys.Status],
            ["Heartbeat", this.statusObjectKeys.HeartbeatInterval],
            ["Service Path", this.statusObjectKeys.ServicePath],
            ["Version", this.statusObjectKeys.Version],
            ["Update Time", this.statusObjectKeys.UpdateTime]
        ]

        this.state = {
            tableSort: {
                key: this.statusObjectKeys.None,
                order: this.statusObjectKeys.None
            },
            selectedID: null,
            isLoading: true,
            error: null,
            updateTime: null
        }
    }

    componentDidMount() {
        CentralService.getServiceList().then(res => {
            this.updateFromRawdata(res.data)
        }).catch(err => {
            console.error(err)
            this.setState({
                error: err.message
            })
        })
    }

    updateFromRawdata = (table) => {
        let data = table;
        for (let i in data) {
            let heartBeat = new Date(data[i].HeartbeatTime+'Z')
            let now = Date.now()
            data[i].DefaultKey = i;
            data[i].HeartbeatInterval = timeIntervalToString(now - heartBeat);
        }

        this.data = data;
        this.setState({
            searchText: '',
            table: data,
            isLoading: false,
            updateTime: new Date()
        })
    }


    compareObject = (key, isAscending) => {
        return (obj1, obj2) => {
            if (key === this.statusObjectKeys.HeartbeatInterval) {
                if (obj1[key].length !== obj2[key].length) {
                    return isAscending ? obj1[key].length - obj2[key].length : obj2[key].length - obj1[key].length
                } else {
                    return compareString(obj1[key], obj2[key], isAscending)
                }
            } else {
                // compare strings
                return compareString(obj1[key], obj2[key], isAscending)
            }
        }
    }

    sortList = (list, key, order) => {
        if (order === SortOrderEnum.None) {
            return list.sort(this.compareObject("DefaultKey", true))
        } else {
            return list.sort(this.compareObject(key, order === SortOrderEnum.Ascending))
        }
    }

    onChangeSearchText = e => {
        this.setState({
            searchText: e.target.value
        }, this.onSearch)
    }

    onSearch = () => {
        const {searchText} = this.state;
        let table = [];
        if (searchText.trim() === '') {
            table = this.data;
        } else {
            table = this.data.filter(x => filterObject(x, searchText, [
                this.statusObjectKeys.ServerName,
                this.statusObjectKeys.ServiceName,
                this.statusObjectKeys.Status,
                this.statusObjectKeys.ServicePath
            ]))
        }
        this.setState({
            table: table,
        })
    }

    onSort = (key) => {
        let { tableSort, table }  = this.state
        let nextOrder = getNextSortOrder(tableSort.order, tableSort.key !== key)
    
        tableSort = {
            key: key,
            order: nextOrder
        };
        
        let sortedList = this.sortList(table, key, nextOrder)
        this.setState({
            table: sortedList,
            tableSort: tableSort
        })
    }

    onChangeSearchText = e => {
        this.setState({
            searchText: e.target.value
        }, this.onSearch)
    }

    refresh = _ => {
        this.setState({isLoading: true});
        document.getElementById('service-status-refresh').innerHTML = "Service Status Refreshed";
        CentralService.getServiceList().then(res => {
            this.updateFromRawdata(res.data)
            this.setState({
                error: null
            })
        }).catch(err => {
            console.error(err)
            this.setState({
                error: err.message
            })
        })        
    }

    onRowClick = item => {
        this.setState({selectedID: item.DefaultKey})
        this.props.selectServiceAndServer(item.ServiceName, item.ServerName)
    }

    renderTable() {
        let { tableSort, table } = this.state
        let columns = this.tableColumns;
        return (
            <Row>
                <Col className="Label">
                    <div style={{ overflowX: 'auto', width: '100%' }} className='CentralServiceMonitorTable' aria-live="polite" aria-atomic="true" aria-label={"Service Status, " + (table ? table.length + " results found" : "")}>
                        <Table striped bordered hover className="statusTable">
                            <thead>
                                <tr>
                                    {
                                        columns.map(item=>{
                                            let friendly_name = item[0];
                                            let key_name = item[1];
                                            return (
                                                <th key={key_name} onClick={this.onSort.bind(this, key_name)} className="tableHeader" scope="col">
                                                    {renderSortMark(tableSort.key, tableSort.order, key_name)}
                                                    {friendly_name}
                                                </th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {table && table.map(status => (
                                    <tr key={status.DefaultKey} className={status.DefaultKey === this.state.selectedID ? 'SelectedRow' : 'HoverRow'}
                                        onClick={()=>this.onRowClick(status)}>
                                        {
                                            columns.map(item => {
                                                let key_name = item[1];
                                                if (key_name === 'Status' && status[key_name] !== 'Running') {
                                                    return (
                                                        <td key={key_name} style={{backgroundColor: 'pink'}}>{status[key_name]}</td>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <td key={key_name}>{status[key_name]}</td>
                                                    )
                                                }
                                            })
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        )
    }

    render() {
        return (
            <div>
                {this.state.error && <Alert variant='danger'>{this.state.error}</Alert>}
                <Row>
                    <Col className="Label">
                        <div style={{display:"flex",alignItems: "center", marginTop: 10}}>
                            <Button onClick={this.refresh}>
                                { this.state.isLoading && <span><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />&ensp;</span> }
                                Refresh&ensp;
                                <FontAwesomeIcon icon={faRedo}/>
                            </Button>
                            <div id="service-status-refresh" role="status" class="sr-only"></div>
                            <span style={{ paddingLeft: 15 }} role="status">
                                Update time: {formatTime(this.state.updateTime)}
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group className="Label" controlId="centralServiceSearch">
                                <Form.Control style={{marginTop: 20}} type="text" placeholder="Search" onChange={this.onChangeSearchText}/>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                {
                    this.state.isLoading ? <LoadingGif></LoadingGif> : this.renderTable()
                }
            </div>
        )
    }
}

export default CentralServiceMonitor;
