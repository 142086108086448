import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import RCADataService from '../../Dataservices/RCADataService';


class RCANegativeFeedBackModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            textValue: "",
            isAChecked: false,
            isBChecked: false,
            isCChecked: false,
            isDChecked: false,
        }
    }

    
    onAChange = (e) => { this.setState({ isAChecked: e.target.checked }) }

    onBChange = (e) => { this.setState({ isBChecked: e.target.checked }) }

    onCChange = (e) => { this.setState({ isCChecked: e.target.checked }) }

    onDChange = (e) => { this.setState({ isDChecked: e.target.checked }) }

    onSubmit = () => {

        const { showModal, toggleModal, resultId, loginUser } = this.props
        const { isAChecked, isBChecked, isCChecked, isDChecked, textValue } = this.state
        let request = {}
        let feedbacks = ""
        feedbacks = feedbacks + isAChecked.toString() + ',' + isBChecked.toString() + ',' + isCChecked.toString() + ',' + isDChecked.toString()
        request.ResultId = resultId
        request.Owner = loginUser.userName
        request.IsHelpful = false
        request.Feedback = feedbacks
        request.AdditionalFeedback = textValue
        console.log(request)
        RCADataService.postFeedback(request).then(res => {
            console.log(res.data)
        })
        toggleModal()
    }

    onTextChange = (e) => {
        this.setState({ textValue: e.target.value })
    }

    

    render() {
        const { showModal, toggleModal } = this.props
        const { isAChecked, isBChecked, isCChecked, isDChecked } = this.state

        return (
            <Modal show={showModal} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Please Provide Your Feedback</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Check type="checkbox" label="The granularity is too fine" checked={isAChecked} onChange={this.onAChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Check type="checkbox" label="The granularity is too coarse" checked={isBChecked} onChange={this.onBChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Check type="checkbox" label="The result is unactionable" checked={isCChecked} onChange={this.onCChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Check type="checkbox" label="The result is inaccurate" checked={isDChecked} onChange={this.onDChange} />
                        </Form.Group>
                        <label>
                            What would the root cause have been?
                        </label>
                        <textarea
                            value={this.state.textValue}
                            onChange={this.onTextChange}
                            rows={5}
                            cols={60}
                        ></textarea>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.onSubmit} >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}


export default RCANegativeFeedBackModal