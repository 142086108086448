import DataService from './DataService'

const CentralService = {
    getServiceList: () => {
        return DataService.get('Central/Services')
    },
    getRegisteredCommand: () => {
        return DataService.get('Central/RegisteredCommand')
    },
    getCommands: () => {
        return DataService.get('Central/Commands')
    },
    getServiceZipPath: () => {
        return DataService.get('Central/ServiceZipPath')
    },
    postCommand: command => {
        return DataService.post('Central/NewCommand', command)
    }
}

export default CentralService