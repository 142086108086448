import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import AnnotationEditor from './AnnotationEditor'

class AnnotationEditorModal extends Component {

    constructor(props) {
        super(props)

        this.currentHTMLContent = ''
        this.plainText = ''
        this.state = {
            error: ''
        }
    }

    onChange = (html, plainText) => {
        this.currentHTMLContent = html
        this.plainText = plainText
        this.setState({
            error: ''
        })
    }
    
    onSave = () => {
        const { onSave } = this.props

        if (this.plainText) {
            onSave(this.currentHTMLContent)
            .catch(err => {
                this.setState({error: err.message})
                console.error(err)
            })
        } else {
            this.setState({error: 'Please add a note!    '})
        }
    }

    render() {
        const { error } = this.state
        const { showModal, toggleModal, text, title } = this.props

        return (
            <Modal show={showModal} onHide={toggleModal} className="annotationModal">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error && <Alert variant='danger'>
                        {error}
                    </Alert>}
                    <Form>
                        <Form.Group>
                            <Form.Label>What is your note?</Form.Label>
                            <AnnotationEditor onChange={this.onChange} text={text}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={toggleModal}>Close</Button>
                    <Button variant="primary" onClick={this.onSave}>Save changes</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}


export default AnnotationEditorModal