import React, { Component } from 'react'
import Select from 'react-select'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import 'antd/dist/antd.css';
import './ServiceMetricsPage.css';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Popover from 'react-bootstrap/Popover';
import ColorPicker from '../PublicComponents/ColorPicker';
import InputGroup from 'react-bootstrap/InputGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { ValidationWarning } from '../PublicComponents/HelperComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import UserSearchInput from '../PublicComponents/UserSearchInput';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faQuestionCircle);

function transformLegend(legend){
    if(legend === null) return '(Global)'
    else if(!legend) return '(blank)'
    else return legend
}

function transformBackLegend(legend){
    if(legend === '(Global)') return null
    else if (legend === '(blank)') return ''
    else return legend
}

function validateLegend(tab, charts, list){
    let legendList = Array(), validList = [...list]
    for(let chart of charts) {
        for(let series of chart.serverData) {
            legendList.push(transformBackLegend(series.Name))
        }
    }
    // return a list with valid property.
    for(let alert of validList) {
        // There are two cases when the alert should change to invalid:
        // 1. Global alert in metrics tab.
        if(tab === "dailymetrics" && alert.Legend === null) alert.valid = false
        // 2. Legend alert with no existing legend.
        else if((alert.Legend !== null) && !legendList.includes(alert.Legend)) alert.valid = false
        else alert.valid = true
    }
    return validList
}

function getStaticListFromProps(props) {
    if(!props.chart || !props.chart.alertList) return []
    return validateLegend(props.tab, props.charts, props.chart.alertList.StaticAlerts.map(alert => ({
        Id: alert.Id,
        Legend: alert.StaticThreshold.Legend,
        IsAboveThreshold: alert.StaticThreshold.IsAboveThreshold,
        SnoozeInterval: alert.StaticThreshold.SnoozeInterval || 0,
        Threshold: alert.StaticThreshold.Threshold || 0,
        IsAlertEnabled: alert.StaticThreshold.IsAlertEnabled,
        valid: true
    })))
}

function getGoallineListFromProps(props) {
    if(!props.chart || !props.chart.alertList) return []
    return props.chart.alertList.GoalLines.map(alert => ({
        Id: alert.Id,
        Name: alert.GoalLine.Name,
        Axis:  alert.GoalLine.Axis,
        Color: alert.GoalLine.Color,
        Goal: alert.GoalLine.Goal || 0,
        IsAlertEnabled: alert.GoalLine.IsAlertEnabled,
        IsGoalLineEnabled: alert.GoalLine.IsGoalLineEnabled,
        LineType: alert.GoalLine.LineType
    }))
}

export class QualityAlertModal extends Component {

    constructor(props) {
        super(props)

        const staticList = getStaticListFromProps(props)

        this.state = {
            startDate: new Date(new Date().setDate(new Date().getDate()-30)),
            endDate: new Date(),
            data: [],
            isLoading: false,
            editIndex: 0,
            editStatus: "",

            // Static Alert
            staticLegend: null,
            staticTriggerValue: 0,
            snoozeInterval: 0,
            staticList: staticList, 
            isTriggerValueValid: true,
            isSnoozeIntervalValid: true,
            error: ``,
        }
    }

    selectStaticLegend = (item) => { this.setState({ staticLegend: item.value, unSave: true, }) }

    onTriggerValueChange = e => { 
        const number = parseFloat(e.target.value)
        this.setState({ 
            staticTriggerValue: number,
            isTriggerValueValid: !isNaN(number) && number > 0,
            unSave: true,
        }) 
    }

    toggleStaticTriggerAbove = () => {
        this.setState(prevState => {
            return {
                staticTriggerAbove: !prevState.staticTriggerAbove,
                unSave: true,
            }
        })
    }

    onSnoozeIntervalChange = e => { 
        const number = parseInt(e.target.value)
        this.setState({ 
            snoozeInterval: number,
            isSnoozeIntervalValid: !isNaN(number) && number > 0,
            unSave: true,
        }) 
    }

    addNewAlert = () => { this.setState({editIndex: -1, editStatus: "New"}) }

    render() {
        const { showModal, toggleModal } = this.props
        const { email, uploading, editStatus, unSave, editIndex, staticLegend, staticTriggerAbove, staticTriggerValue, isTriggerValueValid, snoozeInterval, isSnoozeIntervalValid, error } = this.state
        let legendoption = []

        return (
            <Modal show={showModal} onHide={toggleModal} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Alert Management</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row> <Col sm="12"> {error && <Alert variant='danger' className="dashboardAlert" onClose={this.closeAlert} dismissible> <p> {error} </p> </Alert>} </Col> </Row>
                    <Row> 
                        <Col sm="12">
                        <Form>
                            <Form.Group>
                                <Form.Label>Send alerts to:&ensp;</Form.Label>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                    <Tooltip id={`tooltip-alerts`}>
                                        Alerts are always sent to owners and members. People in this field will only receive alerts when the dashboard is public.
                                    </Tooltip>}>
                                    <FontAwesomeIcon icon={faQuestionCircle} className="promptMark"/>
                                </OverlayTrigger>
                                {/* <UserSearchInput name="email" persons={email} onChange={this.onEmailChangeHandler} /> */}
                            </Form.Group>
                        </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <Card>
                                <Card.Header>Alert list</Card.Header>
                                <Card.Body>              
                                    {/* <AlertList key={`StaticAlertList-${chart.id}`} alertList={staticList} type="StaticAlert" toggleAlert={this.toggleAlert} changeItem={this.changeItem}/> */}
                                </Card.Body>
                                <Card.Footer>
                                    <ButtonGroup>
                                        <Button id="add-button" onClick={this.addNewAlert}> Add </Button>
                                        <Button variant="danger" onClick={this.onDeleteItem} disabled={uploading}> Delete {uploading && <Spinner animation="border" variant="primary" size="sm"/>} </Button>
                                    </ButtonGroup>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col sm="6">
                            <Card>
                                <Card.Header>{editStatus? (editStatus === "Edit"? "Edit " + " [" + editIndex + "]": "New "):"Alert Edit"}</Card.Header>
                                <Card.Body>
                                    <React.Fragment>
                                        {(editStatus === "New") && 
                                        <StaticAlertEdit legendoption={legendoption} staticLegend={staticLegend} staticTriggerAbove={staticTriggerAbove} staticTriggerValue={staticTriggerValue} 
                                                            isTriggerValueValid={isTriggerValueValid} snoozeInterval={snoozeInterval} isSnoozeIntervalValid={isSnoozeIntervalValid}
                                                            selectStaticLegend={this.selectStaticLegend} toggleStaticTriggerAbove={this.toggleStaticTriggerAbove} onTriggerValueChange={this.onTriggerValueChange} 
                                                            onSnoozeIntervalChange={this.onSnoozeIntervalChange}/>}
                                    </React.Fragment>
                                </Card.Body>
                                <Card.Footer>
                                    {editStatus === "Edit"? <Button variant="primary" onClick={this.editAlert} disabled={uploading}> Edit {uploading && <Spinner animation="border" variant="primary" size="sm"/>} </Button> 
                                    : <Button variant="primary" onClick={this.addAlert} disabled={uploading}> Add {uploading && <Spinner animation="border" variant="primary" size="sm"/>} </Button>} {' '}
                                    <Button variant="light" onClick={this.togglePage}> Cancel </Button>
                                    {unSave? <Badge variant="secondary">Unsaved</Badge>: null}
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>


                
                </Modal.Body>
            </Modal>
        )
    }
}

export class GoalLineModal extends Component {

    constructor(props) {
        super(props)

        const goallineList = getGoallineListFromProps(props)

        this.state = {
            startDate: new Date(new Date().setDate(new Date().getDate()-30)),
            endDate: new Date(),
            data: [],
            isLoading: false,
            editIndex: 0,
            editStatus: "",

            // Goal Line
            goallineName: 'Goal line',
            color: '#000000',
            axis: 'Latency',
            lineType: 'Dash',
            goal: 0,
            goalLineEnabled: true,
            goallineList: goallineList,
            isGoalLineValid: true,
            isGoalLineNameValid: true,
            error: ``,
        }
    }

    onGoalChange = e => { 
        const { axis } = this.state
        const number = parseFloat(e.target.value)
        let isValid = false
        if (!isNaN(number) && axis === 'Ratio' && number >= 0 && number <= 1) {
            isValid = true
        } else if (!isNaN(number) && axis !== 'Ratio') {
            isValid = true
        }
        this.setState({ 
            goal: number,
            isGoalLineValid: isValid,
            unSave: true,
        }) 
    }

    onAxisChange = (a) => { this.setState({ axis: a, unSave: true, }) }

    onLineTypeChange = (type) => { this.setState({ lineType: type, unSave: true,  }) }

    onColorChange = (color) => { this.setState({ color: color.hex, unSave: true,  }) }

    addNewGoalline = () => { this.setState({ editIndex: -1, editStatus: "New"}) }

    render() {
        const { showModal, toggleModal } = this.props
        const { uploading, editStatus, unSave, editIndex, goallineName, goal, color, axis, lineType, goallineList, isGoalLineValid, isGoalLineNameValid, error } = this.state

        return (
            <Modal show={showModal} onHide={toggleModal} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Goal Line Management</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row> <Col sm="12"> {error && <Alert variant='danger' className="dashboardAlert" onClose={this.closeAlert} dismissible> <p> {error} </p> </Alert>} </Col> </Row>
                    <Row>
                        <Col sm="6">
                            <Card>
                                <Card.Header>Alert list</Card.Header>
                                <Card.Body>              
                                    {/* <AlertList key={`StaticAlertList-${chart.id}`} alertList={staticList} type="StaticAlert" toggleAlert={this.toggleAlert} changeItem={this.changeItem}/> */}
                                </Card.Body>
                                <Card.Footer>
                                    <ButtonGroup>
                                        <Button id="add-button" onClick={this.addNewGoalline}> Add </Button>
                                        <Button variant="danger" onClick={this.onDeleteItem} disabled={uploading}> Delete {uploading && <Spinner animation="border" variant="primary" size="sm"/>} </Button>
                                    </ButtonGroup>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col sm="6">
                            <Card>
                                <Card.Header>{editStatus? (editStatus === "Edit"? "Edit " + " [" + editIndex + "]": "New "):"Alert Edit"}</Card.Header>
                                <Card.Body>
                                    <React.Fragment>
                                        {(editStatus === "New") && 
                                        <GoalLineEdit goallineName={goallineName} isGoalLineNameValid={isGoalLineNameValid} axis={axis} goal={goal} isGoalLineValid={isGoalLineValid} color={color} lineType={lineType}
                                                      onGoalLineChange={this.onGoalLineChange} onGoalChange={this.onGoalChange} onColorChange={this.onColorChange} onAxisChange={this.onAxisChange} onLineTypeChange={this.onLineTypeChange}/>}
                                    </React.Fragment>
                                </Card.Body>
                                <Card.Footer>
                                    {editStatus === "Edit"? <Button variant="primary" onClick={this.editAlert} disabled={uploading}> Edit {uploading && <Spinner animation="border" variant="primary" size="sm"/>} </Button> 
                                    : <Button variant="primary" onClick={this.addAlert} disabled={uploading}> Add {uploading && <Spinner animation="border" variant="primary" size="sm"/>} </Button>} {' '}
                                    <Button variant="light" onClick={this.togglePage}> Cancel </Button>
                                    {unSave? <Badge variant="secondary">Unsaved</Badge>: null}
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>


                
                </Modal.Body>
            </Modal>
        )
    }
}

class StaticAlertEdit extends Component {
    
    constructor(props){
        super(props)
    }

    render(){
        const { legendoption, staticLegend, staticTriggerAbove, staticTriggerValue, isTriggerValueValid, snoozeInterval, isSnoozeIntervalValid,
                selectStaticLegend, toggleStaticTriggerAbove, onTriggerValueChange, onSnoozeIntervalChange } = this.props

        return(
            <React.Fragment>
                <Form.Group as={Row} controlId="staticLegend">
                    <Form.Label column sm="4">Legend</Form.Label>
                    <Col sm="8">
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Popover id="popover-dynamiclegend">
                                    <Popover.Content>
                                        Please select the legend which you want for your alert. 
                                        <strong> (Global) means enabling alerts for all legends.</strong>
                                    </Popover.Content>
                                </Popover>
                            }>
                            <div>
                                <Select placeholder="Select legend" 
                                    options={legendoption} 
                                    onChange={selectStaticLegend} 
                                    value={(staticLegend === null)?null:{value:staticLegend, label:transformLegend(staticLegend)}}
                                />
                            </div>
                        </OverlayTrigger>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="trigger">
                    <Form.Label column sm="4">Trigger</Form.Label>
                    <Col sm="8">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <Button variant={staticTriggerAbove ? "outline-success" : "outline-warning"} onClick={toggleStaticTriggerAbove}>{ staticTriggerAbove ? "Above" : "Below" }</Button>
                            </InputGroup.Prepend>
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Popover id="popover-basic">
                                        <Popover.Content>
                                            Please enter the threshold number which you want as the max/min for your scenario.
                                        </Popover.Content>
                                    </Popover>
                                }>
                                <Form.Control type="number" min={0} value={staticTriggerValue} onChange={onTriggerValueChange}/>
                            </OverlayTrigger>
                        </InputGroup>
                        {!isTriggerValueValid && <ValidationWarning text={"Positive numbers only"}/>}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="snoozeinterval">
                    <Form.Label column sm="4" className="noWrap">Snooze Interval</Form.Label>
                    <Col sm="8">
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Popover id="popover-basic">
                                    <Popover.Content>
                                        Please enter the snooze interval(in days) which you want for your static threshold alerts.
                                    </Popover.Content>
                                </Popover>
                            }>
                            <Form.Control type="number" min={1} value={snoozeInterval} onChange={onSnoozeIntervalChange}/>
                        </OverlayTrigger>
                        {!isSnoozeIntervalValid && <ValidationWarning text={"Positive integers only"}/>}
                    </Col>
                </Form.Group>
            </React.Fragment>
        )
    }
}

class GoalLineEdit extends Component {

    constructor(props) {
        super(props)
    }

    render(){
        const { goallineName, isGoalLineNameValid, axis, goal, isGoalLineValid, color, lineType,
                onGoalLineChange, onGoalChange, onColorChange, onAxisChange, onLineTypeChange} = this.props

        return(
            <React.Fragment>
                <Form.Group as={Row} controlId="goalline">
                    <Form.Label column sm="4">Name</Form.Label>
                    <Col sm="8">
                        <Form.Control type="text" placeholder="Goal line" value={goallineName} onChange={onGoalLineChange}/>
                        {!isGoalLineNameValid &&  <ValidationWarning text={'Goal line name can only include letters, numbers or spaces.'}/>}
                    </Col>
                </Form.Group>
                            
                <Form.Group as={Row} controlId="goal">
                    <Form.Label column sm="4">Goal</Form.Label>
                    <Col sm="8">
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Popover id="popover-basic">
                                    <Popover.Content>
                                        Please enter the goal number which you want to be drawn on the chart.
                                    </Popover.Content>
                                </Popover>
                            }>
                            {axis === 'Ratio' ? <Form.Control type="number" value={goal} step={0.001} min={0} max={1} onChange={onGoalChange}/>
                            : <Form.Control type="number" value={goal} min={0} onChange={onGoalChange}/>}
                        </OverlayTrigger>
                        {!isGoalLineValid &&  <ValidationWarning text={axis === 'Ratio' ? 'Please input a valid decimal between 0 and 1' : 'Non-negative Integers only'}/>}
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="color">
                    <Form.Label column sm="4">Color</Form.Label>
                    <Col sm="8">
                        <ColorPicker color={color} style={{height: '100%'}} onChange={onColorChange}/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="axis">
                    <Form.Label column sm="4">Axis</Form.Label>
                    <Col sm="8">
                        <DropdownButton variant="outline-secondary" title={axis}>
                            <Dropdown.Item onClick={onAxisChange.bind(this, 'Latency')}>Latency</Dropdown.Item>
                            <Dropdown.Item onClick={onAxisChange.bind(this, 'Count')}>Count</Dropdown.Item>
                            <Dropdown.Item onClick={onAxisChange.bind(this, 'Ratio')}>Ratio</Dropdown.Item>
                        </DropdownButton>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="linetype">
                    <Form.Label column sm="4">Line Type</Form.Label>
                    <Col sm="8">
                        <DropdownButton variant="outline-secondary" title={lineType}>
                            <Dropdown.Item onClick={onLineTypeChange.bind(this, 'Solid')}>Solid</Dropdown.Item>
                            <Dropdown.Item onClick={onLineTypeChange.bind(this, 'Dash')}>Dash</Dropdown.Item>
                            <Dropdown.Item onClick={onLineTypeChange.bind(this, 'DashDot')}>DashDot</Dropdown.Item>
                            <Dropdown.Item onClick={onLineTypeChange.bind(this, 'Dot')}>Dot</Dropdown.Item>
                        </DropdownButton>
                    </Col>
                </Form.Group>
            </React.Fragment>
        )
    }
}
