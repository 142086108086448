import DataService from './DataService'

const DashboardService = {
    getDashboardList: () => {
        return DataService.get('Dashboard/listDetail')
    },
    getDashboardInfo: (id) => {
        return DataService.get('Dashboard/getDashboard?dashboardId=' + id)
    },
    addDashboard: payload => {
        return DataService.post('Dashboard/add', payload)
    },
    editChart: payload => {
        return DataService.post('Dashboard/editChart', payload)
    },
    reorderChart: payload => {
        return DataService.post('Dashboard/reorderCharts', payload)
    },
    deleteChart: payload => {
        return DataService.post('Dashboard/editChart', payload)
    },
    addChart: payload => {
        return DataService.post('Dashboard/addChart', payload)
    },
    deleteDashboard: payload => {
        return DataService.post('Dashboard/editDashboard', payload)
    },
    editDashboard: payload => {
        return DataService.post('Dashboard/editDashboard', payload)
    },
    updateFavoritedDashboards: (dashboardId, isFavorite, userId = null) => {
        const url = `Dashboard/updateFavoritedDashboards?dashboardId=${dashboardId}&isFavorite=${isFavorite}`;
        return userId ? DataService.post(`${url}&userId=${userId}`) : DataService.post(url);
    }

}

export default DashboardService