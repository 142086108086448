import { UserAgentApplication } from "msal";
import { Client } from "@microsoft/microsoft-graph-client"
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser'
import { InteractionType } from '@azure/msal-browser'
import config from "../config";
import DataService from './DataService';

const appID = config.frontendAppID;

export let graphClient;

export const msalApp = new UserAgentApplication({
    auth: {
        clientId: appID,
        authority: "https://login.microsoftonline.com/microsoft.onmicrosoft.com",
        validateAuthority: true,
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    },
    system: {
        navigateFrameWait: 0
    }
});

const REST_API_REQUEST = {
    scopes: ["api://" + appID + "/user_impersonation"]
};

const LOGIN_REQUEST = {
    scopes: ["api://" + appID + "/user_impersonation"],
    prompt: "select_account",
};

export const GRAPH_API_REQUEST = {
    scopes: [
        "user.read",
        "user.read.all"
    ]
};

export function runWithMsal(callback, unauthorizedAccessCallback) {
    if (window.top !== window.self) {
        return;
    }

    // Fix auth loop with suggestion from: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/498#issuecomment-484415185
    // This prevents a second loginRedirect in the middle of the auth sequence if it is already in progress.
    let account = msalApp.getAccount();
    if (account === null && !msalApp.getLoginInProgress())
    {
        msalApp.loginRedirect(LOGIN_REQUEST);
    }
    else if (account.userName.endsWith('@microsoft.com') || account.userName.endsWith('@trs09.prod.exo.microsoft.scloud') || account.userName.endsWith('@ntdev.microsoft.com') || account.userName.endsWith('@winse.microsoft.com'))
    {
        msalApp.acquireTokenSilent(REST_API_REQUEST).then(res=>{
            callback(res.accessToken);
        }).catch((error) => {
            msalApp.acquireTokenRedirect(REST_API_REQUEST);
        })
    }
    else
    {
        unauthorizedAccessCallback(account.userName);
    }
}

export function setUpGraphClient() {
    msalApp.acquireTokenSilent(GRAPH_API_REQUEST).then(tokenRes => {
        const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msalApp, {
            account: tokenRes.account,
            scopes: GRAPH_API_REQUEST.scopes,
            interactionType: InteractionType.Popup,
        })
        graphClient = Client.initWithMiddleware({ authProvider })
    })
    .catch((error) => {
        console.error(error)
    })
}

export function checkListContainsCurrentUser(list, loginUserName, callBack, loginUserGUID) {
    let contains = false
    let groups = {
        groupIds: []
    };

    if (!list || list.length === 0) callBack(false);

    list.forEach(p => {
        if (p.Type === 'Person' && (loginUserName === p.EmailAddress || loginUserGUID === p.GUID)) {
            contains= true
            return
        }

        if (p.Type === 'Group' && p.GUID) {
            groups.groupIds.push(p.GUID)
        }
    })

    if (!contains && groups.groupIds.length > 0 && graphClient){
        graphClient.api('/me/checkMemberGroups')
            .post(groups)
            .then(res => {
                callBack(res.value.length > 0)
            })
    } else {
        callBack(contains)
    }
}

/*********************************************************************************
 * function: load the user's information at the certain page
 * input: none
 * output: Promise
 * ******************************************************************************/
 export function loadUserInfo() {
    if (config.enableDataSaving && window.localStorage.getItem("userInfo") === null) {
        //get user profile data from backend and store in localStorage
        return DataService.get('eventtracking/getClientInfo').then(res => {
            const userInfo = {
                userIP: res.data.UserIP,
                userAgent: res.data.UserAgent,
                sid: res.data.SessionID,
                userName: res.data.UserName,
                userRole: res.data.UserRole
            };
            window.localStorage.setItem("userInfo", JSON.stringify(userInfo));
        }).catch(err => {
            console.error(err)
        })
    } else {
        return new Promise(function(resolve, reject) {
            resolve();
        })
    }
}