import React, {Component} from 'react'
import Table from 'react-bootstrap/Table'
import MSRScenario from './MSRScenario'
import MSRScenarioV1 from './MSRScenarioV1'
import './MSR.css'
import MSREditScenarioModal from './MSREditScenarioModal'
import Col from 'react-bootstrap/Col'
import { convertLocalDateToUTCTime, replaceDateforUrlArray, replaceParamsforUrlArray } from '../../Functions/MSRHelper'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { getStandardizedUrl } from '../../Functions/UrlHelper'
import Select from 'react-select';

class MSRScenariosContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            scenarioList: props.scenarioList,
            currdatelist: this.getRangedates(props.startDate, props.endDate),
            showLegend: true,
            showLatencyThreshold: true,
            showAvgDailySamples: true,
            includeWeekend: null,
            selectedRings: ["MSIT", "PROD|WW"],
            scenarioLengthList: Array(this.props.scenarioList.length).fill(0),
            originator: props.originator,
        }
        this.refreshUrlofScenario()
    }

    refreshUrlofScenario = () => {
        const newList = this.state.scenarioList.map((Scenario, index) => {
            Scenario.url = getStandardizedUrl(Scenario.url);
            Scenario.order = index;
            return Scenario;
        })

        replaceDateforUrlArray(newList, "sd", this.props.startDate);
        replaceDateforUrlArray(newList, "ed", this.props.endDate);
        replaceParamsforUrlArray(newList, 'graphIt', 'true');

        this.setState({ scenarioList: newList });
    }

    getRangedates = (startDate, endDate ) => {
        var startTime = convertLocalDateToUTCTime(startDate), endTime = convertLocalDateToUTCTime(endDate)
        var datelist = [];
        for(var loopTime = startTime; loopTime <= endTime; loopTime += 86400000)
        {
            datelist.push((new Date(loopTime)));
        }

        return datelist;
    }

    componentDidUpdate(prevProps) {
        if (this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate) {
            this.setState({ currdatelist: this.getRangedates(this.props.startDate, this.props.endDate) })
        }
    }

    onToggleMoveScenarioModal = () => {
        this.setState(prevState => {
            return {
                showMoveScenarioModal: !prevState.showMoveScenarioModal
            }
        })
    }

    setWeekends = e => {
        const currSelectText = e.target.options[e.target.selectedIndex].text
        this.setState({ includeWeekend: currSelectText === "Default" ? null : currSelectText === "Yes" ? true : false });
    }

    setRing = selectedOptions => {
        const selectedRings = selectedOptions ? selectedOptions.map(option => option.value) : [];
        this.setState({ selectedRings });
    }

    setLegend = e => {
        this.setState({ showLegend: e.target.checked });
    }

    setLatencyThreshold = e => {
        this.setState({ showLatencyThreshold: e.target.checked });
    }

    setAvgDailySamples = e => {
        this.setState({ showAvgDailySamples: e.target.checked });
    }

    updateScenarioLengthList = (index, value) => {
        const {scenarioLengthList} = this.state
        scenarioLengthList[index] = value;
        this.setState({ scenarioLengthList });
    }  

    getDateRange = weekIndex => {
        const startDateUTC = convertLocalDateToUTCTime(this.props.startDate)
        const currDateRange = (new Date(startDateUTC + (weekIndex * 7) * 86400000)).toLocaleDateString(undefined, {month: '2-digit', day: '2-digit' })
         + "-"
         +  (new Date(startDateUTC + (weekIndex * 7 + 3) * 86400000)).toLocaleDateString(undefined, {month: '2-digit', day: '2-digit' })
        return currDateRange
    }

    getMSRHeader = () => {
        const { msrVersion } = this.props
        const { currdatelist, showLegend, showLatencyThreshold, showAvgDailySamples, includeWeekend, selectedRings } = this.state
        const showMsit = selectedRings.includes('MSIT')
        const showProd = selectedRings.includes('PROD|WW')
        const showSdfv2 = selectedRings.includes('SDFV2')
        const WeekCountsShownonMSR = 4
        const ringCountsShownonMSR = showProd + showMsit + showSdfv2
        
        if(msrVersion === 1)
        {
            return (
                <thead>
                    <tr>
                        <th className="tableHeader" scope="col">
                            Scenario&nbsp;&nbsp;Name
                        </th>
                        {showLegend ? 
                            <th className="tableHeader" scope="col">
                                Legend
                            </th>
                            : 
                            null
                        }                                        
                        {showLatencyThreshold ? 
                            <th className="tableHeader" scope="col">
                                Latency Goal
                            </th>
                            : 
                            null
                        }
						{showAvgDailySamples ? 
                            <th className="tableHeader" rowspan="2" scope="col">
								Avg Daily Samples
							</th>
							: 
							null
						}
                        {currdatelist.map(dateitem => (
                            ((dateitem.getDay() === 0 || dateitem.getDay() === 6) && (includeWeekend === false)) ? null : 
                                <th className="tableHeader" scope="col">
                                {dateitem.toLocaleDateString()}
                                </th>
                        ))}
                        <th className="tableHeader" scope="col">
                            Buttons
                        </th>
                    </tr>
                </thead>
            )
            
        }
        else
        {
            return (
				<thead>
					<tr>
						<th className="tableHeader" rowspan="2">
							Scenario  Name
						</th>
						{showLegend ? 
							<th className="tableHeader" rowspan="2">
								Legend
							</th>
							: 
							null
						}
						{showLatencyThreshold && showSdfv2 ? 
							<th className="tableHeader" rowspan="2">
								SDFV2 Latency Goal
							</th>
							: 
							null
						}
						{showAvgDailySamples && showSdfv2 ? 
							<th className="tableHeader" rowspan="2">
								SDFV2 Avg Daily Samples
							</th>
							: 
							null
						}
                        {showSdfv2?
                            <th className="tableHeader" style={{borderRight: "2px solid #111"}} colspan="4"> 
                                SDFV2
                            </th>
                            :
                            null
                        }
						{showLatencyThreshold && showMsit ? 
							<th className="tableHeader" rowspan="2">
								MSIT Latency Goal
							</th>
							: 
							null
						}
						{showAvgDailySamples && showMsit ? 
							<th className="tableHeader" rowspan="2">
								MSIT Avg Daily Samples
							</th>
							: 
							null
						}
                        {showMsit?
                            <th className="tableHeader" style={{borderRight: "2px solid #111"}} colspan="4"> 
                                MSIT
                            </th>
                            :
                            null
                        }
						
						{showLatencyThreshold && showProd ? 
							<th className="tableHeader" rowspan="2">
								PROD|WW Latency Goal
							</th>
							: 
							null
						}
						{showAvgDailySamples && showProd ? 
							<th className="tableHeader" rowspan="2">
								PROD|WW Avg Daily Samples
							</th>
							: 
							null
						}
                        {showProd?
                            <th className="tableHeader" style={{borderRight: "2px solid #111"}} colspan="4"> 
                                PROD|WW
                            </th>
                            :
                            null
                        }
						<th className="tableHeader" rowspan="4">
							Buttons
						</th>
					</tr>
					<tr>
						{Array(ringCountsShownonMSR).fill(1).map((el, i) =>
							Array(WeekCountsShownonMSR).fill(1).map((ej, j) =>
							<th className="tableHeader" style={{borderRight: (j === 3 ? "2px solid #111" : null), whiteSpace:"nowrap"}}> 
								{this.getDateRange(j)}
							</th>
						))}
					</tr>
				</thead>
            )
        }
    }

    render() {
        const { startDate, endDate, showAddScenarioModal, toggleAddScenarioModal, MSRId, msrVersion, isOwner, scenarioList,
            addNewScenario, deleteScenario, editScenario, moveScenario, setError, icmServices, setIcmServices } = this.props
        const { currdatelist, showLegend, showLatencyThreshold, showAvgDailySamples, includeWeekend, scenarioLengthList, selectedRings, originator } = this.state
        const showMsit = selectedRings.includes('MSIT')
        const showProd = selectedRings.includes('PROD|WW')
        const showSdfv2 = selectedRings.includes('SDFV2')
        let len = scenarioList.length
        return (
            <Container fluid>
                <Row>
                    <Col className="chartingPanePivotCheckbox">
                        <div style={{ display: 'flex', paddingBottom: '10px' }}>
                            { msrVersion === 1 ?
                                <React.Fragment>
                                    <select id="weekends" onChange={this.setWeekends}>
                                        <option>Default</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </select>
                                    <label htmlFor="weekends">&nbsp;&nbsp;Weekends</label>
                                </React.Fragment>
                                    : 
                                <React.Fragment>
                                    <Select
                                        options={[
                                            { value: 'SDFV2', label: 'SDFV2' },
                                            { value: 'MSIT', label: 'MSIT' },
                                            { value: 'PROD|WW', label: 'PROD|WW' },
                                        ]}
                                        defaultValue={[
                                            { value: 'MSIT', label: 'MSIT' },
                                            { value: 'PROD|WW', label: 'PROD|WW' },
                                        ]}
                                        isMulti
                                        onChange={this.setRing}
                                        aria-label="Select Ring"
                                        styles={{
                                            control: (provided) => ({
                                            ...provided,
                                            width: 350,
                                            }),
                                            menu: (provided) => ({
                                            ...provided,
                                            width: 350,
                                            })
                                        }}
                                        />
                                </React.Fragment>
                            }                    
                            {scenarioLengthList.every( v => v === 1 ) ? 
                            <React.Fragment>
                                <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <input type="checkbox" id="showLegend" checked={showLegend} onClick={this.setLegend}/>
                                <label htmlFor="showLegend" style={{ display: 'flex', paddingTop: '10px' }}>Show Legend</label>
                            </React.Fragment> : null}
                            <React.Fragment>
                                <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <input type="checkbox" id="showLatencyThreshold" checked={showLatencyThreshold} onClick={this.setLatencyThreshold}/>
                                <label htmlFor="showLatencyThreshold" style={{ display: 'flex', paddingTop: '10px' }}>Show Latency Goal</label>
                            </React.Fragment>
                            <React.Fragment>
                                <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <input type="checkbox" id="showAvgDailySamples" checked={showAvgDailySamples} onClick={this.setAvgDailySamples}/>
                                <label htmlFor="showAvgDailySamples" style={{ display: 'flex', paddingTop: '10px' }}>Show Avg Daily Samples</label>
                            </React.Fragment>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="MSRTable">
                            <Table striped bordered hover>
                                {this.getMSRHeader()}
                                <tbody>
                                    {scenarioList.map(Scenario =>(
                                        msrVersion === 1 ? 
                                            <MSRScenarioV1
                                                Scenario={Scenario}
                                                key={Scenario.id}
                                                updateScenarioLengthList={this.updateScenarioLengthList}
                                                startDate={startDate}
                                                endDate={endDate}
                                                scenarioLen={len}
                                                onMoveScenario={moveScenario}
                                                datelen={currdatelist.length}
                                                onEditScenarioDelete={deleteScenario}
                                                onEditScenarioSave={editScenario}
                                                showLegend={showLegend}
                                                showLatencyThreshold={showLatencyThreshold}
                                                showAvgDailySamples={showAvgDailySamples}
                                                includeWeekend={includeWeekend}
                                                currdatelist={currdatelist}
                                                isOwner={isOwner}
                                                msrVersion={msrVersion}/>
                                            :
                                            <MSRScenario
                                                Scenario={Scenario}
                                                key={Scenario.id}
                                                updateScenarioLengthList={this.updateScenarioLengthList}
                                                startDate={startDate}
                                                endDate={endDate}
                                                scenarioLen={len}
                                                onMoveScenario={moveScenario}
                                                datelen={currdatelist.length}
                                                onEditScenarioDelete={deleteScenario}
                                                onEditScenarioSave={editScenario}
                                                showLegend={showLegend}
                                                showLatencyThreshold={showLatencyThreshold}
                                                showAvgDailySamples={showAvgDailySamples}
                                                includeWeekend={includeWeekend}
                                                currdatelist={currdatelist}
                                                isOwner={isOwner}
                                                msrVersion={msrVersion}
                                                icmServices={icmServices}
                                                setIcmServices={setIcmServices}
                                                originator={originator}
                                                showMsit={showMsit}
                                                showProd={showProd}
                                                showSdfv2={showSdfv2}/>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            <MSREditScenarioModal
                isNewScenario
                showModalorNot={showAddScenarioModal}
                order={len}
                MSRId={MSRId}
                toggleModal={toggleAddScenarioModal}
                onSave={addNewScenario}
                setError={setError}
                msrVersion={msrVersion}
                icmServices={icmServices}
                setIcmServices={setIcmServices}
                />
            </Container>
        )
    }
}

export default MSRScenariosContainer