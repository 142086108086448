import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import CopilotService from '../../Dataservices/CopilotService';
import { NotPermittedWarning } from '../PublicComponents/HelperComponents';
import { getPageTitle } from '../../Functions/PageTitleHelper';
import { filterObject } from '../../Functions/TableHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import './CopilotComponents.css';
import ValidationInput from '../PublicComponents/ValidationInput';
import Modal from 'react-bootstrap/Modal';

class CopilotManagement extends Component {

    constructor(props) {
        super(props)

        this.tabList = [
            "snapshotlist",
            "promptlist",
            "examplelist",
            "knowledgelib",
        ]

        this.tabToTitleMapping = {
            "snapshotlist": "Snapshot List",
            "promptlist": "Prompt List",
            "examplelist": "Example List",
            "knowledgelib": "Knowledge Library",
        }

        this.state = {
            key: 'snapshotlist'
        }
    }

    componentDidMount() {
        let params = new URLSearchParams(this.props.location.search);
        let tab = params.get('tab');
        if (this.tabList.includes(tab)) {
            this.setState({ key: tab })
        } else {
            this.onSelect('pipeline')
        }
    }

    onSelect = key => {
        this.props.history.push(`${window.location.pathname}?tab=${key}`);
        this.setState({ key })
    }

    render() {
        let { isAdmin, isUserLoading } = this.props;
        document.title = getPageTitle(this.tabToTitleMapping[this.state.key]);
        return (
            <Container fluid>
                {isAdmin ?
                    <Row>
                        <Col>
                            <Tabs activeKey={this.state.key}
                                style={{ marginBottom: '15px' }}
                                onSelect={this.onSelect}>
                                <Tab eventKey="snapshotlist" title="Snapshot List">
                                    <ManagementList type="Snapshot" loginUser={this.props.loginUser} />
                                </Tab>
                                <Tab eventKey="promptlist" title="Prompt List">
                                    <ManagementList type="Prompt" loginUser={this.props.loginUser} />
                                </Tab>
                                <Tab eventKey="examplelist" title="Example List">
                                    <ManagementList type="Example" loginUser={this.props.loginUser} />
                                </Tab>
                                <Tab eventKey="knowledgelib" title="Knowledge Library">
                                    <ManagementList type="Knowledge" loginUser={this.props.loginUser} />
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                    : isUserLoading ? null : NotPermittedWarning("Only admin can access this page")
                }
            </Container>
        )
    }
}

export default withRouter(CopilotManagement);

class ManagementList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            list: [],
            header: [],
            type: props.type,
            searchText: '',
            showModal: false,
            isAddModal: true,
            editId: null,
            editName: "",
        }
    }

    componentDidMount() {
        this.getList()
    }

    getList = () => {
        CopilotService.getManagementList(this.state.type).then((res) => {
            if (res.data.length > 0) {
                const header = Object.keys(res.data[0]);
                this.setState({
                    header: header,
                    list: res.data
                })
            }
        })
    }

    onChangeSearchText = (event) => {
        this.setState({ searchText: event.target.value });
    }

    clickButton = (op, index) => {
        const { type, list } = this.state;
        if (op === 'Delete') {
            CopilotService.deleteManagement({
                Type: type,
                Id: this.state.list[index].Id
            }).then((res) => {
                list.splice(index, 1);
                this.setState({ list: list });
            }).catch((err) => {
                console.error(err);
            });
        } else if (op === 'Edit') {
            if (type === "Snapshot") {
                this.setState({ showModal: true, isAddModal: false, editName: list[index].Name, editId: list[index].Id })
            } else {
                CopilotService.editManagement({
                    Id: list[index].Id,
                    Content: list[index].Content,
                    Type: type,
                    Name: list[index].Name,
                }).then((res) => {
                    list[index].ModifiedTime = new Date(Date.now()).toISOString();
                    this.setState({ list: list });
                }).catch((err) => {
                    console.error(err);
                });
            }
        } else if (op === 'Publish') {
            CopilotService.publishSnapshot({ Id: list[index].Id }).then((res) => {
                this.getList();
            }).catch((err) => {
                console.error(err);
            });
        }
    }

    addNewModal = () => {
        this.setState({ showModal: !this.state.showModal });
    }

    addItem = (Item) => {
        const { list } = this.state;
        list.push(Item);
        this.setState({ list: list });
    }

    editContent = (header, index, event) => {
        const { list } = this.state;
        list[index][header] = event.target.value;
        this.setState({ list: list });
    }

    render() {
        const { searchText, type, showModal, header, list, isAddModal, editId, editName } = this.state;
        return (
            <React.Fragment>
                <AddOrEditManagementModal type={type} showModal={showModal} toggleModal={this.addNewModal} isAddModal={isAddModal} editId={editId} editName={editName} addItem={this.addItem} />
                <Card>
                    <Card.Body>
                        <Card.Text>
                            <Form.Row>
                                <Col xs={11}>
                                    <Form.Control type="text" placeholder={"Search " + this.props.type} onChange={this.onChangeSearchText} />
                                </Col>
                                <Col xs={1}>
                                    <Button className="mb-2" onClick={this.addNewModal}> Add new {type} </Button>
                                </Col>

                            </Form.Row>
                            <Table striped bordered hover className='management'>
                                <thead>
                                    <tr>
                                        {header.map((item, index) => {
                                            return (
                                                <th key={index} className="managementTableCell">{item}</th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {list.map((item, index) => {
                                        if (!searchText || filterObject(item, searchText, header)) {
                                            return (
                                                <tr key={index}>
                                                    {header.map((h) => {
                                                        if (typeof (item[h]) === "boolean") {
                                                            return (<td key={h} className="managementTableCell"><FontAwesomeIcon icon={faCheckCircle} size="lg" color={item[h] ? "green" : "grey"} /></td>)
                                                        } else if (typeof (item[h]) === "object") {
                                                            return (
                                                                <td key={h} className="managementTableCell">
                                                                    {item[h].map((subItem) =>
                                                                        (<><Button key={subItem} onClick={this.clickButton.bind(this, subItem, index)}>{subItem}</Button>{' '}</>)
                                                                    )}
                                                                </td>
                                                            )
                                                        } else if (h === "Content") {
                                                            return (
                                                                <td key={h} className="managementTableCell contentCell">
                                                                    <FormControl as="textarea" rows={type === "Knowledge" ? 1 : 3} onChange={this.editContent.bind(this, h, index)} defaultValue={item[h]} />
                                                                </td>
                                                            )
                                                        } else {
                                                            return (
                                                                <td key={h} className="managementTableCell">
                                                                    <Form.Control plaintext readOnly value={item[h]} />
                                                                </td>
                                                            )
                                                        }
                                                    })}
                                                </tr>
                                            );
                                        }
                                        return null;
                                    })}
                                </tbody>
                            </Table>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </React.Fragment>
        )
    }
}

class AddOrEditManagementModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            type: props.type,
            name: "",
            nameError: "",
            scenario: "",
            scenarioList: [],
            selectedType: "",
            typeList: [],
            content: "",
            promptlist: {},
            snapshotSetting: {},
            add: props.isAddModal
        }
    }

    componentDidMount() {
        CopilotService.getScenarioList().then((res) => {
            this.setState({ scenarioList: res.data, scenario: res.data.length > 0 ? res.data[0] : "" });
        }).catch((err) => {
            console.error(err);
        });
        if (this.props.type === "Knowledge") {
            CopilotService.getKnowledgeTypeList().then((res) => {
                this.setState({ typeList: res.data, selectedType: res.data.length > 0 ? res.data[0] : "" });
            }).catch((err) => {
                console.error(err);
            });
        }
        if (this.props.type === "Snapshot") {
            CopilotService.getManagementList("Prompt").then((res) => {
                let promptlist = {};
                let snapshotSetting = {};
                res.data.forEach(element => {
                    if (promptlist[element.Scenario] === undefined) {
                        promptlist[element.Scenario] = [];
                    }
                    if (snapshotSetting[element.Scenario] === undefined) {
                        snapshotSetting[element.Scenario] = element.Name;
                    }
                    promptlist[element.Scenario].push(element.Name);
                });
                this.setState({ promptlist: promptlist, snapshotSetting: snapshotSetting });
            }).catch((err) => {
                console.error(err);
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.editId !== prevProps.editId) {
            CopilotService.getSnapshot(this.props.editId).then((res) => {
                this.setState({ add: this.props.isAddModal, name: this.props.editName, snapshotSetting: JSON.parse(res.data) })
            }).catch((err) => {
                console.error(err);
            });
        }
    }

    addOrEdit = () => {
        const { type, add, selectedType, name, scenario, content, snapshotSetting } = this.state
        const { addItem, toggleModal } = this.props
        if (add) {
            CopilotService.addManagement({
                Type: selectedType ? selectedType : type,
                Name: name,
                Scenario: type === "Snapshot" ? "" : scenario,
                Content: type === "Snapshot" ? JSON.stringify(snapshotSetting) : content
            }).then((res) => {
                addItem(res.data);
            }).catch((err) => {
                console.error(err);
            });
        } else {
            CopilotService.editManagement({
                Id: this.props.editId,
                Name: name,
                Type: type,
                Scenario: type === "Snapshot" ? "" : scenario,
                Content: type === "Snapshot" ? JSON.stringify(snapshotSetting) : content
            }).then((res) => {
                addItem(res.data);
            }).catch((err) => {
                console.error(err);
            });
        }
        toggleModal()
    }

    onNameChange = (e) => {
        const name = e.target.value;
        this.setState({ name: name, nameError: "" })
        if (name === "") {
            this.setState({ nameError: "Name is required" })
        }
    }

    onSnapshotChange = (scenario, e) => {
        let { snapshotSetting } = this.state;
        snapshotSetting[scenario] = e.target.value;
        this.setState({ snapshotSetting: snapshotSetting })
    }

    render() {
        const { toggleModal, showModal } = this.props
        const { type, add, name, nameError, scenarioList, promptlist } = this.state

        return (
            <Modal show={showModal} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New {type} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Name:&ensp;</Form.Label>
                            <ValidationInput name="name" isRequired={true} value={name} error={nameError} onChange={this.onNameChange} placeholder="Name" />
                        </Form.Group>
                        {type === "Knowledge" && (
                            <Form.Group>
                                <Form.Label>Type:&ensp;</Form.Label>
                                <Form.Control as="select" className="mr-sm-2" custom onChange={(e) => this.setState({ selectedType: e.target.value })}>
                                    {this.state.typeList.map(item => (<option value={item}>{item}</option>))}
                                </Form.Control>
                            </Form.Group>
                        )}
                        {type === "Snapshot" ?
                            (<div>
                                <Form.Group><Form.Label>Prompt List</Form.Label></Form.Group>
                                {scenarioList.map(item => (
                                    <Form.Group>
                                        <Form.Label>{item}:&ensp;</Form.Label>
                                        <Form.Control as="select" className="mr-sm-2" custom onChange={this.onSnapshotChange.bind(this, item)}>
                                            {promptlist[item] && promptlist[item].map(p => (<option value={p}>{p}</option>))}
                                        </Form.Control>
                                    </Form.Group>
                                ))}
                            </div>
                            ) :
                            (<div>
                                <Form.Group>
                                    <Form.Label>Scenario:&ensp;</Form.Label>
                                    <Form.Control as="select" className="mr-sm-2" custom onChange={(e) => { this.setState({ scenario: e.target.value }) }}>
                                        {scenarioList.map(item => (<option value={item}>{item}</option>))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Content:&ensp;</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Content" onChange={(e) => { this.setState({ content: e.target.value }) }} />
                                </Form.Group>
                            </div>
                            )}

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.addOrEdit}>
                        {add ? "Add" : "Edit"}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}