import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import 'antd/dist/antd.css';
import axios from 'axios';
import Message from './Message';
import BotPanel from './BotPanel';
import SendBox from './SendBox';
import CopilotService from '../../Dataservices/CopilotService';
import DataService from '../../Dataservices/DataService';
import "./CopilotComponents.css";

export class CopilotPage extends Component {

    constructor(props) {
        super(props)

        const helloMessage = "Hello, how can I help?"

        this.dataQuerySource = axios.CancelToken.source();
        this.snapshot = null;

        this.state = {
            recommend: ["What's PerfPanel?", "Draw latency of Rest"],
            conversation: [
                { role: "assistant", content: helloMessage },
                // { role: "assistant", pivotDict: {"Response Size": {Value: [], Rollup: "false"}} , url: "https://perfpanel.azurewebsites.net:443/Analysis/DSAPI_FD2BE_V4?tab=daily&rrss=false" },
            ], // array of {role: string, content: string, url: string, pivotDict: [], pivotRelated: {}, debug: string, loading: boolean}
            chatId: Math.random().toString(36).substring(2, 10),
            debug: false,
            updateMessage: "", 
            updateMessageTime: new Date().getTime()
        }
    }

    componentDidMount() {
    }

    newChat = () => {
        this.setState({ conversation: [{ role: "assistant", content: "Hello, how can I help?" }], chatId: Math.random().toString(36).substring(2, 10) });
    }

    sendMessage = (message) => {
        let { conversation, chatId, debug } = this.state;
        conversation.push({ role: "user", content: message });
        let loadingItem = { role: "assistant", loading: "Select component to precess request", progress: 10 };
        conversation.push(loadingItem);
        this.setState({ conversation: conversation });
        const debugsetting = debug? { snapshot: this.snapshot }: null;
        CopilotService.selectAgent({ ChatId: chatId, Message: message, Debug: debugsetting }, this.dataQuerySource.token).then((res) => {
            loadingItem.loading = "Processing request with " + res.data.Message + " agent";
            loadingItem.progress = 30;
            conversation[conversation.length - 1] = loadingItem;
            this.setState({ conversation: conversation });
            if (debug) console.log(res.data.DebugInfo);
            DataService.post("/copilot/" + res.data.Message, { ChatId: chatId, Message: message, Debug: debugsetting }, this.dataQuerySource.token).then((res) => {
                conversation.pop();
                conversation.push({
                    role: "assistant",
                    content: res.data.Message,
                    pivotRelated: res.data.PivotList,
                    url: res.data.Url,
                    pivotDict: res.data.PivotDict,
                    debug: debug? res.data.DebugInfo : null
                });
                this.setState({ conversation: conversation });
            }).catch((err) => {
                conversation.pop();
                conversation.push({
                    role: "assistant",
                    content: "Sorry, " + err.message,
                });
                this.setState({ conversation: conversation });
            });
        }).catch((err) => {
            conversation.pop();
            conversation.push({
                role: "assistant",
                content: "Sorry, " + err.message,
            });
            this.setState({ conversation: conversation });
        });

        this.refreshRecommend(message);
    }

    refreshRecommend = (message) => {
        const { chatId, debug } = this.state;
        const debugsetting = debug? { snapshot: this.snapshot }: null;
        CopilotService.recommend({ ChatId: chatId, Message: message, Debug: debugsetting }).then((res) => {
            if (res.data.RecommendQuery) this.setState({ recommend: res.data.RecommendQuery });
            if (debug) console.log(res.data.DebugInfo);
        }).catch((err) => {
            console.error(err);
        });
    }

    stopGenerate = () => {
        this.dataQuerySource.cancel();
        this.setState({ conversation: this.state.conversation.pop() });
    }

    switchDebug = () => {
        this.setState({ debug: !this.state.debug });
    }

    changeSnapshot = (snapshot) => {
        this.snapshot = snapshot;
    }

    addToMessage = (message) => {
        this.setState({ updateMessage: message, updateMessageTime: new Date().getTime()});
    }

    parseConversation = (conversation) => {
        const { cubes, loginUser } = this.props
        const { chatId } = this.state
        return conversation.map((item, index) => (
            <Message item={item} chatId={chatId} cubes={cubes} loginUser={loginUser} index={index} addToMessage={this.addToMessage}/>
        ));
    }

    render() {
        const { conversation, debug, recommend, updateMessage, updateMessageTime } = this.state
        const { showBot, isShowBot, loginUser } = this.props;

        const botPanelStyle = isShowBot ? { "width": "60%" } : { "width": "0%" };

        return (
            <div className="copilot" style={botPanelStyle}>
                <Container fluid className="botcontainer" key="conversation">
                    <BotPanel newChat={this.newChat} hideChat={showBot} />
                    <div className='messagecontainer'>
                        {this.parseConversation(conversation)}
                    </div>
                    <SendBox debug={debug}
                        loginUser={loginUser}
                        sendMessage={this.sendMessage}
                        switchDebug={this.switchDebug}
                        changeSnapshot={this.changeSnapshot}
                        recommend={recommend}
                        refreshRecommend={this.refreshRecommend}
                        updateMessage={updateMessage}
                        updateMessageTime={updateMessageTime}
                    />
                </Container>
            </div>
        )
    }
}

export default CopilotPage;
