import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import DeleteAnnotationModal from './DeleteAnnotationModal'
import EditAnnotationModal from './EditAnnotationModal'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import { createMarkup } from '../../Functions/RichTextHelpers'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faTrashAlt, faEdit);

class AnnotationRow extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showDeleteAnnotationModal: false,
            showEditAnnotationModal: false
        }
    }

    toggleDeleteAnnotationModal = (e) => {
        if (e) {
            e.stopPropagation();
        }

        this.props.toggleShowAnyModal()
        this.setState(prevState => {
            return {
                showDeleteAnnotationModal: !prevState.showDeleteAnnotationModal
            }
        })
    }

    toggleEditAnnotationModal = (e) => {
        if (e) {
            e.stopPropagation();
        }
        
        this.props.toggleShowAnyModal()
        this.setState(prevState => {
            return {
                showEditAnnotationModal: !prevState.showEditAnnotationModal
            }
        })
    }

    onDeleteAnnotation = (id, text) => {
        this.toggleDeleteAnnotationModal()
        this.props.deleteAnnotation(id, text)
    }

    onEditAnnotation = (id, text) => {
        this.toggleEditAnnotationModal()
        this.props.editAnnotation(id, text)
    }

    render () {
        const { index, loginUser, annotation, date } = this.props
        const { showDeleteAnnotationModal, showEditAnnotationModal } = this.state
        return (
            <React.Fragment>
                {index !== 0 && <hr className="annoDivider"/>}
                <div className="annoTitle">
                    {loginUser && (loginUser.isAdmin || loginUser.userName === annotation.user) && <ButtonGroup>
                        <Button variant="outline-light" size="sm" onClick={this.toggleDeleteAnnotationModal}><FontAwesomeIcon icon={faTrashAlt} /></Button>
                        <Button variant="outline-light" size="sm" onClick={this.toggleEditAnnotationModal}><FontAwesomeIcon icon={faEdit} /></Button>
                    </ButtonGroup>}
                    <span className="annoUser">{annotation.user.indexOf('@') !== -1 ? annotation.user.substring(0, annotation.user.indexOf('@')) : annotation.user}</span>
                </div>
                <div className="annoText" dangerouslySetInnerHTML={createMarkup(annotation.text)}></div>
                <DeleteAnnotationModal onDeleteAnnotation={this.onDeleteAnnotation} annotation={annotation} date={date} showModal={showDeleteAnnotationModal} toggleModal={this.toggleDeleteAnnotationModal}/>
                <EditAnnotationModal onEditAnnotation={this.onEditAnnotation} annotation={annotation} date={date} showModal={showEditAnnotationModal} toggleModal={this.toggleEditAnnotationModal}/>
            </React.Fragment>
        )
    }
}

export default AnnotationRow