import React, { useEffect, useState} from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { ValidationWarning } from '../PublicComponents/HelperComponents';

 function ChartSetYModal(props){
    const { setMinMaxY, show, onHide } = props 
    const [minY, setMinY] = useState(props.minY);
    const [maxY, setMaxY] = useState(props.maxY);
    const [isMinYValid, setIsMinYValid] = useState(true);
    const [isMaxYValid, setIsMaxYValid] = useState(true);

    useEffect(() => {
        setMinY(props.minY)
        setMaxY(props.maxY)
    }, [props.minY, props.maxY])
    
    return <Modal show={show} onHide={onHide}>
            <Modal.Header>
                Enter new min and max y-axis values.
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e) => { e.preventDefault(); if(isMinYValid && isMaxYValid){ setMinMaxY(minY || 0, maxY || props.maxY); onHide() }}}>
                    <Form.Group>
                        <Form.Label>Min Y:   </Form.Label>
                        <Form.Control aria-label="Min Y" name="name" type="text" value={minY} onChange={(e) => { setIsMinYValid(!isNaN(e.target.value)); setMinY(e.target.value) }}/>
                        {!isMinYValid && <ValidationWarning text={"Please input a number"}/>}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Max Y:  </Form.Label>
                        <Form.Control aria-label="MaxY" name="name" type="text" value={maxY} onChange={(e) => { setIsMaxYValid(!isNaN(e.target.value)); setMaxY(e.target.value) }}/>
                        {!isMaxYValid && <ValidationWarning text={"Please input a number"}/>}
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                 </Form>
            </Modal.Body>
           </Modal>;
}

export default React.memo(ChartSetYModal);