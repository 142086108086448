import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare, faThumbsDown, faThumbsUp, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { ScenarioTags } from '../../Dataservices/ChartService';
import ProgressiveChart from '../ChartComponents/ProgressiveChart';
import { convertStringToUTCDate, addDaysToUTCDate } from '../../Functions/DateTimeHelper';
import { getStartDateFromUrl, getEndDateFromUrl } from '../../Functions/UrlHelper';
import Table from 'react-bootstrap/Table';
import Column from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CopilotService from "../../Dataservices/CopilotService";
import ProgressBar from "react-bootstrap/ProgressBar";
import FormControl from "react-bootstrap/FormControl";

export default class Message extends Component {
    constructor(props) {
        super(props)

        this.state = {
            feedbackValue: null
        }
    }

    feedback = (value) => {
        const jsonMessage = JSON.stringify(this.props.item);
        CopilotService.feedback({
            ChatId: this.props.chatId,
            IsThumbsUp: value,
            Message: jsonMessage,
            Feedback: '',
        }).then(() => {
            this.setState({ feedbackValue: value });
        });
        if(value == 0) {
            window.open("https://forms.office.com/r/hbcsKjqzHV")
        }
        else {
            window.open("https://forms.office.com/r/8LPQPvpdhj")
        }
    }

    renderPivotRelated = (pivotRelated) => {
        const { addToMessage } = this.props;
        return (
            <div>
                {pivotRelated.length>0 && (<p>Some possible pivot that may be related to your scenario:</p>)}
                {pivotRelated.map(pivot => (
                <Row>
                    <Column sm={2}>
                        {pivot.PivotName}
                    </Column>
                    <Column sm={10}>
                        {
                            pivot.PivotValue.map(pivotValue => (
                                <Button variant="outline-dark" size="sm" onClick={addToMessage.bind(this, pivot.PivotName + " " + pivotValue)}>{pivotValue}</Button>
                            ))
                        }
                    </Column>
                </Row>
            ))}
            </div>
            
        )
    }

    renderGraph = (url) => {
        const { cubes, loginUser } = this.props;
        const chart = { url: url, title: 'Latency', id: 0, alertList: { ChartId: 0, StaticAlerts: [], DynamicAlerts: [], GoalLines: [] } };
        const dailyStartDate = getStartDateFromUrl(url) ? convertStringToUTCDate(getStartDateFromUrl(url)) : addDaysToUTCDate(new Date(), -30);
        const dailyEndDate = getEndDateFromUrl(url) ? convertStringToUTCDate(getEndDateFromUrl(url)) : addDaysToUTCDate(new Date(), -2);
        const hourlyStartDate = null; // placeholder for hourly cube support
        const hourlyEndDate = null; // placeholder for hourly cube support
        const containerStyle = { height: '450px', width: '100%' };

        return (
            <div key={url} style={containerStyle}>
                <Button aria-label="Open chart in new tab" variant="light" onClick={() => window.open(url)} className="insightPopout float-right">
                    <FontAwesomeIcon icon={faShareSquare} />
                </Button>
                <ProgressiveChart
                    cubes={cubes}
                    chart={chart}
                    dailyStartDate={dailyStartDate}
                    dailyEndDate={dailyEndDate}
                    hourlyStartDate={hourlyStartDate}
                    hourlyEndDate={hourlyEndDate}
                    loginUser={loginUser}
                    isInExpertInsight
                    scenarioTags={ScenarioTags.Copilot}
                />
            </div>
        )
    }

    renderPivotDict = (pivotDict) => {
        return (
            Object.entries(pivotDict).length > 0 && <Table bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Value</th>
                        <th>Rollup</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.entries(pivotDict).map(([key, value]) => (
                            <tr>
                                <td>{key}</td>
                                <td>{value && value.value ? value.value.join(',') : ''}</td>
                                <td>{value.rollup}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        );
    }

    render() {
        const { item, index, stopGenerate } = this.props;
        const { feedbackValue } = this.state;

        if (item.role === "assistant") {
            return (
                <Card className="assistantmessagecard" key={`Assistant-${item.content}-${item.feedback}-${index}`} >
                    <Card.Body className="messagecardbody">
                        {
                            item.content && (
                                <p>{item.content}</p>
                            )
                        }
                        {
                            item.pivotDict && this.renderPivotDict(item.pivotDict)
                        }
                        {
                            item.url && this.renderGraph(item.url)
                        }
                        {
                            item.pivotRelated && this.renderPivotRelated(item.pivotRelated)
                        }
                        {
                            item.loading && (
                                <div style={{ "text-align": "center" }}>
                                    <p>{item.loading}</p>
                                    <ProgressBar animated variant="info" now={item.progress} />
                                    <Button className="messagebutton" variant="outline-light" size="sm" onClick={stopGenerate}><FontAwesomeIcon icon={faSignOutAlt} /> Stop generating </Button>
                                </div>
                            )
                        }
                        {
                            item.debug && (
                                <FormControl as="textarea" rows={5} value={item.debug} />
                            )
                        }
                    </Card.Body>
                    {
                        !item.loading && (
                            <Form.Row className="messagefooter">
                            <Form.Text id="messageLength" muted>
                                AI-generated content may be incorrect.
                            </Form.Text>
                            <div className="feedback">
                                <ToggleButtonGroup type="radio" name="options" value={feedbackValue} onChange={this.feedback}>
                                    <ToggleButton value={1} className="messagebutton" aria-label="like" variant="outline-light" size="sm"><FontAwesomeIcon icon={faThumbsUp} /></ToggleButton>
                                    <ToggleButton value={0} className="messagebutton" aria-label="dislike" variant="outline-light" size="sm"><FontAwesomeIcon icon={faThumbsDown} /></ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                            </Form.Row>
                        )
                    }

                </Card>
            );
        } else if (item.role === "user") {
            return (
                <Card className="usermessagecard" key={`Assistant-${item.content}-${index}`} >
                    <Card.Body className="messagecardbody">{item.content}</Card.Body>
                </Card>
            );
        } else {
            return <></>
        }
    }
}
