import React, { Fragment } from 'react'
import { Row, Button, Form } from 'react-bootstrap';
import './EvoServiceRegistration.css';
import UserSearchInput from '../PublicComponents/UserSearchInput';
import RenderInformativeTooltip from '../RenderInformativeTooltip';
import SectionStatus from './SectionStatus';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';

library.add(faSave);

function Service(props) {
    const { serviceName, isEditable, onCallUserList, adminUserList, onServiceChange, onSaveService, sectionStatus } = props;

    const onServiceNameChange = (e) => {
        onServiceChange(e.target.value, 'serviceName');
    };

    const onOnCallUserChange = (contactUsers) => {
        onServiceChange(contactUsers, 'onCallUsers');
    };

    const onAdminUserChange = (adminUsers) => {
        onServiceChange(adminUsers, 'adminUsers');
    };

    return (
        <Fragment>
            {isEditable && <Row>
                <div className="section-actions">
                    <SectionStatus text={sectionStatus[0]} type={sectionStatus[1]}/>
                    <Button variant="outline-primary" onClick={onSaveService} ><FontAwesomeIcon icon={faSave}/>&ensp;Save</Button>
                </div>       
            </Row>}
            <Form>
                <Row>
                    <Form.Group className="input-rows" controlId="serviceName">
                        <input className="service-header" placeholder="Service Name" value={serviceName} onChange={onServiceNameChange} disabled={!isEditable} required/>
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Group className="input-rows" controlId="adminUsers">
                        <Form.Label>Admin Users&ensp;
                            <RenderInformativeTooltip field="adminUsers" placement="right" message="Admin users are those who can manage this service." />
                            {isEditable && <span className="required-star">*</span>}
                        </Form.Label>
                        <UserSearchInput
                            name="adminusers"
                            className="service-users-input"
                            disabled={!isEditable}
                            persons={adminUserList} 
                            onChange={onAdminUserChange}
                            />
                    </Form.Group>
                </Row>
                
                <Row>
                    <Form.Group className="input-rows" controlId="contactUsers">
                        <Form.Label>On Call Users&ensp;
                            <RenderInformativeTooltip field="contactUsers" placement="right" message="On call users are those who will receive notifications in case of any data issues." />
                            {isEditable && <span className="required-star">*</span>}
                        </Form.Label>
                        <UserSearchInput
                            name="contactusers"
                            className="service-users-input"
                            disabled={!isEditable}
                            persons={onCallUserList} 
                            onChange={onOnCallUserChange}
                            />
                    </Form.Group>
                </Row>
            </Form>
        </Fragment>
    );
}

export default Service;