import DataService from './DataService'

const CubeCSVDataService = {
    getList: () => {
        return DataService.get('/CSVManagement/listCube')
    },

    selectItem: (request) => {
        return DataService.get('/CSVManagement/cubeCSV?id=' + request)
    },
    createCubeCSV: (request) => {
        return DataService.post('/CSVManagement/addCSVState', request)
    },
    saveCSV: (request) => {
        return DataService.post('/CSVManagement/saveCSV', request)
    },
    deleteCSV: (request) => {
        return DataService.post('/CSVManagement/deleteCSV', request)
    },
    deleteCube: (request) => {
        return DataService.post('/CSVManagement/deleteCube', request)
    },
    downloadCube: (request) => {
        return DataService.post('/CSVManagement/downloadCube', request)
    },
    getPivot: (pivot, header, isFlat) => {
        return DataService.get('/CSVManagement/GetPivot?pivot=' + pivot + '&header=' + header + '&isFlat=' + isFlat)
    },
    getSuggestedPivots : (cubeName, header) => {
        return DataService.get('/CSVManagement/getSuggestedPivots?cubeName=' + cubeName + '&header=' + header)
    },
    getSuggestedRoles : (cubeName, header) => {
        return DataService.get('/CSVManagement/getSuggestedRoles?cubeName=' + cubeName + '&header=' + header)
    },
    getSuggestedLogs : (cubeName, header) => {
        return DataService.get('/CSVManagement/getSuggestedLogs?cubeName=' + cubeName + '&header=' + header)
    },
    getTemplate : (template, isFlat) => {
        return DataService.get('/CSVManagement/getTemplate?template=' + template + '&isFlat=' + isFlat)
    },
    getTemplateType: () => {
        return DataService.get('/CSVManagement/getTemplateType')
    },
    addRole: (request) => {
        return DataService.post('/CSVManagement/addRole', request)
    }

}

export default CubeCSVDataService