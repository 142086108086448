import React, { Component } from 'react'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { listColumn } from '../../Functions/CubeCSVManagement/CubeCSVConst'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './CubeCsvComponents.css'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faEllipsisV);

class CsvCellInput extends Component {

    constructor(props) {
        super(props)

        this.state = {
            initialValue: props.initialValue,
            value: props.initialValue,
            error: '',
            isInvalid: false,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.initialValue !== prevState.initialValue) {
            return {
                initialValue: nextProps.initialValue,
                value: nextProps.initialValue,
            }
        }
        return null
    }

    render() {
        // const { value, error, isInvalid } = this.state
        const { isRequired, value, columnkey, error, onChange, column, onBlur, comment } = this.props

        return (
            <React.Fragment>
            {columnkey in listColumn && listColumn[columnkey].includes(value)? 
            <InputGroup key={`${columnkey}-select`} hasValidation onClick={e => e.stopPropagation()}>
                <Form.Control className="nopadding" as="select" required={isRequired} isInvalid={error} value={value} onChange={onChange.bind(this, column)} onBlur={onBlur.bind(this, column)}>
                {
                    listColumn[columnkey].map((key) => {
                        return (<option key={key}>{key}</option>)
                    })
                }
                </Form.Control>
                <InputGroup.Append>
                    <CellComment key={`${columnkey}-comment`} columnkey={columnkey} comment={comment} column={column}/>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </InputGroup>
            :<InputGroup key={`${columnkey}-text`} hasValidation onClick={e => e.stopPropagation()}>
                <Form.Control className="nopadding" type="text" required={isRequired} isInvalid={error} value={value} onChange={onChange.bind(this, column)} onClick={e => e.stopPropagation()} onBlur={onBlur.bind(this, column)}/>                
                <InputGroup.Append>
                    <CellComment key={`${columnkey}-comment`} columnkey={columnkey} comment={comment} column={column}/>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </InputGroup>
            }
            </React.Fragment>
        )
    }
}

class CellComment extends Component {
    constructor(props) {
        super(props)

        this.state = {
            comment: props.comment,
            updateTime: new Date().getTime(),
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.updateTime !== prevState.updateTime) {
            return {
                comment: nextProps.comment,
                updateTime: new Date().getTime(),
            }
        }
        return null
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.updateTime !== this.state.updateTime) {
            return true
        }
        return false
    }

    onChange = (e) => {
        const { comment } = this.state
        comment.value = e.target.value
        this.setState({
            comment: comment,
            updateTime: new Date().getTime(),
        })
    } 

    render() {
        const { columnkey } = this.props
        let { comment } = this.state
        let dateString = ""
        if(!comment) comment = {user:"", value:"", updateTime:null}
        if(comment.updateTime) {
            const date = new Date(comment.updateTime)
            dateString = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() 
        }

        return (
            <OverlayTrigger
            trigger="click"
            key={columnkey}
            placement="top"
            overlay={
                <Popover id={`tooltip-${columnkey}`}>
                    {(comment.user)?<Popover.Title>{`${comment.user} edit in ${dateString}`}</Popover.Title>:null}
                    <Popover.Content>
                        <Form.Control as="textarea" value={comment.value} rows={2} onChange={this.onChange}/>
                    </Popover.Content>
                </Popover>
            }>
            {(comment.value)?
            <Button variant="link" size="sm"> <FontAwesomeIcon icon={faEllipsisV} size="sm"/> </Button>
            :<Button variant="link" size="sm"> <FontAwesomeIcon icon={faEllipsisV} size="sm" color="#ced4da"/> </Button>}
            </OverlayTrigger>
        )
    }
}

export default CsvCellInput