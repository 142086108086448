import React, { Component } from 'react'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/esm/Tooltip'
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { pageComment, fileStatus } from '../../Functions/CubeCSVManagement/CubeCSVConst'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import './CubeCsvComponents.css'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faCheckCircle, faExclamationCircle);

class ValidationInput extends Component {

    constructor(props) {
        super(props)

        this.state = {
            initialValue: props.initialValue,
            value: props.initialValue,
            error: '',
            isInvalid: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.initialValue !== prevState.initialValue) {
            return {
                initialValue: nextProps.initialValue,
                value: nextProps.initialValue
            }
        }
        return null
    }

    getComment = (key) => {
        return pageComment[key]
    }

    render() {
        // const { value, error, isInvalid } = this.state
        const { isRequired, value, columnkey, error, onChange, onBlur, placeholder} = this.props

        let comment = this.getComment(columnkey)
        return (
            <InputGroup hasValidation onClick={e => e.stopPropagation()}>
                <OverlayTrigger
                key={columnkey}
                placement="top"
                overlay={
                <Tooltip id={`tooltip-${columnkey}`}>
                    {comment}
                </Tooltip>
                }>
                <Form.Control type="text" required={isRequired} isInvalid={error} placeholder={placeholder} value={value} onChange={onChange} onClick={e => e.stopPropagation()} onBlur={onBlur}/>
                </OverlayTrigger>
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </InputGroup>
        )
    }
}

class FileInput extends Component {

    constructor(props) {
        super(props)

        this.state = {
            initialValue: props.initialValue,
            value: props.initialValue,
            error: '',
            isInvalid: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.initialValue !== prevState.initialValue) {
            return {
                initialValue: nextProps.initialValue,
                value: nextProps.initialValue
            }
        }
        return null
    }

    getComment = (key) => {
        return pageComment[key]
    }

    render() {
        const { columnkey, error, onChange, label, status } = this.props

        let comment = this.getComment(columnkey)
        return (
            <InputGroup hasValidation onClick={e => e.stopPropagation()}>
                <OverlayTrigger
                key={columnkey}
                placement="top"
                overlay={
                <Tooltip id={`tooltip-${columnkey}`}>
                    {comment}
                </Tooltip>
                }>
                    <Form.File 
                        label={label}
                        onChange={onChange} 
                        custom
                    />
                </OverlayTrigger>
                <InputGroup.Append>
                    <React.Fragment key={columnkey}>
                    {status === fileStatus.Waiting? null :
                     status === fileStatus.Uploading? <Spinner className="fileProcess" animation="border" variant="primary" /> :
                     status === fileStatus.Success? <FontAwesomeIcon className="fileProcess" icon={faCheckCircle} size="2x" color="#00ad00"/> : 
                     <OverlayTrigger
                    key={`${columnkey}-error`}
                    placement="top"
                    overlay={
                    <Tooltip id={`tooltip-${columnkey}-error`}>
                        {error}
                    </Tooltip>
                    }>
                    <FontAwesomeIcon className="fileProcess" icon={faExclamationCircle} size="2x" color="#ad0000"/>
                    </OverlayTrigger>
                    }
                    </React.Fragment>
                </InputGroup.Append>
            </InputGroup>
            
        )
    }
}

class FileInputWithType extends Component {

    constructor(props) {
        super(props)

        this.state = {
            initialValue: props.initialValue,
            value: props.initialValue,
            error: '',
            isInvalid: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.initialValue !== prevState.initialValue) {
            return {
                initialValue: nextProps.initialValue,
                value: nextProps.initialValue
            }
        }
        return null
    }

    getComment = (key) => {
        return pageComment[key]
    }

    render() {
        const { columnkey, error, onChange, onDelete, onTypeChange, label, status } = this.props

        let comment = this.getComment(columnkey)
        return (
            <React.Fragment>
                <Row>
                <Col sm="1">
                    <Button variant="light" onClick={onDelete}><FontAwesomeIcon icon={faTrash} /></Button>
                </Col>
                <Col sm="3">
                    <Form.Control
                        as="select"
                        id="file-select"
                        onChange={onTypeChange}
                        custom
                    >
                        <option value="column">Column</option>
                        <option value="role">Role</option>
                        <option value="log">Log</option>
                        <option value="mixer">Mixer</option>
                        <option value="app">App</option>
                    </Form.Control>
                </Col>
                <Col sm="8">
                    <InputGroup hasValidation onClick={e => e.stopPropagation()}>
                        <OverlayTrigger
                        key={columnkey}
                        placement="top"
                        overlay={
                        <Tooltip id={`tooltip-${columnkey}`}>
                            {comment}
                        </Tooltip>
                        }>
                            <Form.File 
                                label={label}
                                onChange={onChange} 
                                custom
                            />
                        </OverlayTrigger>
                        <InputGroup.Append>
                            <React.Fragment key={columnkey}>
                            {status === fileStatus.Waiting? null :
                            status === fileStatus.Uploading? <Spinner className="fileProcess" animation="border" variant="primary" /> :
                            status === fileStatus.Success? <FontAwesomeIcon className="fileProcess" icon={faCheckCircle} size="2x" color="#00ad00"/> : 
                            <OverlayTrigger
                            key={`${columnkey}-error`}
                            placement="top"
                            overlay={
                            <Tooltip id={`tooltip-${columnkey}-error`}>
                                {error}
                            </Tooltip>
                            }>
                            <FontAwesomeIcon className="fileProcess" icon={faExclamationCircle} size="2x" color="#ad0000"/>
                            </OverlayTrigger>
                            }
                            </React.Fragment>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                </Row>
            </React.Fragment>
        )
    }
}

class SelectWithList extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { onChange, required, value, options } = this.props

        return (
            <Form.Control value={value} as="select" required={required} onChange={onChange}>
                {
                    options.map((key) => {
                        return (<option key={key}>{key}</option>)
                    })
                }
            </Form.Control>
        )
    }
}

class CubeSetting extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {cubeName, cubeError, onCubeNameChange, onCubeNameBlur, owners, ownersError, onOwnersChange, isFlat, onIsFlatChange} = this.props

        return (
            <React.Fragment>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3">Cube Name</Form.Label>
                    <Col sm="9">
                    <ValidationInput type="page" columnkey="cubeName" value={cubeName} error={cubeError} onChange={onCubeNameChange} onBlur={onCubeNameBlur}/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3">Owners</Form.Label>
                    <Col sm="9">
                        <ValidationInput type="page" columnkey="owners" value={owners} error={ownersError} onChange={onOwnersChange}/>
                    </Col>
                </Form.Group>
            </React.Fragment>
        )
    }
}

export default ValidationInput
export { FileInput, FileInputWithType, SelectWithList, CubeSetting }