import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Alert from 'react-bootstrap/Alert'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DataService from '../../Dataservices/DataService'
import IcMDataService from '../../Dataservices/IcMDataService'
import { ScenarioTags } from '../../Dataservices/ChartService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faSync, faShareSquare, faArrowsAlt, faChevronUp, faChevronDown, faEdit } from '@fortawesome/free-solid-svg-icons'
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons'
import MSREditScenarioModal from './MSREditScenarioModal'
import MSRMoveScenarioModal from './MSRMoveScenarioModal'
import MSRCalloutModal from './MSRCalloutModal'
import DataSuiteIndividualAlertModal from '../AlertingComponents/DataSuiteIndividualAlertModal'
import './MSR.css'
import { replaceSingleParamforUrl, formatSampleCount, getBackgroundColorByLatency } from '../../Functions/MSRHelper'
import { getCubeFromURL } from '../../Functions/UrlHelper'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faSync, faShareSquare, faArrowsAlt, faChevronUp, faChevronDown, faEdit, faCommentAlt);

const WeekCountsShownonMSR = 4

class MSRScenario extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: props.startDate,
            endDate: props.endDate,
            cubeName: '',
            originator: props.originator,
            icmServiceInfo: null,
            icmTeamInfo: null,
            IcmConfigId: null,
            showEditScenarioModal: false,
            showMoveScenarioModal: false,
            showMSRCallout: false,
            showIndividualAlertModal: false,
            isAllAlertsValid: true,
            goalPercents: props.Scenario.GoalPercent,
            error: null,
            ringParamName: null,
            MsitScenarioData: {},
            MsitSampleData: {},
            ProdScenarioData: {},
            ProdSampleData: {},
            Sdfv2ScenarioData: {},
            Sdfv2SampleData: {},
            thresholdList: {},
            ringUsedforMSR: "%22PROD%22%7C%22WW%22%7C%22MSIT%22%7C%22SDFV2%22",
            isLoading: Array(WeekCountsShownonMSR).fill(true),
        }
    }

    componentDidMount() {
        this.getRingParamNameAndFetchFirst()
    }

    getRingParamNameAndFetchFirst = () => {
        const appName = getCubeFromURL(this.props.Scenario.url);
        if (this.props.Scenario.icmConfigId != null) {
            IcMDataService.getIcmTeam(this.props.Scenario.icmConfigId).then(icmResult => {
                let serviceInfo = []
                serviceInfo.value = icmResult.data.ServiceConnectorId
                serviceInfo.label = icmResult.data.ServiceName
                serviceInfo.serviceId = icmResult.data.ServiceId

                let teamInfo = []
                teamInfo.value = icmResult.data.RoutingId
                teamInfo.label = icmResult.data.TeamName
                this.setState({                  
                    icmServiceInfo: serviceInfo,
                    icmTeamInfo: teamInfo,
                    icmConfigId: icmResult.data.IcmConfigId,
                })
            });
        }

        DataService.get(appName + "/RingParamName").then(res => {
            this.setState({ ringParamName: res.data })
            this.getScenario()
        }).catch(err => {
            console.error(err)
            return {}
        })
    }

    convertUrlIntoRequestObjects = (Scenario, startDate, endDate) => {
        const appName = getCubeFromURL(Scenario.url)
        const indexOfSeperator = this.state.ringParamName.indexOf('|')
        let currentScenarioUrl = Scenario.url
        currentScenarioUrl = replaceSingleParamforUrl(currentScenarioUrl, this.state.ringParamName.substr(0, indexOfSeperator), this.state.ringUsedforMSR)
        currentScenarioUrl = replaceSingleParamforUrl(currentScenarioUrl, this.state.ringParamName.substr(1 + indexOfSeperator), "false")
        currentScenarioUrl = replaceSingleParamforUrl(currentScenarioUrl, "pct", Scenario.GoalPercent)
        if (appName) {
            this.setState({ cubeName: appName });
            return {
                endpointUrl: appName + '/dataQueryOverrides',
                obj: {
                    Url: currentScenarioUrl,
                    StartDate: startDate,
                    EndDate: endDate
                }
            }
        }
        else {
            return {};
        }
    };

    sortObjById = (obj) => {
        obj.sort((a, b) => {
            return parseInt(a.ID) - parseInt(b.ID);
        });
    }

    setIcmConfigId = (icmConfigId) => {
        this.setState({
            icmConfigId: icmConfigId,
        })
    }

    setTeamInfo = (icmTeamInfo) => {
        this.setState({
            icmTeamInfo: icmTeamInfo,
            icmConfigId: null
        });
    }

    setServiceInfo = (icmServiceInfo) => {
        this.setState({
            icmServiceInfo: icmServiceInfo,
            icmTeamInfo: null,
            icmConfigId: null
        });
    }

    transformDailyDataToMSR = (LatencyData, SampleData, indexOfWeek) => {
        const { Scenario, updateScenarioLengthList } = this.props
        let retMsitLatencyData = this.state.MsitScenarioData
        let retMsitSampleData = this.state.MsitSampleData
        let retProdLatencyData = this.state.ProdScenarioData
        let retProdSampleData = this.state.ProdSampleData
        let retSdfv2LatencyData = this.state.Sdfv2ScenarioData
        let retSdfv2SampleData = this.state.Sdfv2SampleData
        let thresholdList = {}
        this.sortObjById(LatencyData)
        this.sortObjById(SampleData)

        for (let j = 0; j < LatencyData.length; j++) {
            let Legend = LatencyData[j].Name
            if (Legend.indexOf('MSIT') > -1) {
                thresholdList[Legend] = Scenario.MsitLatencyThreshold
                Legend = Legend.replace('MSIT|', '')
                Legend = Legend.replace('|MSIT', '')
                Legend = Legend.replace('MSIT', '')
                Legend = Legend === '' ? "All-Up" : Legend
                if (retMsitLatencyData[Legend] === undefined) {
                    retProdLatencyData[Legend] = Array(WeekCountsShownonMSR).fill('Empty Data')
                    retProdSampleData[Legend] = Array(WeekCountsShownonMSR).fill('Empty Data')
                    retMsitLatencyData[Legend] = Array(WeekCountsShownonMSR).fill('Empty Data')
                    retMsitSampleData[Legend] = Array(WeekCountsShownonMSR).fill('Empty Data')
                    retSdfv2LatencyData[Legend] = Array(WeekCountsShownonMSR).fill('Empty Data')
                    retSdfv2SampleData[Legend] = Array(WeekCountsShownonMSR).fill('Empty Data')
                }
                retMsitLatencyData[Legend][indexOfWeek] = Math.floor(LatencyData[j].Data[0].y)
                retMsitSampleData[Legend][indexOfWeek] = Math.floor(SampleData[j].Data[0].y)
            }
            else if (Legend.indexOf('PROD') > -1 || Legend.indexOf('WW') > -1) {
                thresholdList[Legend] = Scenario.ProdLatencyThreshold
                Legend = Legend.replace('PROD|', '').replace('WW|', '')
                Legend = Legend.replace('|PROD', '').replace('|WW', '')
                Legend = Legend.replace('PROD', '').replace('WW', '')
                Legend = Legend === '' ? "All-Up" : Legend
                if (retProdLatencyData[Legend] === undefined) {
                    retProdLatencyData[Legend] = Array(WeekCountsShownonMSR).fill('Empty Data')
                    retProdSampleData[Legend] = Array(WeekCountsShownonMSR).fill('Empty Data')
                    retMsitLatencyData[Legend] = Array(WeekCountsShownonMSR).fill('Empty Data')
                    retMsitSampleData[Legend] = Array(WeekCountsShownonMSR).fill('Empty Data')
                    retSdfv2LatencyData[Legend] = Array(WeekCountsShownonMSR).fill('Empty Data')
                    retSdfv2SampleData[Legend] = Array(WeekCountsShownonMSR).fill('Empty Data')
                }
                retProdLatencyData[Legend][indexOfWeek] = Math.floor(LatencyData[j].Data[0].y)
                retProdSampleData[Legend][indexOfWeek] = Math.floor(SampleData[j].Data[0].y)
            }
            else if (Legend.indexOf('SDFV2') > -1) {
                thresholdList[Legend] = Scenario.Sdfv2LatencyThreshold
                Legend = Legend.replace('SDFV2|', '')
                Legend = Legend.replace('|SDFV2', '')
                Legend = Legend.replace('SDFV2', '')
                Legend = Legend === '' ? "All-Up" : Legend
                if (retProdLatencyData[Legend] === undefined) {
                    retProdLatencyData[Legend] = Array(WeekCountsShownonMSR).fill('Empty Data')
                    retProdSampleData[Legend] = Array(WeekCountsShownonMSR).fill('Empty Data')
                    retMsitLatencyData[Legend] = Array(WeekCountsShownonMSR).fill('Empty Data')
                    retMsitSampleData[Legend] = Array(WeekCountsShownonMSR).fill('Empty Data')
                    retSdfv2LatencyData[Legend] = Array(WeekCountsShownonMSR).fill('Empty Data')
                    retSdfv2SampleData[Legend] = Array(WeekCountsShownonMSR).fill('Empty Data')
                }
                retSdfv2LatencyData[Legend][indexOfWeek] = Math.floor(LatencyData[j].Data[0].y)
                retSdfv2SampleData[Legend][indexOfWeek] = Math.floor(SampleData[j].Data[0].y)
            }
        }

        updateScenarioLengthList(Scenario.order, Object.keys(retMsitLatencyData).length)
        updateScenarioLengthList(Scenario.order, Object.keys(retProdLatencyData).length)
        updateScenarioLengthList(Scenario.order, Object.keys(retSdfv2LatencyData).length)

        this.setState({
            MsitScenarioData: this.sortObjKeys(retMsitLatencyData),
            MsitSampleData: this.sortObjKeys(retMsitSampleData),
            ProdScenarioData: this.sortObjKeys(retProdLatencyData),
            ProdSampleData: this.sortObjKeys(retProdSampleData),
            Sdfv2ScenarioData: this.sortObjKeys(retSdfv2LatencyData),
            Sdfv2SampleData: this.sortObjKeys(retSdfv2SampleData),
            thresholdList: thresholdList
        });
    }

    sortObjKeys = (unordered) => {
        return Object.keys(unordered).sort().reduce(
            (obj, key) => {
                obj[key] = unordered[key];
                return obj;
            },
            {}
        )
    }

    getScenario = scenarioRawData => {
        const { startDate } = this.state
        this.setState({
            isLoading: Array(WeekCountsShownonMSR).fill(true),
            MsitScenarioData: {},
            MsitSampleData: {},
            ProdScenarioData: {},
            ProdSampleData: {},
            Sdfv2ScenarioData: {},
            Sdfv2SampleData: {}
        })
        for (let i = 0; i < WeekCountsShownonMSR; i++) {
            this.getScenarioForDateRange(scenarioRawData, new Date(startDate.getTime() + 86400000 * 7 * i), new Date(startDate.getTime() + 86400000 * (7 * i + 3)), i)
        }
    }

    getScenarioForDateRange = (scenarioRawData, startDate, endDate, indexOfWeek) => {
        this.props.updateScenarioLengthList(this.props.Scenario.order, 0)
        const scenario = scenarioRawData || this.props.Scenario

        const requestObj = this.convertUrlIntoRequestObjects(scenario, startDate, endDate)
        requestObj.obj.OverrideTab = "pivottable"

        if (requestObj.endpointUrl) {
            DataService.post(requestObj.endpointUrl, requestObj.obj, null, ScenarioTags.WeeklyMSR)
                .then(res => {
                    if (res.data.IsSuccess) {
                        this.transformDailyDataToMSR(res.data.LatencyDataSeries, res.data.SampleCountDataSeries, indexOfWeek)
                        this.setState({
                            latencyDataSeries: res.data.LatencyDataSeries
                        })
                    } else {
                        console.error(res.data.RequestID);
                        console.error(res.data.RequestLog);
                        console.error(res.data.ErrorMessage);
                        this.setState({
                            error: res.data.ErrorMessage,
                        });
                    }
                    this.setIsLoadingFalse(indexOfWeek)
                }).catch(err => {
                    console.error(err);
                    this.setState({
                        error: err
                    });
                    this.setIsLoadingFalse(indexOfWeek)
                })
        } else {
            this.setIsLoadingFalse(indexOfWeek)
        }
    }

    setIsLoadingFalse = indexOfWeek => {
        this.setState(prevState => {
            prevState.isLoading[indexOfWeek] = false
            return {
                isLoading: prevState.isLoading
            }
        })
    }


    hasLoadCompleteAll = () => {
        return this.state.isLoading.every(isLoading => !isLoading)
    }

    onToggleEditScenarioModal = () => {
        this.setState(prevState => {
            return {
                showEditScenarioModal: !prevState.showEditScenarioModal
            }
        })
    }

    onChangeIsAllAlertsValid = (valid) => {
        this.setState({ isAllAlertsValid: valid })
    }

    onToggleIndividualAlertModal = () => {
        this.setState(prevState => {
            return {
                showIndividualAlertModal: !prevState.showIndividualAlertModal
            }
        })
    }

    onToggleMSRCalloutModal = () => {
        this.setState(prevState => {
            return {
                showMSRCallout: !prevState.showMSRCallout
            }
        })
    }

    openMSRUrl = () => {
        window.open(this.props.Scenario.url)
    }

    onToggleMoveScenarioModal = () => {
        this.setState(prevState => {
            return {
                showMoveScenarioModal: !prevState.showMoveScenarioModal
            }
        })
    }

    onScenarioRefresh = () => {
        document.getElementById('msr-scenario-refresh').innerHTML = this.props.Scenario.title + " refreshed";
        this.getScenario()
    }

    onMoveScenario = (MSRId, oldIndex, newIndex) => {
        const { onMoveScenario } = this.props
        this.onToggleMoveScenarioModal()
        onMoveScenario(MSRId, oldIndex, newIndex)
    }

    onEditscenarioSave = (scenario, shouldRefresh) => {
        if (shouldRefresh) {
            this.getScenario(scenario)
        }
        this.props.onEditScenarioSave(scenario)
    }

    getScenarioButtons = (Scenario, scenarioLen, onMoveScenario) => {
        const { isOwner } = this.props
        const { isAllAlertsValid } = this.state
        const countEnabledAlert = (chart) => {
            if (chart && chart.alertList) {
                return chart.alertList.StaticAlerts.filter(alert => (alert.StaticThreshold.IsAlertEnabled)).length
            } else {
                return 0
            }
        }

        return (
            <ButtonToolbar className="ScenarioToolbar">
                <ButtonGroup>
                    <OverlayTrigger overlay={
                        <Tooltip id={`tooltip-refresh`}>Refresh</Tooltip>
                    }>
                        <Button aria-label="Refresh" variant="light" onClick={this.onScenarioRefresh}><FontAwesomeIcon icon={faSync} /></Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={
                        <Tooltip id={`tooltip-open-in-new-tab`}>Open in new tab</Tooltip>
                    }>
                        <Button aria-label="Open in new tab" variant="light" onClick={this.openMSRUrl}><FontAwesomeIcon icon={faShareSquare} /></Button>
                    </OverlayTrigger>
                    {isOwner &&
                        <React.Fragment>
                            <OverlayTrigger overlay={
                                <Tooltip id={`tooltip-edit-scenario`}>Edit scenario</Tooltip>
                            }>
                                <Button aria-label="Edit scenario" variant="light" onClick={this.onToggleEditScenarioModal}><FontAwesomeIcon icon={faEdit} /></Button>
                            </OverlayTrigger>
                            {Scenario.order !== scenarioLen - 1 ? <OverlayTrigger overlay={
                                <Tooltip id={`tooltip-move-down`}>Move scenario down</Tooltip>}><Button aria-label="Move scenario down" variant="light" onClick={onMoveScenario.bind(this, Scenario.order, Scenario.order + 1)}><FontAwesomeIcon icon={faChevronDown} /></Button></OverlayTrigger> : null}
                            {Scenario.order !== 0 ? <OverlayTrigger overlay={<Tooltip id={`tooltip-move-up`}>Move scenario up</Tooltip>}><Button aria-label="Move scenario up" variant="light" onClick={onMoveScenario.bind(this, Scenario.order, Scenario.order - 1)}><FontAwesomeIcon icon={faChevronUp} /></Button></OverlayTrigger> : null}
                        </React.Fragment>}
                    <OverlayTrigger overlay={
                        <Tooltip id={`tooltip-move`}>Move Scenario</Tooltip>
                    }>
                        <Button variant="light" aria-label="Move scenario" onClick={this.onToggleMoveScenarioModal}><FontAwesomeIcon icon={faArrowsAlt} /></Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={
                        <Tooltip id={`tooltip-callout`}>CallOut for Scenario</Tooltip>
                    }>
                        <Button variant="light" aria-label="CallOut for scenario" onClick={this.onToggleMSRCalloutModal}><FontAwesomeIcon icon={faCommentAlt} /></Button>
                    </OverlayTrigger>
                    {isOwner &&
                        <React.Fragment>
                            <OverlayTrigger overlay={
                                <Tooltip id={`tooltip-edit-scenario`}>Edit alerts</Tooltip>
                            }>
                                <Button aria-label="Edit alerts" variant="light" onClick={this.onToggleIndividualAlertModal}>
                                    <FontAwesomeIcon icon={faBell} style={isAllAlertsValid ? ((countEnabledAlert(Scenario) > 0) ? null : { color: '#aaaaaa' }) : { color: "#8B0000" }} />
                                </Button>
                            </OverlayTrigger>
                        </React.Fragment>}
                </ButtonGroup>
            </ButtonToolbar>
        )
    }

    setError = error => {
        this.setState({ error: error });
    }

    getAvgDailySample = (currLegend, SampleData) => {
        const DayCountShownInEachWeek = 4
        const currSampleData = SampleData[currLegend]
        let sumOfSample = 0
        for (var i = 0; i < currSampleData.length; ++i) {
            if (currSampleData[i] !== 'Empty Data') {
                sumOfSample += parseInt(currSampleData[i])
            }
        }

        const currAvgDailySample = parseInt(sumOfSample / (WeekCountsShownonMSR * DayCountShownInEachWeek))

        return formatSampleCount(currAvgDailySample)
    }

    renderScenarioData = (ScenarioData, SampleData, latencyThreshold, currLegend, showCurrentRing) => {
        const { Scenario, showLegend, showAvgDailySamples, showLatencyThreshold } = this.props
        return (
            showCurrentRing ?
                <>
                    {showLatencyThreshold ? <td className='noWordBreak'>{`${latencyThreshold} ms @P${Scenario.GoalPercent}`}</td> : null}
                    {showAvgDailySamples ? <td className='noWordBreak'>{this.getAvgDailySample(currLegend, SampleData)}</td> : null}

                    {ScenarioData[currLegend].map((currnum, i) => (
                        this.state.isLoading[i % WeekCountsShownonMSR] ?
                            <td className='noWordBreak high-contrast-red'
                                style={{ "border-right": (i === 3 + showLegend + showAvgDailySamples + showLatencyThreshold ? "2px solid #111" : null) }}>
                                getting data...
                            </td>
                            : <td className='noWordBreak'
                                style={{ "border-right": (i === 3 ? "2px solid #111" : null), 'font-size': '20px', 'background-color': getBackgroundColorByLatency(currnum, latencyThreshold), 'color': (currnum > latencyThreshold * 1.6 ? "#fff" : "#000") }}>
                                {currnum}
                            </td>
                    ))}
                </> : null
        );
    }

    render() {
        const { Scenario, onEditScenarioDelete, scenarioLen, onMoveScenario, showLegend, showAvgDailySamples, showLatencyThreshold, msrVersion, showMsit, showProd, showSdfv2, icmServices, setIcmServices } = this.props
        const { showMSRCallout, showEditScenarioModal, showMoveScenarioModal, MsitScenarioData, ProdScenarioData, Sdfv2ScenarioData, MsitSampleData, ProdSampleData, Sdfv2SampleData, error, showIndividualAlertModal, originator, icmServiceInfo, icmTeamInfo, icmConfigId, thresholdList } = this.state
        const ringLen = showSdfv2 + showMsit + showProd

        return (
            <React.Fragment>
                {error && <Alert variant='danger'>{error}</Alert>}

                {/*If this scenario does not contain any legend and all the requests are completed, we show one row of 'Empty Data'.*/}
                {Object.keys(MsitScenarioData).length === 0 && this.hasLoadCompleteAll() &&
                    <tr className="MSR">
                        <td className='noWordBreak'>{Scenario.title}</td>
                        {showLegend ? <td className='noWordBreak'>Empty Data</td> : null}

                        {showLatencyThreshold ? <td className='noWordBreak'>{Scenario.Sdfv2LatencyThreshold} ms @P{Scenario.GoalPercent}</td> : null}
                        {showAvgDailySamples ? <td className='noWordBreak'>Empty Data</td> : null}
                        {(Array.from(Array(WeekCountsShownonMSR).keys())).map((_, i) => (
                            <td className='noWordBreak' style={{ "border-right": (i === 3 ? "2px solid #111" : null), 'font-size': '20px', 'background-color': '#c8e6c9', 'color': "#000" }}>Empty Data</td>
                        ))}

                        {showLatencyThreshold ? <td className='noWordBreak'>{Scenario.MsitLatencyThreshold} ms @P{Scenario.GoalPercent}</td> : null}
                        {showAvgDailySamples ? <td className='noWordBreak'>Empty Data</td> : null}
                        {(Array.from(Array(WeekCountsShownonMSR).keys())).map((_, i) => (
                            <td className='noWordBreak' style={{ "border-right": (i === 3 ? "2px solid #111" : null), 'font-size': '20px', 'background-color': '#c8e6c9', 'color': "#000" }}>Empty Data</td>
                        ))}

                        {showLatencyThreshold ? <td className='noWordBreak'>{Scenario.ProdLatencyThreshold} ms @P{Scenario.GoalPercent}</td> : null}
                        {showAvgDailySamples ? <td className='noWordBreak'>Empty Data</td> : null}
                        {(Array.from(Array(WeekCountsShownonMSR).keys())).map((_, i) => (
                            <td className='noWordBreak' style={{ "border-right": (i === 3 ? "2px solid #111" : null), 'font-size': '20px', 'background-color': '#c8e6c9', 'color': "#000" }}>Empty Data</td>
                        ))}

                        <td style={{ width: 100 }}>
                            {this.getScenarioButtons(Scenario, scenarioLen, onMoveScenario)}
                        </td>
                    </tr>}

                {/*If this scenario does not contain any legend but not all the requests are completed, we show one row of 'getting data...'.*/}
                {Object.keys(MsitScenarioData).length === 0 && !this.hasLoadCompleteAll() &&
                    <tr className="MSR">
                        <td className='noWordBreak'>{Scenario.title}</td>
                        {(Array.from(Array(WeekCountsShownonMSR * ringLen + showLegend + showAvgDailySamples * ringLen + showLatencyThreshold * ringLen).keys())).map((currnum, i) => (
                            <td className="high-contrast-red noWordBreak" style={{ "border-right": (i === 3 + showLegend + showAvgDailySamples + showLatencyThreshold ? "2px solid #111" : null) }}>getting data...</td>
                        ))}
                        <td style={{ width: 100 }}>
                            {this.getScenarioButtons(Scenario, scenarioLen, onMoveScenario)}
                        </td>
                    </tr>}

                {/*If some requests are completed and this scenario contains legends, we show one row for each legend.*/}
                {Object.keys(MsitScenarioData).map((currLegend, index) => (
                    <tr className="MSR" key={currLegend}>
                        {index === 0 && <td rowspan={Object.keys(MsitScenarioData).length} className='noWordBreak'>{Scenario.title}</td>}

                        {showLegend ? <td className='noWordBreak'>{currLegend}</td> : null}
                        {this.renderScenarioData(Sdfv2ScenarioData, Sdfv2SampleData, Scenario.Sdfv2LatencyThreshold, currLegend, showSdfv2)}
                        {this.renderScenarioData(MsitScenarioData, MsitSampleData, Scenario.MsitLatencyThreshold, currLegend, showMsit)}
                        {this.renderScenarioData(ProdScenarioData, ProdSampleData, Scenario.ProdLatencyThreshold, currLegend, showProd)}
                        {index === 0 &&
                            <td rowspan={Object.keys(MsitScenarioData).length} style={{ width: 100, paddingTop: '3px', paddingBottom: '3px' }}>
                                {this.getScenarioButtons(Scenario, scenarioLen, onMoveScenario)}
                            </td>}
                    </tr>
                ))}

                <div id="msr-scenario-refresh" role="status" class="sr-only"></div>
                <MSRCalloutModal
                    showModalorNot={showMSRCallout}
                    toggleModal={this.onToggleMSRCalloutModal}
                    Scenario={Scenario}
                    setError={this.setError} />
                <MSREditScenarioModal
                    showModalorNot={showEditScenarioModal}
                    toggleModal={this.onToggleEditScenarioModal}
                    Scenario={Scenario}
                    onSave={this.onEditscenarioSave}
                    onDelete={onEditScenarioDelete}
                    setState={this.setError}
                    msrVersion={msrVersion}
                    icmTeamInfo={icmTeamInfo}
                    icmServiceInfo={icmServiceInfo}
                    icmConfigId={icmConfigId}
                    icmServices={icmServices}
                    setTeamInfo={this.setTeamInfo}
                    setServiceInfo={this.setServiceInfo}
                    setIcmConfigId={this.setIcmConfigId}
                    setIcmServices={setIcmServices} />
                <MSRMoveScenarioModal
                    showModal={showMoveScenarioModal}
                    toggleModal={this.onToggleMoveScenarioModal}
                    onMoveScenario={this.onMoveScenario}
                    MSRId={Scenario.dataSuiteId}
                    orginalOrder={Scenario.order}
                    maxOrder={scenarioLen - 1} />
                {<DataSuiteIndividualAlertModal
                    originator={originator}
                    chart={Scenario}
                    thresholdList={thresholdList}
                    icmConfigId={icmConfigId}
                    showModal={showIndividualAlertModal}
                    toggleModal={this.onToggleIndividualAlertModal}
                    onSave={this.onEditscenarioSave}
                    onChangeValidation={this.onChangeIsAllAlertsValid}
                    icmServices={icmServices}
                    setIcmServices={setIcmServices} />}
            </React.Fragment>
        );
    }

}

export default MSRScenario