import { handleClick } from '../../Dataservices/EventTrackingService';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { faBars, faTable, faChartLine, faCropAlt, faKeyboard } from '@fortawesome/free-solid-svg-icons';
import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faBars, faTable, faChartLine, faCropAlt, faKeyboard);

const ChartTypeOptions = (props) => {
    return <>
    <Dropdown.Item onClick={() => {handleClick("line", "chart", "all"); props.changeChartDisplayType("Line")}}>Line</Dropdown.Item>
    <Dropdown.Item onClick={() => {handleClick("stackedArea", "chart", "all"); props.changeChartDisplayType("StackedArea")}}>Stacked Area</Dropdown.Item>
    <Dropdown.Item onClick={() => {handleClick("100PercentArea", "chart", "all"); props.changeChartDisplayType("100PercentArea")}}>100 Percent Area</Dropdown.Item>
    </>;
}

const AnalysisPageVariant = (props) => {
    return <>
        <Dropdown>
            <OverlayTrigger overlay={<Tooltip>Chart Type</Tooltip>}>
                <Dropdown.Toggle id="dropdown-basic" variant="light" aria-label="Chart Type"><FontAwesomeIcon icon={faChartLine}/></Dropdown.Toggle>
            </OverlayTrigger>
            <Dropdown.Menu><ChartTypeOptions changeChartDisplayType={props.changeChartDisplayType}/></Dropdown.Menu>
        </Dropdown>
        <OverlayTrigger overlay={<Tooltip>Set Min/Max Y</Tooltip>}>
            <Button aria-label="Set Min/Max Y" variant="light" onClick={() => {handleClick("setMinMaxY", "chart", "all"); props.setMinMaxY()}}><FontAwesomeIcon icon={faCropAlt} /></Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>View data table</Tooltip>}>
            <Button aria-label="View data table" variant="light" onClick={() => {handleClick("viewDataTable", "chart", "all"); props.showDataTable()}}><FontAwesomeIcon icon={faTable} /></Button>
        </OverlayTrigger>
    </>
};

const DashboardPageVariant = (props) => {
    return <>
        <Dropdown.Header>Change chart type</Dropdown.Header>
        <ChartTypeOptions changeChartDisplayType={props.changeChartDisplayType}/>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => {handleClick("setMinMaxY", "chart", "all"); props.setMinMaxY()}}>Set Min/Max Y</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => {handleClick("viewDataTable", "chart", "all"); props.showDataTable()}}>View data as table</Dropdown.Item>
        <Dropdown.Divider />
    </>;
};

const ChartToolbar = (props) => {

    const [isAccessibilityEnabled, setIsAccessibilityEnabled] = useState(false);

    return (
        <ButtonToolbar className="chartToolbar">
            <ButtonGroup>
                <OverlayTrigger overlay={<Tooltip>Enable keyboard accessibility for chart</Tooltip>}>
                    <Button aria-label="Enable keyboard accessibility for chart" variant="light" onClick={() => {handleClick("enableAccessibility", "highcharts", "shared"); props.setAccessibility(!isAccessibilityEnabled); setIsAccessibilityEnabled(!isAccessibilityEnabled);}}><FontAwesomeIcon icon={faKeyboard} style={isAccessibilityEnabled ? null: {color: '#aaaaaa'}} /></Button>
                </OverlayTrigger>
                {!props.isInDashboard && <AnalysisPageVariant changeChartDisplayType={props.changeChartDisplayType} setMinMaxY={props.setMinMaxY} showDataTable={props.showDataTable}/>}
                <Dropdown>
                    <OverlayTrigger overlay={<Tooltip>Download chart data</Tooltip>}>
                        <Dropdown.Toggle id="dropdown-basic" variant="light" aria-label="Download Chart Data">
                            <FontAwesomeIcon icon={faBars} />
                        </Dropdown.Toggle>
                    </OverlayTrigger>
                    <Dropdown.Menu>
                        {props.isInDashboard && <DashboardPageVariant changeChartDisplayType={props.changeChartDisplayType} setMinMaxY={props.setMinMaxY} showDataTable={props.showDataTable}/>}
                        <Dropdown.Item variant="light" onClick={props.chart ? () => {handleClick("printChart", "highcharts", "shared"); props.chart.print()} : null}>Print Chart</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Header>Download chart image</Dropdown.Header>
                        <Dropdown.Item variant="light" onClick={props.chart ? () => {handleClick("downloadPNG", "highcharts", "shared"); props.chart.exportChart({type: 'image/png'})} : null}>Download PNG image</Dropdown.Item>
                        <Dropdown.Item variant="light" onClick={props.chart ? () => {handleClick("downloadJPEG", "highcharts", "shared"); props.chart.exportChart({type: 'image/jpeg'})} : null }>Download JPEG image</Dropdown.Item>
                        <Dropdown.Item variant="light" onClick={props.chart ? () => {handleClick("downloadSVG", "highcharts", "shared"); props.chart.exportChart({type: 'image/svg+xml'})} : null}>Download SVG vector image</Dropdown.Item>
                        <Dropdown.Item variant="light" onClick={props.chart ? () => {handleClick("downloadPDF", "highcharts", "shared"); props.chart.exportChart({type: 'application/pdf'})} : null}>Download PDF document</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Header>Download chart data</Dropdown.Header>
                        <Dropdown.Item variant="light" onClick={props.chart ? () => {handleClick("downloadCSV", "highcharts", "shared"); props.chart.downloadCSV()} : null}>Download CSV</Dropdown.Item>
                        <Dropdown.Item variant="light" onClick={props.chart ? () => {handleClick("downloadXLS", "highcharts", "shared"); props.chart.downloadXLS()} : null}>Download XLS</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </ButtonGroup>
        </ButtonToolbar>
    )
}

export default React.memo(ChartToolbar);