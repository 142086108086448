import React from 'react';
import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import RenderInformativeTooltip from '../RenderInformativeTooltip';

function IcMSelector(props) {

    return (
        <React.Fragment>
            <Form.Group aria-label="Icm Owning Service">
                <Form.Label>Select IcM Owning Service:&ensp;</Form.Label>
                <RenderInformativeTooltip field="alerts" placement="right" message="Please select the IcM Service that your team belongs to." />
                <div className="servicePicker">
                    <Select
                        isClearable
                        name="service"
                        options={props.serviceList}
                        value={props.icmServiceInfo ? { value: props.icmServiceInfo.value, label: props.icmServiceInfo.label } : null}
                        onChange={props.setServiceInfo}
                        noOptionsMessage={() => "Searching..."}
                    />
                </div>
            </Form.Group>
            <Form.Group aria-label="Icm Owning Team">
                <Form.Label>Select IcM Owning Team:&ensp;</Form.Label>
                <RenderInformativeTooltip field="alerts" placement="right" message="Please select the IcM team to receive the incident. Please use RoutingId to distinguish teams with the same name. Ensure that the correct RoutingId has been selected for escalating the incident ticket to your team." />
                <div className="teamPicker">
                    <Select
                        isClearable
                        name="team"
                        options={props.teamList}
                        value={props.icmTeamInfo ? { value: props.icmTeamInfo.value, label: props.icmTeamInfo.label } : null}
                        onChange={props.setTeamInfo}
                        noOptionsMessage={() => props.teamSelectMessage}
                    />
                </div>
            </Form.Group>
        </React.Fragment>
    )
}
export default IcMSelector;