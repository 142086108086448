import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import './pages.css'
import { handleClick } from '../../Dataservices/EventTrackingService'
import titles from '../../Store/titles.js'
import { getPageTitle } from '../../Functions/PageTitleHelper'

function Welcome() {
    useEffect(() => {
        document.title = getPageTitle(titles.WELCOME, null);
    }, [])

    return (
        <React.Fragment>
            <main>
                <Container>
  
                    <Row>
                        <Col>
                            <h1 className="welcomeTitle">Welcome to PerfPanel</h1>
                        </Col>
                    </Row>

                    <br/>
                    <br/>

                    <Row>
                        <Col>
                            <div className="welcomeBox">
                                <h2>Know your daily, weekly, monthly latency trends in Substrate!</h2>
                                <ul>
                                    <li>Report on any percentiles</li>
                                    <li>Drill down to checkpoint tab to see time spent in various components, processes (Layercake)</li>
                                    <li>For parallel processes timings that cannot be represented in layer cake we have correlated metrics</li>
                                    <li>There are lots of other cool features in PerfPanel<a className="accessibleLink" aria-label="Learn more about other cool features in PerfPanel" href="https://o365exchange.visualstudio.com/O365 Core/_wiki/wikis/O365 Core.wiki?pagePath=%2FPerf%20Panel&pageId=2276&wikiVersion=GBwikiMaster" onClick={() => { handleClick("Learn More", "body", "welcome") }}> Learn More </a></li>
                                    <li>To see how PerfPanel can help you, check out these<a className="accessibleLink" aria-label="Check out these videos to see how PerfPanel can help you" href="https://msit.microsoftstream.com/channel/272397c4-393a-4814-b199-52b975906372" onClick={() => { handleClick("videos", "body", "welcome") }}> videos </a></li>
                                    <li>To access videos and other information, you can join the EXO Substrate Performance Group <a className="accessibleLink" aria-label="Join the EXO Substrate Performance Group to access videos and other information" href="https://outlook-sdf.office.com/owa/exosubstrateperformance@service.microsoft.com/groupsubscription.ashx?action=join" onClick={() => { handleClick("here", "body", "welcome") }}> here </a></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="welcomeBox">
                                <p><strong>Do you have a question on latency?</strong> Come to our office hours for consultation.  <a className="accessibleLink" aria-label="Add office hours meeting to your calendar for consultation" href="Performance Office Hours.ics" onClick={() => { handleClick("Add this meeting to your calendar", "body", "welcome") }}> Add this meeting to your calendar</a></p>
                                <p>
                                    <strong>Do you have a new request in PerfPanel?</strong> 
                                    <a className="accessibleLink" aria-label="Make a new request in PerfPanel" href="https://o365exchange.visualstudio.com/O365%20Core/_workitems/create/User%20Story?templateId=9c290d7e-623a-4dba-ae28-1bab16d5f5be&ownerId=8327447e-1e98-4409-9449-496e8a82cab2" onClick={() => { handleClick("Request here", "body", "welcome") }}> Request here.
                                    </a> You can see other intakes <a className="accessibleLink" aria-label="See other intakes" href="https://o365exchange.visualstudio.com/O365%20Core/_queries?tempQueryId=c612832a-fcc0-4feb-9641-741d5a7a505b" onClick={() => { handleClick("here", "body", "welcome") }}> here.</a>
                                </p>
                                <p><strong>Can't wait for office hours? Need help from our team?</strong><a className="accessibleLink" href="mailto:o365coreperf@microsoft.com?subject=[Need%20Help]" onClick={() => { handleClick("Contact us", "body", "welcome") }}> Contact us</a></p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <h3>Dashboards we use in Perf Shiproom</h3>
                                    <a className="accessibleLink" aria-label="List of dashboards we use in Perf Shiproom" href="https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki?pagePath=%2FPerf%20Panel%2FShip%20room%20Dashboards&pageId=2388&wikiVersion=GBwikiMaster" onClick={() => { handleClick("List of dashboards", "body", "welcome") }}>List of dashboards</a>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <h3>Documents to on-board to PerfPanel</h3>
                                    <a className="accessibleLink" aria-label="Start here to see documents to on-board to PerfPanel" href="https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/2276/Perf-Panel" onClick={() => { handleClick("Start here", "body", "welcome") }}>Start here </a>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </main>
        </React.Fragment>
    )
}


export default Welcome;