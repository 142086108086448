import React from 'react';
import './ChartComponents.css'

 function NoDataContainer(props) {
    const { title, errorMessage, defaultMessage } = props
    const message = (!errorMessage || errorMessage === '') ? defaultMessage : errorMessage
    const isLongError = message.length > 100

    return <div className="noDataContainer">
                <h3>{title}</h3>
                <div className={ isLongError ? "longError" : "shortError"}>
                    <p>{message}</p>
                </div>
            </div>
}

export default React.memo(NoDataContainer);