import React, { Fragment, useState, useRef, useEffect } from 'react'
import { Spinner, Row, FormControl, InputGroup, Button, Form, Jumbotron, ButtonGroup } from 'react-bootstrap';
import './EvoServiceRegistration.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faChevronCircleUp, faPlus, faSave, faBroom, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import EditableSelect from '../PublicComponents/EditableSelect';
import SectionStatus from './SectionStatus';
import RenderInformativeTooltip from '../RenderInformativeTooltip';
import EvoDataService from '../../Dataservices/EvoDataService';
import { getErrorMessage } from '../../Functions/CommonHelper';
import { getOtherPivotNameAlias } from './EvoRegistrationHelper';
import ValidationInput from '../PublicComponents/ValidationInput';

library.add(faChevronCircleDown, faChevronCircleUp, faBroom);

function ServiceLogDefinition(props) {
    const { versionDropdownList, isEditable, onSelectServiceLogDefinition, selectedVersionValue, isLoading, dataSourceList, parserList, definitionData, 
        onServiceLogDefinitionChange, onCreateServiceLogDefinition, initDefinitionMessage, onSaveServiceLogDefinition, sectionStatus, basicColumns, advancedColumns } = props;

    const [dataSourceShema, setDataSourceSchema] = useState([]);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [validateDataSourceStatus, setValidateDataSourceStatus] = useState(true);
    const [isLoadingSchema, setIsLoadingSchema] = useState(false);
    const [validateDataSourceMessage, setValidateDataSourceMessage] = useState('');
    const [dataSourceOptionList, setDataSourceOptionList] = useState([]);
    const [parserOptionList, setParserOptionList] = useState([]);
    const [dataSourceAccessInfoLabel, setDataSourceAccessInfoLabel] = useState('Data Source Access Info');
    const [otherPivotColumnErrors, setOtherPivotColumnErrors] = useState([]);

    const otherPivotAliasRegex = useRef(/^[A-Za-z0-9_]*$/);

    useEffect(() => {
        setDataSourceOptionList(dataSourceList.map(d => d.name));
        let label = dataSourceList.find(d => d.id === definitionData?.dataSourceId)?.accessInfoDisplayName;
        setDataSourceAccessInfoLabel(label || 'Data Source Access Info');
    }, [dataSourceList, definitionData?.dataSourceId]);

    useEffect(() => {
        setParserOptionList(parserList.map(d => d.name));
    }, [parserList]);

    useEffect(() => {
        setOtherPivotColumnErrors(new Array(definitionData?.serviceLogColumns?.otherPivotColumns?.length).fill(''));
    }, [definitionData?.serviceLogColumns?.otherPivotColumns]);
 

    // This is a temporary condition and will be removed after we figure out how to get schema for all the data sources
    const showValidateButton = () => { 
        let validDataSources = ['CosmosView', 'CosmosViaGeneva'];
        return  definitionData && isEditable && validDataSources.includes(dataSourceList.find(d => d.id === definitionData.dataSourceId)?.name)
    }


    const dataSourceInfoMessages =() =>{
        if (dataSourceList.find(d => d.id === definitionData?.dataSourceId)?.name == "CosmosViaGeneva") {
            return  <SectionStatus text={"For CosmosViaGeneva you can add multiple schemas by semicolon(;)"} type="info"/>
        }
    }
 
    const validateDataSource = (dataSourceId, dataSourceAccessInfo) => {
        const dataSourceName = dataSourceList.find(d => d.id === dataSourceId)?.name;

        if (!dataSourceName) {
            setValidateDataSourceStatus('Failure');
            setValidateDataSourceMessage('Please select a data source and retry.');
            setDataSourceSchema([]);
            return;
        }

        if (!dataSourceAccessInfo) {
            setValidateDataSourceStatus('Failure');
            setValidateDataSourceMessage(`Please enter ${dataSourceAccessInfoLabel.toLowerCase()} and retry.`);
            setDataSourceSchema([]);
            return;
        };

        setIsLoadingSchema(true);

        let getSchemaPromise;
        if (dataSourceName === 'CosmosView') {
            getSchemaPromise = EvoDataService.getCosmosViewSchema(dataSourceAccessInfo);
        }

        if (dataSourceName === 'CosmosViaGeneva') {
            getSchemaPromise = EvoDataService.getCosmosViaGenevaSchema(dataSourceAccessInfo);
        }

        if (!getSchemaPromise) {
            setDataSourceSchema([]);
            setIsLoadingSchema(false);
            return;
        }

        getSchemaPromise.then(res=>{
            setDataSourceSchema(res.data);
            setValidateDataSourceStatus('Success');
            setIsLoadingSchema(false);
            setValidateDataSourceMessage('Successfully get schema. Please select columns from the dropdown list.');
        }).catch(err => {
            setDataSourceSchema([]);
            setValidateDataSourceStatus('Failure');
            setIsLoadingSchema(false);
            setValidateDataSourceMessage(getErrorMessage(err));
            console.error(getErrorMessage(err, true));
        })
    };

    const onClickShowAdvanced = () => {
        setShowAdvanced(!showAdvanced);
    };

    const onDataAccessInfoChange = e => {
        setValidateDataSourceStatus('New');
        setDataSourceSchema([]);
        onServiceLogDefinitionChange('dataSourceAccessInfo', e.target.value);
    };

    const onVersionChange = (option, payload, versionIndex) => {
        onSelectServiceLogDefinition(versionIndex, true);
    }

    const onFieldChange = (newVal, payload) => {
        let val = newVal;
        if (payload === 'dataSourceId') {
            setValidateDataSourceStatus('New');
            setDataSourceSchema([]);

            val = dataSourceList.find(d => d.name === newVal).id;
        }

        if (payload === 'parserId') {
            val = parserList.find(p => p.name === newVal).id;
        }

        onServiceLogDefinitionChange(payload, val);
    };

    const onFilterClauseChange = (e) => {
        onServiceLogDefinitionChange('filterClause', e.target.value);
    };

    const onLogSizeChange = (e) => {
        onServiceLogDefinitionChange('logSizeInGB', e.target.value);
    };

    const onOtherPivotColumnChange = (otherPivotIndex, newColumnName, oldColumnName, _, alias) => {
        let newOtherPivotColumns = [...definitionData.serviceLogColumns.otherPivotColumns];

        let splitted = getOtherPivotNameAlias(newOtherPivotColumns[otherPivotIndex]);
        let oldName = splitted[0];
        let oldAlias = splitted[1];

        if (newColumnName === oldColumnName && alias === oldAlias) {
            // nothing is changed
            return;
        } else if (newColumnName === oldColumnName && alias !== undefined) {
            // only alias is changed
            if (otherPivotAliasRegex.current.test(alias)) {
                newOtherPivotColumns[otherPivotIndex] = oldName + ' AS ' + alias;
            }
            else
            {
                setOtherPivotColumnErrors(prev => {
                    let newErrors = [...prev];
                    newErrors[otherPivotIndex] = 'Alias can only contain letters, numbers, and underscores.';
                    return newErrors;
                });
                return;
            }
        } else {
            // column name is changed
            newOtherPivotColumns[otherPivotIndex] = newColumnName + ' AS ' + oldAlias;
        }
        
        onServiceLogDefinitionChange('serviceLogColumns.otherPivotColumns', newOtherPivotColumns);
    };

    const onAddOtherPivotColumn = (index) => {
        let newOtherPivotColumns = [...definitionData.serviceLogColumns.otherPivotColumns];
        newOtherPivotColumns.splice(index + 1, 0, '');
        onServiceLogDefinitionChange('serviceLogColumns.otherPivotColumns', newOtherPivotColumns);
    };

    const onRemoveOtherPivotColumn = index => {
        let newOtherPivotColumns = [...definitionData.serviceLogColumns.otherPivotColumns];
        newOtherPivotColumns.splice(index, 1);
        onServiceLogDefinitionChange('serviceLogColumns.otherPivotColumns', newOtherPivotColumns);
    };

    return (
        <Fragment>
            {isLoading && <div>
                <Spinner animation="border" variant="primary"/>&emsp;Loading log definitions...
            </div>}

            {!definitionData && !isLoading
            ? <Row>
                <div className="input-rows">
                    <Jumbotron className="empty-prompt-Jumbotron">
                        <p>No Log Definition Available</p>
                        {isEditable && <Button variant="primary" onClick={onCreateServiceLogDefinition}><FontAwesomeIcon icon={faPlus} />&ensp;Create New Definition</Button>}
                        {initDefinitionMessage && <SectionStatus text={initDefinitionMessage} type="error"/>}
                    </Jumbotron>
                </div>
            </Row>
            : <Fragment>
                {isEditable && <Row>
                    <div className="section-actions">
                        <SectionStatus text={sectionStatus[0]} type={sectionStatus[1]}/>
                        <ButtonGroup>
                            <Button variant="outline-primary" onClick={onCreateServiceLogDefinition}><FontAwesomeIcon icon={faBroom} />&ensp;Clear</Button>
                            <Button variant="outline-primary" onClick={onSaveServiceLogDefinition}><FontAwesomeIcon icon={faSave} />&ensp;Save</Button>
                        </ButtonGroup>
                    </div>
                </Row>}
                <Form>
                    <Row>
                        <Form.Group className="input-rows" controlId="historyVersion">
                            <Form.Label>History Version</Form.Label>
                            <EditableSelect
                                    className="editable-select"
                                    value={selectedVersionValue}
                                    options={versionDropdownList}
                                    onChange={onVersionChange}
                                    disableValueChange
                                    />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group className="input-rows" controlId="dataSource">
                            <Form.Label>Data Source{isEditable && <span className="required-star">*</span>}</Form.Label>
                            <EditableSelect
                                    className="editable-select"
                                    value={definitionData && dataSourceList ? dataSourceList.find(d => d.id === definitionData.dataSourceId)?.name || '' : ''}
                                    options={dataSourceOptionList}
                                    onChangePayload="dataSourceId"
                                    onChange={onFieldChange}
                                    disableValueChange
                                    disableSelectChange={!isEditable}
                                    isRequired
                                    />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group className="input-rows" controlId="dataSourceAccessInfo">
                            <Form.Label>{dataSourceAccessInfoLabel}{isEditable && <span className="required-star">*</span>}</Form.Label>
                            <InputGroup>
                                <FormControl type="text" aria-required="true" value={definitionData?.dataSourceAccessInfo} onChange={onDataAccessInfoChange} disabled={!isEditable} />
                                {showValidateButton()
                                && <InputGroup.Append>
                                    <Button variant="outline-primary" onClick={validateDataSource.bind(this, definitionData.dataSourceId, definitionData.dataSourceAccessInfo)}>
                                        Validate
                                    </Button>
                                </InputGroup.Append>}
                            </InputGroup>
                            {isLoadingSchema && <Form.Text><Spinner animation="border" variant="primary" size="sm" role="status" aria-atomic="true"/>&emsp;Loading schema...</Form.Text>}
                            {dataSourceInfoMessages()}
                            {!isLoadingSchema && validateDataSourceStatus === 'Success' && <SectionStatus text={validateDataSourceMessage} type="success"/>}
                            {!isLoadingSchema && validateDataSourceStatus === 'Failure' && <SectionStatus text={validateDataSourceMessage} type="error"/>}
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group className="input-rows" controlId="parser">
                            <Form.Label>Parser{isEditable && <span className="required-star">*</span>}</Form.Label>
                            <EditableSelect
                                    className="editable-select"
                                    value={definitionData && parserList ? parserList.find(p => p.id === definitionData.parserId).name : ''}
                                    options={parserOptionList}
                                    onChangePayload="parserId"
                                    onChange={onFieldChange}
                                    disableValueChange={!isEditable}
                                    disableSelectChange={!isEditable}
                                    isRequired
                                    />
                        </Form.Group>
                    </Row>
                    
                    {basicColumns.map(item => 
                        <Row key={item.dataKey}>
                            <Form.Group className="input-rows" controlId={item.dataKey}>
                                <Form.Label>
                                    {item.label}
                                    {isEditable && item.isRequired && <span className="required-star">*</span>}
                                </Form.Label>
                                <EditableSelect
                                    className="editable-select"
                                    value={definitionData ? definitionData.serviceLogColumns[item.dataKey.split('.')[1]] : ''}
                                    options={dataSourceShema}
                                    onChangePayload={item.dataKey}
                                    onChange={onFieldChange}
                                    disableValueChange={!isEditable}
                                    disableSelectChange={!isEditable}
                                    />
                            </Form.Group>
                        </Row>
                    )}
                    
                    <Row>
                        <div className="input-rows">
                            <Button variant="link" id="show-advance-link" onClick={onClickShowAdvanced}>
                                {showAdvanced ? 'Hide' : 'Show'} advanced columns&ensp;
                                {showAdvanced ? <FontAwesomeIcon icon={faChevronCircleUp}/> : <FontAwesomeIcon icon={faChevronCircleDown}/>}
                            </Button>
                        </div>
                    </Row>

                    {showAdvanced && advancedColumns.map(item => 
                        <Row key={item.dataKey}>
                            <Form.Group className="input-rows" controlId={item.dataKey}>
                                <Form.Label>
                                    [Advanced] {item.label}
                                    {isEditable && item.isRequired && <span className="required-star">*</span>}
                                </Form.Label>
                                <EditableSelect
                                    className="editable-select"
                                    value={definitionData ? definitionData.serviceLogColumns[item.dataKey.split('.')[1]] : ''}
                                    options={dataSourceShema}
                                    onChangePayload={item.dataKey}
                                    onChange={onFieldChange}
                                    disableValueChange={!isEditable}
                                    disableSelectChange={!isEditable}
                                    />
                            </Form.Group>
                        </Row>
                    )}

                    {showAdvanced && <Row>
                        <Form.Group className="input-rows" controlId="otherPivotColumn">
                            <Form.Label>[Advanced] Other Pivot Columns</Form.Label>
                            {definitionData?.serviceLogColumns.otherPivotColumns.length === 0 && 
                                <Button variant="outline-secondary" onClick={onAddOtherPivotColumn.bind(this, 0)}>
                                    <FontAwesomeIcon icon={faPlus} />&ensp;Add Other Pivot Column
                                </Button>}
                            {definitionData?.serviceLogColumns.otherPivotColumns.map((pivot, index) => {
                                let [pivotName, pivotAlias] = getOtherPivotNameAlias(pivot);
                                return <Form.Group key={index} className="other-pivot-column-row">
                                    <EditableSelect
                                        className="other-pivot-column-editable-select"
                                        value={pivotName}
                                        options={dataSourceShema}
                                        onChangePayload={pivotName}
                                        onChange={onOtherPivotColumnChange.bind(this, index)}
                                        disableValueChange={!isEditable}
                                        disableSelectChange={!isEditable}
                                        />
                                    <span style={{ paddingTop: '5px' }}>&emsp;AS&emsp;</span>
                                    <ValidationInput
                                        width="250px"
                                        name="otherpivot"
                                        value={pivotAlias}
                                        onChange={e => onOtherPivotColumnChange(index, pivotName, pivotName, 0, e.target.value)} 
                                        disabled={!isEditable}
                                        error={otherPivotColumnErrors[index]}
                                        />
                                    &emsp;
                                    <Button variant="outline-secondary" onClick={onAddOtherPivotColumn.bind(this, index)}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                    &emsp;
                                    <Button variant="outline-secondary" onClick={onRemoveOtherPivotColumn.bind(this, index)}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                </Form.Group>
                            })}
                        </Form.Group>
                    </Row>}

                    {showAdvanced && <Row>
                        <Form.Group className="input-rows" controlId="filterClause">
                            <Form.Label>[Advanced] Filter Clause&ensp;
                                <RenderInformativeTooltip field="filterClause" placement="right" message="Please only change this when you are using a customized parser." />
                            </Form.Label>
                            <FormControl type="text" value={definitionData?.filterClause} onChange={onFilterClauseChange} disabled={!isEditable}/>
                        </Form.Group>
                    </Row>}

                    {showAdvanced && <Row>
                        <Form.Group className="input-rows" controlId="logSizeInGB">
                            <Form.Label>[Advanced] Log Size In GB&ensp;
                                <RenderInformativeTooltip field="logSizeInGB" placement="right" 
                                    message="Please enter an estimated daily log size in GB. This will help the pipeline allocate an appropriate number of tokens and run more efficiently. Please leave it 0 if the estimated size is less than 100 GB." />
                            </Form.Label>
                            <FormControl type="number" min="0" step="1" value={definitionData?.logSizeInGB} onChange={onLogSizeChange} disabled={!isEditable}/>
                        </Form.Group>
                    </Row>}

                    {showAdvanced && <Row>
                        <Form.Group className="input-rows" controlId="s2sJoinSide">
                            <Form.Label>[Advanced] S2S Join Side</Form.Label>
                            <EditableSelect
                                    className="editable-select"
                                    value={definitionData ? definitionData.joinSide.value : ''}
                                    options={['NotApplicable', 'Incoming', 'Outgoing']}
                                    onChangePayload="joinSide"
                                    onChange={onFieldChange}
                                    disableValueChange
                                    disableSelectChange={!isEditable}
                                    />
                        </Form.Group>
                    </Row>}
                </Form>
            </Fragment>}
        </Fragment>
    );
}

export default ServiceLogDefinition;