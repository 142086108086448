import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faDownload, faFileCsv, faPeopleCarry, faPeopleArrows, faSnowman, faClipboardCheck } from '@fortawesome/free-solid-svg-icons'
import AddNewCsvModal from './AddNewCsvModal'
import UploadCSVModal from './UploadCSVModal'
import CloneCscModal from './CloneCsvModal'
import MessageModal from './MessageModal'
import OwnersModal from './OwnersModal'
import AddAccessModal from './AddAccessModal'
import CubeCsvTable from './CubeCsvTable'
import CubeCSVDataService from '../../Dataservices/CubeCSVDataService'
import { LoadingOverlay } from '../PublicComponents/HelperComponents';
import './CubeCsvComponents.css'
import { UploadType } from '../../Functions/CubeCSVManagement/CubeCSVConst'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faPaperPlane, faDownload, faFileCsv, faPeopleCarry, faPeopleArrows, faSnowman, faClipboardCheck);

class CubeCsvManagement extends Component {

    constructor(props) {
        super(props)

        this.tableList = []

        this.tableOrder = {'column': 3, 'role' : 2, 'log' : 1}

        this.state = {

            // Select component
            csvList: [],

            // CSV component
            // Cube properties
            cubeId: -1,
            cubeName: "",
            isFlat: false,
            isDraft: true,
            isDeleted: false,
            owners: [],
            updateDate: null,
            cubeType: 'Unknown',
            cubeArchitecture: 'Unknown',

            tableList: [], // {CSVName: '', Id: 0, Content: '', Comments: [], Type: ''}

            // Show modal
            showAddCsvModal: false,
            showMessageModal: false,
            showUploadCsvModal: false,
            showCloneCsvModal: false,
            showOwnersModal: false,
            showAddAccessModal: false,
            firstPage: true,
            Alertvisible: false,
            loading: false,

            // Message modal
            validateErrorMsg: '',

            // Download modal
            fileDownloadUrl: '',
            
            // Page properties
            dataUpdateTime: new Date().getTime(),
        }
    }

    componentDidMount() {
        this.updateSelectList()
    }

    selectCSV = (item) => {
        this.setState({
            loading: true,
            firstPage: false,
        })

        CubeCSVDataService.selectItem(item.value).then(res => {
            const CSVData = res.data

            const ownerlist = CSVData.CubeCSVInfo.Owner.split(";")
            const updateDate = new Date(CSVData.CubeCSVInfo.UpdateDate)
            
            const sortedTable = CSVData.TableList.sort((a, b) => {
                const aorder = this.tableOrder[a.Type]?this.tableOrder[a.Type]:0
                const border = this.tableOrder[b.Type]?this.tableOrder[b.Type]:0
                return border-aorder
            })

            this.tableList = sortedTable.map(()=>(null))

            this.setState({
                // Cube properties
                cubeId: item.value,
                cubeName: CSVData.CubeCSVInfo.CubeName,
                cubeType: CSVData.CubeCSVInfo.CubeType,
                cubeArchitecture: CSVData.CubeCSVInfo.CubeArchitecture,
                isDeleted: CSVData.CubeCSVInfo.IsDeleted,
                isDraft: CSVData.CubeCSVInfo.IsDraft,
                isFlat: CSVData.CubeCSVInfo.IsFlat,
                owners: ownerlist,
                updateDate: updateDate.getFullYear() + '-' + (updateDate.getMonth() + 1) + '-' + updateDate.getDate(),

                tableList: sortedTable,

                // Show setting
                firstPage: false,
                loading: false,
                showCSVModal: true,
                dataUpdateTime: new Date().getTime(),
            })
        })
    }

    //#region Toggling
    // Showmodal setting function.
    toggleAddNewCsvModal = () => { this.setState(prevState => { return { showAddCsvModal: !prevState.showAddCsvModal } }) }

    toggleUploadNewCsvModal = () => { this.setState(prevState => { return { showUploadCsvModal: !prevState.showUploadCsvModal } }) }

    toggleOwnersModal = () => { this.setState(prevState => { return { showOwnersModal: !prevState.showOwnersModal } }) }

    toggleMessageModal = () => { this.setState(prevState => { return { showMessageModal: !prevState.showMessageModal } }) }

    toggleCloneCsvModal = () => { this.setState(prevState => { return { showCloneCsvModal: !prevState.showCloneCsvModal } }) }

    toggleAddAccessModal = () => { this.setState(prevState => { return { showAddAccessModal: !prevState.showAddAccessModal } }) }
    //#endregion

    showMessage = (title, message) => {
        this.MessageModal.setMessage(title, message)
        this.toggleMessageModal()
    }

    //#region csv creation
    // Add new CSV.
    addNewCsv = (cubeName, newTableList, isFlat, owners, cubeType, cubeArchitecture) => {
        const ownerlist = owners.split(";")
        const tableList = newTableList.map(table => ({Id: -1, CSVName: table.fileName, Content: table.file, Comments: [], Type: table.type}))

        this.setState({
            // Cube properties
            cubeId: -1, 
            cubeName: cubeName,
            isFlat: isFlat,
            isDraft: true,
            owners: ownerlist, 

            // CSV properties
            tableList: tableList,

            // Showmodal setting
            firstPage: false,
            showCSVModal: true,
            dataUpdateTime: new Date().getTime(),
        })
    }

    // Upload new CSV
    uploadCSV = (cubeName, fileList, isFlat, owners, uploadType, cubeType, cubeArchitecture, show) => {
        const { loginUser } = this.props
        let request = {}
        request.CubeId = -1
        request.Name = cubeName
        request.IsFlat = isFlat 
        request.IsDeleted = false
        request.IsDraft = true
        request.UploadType = uploadType
        request.CubeType = cubeType
        request.CubeArchitecture = cubeArchitecture
        request.EditUser = loginUser.userName
        request.Owner = owners
        if(show) {
            this.setState({
                loading: true,
                firstPage: false,
            })
        }
        CubeCSVDataService.createCubeCSV(request).then(res =>{
            const cubeId = res.data
            const tableList = fileList.map(file => ({Id: -1, CSVName: this.csvFileName(cubeName, file.type), Content: file.file, Comments: [], Type: file.type}))
            this.setState({
                cubeId: cubeId,
                tableList: tableList
            })
            tableList.map((table, index) => {
                this.createOrSaveCsv(cubeId, table.Id, table.CSVName, table.Content, [], table.Type, index)
            })
            this.updateSelectList()
            this.showMessage("Save", "Finish upload and save.")
            if(show) {
                const ownerlist = owners.split(";")
                this.setState({
                    // Cube properties
                    cubeId: cubeId, 
                    cubeName: cubeName,
                    isFlat: isFlat,
                    isDraft: true,
                    owners: ownerlist, 
        
                    // Showmodal setting
                    firstPage: false,
                    loading: false,
                    showCSVModal: true,
                    dataUpdateTime: new Date().getTime(),
                })
            }
        })
    }

    csvFileName = (cubeName, type) => {
        const name = type.length>0?cubeName + type[0].toUpperCase() + type.slice(1) + "Table":cubeName + "Table";
        return name
    }

    cloneCsv = (cubeName, owners) => {
        const ownerlist = owners.split(";")
        const { tableList } = this.state
        const newList = tableList.map(table => {
            return {
                ...table,
                Id: -1, 
                CSVName: this.csvFileName(cubeName, table.Type),
            }
        })

        this.setState({
            // Cube properties
            cubeId: -1, 
            cubeName: cubeName,
            isDraft: true,
            owners: ownerlist, 
            // CSV properties
            tableList: newList,
            // Showmodal setting
            firstPage: false,
            showCSVModal: true,
            dataUpdateTime: new Date().getTime(),
        })
    }
    //#endregion

    //#region help function
    validateCubeName = (cubeName, isFlat) => {
        for(let i = 0; i < (this.cubeList?this.cubeList.length:0); i++) {
            if(cubeName === this.cubeList[i].CubeName && isFlat === this.cubeList[i].IsFlat) {
                return "Cube name exists."
            }
        }
        return ""
    }

    changeOwners = (owners) => {
        this.ownerlist = owners.split(";")
    }

    updateSelectList = () => {
        CubeCSVDataService.getList().then(res => {
            const CSVData = res.data
            this.cubeList = CSVData

            this.setState({
                csvList: CSVData.map(item => {
                    let label = item.CubeName
                    if(item.IsFlat) {
                        label = label + '(Flat)'
                    }else {
                        label = label + '(not Flat)'
                    }
                    if(item.IsDraft) {
                        label = label + '(Draft)'
                    }

                    return {
                        label: label,
                        value: item.Id,
                    }
                })
            })
        })
    }

    // Access control.
    isOwner = () => {
        const {loginUser} = this.props
        const {owners} = this.state
        if(loginUser.isAdmin || owners.includes(loginUser.userName)) {
            return true
        }
        return false
    }

    // Save as draft.
    onSaveAsDraft = () => {
        const {cubeType, cubeArchitecture} = this.state
        if(!this.isOwner()) {
            this.showMessage("Error", "Have no access to this cube csv.")
            return
        }
        this.createOrSaveCube(true, UploadType.Cube, cubeType, cubeArchitecture)
    }

    // Save and validate
    onSaveAndValidate = () => {
        if(!this.isOwner()) {
            this.showMessage("Error", "Have no access to this cube csv.")
            return
        }
        let error = false
        this.tableList.map((table) => {
            if(table.getError()) error = true
        })
        if(error) {
            this.setState({ Alertvisible: true }, 
                ()=> {window.setTimeout(()=>{this.setState({Alertvisible:false})},8000) });
            return;
        }
        this.showMessage("Validation", "Finish validation and find no error!")
    }

    // Save and download.
    onSaveAndDownload = () => {
        const {cubeType, cubeArchitecture} = this.state
        if(!this.isOwner()) {
            this.showMessage("Error", "Have no access to this cube csv.")
            return
        }
        let error = false
        this.tableList.map((table) => {
            if(table.getError()) error = true
        })
        if(error) {
            this.setState({ Alertvisible: true }, 
                ()=> {window.setTimeout(()=>{this.setState({Alertvisible:false})},8000) });
            return;
        }
        this.createOrSaveCube(false, UploadType.Cube, cubeType, cubeArchitecture)
        this.showMessage("Save", "Finish save and start to download")

        this.tableList.map((table) => {
            const csvfile = table.getContent()
            this.downloadCSV(csvfile.title, csvfile.data)
        })
    }

    // Download csv.
    downloadCSV = (title, content) => {
        const blob = new Blob([content]);
        const fileDownloadUrl = URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = fileDownloadUrl;
        a.download = title+'.csv';
        a.click();
    }

    // Create or save a cube.
    createOrSaveCube = (isDraft, uploadType, cubeType, cubeArchitecture) => {
        const { cubeId, tableList, owners} = this.state
        const update = (cubeId == -1)
        const ownerlist = owners.join(";")
        let createCube = this.createOrSaveCubeState(isDraft, ownerlist, uploadType, cubeType, cubeArchitecture)
        createCube.then(res =>{
            const cubeId = res.data
            this.setState({
                cubeId: cubeId
            })
            this.tableList.map((table, index) => {
                    const id = tableList[index].Id
                    const name = tableList[index].CSVName
                    const content = table.getContent().data
                    const comment = table.getComment()
                    const type = tableList[index].Type
                    this.createOrSaveCsv(cubeId, id, name, content, comment, type, index)
            })
            if(update) this.updateSelectList()
        })
    }

    // Create or save a cube state. 
    createOrSaveCubeState = (isDraft, owners, uploadType, cubeType, cubeArchitecture) => {
        const { loginUser } = this.props
        const {cubeId, cubeName, isFlat} = this.state;
        let request = {};
        request.CubeId = cubeId;
        request.Name = cubeName;
        request.IsFlat = isFlat;
        request.IsDeleted = false;
        request.IsDraft = isDraft;
        request.Owner = owners;
        request.UploadType = uploadType;
        request.CubeType = cubeType;
        request.CubeArchitecture = cubeArchitecture;
        request.EditUser = loginUser.userName;
        return CubeCSVDataService.createCubeCSV(request)
    }

    // Create or save a csv state.
    createOrSaveCsv = (cubeId, csvId, csvTitle, csvData, csvComment, type, index) => {
        let request = {};
        request.CubeId = cubeId;
        request.Id = csvId;
        request.Name = csvTitle;
        request.Content = csvData;
        request.IsDelete = false;
        request.Comments = csvComment;
        request.Type = type;
        CubeCSVDataService.saveCSV(request).then(res =>{
            let { tableList } = this.state
            tableList[index].Id = res.data
            this.setState({ tableList: tableList })
        });
    }
    //#endregion

    //#region click function
    onChangeOwners = () => {
        if(!this.isOwner()) {
            this.showMessage("Error", "Have no access to this cube csv.")
            return
        }
        this.setState({ showOwnersModal: true })
    }

    onAddAccess = (users) => {
        const {loginUser} = this.props
        if(!loginUser.isAdmin) {
            this.showMessage("Error", "Admin Access Only")
            return
        } 
        let request = {};
        request.users = users;
        request.role = "CSVBuilder";
        CubeCSVDataService.addRole(request).then(res => {
            this.showMessage("Access granted")
        });
       
    }

    // Clone a cube csv as new cube csv.
    onSaveAsNewDraftCSV = () => { this.setState({ showCloneCsvModal: true }) }

    // Delete all file of a cube. (soft delete)
    onDeleteCube = () => {
        if(!this.isOwner()) {
            this.showMessage("Error", "Have no access to this cube csv.")
            return
        }

        let deleteCubeRequest = {};
        deleteCubeRequest.Id = this.state.cubeId;
        CubeCSVDataService.deleteCube(deleteCubeRequest).then(res => {
            this.showMessage("Successfully deleted", res.data)
            this.setState({
                firstPage: true,
                tableList: [],
                cubeName: "",
                cubeId: -1,
            })
            this.updateSelectList()
        })
    }
    //#endregion

    render() {
        const { cubeName, cubeId, isDraft, isFlat, updateDate, owners, cubeType, cubeArchitecture, // Cube properties 
                tableList,
                showAddCsvModal, showUploadCsvModal, showMessageModal, showCloneCsvModal, showOwnersModal, showAddAccessModal,firstPage, Alertvisible, loading, // Show modal setting
                validateErrorMsg, csvList, // Shown data
                dataUpdateTime, // Page setting
             } = this.state
        const {loginUser} = this.props

        return (
            <Container fluid className="cubeCsv">
                {<Alert show={Alertvisible} toggle={(e) => this.setState({Alertvisible: false})} variant='danger'>{"Please fix all errors before downloading."}</Alert>}
                <hr/>
                <Row className="cubeCsvHeader">
                    <Col className="cubeCsvHeaderItem">
                        <Select placeholder="Select CSV" className="search" value={cubeName? {value: cubeId, label: cubeName+(isDraft?"(Draft)":"")}: null} options={csvList} onChange={this.selectCSV.bind(this)}/>
                        <Button onClick={this.toggleAddNewCsvModal}>+ CSV</Button>
                        <Button variant="secondary" onClick={this.toggleUploadNewCsvModal}>Upload</Button>
                    </Col>
                    <React.Fragment>
                    {firstPage?null:
                    <Col className="cubeCsvSaveBtns">
                        <Button onClick={this.onDeleteCube} key="delete-button" variant="outline-danger" id="button-deletefile" >Delete&ensp;</Button>
                        <Button onClick={this.onSaveAndDownload} key="download-button" >Download&ensp;<FontAwesomeIcon icon={faDownload} /></Button>
                        <Button onClick={this.onSaveAndValidate} key="validate-button" variant="outline-primary">Validate&ensp;<FontAwesomeIcon icon={faClipboardCheck} /></Button>
                        <Button onClick={this.onSaveAsDraft} key="draft-button" variant="outline-primary">Draft&ensp;<FontAwesomeIcon icon={faPaperPlane} /></Button>
                        <Button onClick={this.onSaveAsNewDraftCSV} key="clone-button" variant="outline-secondary">Clone&ensp;<FontAwesomeIcon icon={faFileCsv} /></Button>
                        <Button onClick={this.onChangeOwners} key="owner-button" variant="outline-secondary">Owners&ensp;<FontAwesomeIcon icon={faSnowman} /></Button>
                        <span>{cubeType + ' | ' + cubeArchitecture}</span> 
                        <Form.Check type="checkbox" label="IsFlat" checked={isFlat} disabled/>
                        <span>{updateDate? 'Last edited at ' + updateDate: ''}</span> 
                    </Col>
                    }
                    </React.Fragment>
                </Row>

                <UploadCSVModal showModal={showUploadCsvModal} toggleModal={this.toggleUploadNewCsvModal} onCreateCsv={this.uploadCSV} loginUser={loginUser} validateCube={this.validateCubeName}/>

                <AddNewCsvModal showModal={showAddCsvModal} toggleModal={this.toggleAddNewCsvModal} onCreateCsv={this.addNewCsv} loginUser={loginUser} validateCube={this.validateCubeName}/>

                <CloneCscModal showModal={showCloneCsvModal} toggleModal={this.toggleCloneCsvModal} onCreateCsv={this.cloneCsv} loginUser={loginUser} validateCube={this.validateCubeName} isFlat={isFlat}/>

                <OwnersModal showModal={showOwnersModal} toggleModal={this.toggleOwnersModal} change={this.changeOwners} owners={owners} dataUpdateTime={dataUpdateTime}/>

                <MessageModal ref={(ref) => this.MessageModal = ref} showModal={showMessageModal} errorMsg={validateErrorMsg} toggleModal={this.toggleMessageModal} />

                <AddAccessModal showModal={showAddAccessModal} toggleModal={this.toggleAddAccessModal} onSubmit={this.onAddAccess}/>

                <React.Fragment>
                {loading? 
                <LoadingOverlay/>
                : firstPage ?
                <Row className="welcomeRow">
                    <Col>
                        <div className="welcomeBox">
                            <h3>Set up cubes!</h3>
                            <u>
                                <li>Design document : <a className="accessibleLink" href="https://microsoft-my.sharepoint.com/:w:/p/qinqixu/EUnIYn4UyfdIoiAaB_30Si0BgRLfiWLS3hnGtTn7aWpY0g?e=LnqpTE"> CSV Management</a></li>
                                <li>User guide: <a className="accessibleLink" href="https://microsoftapc-my.sharepoint.com/:w:/g/personal/fangyaxu_microsoft_com/EWrydrJ26FtLvMQHrI0_MjABC_VUeT6DSdiCxNXrblTSSw"> CSV Management user guide </a></li>
                                <li>Need more, add here:<a className="accessibleLink" href="https://microsoft-my.sharepoint.com/:w:/p/qinqixu/EYlGlInFsqBDhsBfzbvVLKEByxBMhkPSbRcj4bFN3b-NRQ?e=4M8cse"> New requests </a> </li>
                                <li>Grant access to users, click here:  <Button onClick={this.toggleAddAccessModal}>Add Users</Button> </li>
                            </u>
                        </div>
                    </Col>
                </Row>
                : 
                <div>
                {tableList.map((table, index) => (
                    <div>
                        <hr/>
                        <CubeCsvTable ref={(ref) => this.tableList[index] = ref} key={`${table.Type}-${index}`} title={table.CSVName} cubeId = {cubeId} csvId={table.Id} data={table.Content} type={table.Type}
                            isFlat={isFlat} dataUpdateTime={dataUpdateTime} loginUser={loginUser} comment={table.Comments}/>
                    </div>
                ))}
                </div>
                }
                </React.Fragment>
            </Container>
        )
    }
}

export default CubeCsvManagement