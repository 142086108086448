import DataService from './DataService'

const PerfPanelStatsService = {
    getUsage: payload => {
        return DataService.post('PerfPanelStats/usage', payload)
    },

    getQuality: payload => {
        return DataService.post('PerfPanelStats/quality', payload)
    },

    getQualityOverall: (endDate) => {
        return DataService.get('PerfPanelStats/qualityOverall?endDate=' + endDate)
    },

    getException: payload => {
        return DataService.post('PerfPanelStats/exception', payload)
    },

    getInteractionTime:payload =>{
        return DataService.post('PerfPanelStats/interactionTime', payload)
    }
}

export default PerfPanelStatsService