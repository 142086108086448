import { getTimezoneFromShortName, formatUTCDateAsString } from './DateTimeHelper'

export function getCubeFromURL (url) {
    const regExpAngular = /(?<=[&?]app=)([\w]+)(?=&)/;
    const regExpReact = /(?<=\/Analysis\/)([\w]+)(?=\?)/;
    const regExpEvo = /(?<=\/Evo\/)\d+\/\d+(?=\?)/;
    const regExpReactWithoutAnalysis = /(?<=\/)([\w]+)(?=\?)/;
    const matchGroupsAngular = url.match(regExpAngular);
    const matchGroupsReact = url.match(regExpReact);
    const matchGroupsEvo = url.match(regExpEvo);
    const matchGroupsReactWithoutAnalysis = url.match(regExpReactWithoutAnalysis);
    const match = matchGroupsReact || matchGroupsEvo || matchGroupsReactWithoutAnalysis || matchGroupsAngular;

    if (match) {
        return match['0'];
    } else {
        return null;
    }
}

export function getIsEvo (url) {
    const regExpEvo = /(?<=\/Evo\/)\d+_\d+(?=\?)/;
    const match = url.match(regExpEvo);

    if(match) {
        return true;
    } else {
        return false;
    }
}

export function getIsHourly (url, cubes) {
    const cubeName = getCubeFromURL(url);
    const cube = cubes.filter(cube => cube.AppName && cubeName && cube.AppName.toLowerCase() === cubeName.toLowerCase())[0];
    if (cube){
        return cube.IsHourly;
    }
    return false;
}

export function getIsDeprecated (url, cubes, evoServices) {
    const isEvo = getIsEvo(url);
    if(isEvo) {
        const [serviceId, serviceLogId] = getEvoServiceAndLogIdFromUrl(url);

        let isDeprecated = true;
        evoServices.forEach(s => {
            if (s.serviceId === serviceId && s.status === 'Active') {
                s.serviceLogs.forEach(l => {
                    if (l.logId === serviceLogId && l.status === 'Active') {
                        isDeprecated = false;
                        return;
                    }
                });

                if (!isDeprecated) {
                    return;
                }
            }
        });
        
        return isDeprecated;
    } else {
        const cubeName = getCubeFromURL(url);
        const cube = cubes.filter(cube => cube.AppName && cubeName && cube.AppName.toLowerCase() === cubeName.toLowerCase())[0];
        if (cube){
            return cube.CubeStatusCode === 3; // cube status code is saved in database, 3 is deprecated
        }
        return true; // if cube is not found, assume it is deprecated
    }
}

export function getTabFromUrl (url) {
    var searchParams = new URLSearchParams(url.slice(url.indexOf("?")));
    return searchParams.get("tab");
}

export function getTimezoneFromUrl (url) {
    var timezone = (new URLSearchParams(url)).get("tz");
    return timezone ? getTimezoneFromShortName(timezone) : "UTC";
}

export function getChartTypeFromIndex (chartTypeIndex) {
    const chartTypeList = {
        "0" : "Line", 
        "1" : "StackedArea",
        "2" : "100PercentArea"
    }
    return chartTypeList[chartTypeIndex]
}

export function getChartTypeIndex (chartType) {
    const chartTypeIndex = {
        "Line" : "0",
        "StackedArea" : "1",
        "100PercentArea" : "2"
    }
    return chartTypeIndex[chartType]
}

export function getChartTypeFromUrl (url) {
    let chartType = (new URLSearchParams(url)).get("type");
    return chartType ? chartType : null;
}

export function getMetricsFromUrl (url){
    let metrics = (new URLSearchParams(url)).get("metric");
    return metrics ? metrics.split(',') : null;
}

export function getPercentilesFromUrl (url) {
    let pct = (new URLSearchParams(url)).get("pct");
    if (pct) {
        pct = pct.replaceAll(' ', '');
        const percentiles = pct.split(',');
        return percentiles;
    } else {
        return [];
    }
}

export function getIncludeWeekendsFromUrl (url) {
    var w = (new URLSearchParams(url)).get("w");
    return w ? w.toLowerCase() : "true"; 
}

export function getGoalLineFromUrl (goalline) {
    if (goalline) {
        const axisMap = {
            l: 'Latency',
            c: 'Count',
            r: 'Ratio'
        }

        const chartLineTypeMap = {
            s: 'Solid',
            da: 'Dash',
            do: 'Dot',
            dao: 'DashDot'
        }

        return goalline.split('|').map( goalLine => {
            const goals = goalLine.split('-')
            return {
                Axis: axisMap[goals[0]],
                Color: "#" + goals[2],
                Goal: parseFloat(goals[1]),
                IsGoalLineEnabled: true,
                LineType: chartLineTypeMap[goals[3]]
            }
        })

    } else {
        return []
    }
}

export function getNameFromAnalysisUrl (url) {
    return getIdFromUrl(url, "Analysis", '\\w_');
}

export function getNameFromCosmicUrl (url) {
    return getIdFromUrl(url, "Cosmic", '\\w_');
}

export function getEvoServiceAndLogNameFromUrl (url, evoServices) {
    let [serviceId, serviceLogId] = getEvoServiceAndLogIdFromUrl(url);

    let serviceName = null;
    let logName = null;

    evoServices.forEach(s => {
        if (s.serviceId === serviceId) {
            serviceName = s.serviceName;
            s.serviceLogs.forEach(l => {
                if (l.logId === serviceLogId) {
                    logName = l.logName;
                    return;
                }
            });
        }
    });

    return [serviceName, logName];
}

export function getEvoServiceAndLogIdFromUrl (url) {
    let serviceAndLogId = getIdFromUrl(url, "Evo", '\\d+_\\d+');

    let [serviceId, serviceLogId] = [-1, -1];
    if (serviceAndLogId) {
        [serviceId, serviceLogId] = serviceAndLogId.split('_');
    }

    return [parseInt(serviceId), parseInt(serviceLogId)];
}

export function getIdFromDashboardUrl (url) {
    return parseInt(getIdFromUrl(url, "Dashboard", '\\d'));
}

export function getIdFromMSRUrl (msrVersion, url) {
    return msrVersion === 1 ? parseInt(getIdFromUrl(url, "msrsv1",'\\d')) : parseInt(getIdFromUrl(url, "msrsv2",'\\d'));
}

export function getIdFromUrl(url, type, allowedChars) {
    let regex = new RegExp(`/${type}/([${allowedChars}]+)`);
    let idMatches = url.match(regex);
    if (idMatches) {
        return idMatches[1];
    }
    else {
        return null;
    }
}

export function setGraphItToTrue(url) {
    let params = (new URLSearchParams(url.slice(url.indexOf("?"))));
    params.set("graphIt", "true");
    return url.slice(0, url.indexOf('?') + 1) + params.toString();
}

export function getStandardizedUrl(url) {
    let params = (new URLSearchParams(url.slice(url.indexOf("?"))));
    let app = params.get("app");
    if (app) {
        params.delete("app");
    }
    const isEvo = getIsEvo(url);
    url = window.location.origin + (isEvo?"/Evo/":"/Analysis/") + getCubeFromURL(url) + '?' + params.toString();
    return url;
}

export function getStartDateFromUrl(url) {
    return (new URLSearchParams(url)).get("sd");
}

export function getEndDateFromUrl(url) {
    return (new URLSearchParams(url)).get("ed");
}

export function getAnalyzeDateFromUrl(url) {
    return (new URLSearchParams(url)).get("xdla");
}

export function replaceDate (url, urlParam, date, isHourly) {
    var dateStr = formatUTCDateAsString(date, isHourly);
    var param = urlParam + '=';
    var startIndex = url.indexOf(param);

    var newUrl;

    if(startIndex > -1) {
        newUrl = url.substring(0, startIndex + param.length) + dateStr;
        var endIndex = url.indexOf("&", startIndex + 1);

        if(endIndex > -1) {
            newUrl += url.substring(endIndex, url.length);
        }
        } else {
            newUrl = url + '&' + param + dateStr;
        }
     return newUrl;
};

export function replaceGraphIt (url) {
    var param = 'graphIt=';
    var startIndex = url.indexOf(param);

    var newUrl;

    if(startIndex > -1) {
        newUrl = url.substring(0, startIndex + param.length) + 'true';
        var endIndex = url.indexOf("&", startIndex + 1);

        if(endIndex > -1) {
            newUrl += url.substring(endIndex, url.length);
        }
    } else {
        newUrl = url + '&' + param + 'true';
    }

    return newUrl;
};

export function updateGoalLineInUrl(url, goallines) {
    let params = (new URLSearchParams(url.slice(url.indexOf("?"))));
    let goalLine = params.get("ydl");

    if (goalLine) {
        params.delete("ydl")
    }

    const axisMap = {
        Latency: 'l',
        Count: 'c',
        Ratio: 'r'
    }

    const chartLineTypeMap = {
        Solid: 's',
        Dash: 'da',
        Dot: 'do',
        DashDot: 'dao'
    }

    const goalLineValue = goallines.filter(x => (x.GoalLine.IsAlertEnabled || x.GoalLine.IsGoalLineEnabled)).map((goalline) => {
        const goalLine = goalline.GoalLine
        return axisMap[goalLine.Axis] + '-' + goalLine.Goal + '-' + goalLine.Color.substring(1) + '-' + chartLineTypeMap[goalLine.LineType]
    }).join("|")

    if(goalLineValue) params.set('ydl', goalLineValue)
    return url.slice(0, url.indexOf('?') + 1) + params.toString();
}