import React from 'react'
import uuid from 'react-uuid'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'


 function DataTableModal(props){

    return <Modal show={props.show} onHide={props.onHide} className="dataTable">
                <Modal.Body>
                    <div className="dataTableWrap">
                        <Table size="sm" hover bordered>
                            <thead>
                                <tr>
                                    <th className="cell">{props.xAxis}</th>
                                    {props.legends.map(legend => legend !== 'Goal line' ? <th key={uuid()} className="cell">{legend}</th> : null)}
                                </tr>
                            </thead>
                            <tbody>
                                {props.dataTable.map(row => <tr key={uuid()}>
                                    {row.map(cell => <td key={uuid()} className="cell">{cell}</td>)}
                                </tr>)}
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
            </Modal>
}

export default React.memo(DataTableModal);