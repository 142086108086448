import React, {Component} from 'react'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Alert from 'react-bootstrap/Alert'
import DataService from '../../Dataservices/DataService'
import { ScenarioTags } from '../../Dataservices/ChartService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faShareSquare, faArrowsAlt, faChevronUp, faChevronDown, faEdit } from '@fortawesome/free-solid-svg-icons'
import MSREditScenarioModal from './MSREditScenarioModal'
import MSRMoveScenarioModal from './MSRMoveScenarioModal'
import './MSR.css'
import { replaceSingleParamforUrl } from '../../Functions/MSRHelper'
import { getCubeFromURL } from '../../Functions/UrlHelper'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faSync, faShareSquare, faArrowsAlt, faChevronUp, faChevronDown, faEdit);

class MSRScenarioV1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: props.startDate,
            endDate: props.endDate,
            isLoading: true,
            cubeName: '',
            showEditScenarioModal: false,
            showMoveScenarioModal: false,
            goalPercents: props.Scenario.GoalPercent,
            includeWeekend: true,
            error: null
        }

        this.data = {
            ScenarioData: {},
            ResponseRawData: {}
        }
    }
    
    componentDidMount() {
        this.getScenario()
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(prevState.startDate !== nextProps.startDate || prevState.endDate !== nextProps.endDate) {
            return {
                startDate: nextProps.startDate,
                endDate: nextProps.endDate
            }
        }
        return null
    }

    componentDidUpdate(prevProps) {
        if (this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate) {
            this.getScenario()
        }
    }

    convertUrlIntoRequestObjects = (Scenario, startDate, endDate) => {
        const appName = getCubeFromURL(Scenario.url);        
        if (appName) {
            this.setState({cubeName: appName});
            return {
                endpointUrl: appName + '/dataQueryOverrides',
                obj: {
                    Url: replaceSingleParamforUrl(Scenario.url, "w", "true"),
                    StartDate: startDate,
                    EndDate: endDate
                }
            }
        }
        else
        {
            console.log(Scenario.url);
            return {};
        }
    };

    transformSingleDailyHistogramData = (data, datelen, GoalPercent) => {
        const {currdatelist} = this.props
        let MSRdata = {}
        let retData = Array(datelen).fill("Empty Data")
        

        var firstData = data == null ? null : data[Object.keys(data)[0]];
        var len = firstData == null ? 0 : firstData.length;
        for(var k = 0;k < len;++ k){
            MSRdata[firstData[k].name] = [0,0];
        }

        var dataKeys = Object.keys(data);
        for (var j = 0; j < dataKeys.length; j ++)
        {
            var currName = dataKeys[j]
            var series = data[currName]

            for (var i = 0; i < series.length; i++)
            {
                MSRdata[firstData[i].name][0] += series[i].y
                if(parseFloat(currName.substring(currName.lastIndexOf('|') + 1)) <= GoalPercent) MSRdata[firstData[i].name][1] += series[i].y
            }
        }

        for (var m = 0;m < datelen;++ m) 
        {
            const currDate = currdatelist[m].toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })
            if (MSRdata[currDate]){
                retData[m] = (MSRdata[currDate][1] * 100 / MSRdata[currDate][0]).toFixed(4)
            }
        }

        return retData;
    }

    getAvgDailySample = (data) => {
        const {Scenario, datelen, currdatelist, includeWeekend} = this.props
        const showWeekends = includeWeekend != null ? includeWeekend : !Scenario.url.includes("w=false")

        let RawDailySampleData = {}

        for (var i = 0;i < data.length;++ i)
        {
            RawDailySampleData[data[i].name] = data[i].y;
        }

        let sumOfSample = 0
        let sumOfWeekDay = 0

        for (var m = 0;m < datelen;++ m) 
        {
            if(showWeekends || (currdatelist[m].getDay() !== 6 && currdatelist[m].getDay() !== 0)){
                const currDate = currdatelist[m].toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })
                if (RawDailySampleData[currDate]){
                    sumOfSample += RawDailySampleData[currDate]
                    sumOfWeekDay += 1
                }
            }
        }

        const currAvgDailySample = sumOfWeekDay === 0 ? 0 : parseInt(sumOfSample / sumOfWeekDay)
        let retAvgDailySample = currAvgDailySample

        if (currAvgDailySample > 1000 && currAvgDailySample <= 1000000)
        {
            retAvgDailySample = (currAvgDailySample  / 1000).toFixed(2) + 'K'
        }
        else if (currAvgDailySample > 1000000 && currAvgDailySample <= 1000000000)
        {
            retAvgDailySample = (currAvgDailySample  / 1000000).toFixed(2) + 'M'
        }
        else if (currAvgDailySample > 1000000000)
        {
            retAvgDailySample = (currAvgDailySample  / 1000000000).toFixed(2) + 'B'
        }

        return retAvgDailySample 
    }

    transformDailyHistogramDataToMSR = (LatencyDataSeries, SampleCountDataSeries, datelen, GoalPercent) => {
        const{Scenario, updateScenarioLengthList} = this.props
        let mappedData = {}
        let mappedSampleData = {}
        let retData = {}
        for (var j = 0; j < LatencyDataSeries.length; j ++)
        {
            var series = LatencyDataSeries[j].Data;
            var indexOfLastVerticalline = LatencyDataSeries[j].Name.lastIndexOf('|')
            var Legend = LatencyDataSeries[j].Name.substring(0, indexOfLastVerticalline)
            mappedData[Legend] = {...mappedData[Legend], [LatencyDataSeries[j].Name]: series}
        }

        for (var j = 0;j < SampleCountDataSeries.length; j ++)
        {
            var series = SampleCountDataSeries[j].Data;
            var Legend = SampleCountDataSeries[j].Name
            mappedSampleData[Legend] = series
        }

        updateScenarioLengthList(Scenario.order, Object.keys(mappedData).length)
        
        let Legends = Object.keys(mappedData);
        for(var i = 0; i < Legends.length; i ++)
        {
            let currPercent = this.transformSingleDailyHistogramData(mappedData[Legends[i]], datelen, GoalPercent)
            let avgDailySamples = mappedSampleData[Legends[i]]
            retData[Legends[i]] = {...retData[Legends[i]], currPercent}
            retData[Legends[i]] = {...retData[Legends[i]], avgDailySamples}
        }
        
        return retData;
    }

    getScenario = scenarioRawData => {
        this.props.updateScenarioLengthList(this.props.Scenario.order, 0)
        this.setState({ isLoading: true })
        const scenario = scenarioRawData || this.props.Scenario
        const { startDate, endDate } = this.state
        
        const requestObj = this.convertUrlIntoRequestObjects(scenario, startDate, endDate)
        requestObj.obj.OverrideTab = "dailyHistogram"

        console.log("getScenario called")

        if (requestObj.endpointUrl) {
            DataService.post(requestObj.endpointUrl, requestObj.obj, null, ScenarioTags.DailyMSR)
                .then(res => {
                    if (res.data.IsSuccess) {
                        this.data.ScenarioData = this.transformDailyHistogramDataToMSR(res.data.LatencyDataSeries, res.data.SampleCountDataSeries, this.props.datelen, this.props.Scenario.ProdLatencyThreshold)
                        this.data.ResponseRawData = res.data.LatencyDataSeries
                        this.setState({ isLoading: false })
                    } else {
                        console.error(res.data.RequestID);
                        console.error(res.data.RequestLog);
                        console.error(res.data.ErrorMessage);
                        this.setState({error : res.data.ErrorMessage});
                    }
                    
            }).catch(err => {
                console.error(err);
                this.setState({
                    error : err,
                    isLoading: false
                })
            })
        } else {
            this.setState({ isLoading: false })
        }
    }

    onToggleEditScenarioModal = () => {
        this.setState(prevState => {
            return {
                showEditScenarioModal: !prevState.showEditScenarioModal
            }
        })
    }

    openMSRUrl = () => {
        window.open(this.props.Scenario.url)
    }

    onToggleMoveScenarioModal = () => {
        this.setState(prevState => {
            return {
                showMoveScenarioModal: !prevState.showMoveScenarioModal
            }
        })
    }

    onScenarioRefresh = () => {
        document.getElementById('msr-scenario-v1-refresh').innerHTML = this.props.Scenario.title + " refreshed";
        this.getScenario()
    }

    onMoveScenario = (MSRId, oldIndex, newIndex) => {
        const { onMoveScenario } = this.props
        this.onToggleMoveScenarioModal()
        onMoveScenario(MSRId, oldIndex, newIndex)
    }

    onEditscenarioSave = (scenario, shouldRefresh) => {
        
        if (shouldRefresh) {
            this.getScenario(scenario)
        }
        this.props.onEditScenarioSave(scenario)
    }

    getScenarioButtons = (Scenario, scenarioLen, onMoveScenario) => {
        const {isOwner} = this.props
        return (
            <ButtonToolbar className="ScenarioToolbar">
                <ButtonGroup>
                        <Button aria-label="Refresh" variant="light" onClick={this.onScenarioRefresh}><FontAwesomeIcon icon={faSync} /></Button>
                        <Button aria-label="Open url in new tab" variant="light" onClick={this.openMSRUrl}><FontAwesomeIcon icon={faShareSquare} /></Button> 
                        {isOwner &&
                        <React.Fragment>
                            <Button aria-label="Edit scenario" variant="light" onClick={this.onToggleEditScenarioModal}><FontAwesomeIcon icon={faEdit} /></Button>
                            {Scenario.order !== scenarioLen - 1 ? <Button aria-label="Move scenario down" variant="light" onClick={onMoveScenario.bind(this, Scenario.order, Scenario.order + 1)}><FontAwesomeIcon icon={faChevronDown} /></Button> : null}
                            {Scenario.order !== 0 ? <Button aria-label="Move scenario up" variant="light" onClick={onMoveScenario.bind(this, Scenario.order, Scenario.order - 1)}><FontAwesomeIcon icon={faChevronUp} /></Button> : null}
                        </React.Fragment>}
                    <Button aria-label="Move scenario" variant="light" onClick={this.onToggleMoveScenarioModal}><FontAwesomeIcon icon={faArrowsAlt} /></Button> 
                </ButtonGroup>
            </ButtonToolbar>
        )
    }

    setError = error => {
        this.setState({error: error});
    }
    
    render() {
        const {Scenario, onEditScenarioDelete, scenarioLen, onMoveScenario, datelen, showLegend, showLatencyThreshold, showAvgDailySamples, currdatelist, includeWeekend, msrVersion} = this.props
        const {showEditScenarioModal, isLoading, showMoveScenarioModal} = this.state
        const {GoalPercent, DiffPercent} = Scenario
        const secondGoalPercent = GoalPercent - (GoalPercent * DiffPercent / 100)
        const showWeekends = includeWeekend != null ? includeWeekend : !Scenario.url.includes("w=false")
        let flag = false;

        return (
            <React.Fragment>
                    {isLoading ?
                        <React.Fragment>
                            <tr className="MSR">
                                <td className='noWordBreak'>{Scenario.title}</td>
                                {(Array.from(Array(datelen + this.props.showLatencyThreshold + this.props.showLegend + this.props.showAvgDailySamples).keys())).map(() =>(
                                    <td className ="high-contrast-red noWordBreak">getting data...</td>
                                ))}
                                <td style={{width: 100, paddingTop: '3px', paddingBottom: '3px'}}>
                                    {this.getScenarioButtons(Scenario, scenarioLen, onMoveScenario)}
                                </td>  
                            </tr>
                        </React.Fragment> 
                    :
                    Object.keys(this.data.ScenarioData).length === 0 ? 
                    <React.Fragment>
                        <tr className="MSR">
                            <td className='noWordBreak'>{Scenario.title}</td>
                            {showLegend ? <td className = 'noWordBreak'>Empty Data</td> : null}
                            {showLatencyThreshold ? <td  className='noWordBreak'>{Scenario.ProdLatencyThreshold} ms @P{Scenario.GoalPercent}</td> : null}
                            {showAvgDailySamples ? <td className = 'noWordBreak'>Empty Data</td> : null}
                            {(Array.from(Array(datelen).keys())).map(() =>(
                                <td className='noWordBreak' style={{'background-color': '#c8e6c9', 'color': "#000"}}>Empty Data</td>
                            ))}
                            <td style={{width: 100}}>
                                {this.getScenarioButtons(Scenario, scenarioLen, onMoveScenario)}
                            </td> 
                        </tr>
                    </React.Fragment>
                    :
                    Object.keys(this.data.ScenarioData).map(currLegend => (
                        <React.Fragment>
                            
                            {this.state.error && <Alert variant='danger'>{this.state.error}</Alert>}
                            <tr className="MSR">
                                {flag ? null : 
                                    <td rowspan={Object.keys(this.data.ScenarioData).length} className='noWordBreak'>{Scenario.title}</td>
                                }
                                {showLegend ? <td  className='noWordBreak'>{currLegend}</td> : null}
                                {showLatencyThreshold ? <td  className='noWordBreak'>{this.props.Scenario.ProdLatencyThreshold} ms @P{this.props.Scenario.GoalPercent}</td> : null}
                                {showAvgDailySamples ? <td  className='noWordBreak'>{this.getAvgDailySample(this.data.ScenarioData[currLegend]["avgDailySamples"])}</td> : null}
                                {this.data.ScenarioData[currLegend]["currPercent"].map((currnum, index) => (
                                    ((currdatelist[index].getDay() === 0 || currdatelist[index].getDay() === 6) && (includeWeekend == null) && (Scenario.url.includes("w=false"))) ? 
                                    <td className='noWordBreak' style={{'background-color': "#c8e6c9"}}>{"Weekend"}</td> : 
                                    ((currdatelist[index].getDay() === 0 || currdatelist[index].getDay() === 6) && !showWeekends) ? null :
                                    <td className='noWordBreak' style={{'background-color': currnum > GoalPercent ? "#388e3c" : currnum < secondGoalPercent ? "#f44336" : currnum <= GoalPercent ? "#ffeb3b" : '#c8e6c9', 'color': "#000"}}>{currnum}</td>
                                ))}
                                {flag ? null : 
                                        <td rowspan={Object.keys(this.data.ScenarioData).length} style={{width: 100, paddingTop: '3px', paddingBottom: '3px'}}>
                                            {this.getScenarioButtons(Scenario, scenarioLen, onMoveScenario)}
                                        </td>  }
                                {flag = true}
                            </tr>
                        </React.Fragment>
                    ))}
                <div id="msr-scenario-v1-refresh" role="status" class="sr-only"></div> 
                <MSREditScenarioModal
                    showModalorNot={showEditScenarioModal}
                    toggleModal={this.onToggleEditScenarioModal}
                    Scenario={Scenario}
                    onSave={this.onEditscenarioSave}
                    onDelete={onEditScenarioDelete}
                    setState={this.setError}
                    msrVersion={msrVersion}/>
                <MSRMoveScenarioModal 
                        showModal={showMoveScenarioModal}
                        toggleModal={this.onToggleMoveScenarioModal}
                        onMoveScenario={this.onMoveScenario}
                        MSRId={Scenario.MSRId}
                        orginalOrder={Scenario.order}
                        maxOrder={scenarioLen - 1}/>
            </React.Fragment>
        );
    }

}

export default MSRScenarioV1