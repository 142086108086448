import React, {Component, Fragment} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ValidationInput, { SelectWithList, CubeSetting, FileInput } from './ValidationInput'
import CubeCSVDataService from '../../Dataservices/CubeCSVDataService'
import { appTableDefaultCSV, mixerTableDefaultCSV, ScenarioList, fileStatus, columnTableDefaultRowsCSVNonFlat } from '../../Functions/CubeCSVManagement/CubeCSVConst'
import { getHeaderString, joinTemplateWithSuggestion, joinTemplateWithView, addNewRoleToTemplate, AutoGetRowFromView, generateRowWithHeader, getHeaderAndIndex } from '../../Functions/CubeCSVManagement/CubeCSVProcessCSV'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { AutoFill } from '../../Functions/CubeCSVManagement/CubeCSVAutofill'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faTimes);

class AddNewCsvModal extends Component {
    constructor(props) {
        super(props)

        this.columnTemplate = columnTableDefaultRowsCSVNonFlat
        this.roleTemplate = ''
        this.logTemplate = ''
        this.defaultType = 'Empty'

        this.state = {
            // Cube properties
            cubeName: '',
            cubeError: '',
            owners: props.loginUser.userName+';',
            ownersError: '',
            isFlat: false,
            isApp: false,
            isMixer: false,
            scenario: this.defaultType,            
            cubeType: 'Daily',
            cubeArchitecture: 'V4',
            cubeTypeList: [],
            cubeArchitectureList: [],

            // Custom role
            roleName: '',
            roleNameError: '',
            shortRoleName: '',
            shortRoleNameError: '',
            customRoleList: [],
            
            // Suggestion Row
            selectedColumnRow: [],
            suggestionColRowNames: [],
            suggestionColRowContents: [],
            selectedRoleRow: [],
            suggestionRoleRowNames: [],
            suggestionRoleRowContents: [],
            selectedLogRow: [],
            suggestionLogRowNames: [],
            suggestionLogRowContents: [],

            // View Row
            viewFileName: "Please upload a view file",
            viewData: '',
            viewError: '', 
            viewStatus: fileStatus.Waiting, 
            viewRow: [],
            selectedViewRow: [],

            // Show setting
            collapse: false,
        }
    }

    componentDidMount() {
        CubeCSVDataService.getTemplateType().then(res => {
            this.setState({
                cubeTypeList: res.data.CubeType,
                cubeArchitectureList: res.data.CubeArchitecture,
            })
        })
    }

    initState = () => {
        this.columnTemplate = columnTableDefaultRowsCSVNonFlat
        this.roleTemplate = ''
        this.logTemplate = ''

        this.setState({
            // Cube properties
            cubeName: '',
            cubeError: '',
            owners: this.props.loginUser.userName+';',
            ownersError: '',
            isFlat: false,
            isApp: false,
            isMixer: false,
            scenario: this.defaultType,
            cubeType: 'Daily',
            cubeArchitecture: 'V4',
            
            // Custom role
            roleName: '',
            roleNameError: '',
            shortRoleName: '',
            shortRoleNameError: '',
            customRoleList: [],
            
            // Suggestion Row
            selectedColumnRow: [],
            suggestionColRowNames: [],
            suggestionColRowContents: [],
            selectedRoleRow: [],
            suggestionRoleRowNames: [],
            suggestionRoleRowContents: [],
            selectedLogRow: [],
            suggestionLogRowNames: [],
            suggestionLogRowContents: [],

            // View Row
            viewFileName: "Please upload a view file",
            viewError: '', 
            viewStatus: fileStatus.Waiting, 
            viewRow: [],
            selectedViewRow: [],

            // Show setting
            collapse: false,
        })
    }

    validateCubeName = (cubeName) => {
        const { validateCube } = this.props
        const { isFlat } = this.state
        let err = validateCube(cubeName, isFlat)
        if(err) return err
        return this.validateTitle(cubeName)
    }

    validateTitle = (title) => {
        if (title && title.indexOf(' ') !== -1) { return 'File title cannot contain spaces!' }
        return ''
    }

    validateOwners = (owner) => {
        if (owner && owner.indexOf(' ') !== -1) { return 'Owners cannot contain spaces!' } 
        return ''
    } 

    createEmptySelectedList = (len) => { return Array(len).fill(false) }

    onCubeNameBlur = (e) => {
        const {scenario, cubeType, cubeArchitecture, isFlat, cubeName} = this.state
        this.setTemplateAndSuggestion(scenario, cubeType, cubeArchitecture,  isFlat, cubeName)
    }

    onCubeNameChange = (e) => { this.setState({ cubeName: e.target.value, cubeError: this.validateCubeName(e.target.value) }) }

    onRoleNameChange = (e) => { this.setState({ roleName: e.target.value, roleNameError: this.validateTitle(e.target.value) }) }

    onShortRoleNameChange = (e) => { this.setState({ shortRoleName: e.target.value, shortRoleNameError: this.validateTitle(e.target.value) }) }

    onIsFlatChange = (e) => { this.setState({ isFlat: e.target.checked }) }

    onOwnersChange = (e) => { this.setState({ owners: e.target.value, ownersError: this.validateOwners(e.target.value) }) }

    onTypeChange = (e) => {
        const cubeType = e.target.value
        const {isFlat, cubeName, scenario, cubeArchitecture} = this.state
        this.setTemplateAndSuggestion(scenario, cubeType, cubeArchitecture, isFlat, cubeName)
        this.setState({ cubeType: cubeType })
    }

    onScenarioChange = (e) => { 
        const scenario = e.target.value
        const {isFlat, cubeName, cubeType, cubeArchitecture} = this.state
        this.setTemplateAndSuggestion(scenario, cubeType, cubeArchitecture, isFlat, cubeName)
        this.setState({ scenario: scenario}) 
    }

    onArchitectureChange = (e) => { 
        const cubeArchitecture = e.target.value
        const {isFlat, cubeName, scenario, cubeType} = this.state
        this.setTemplateAndSuggestion(scenario, cubeType, cubeArchitecture, isFlat, cubeName)
        this.setState({ cubeArchitecture: cubeArchitecture}) 
    }

    onDeleteRole = (index, e) => {
        let { customRoleList } = this.state
        customRoleList.splice(index, 1)
        this.setState({ customRoleList: customRoleList })
    }

    onAddRole = () => {
        let { roleName, roleNameError, shortRoleName, shortRoleNameError, customRoleList } = this.state
        if(roleNameError || shortRoleNameError) return
        let roleItem = { RoleName: roleName, ShortRoleName: shortRoleName, }
        customRoleList.push(roleItem)
        this.setState({
            roleName: '', 
            roleNameError: '', 
            shortRoleName: '', 
            shortRoleNameError: '', 
            customRoleList: customRoleList
        })
    }

    onIsAppChange = (e) => { this.setState({ isApp: e.target.checked }) }

    onIsMixerChange = (e) => { this.setState({ isMixer: e.target.checked }) }

    showCollapse = () => { this.setState({ collapse: !this.state.collapse }) }

    setTemplateAndSuggestion = (scenario, cubeType, cubeArchitecture, isFlat, cubeName) => {
        CubeCSVDataService.getTemplate(scenario + '_' + cubeType + '_' + cubeArchitecture, isFlat).then(res => {
            const templateData = res.data // A list contains 3 csv templates in column, role, log order. 

            if(templateData.length === 3) {
                this.columnTemplate = templateData[0].Content
                this.roleTemplate = templateData[1].Content
                this.logTemplate = templateData[2].Content
            
                if(cubeName){
                    // Get suggestion for column defination csv
                    const columnHeader = getHeaderString(this.columnTemplate)
                    CubeCSVDataService.getSuggestedPivots(cubeName, columnHeader).then(res => { 
                        const suggestionData = res.data 
                        const selectedColumnRow = this.createEmptySelectedList(suggestionData.Names.length) 
                        this.setState({
                            selectedColumnRow: selectedColumnRow,
                            suggestionColRowNames: suggestionData.Names, 
                            suggestionColRowContents: suggestionData.Contents,
                        })
                    })

                    // Get suggestion for role csv
                    const roleHeader = getHeaderString(this.roleTemplate)
                    CubeCSVDataService.getSuggestedRoles(cubeName, roleHeader).then(res => { 
                        const suggestionData = res.data
                        const selectedRoleRow = this.createEmptySelectedList(suggestionData.length)
                        this.setState({
                            selectedRoleRow: selectedRoleRow,
                            suggestionRolwRowNames: suggestionData, 
                            suggestionRoleRowContents: suggestionData,
                        })
                    })

                    // Get suggestion for log csv
                    const logHeader = getHeaderString(this.logTemplate)
                    CubeCSVDataService.getSuggestedLogs(cubeName, logHeader).then(res => { 
                        const suggestionData = res.data
                        const selectedLogRow = this.createEmptySelectedList(suggestionData.Names.length)
                        this.setState({
                            selectedLogRow: selectedLogRow,
                            suggestionLogRowNames: suggestionData.Names, 
                            suggestionLogRowContents: suggestionData.Contents,
                        })
                    })
                } 
            }
        })
    }

    onSelectColumnSuggestion = (index, e) => {
        let { selectedColumnRow } = this.state
        if(e.target.checked){ selectedColumnRow[index] = true }
        else{ selectedColumnRow[index] = false }
        this.setState({ selectedColumnRow: selectedColumnRow, })
    }

    onSelectRoleSuggestion = (index, e) => {
        let { selectedRoleRow } = this.state
        if(e.target.checked){ selectedRoleRow[index] = true }
        else{ selectedRoleRow[index] = false }
        this.setState({ selectedRoleRow: selectedRoleRow, })
    }

    onSelectLogSuggestion = (index, e) => {
        let { selectedLogRow } = this.state
        if(e.target.checked){ selectedLogRow[index] = true }
        else{ selectedLogRow[index] = false }
        this.setState({ selectedLogRow: selectedLogRow, })
    }

    onGetViewRow = () => {
        const { viewData, viewStatus } = this.state
        if(viewStatus != fileStatus.Success) {
            this.setState({viewError: 'Please upload view file or wait for the upload to complete.'})
            return
        }
        const { message, schema } = AutoGetRowFromView(viewData)
        if(schema) {
            const selectRow = schema.map(() => (true))
            this.setState({ viewRow: schema, selectedViewRow: selectRow, viewError: '' })
        }else {
            this.setState({ viewError: message })
        }
    }

    onSelectViewColumn = (index, e) => {
        let { selectedViewRow } = this.state
        selectedViewRow[index] = e.target.checked
        this.setState({ selectedViewRow: selectedViewRow, })
    }

    uploadView = (e) => {
        const files = e.target.files
        if(files.length > 0) {
            this.setState({  viewFileName: files[0].name, viewStatus: fileStatus.Uploading })
            const model = this
            files[0].text().then(
                function(value){ model.setState({ viewData: value, viewStatus: fileStatus.Success, viewError: '' }) },
                function(error){ model.setState({ viewStatus: fileStatus.Fail, viewError: error.message }) }
            )
        }
    }

    onCreate= () => {
        const { cubeName, cubeError, isFlat, owners, ownersError, cubeType, cubeArchitecture, isApp, isMixer, selectedViewRow, viewRow,
                suggestionLogRowContents, suggestionRoleRowContents, suggestionColRowContents, selectedColumnRow, selectedRoleRow, selectedLogRow, customRoleList } = this.state
        const { onCreateCsv, toggleModal } = this.props
        const fileNameEmptyError = 'File name cannot be empty!'

        let columnTitle = cubeName + "ColumnTable"
        let roleTitle = cubeName + "RoleTable"
        let logTitle = cubeName + "LogTable"

        // Validate properties
        if (cubeError || ownersError) return 
        if (!cubeName) { this.setState({ cubeError: fileNameEmptyError }) }
        if (!owners) { this.setState({ ownersError: fileNameEmptyError }) }
        if (!cubeName || !owners) return

        // Create default csv file using template
        let columnData = '', roleData = '', logData = ''

        // Add selected suggestion row
        for(let i = 0; i < selectedColumnRow.length; i++) {
            if(selectedColumnRow[i]) { columnData = columnData + '\n' + suggestionColRowContents[i]; }
        }
        for(let i = 0; i < selectedRoleRow.length; i++) {
            if(selectedRoleRow[i]) { roleData = roleData + '\n' + suggestionRoleRowContents[i]; }
        }
        for(let i = 0; i < selectedLogRow.length; i++) {
            if(selectedLogRow[i]) { logData = logData + '\n' + suggestionLogRowContents[i]; }
        }
        const { header, headerIndex } = getHeaderAndIndex(this.columnTemplate)
        for(let i = 0; i < selectedViewRow.length; i++) {
            if(selectedViewRow[i]) { 
                const csvRow = generateRowWithHeader(viewRow[i], header)
                const autofillRow = AutoFill(csvRow, header, headerIndex, isFlat)
                if(autofillRow) columnData = columnData + '\n' + autofillRow.join(',')
                else columnData = columnData + '\n' + csvRow.join(',')
            }
        }

        if(customRoleList.length != 0) {
            this.columnTemplate = addNewRoleToTemplate(this.columnTemplate, customRoleList, isFlat)
        }

        if(selectedViewRow.length > 0) {
            columnData = joinTemplateWithView(this.columnTemplate, columnData)
        }else{
            columnData = joinTemplateWithSuggestion(this.columnTemplate, columnData)
        }
        roleData = joinTemplateWithSuggestion(this.roleTemplate, roleData)
        logData = joinTemplateWithSuggestion(this.logTemplate, logData)

        let tableList = [
            {file: columnData, fileName: columnTitle, type: 'column'},
            {file: roleData, fileName: roleTitle, type: 'role'},
            {file: logData, fileName: logTitle, type: 'log'}
        ]

        if(isApp) tableList.push({file: appTableDefaultCSV, fileName: cubeName + 'AppTable', type: 'app'})
        if(isMixer) tableList.push({file: mixerTableDefaultCSV, fileName: cubeName + 'MixerTable', type: 'mixer'})

        toggleModal()
        onCreateCsv(cubeName, tableList, isFlat, owners, cubeType, cubeArchitecture)
        this.initState()
    }

    render() {
        const { showModal, toggleModal } = this.props
        const { cubeName, cubeError, isFlat, owners, ownersError, 
                scenario, cubeType, cubeArchitecture, cubeTypeList, cubeArchitectureList, 
                roleName, roleNameError, shortRoleName, shortRoleNameError,customRoleList,
                suggestionColRowNames, suggestionColRowContents, suggestionRoleRowNames, suggestionLogRowNames, suggestionLogRowContents, selectedColumnRow, selectedRoleRow, selectedLogRow, 
                isApp, isMixer,
                viewFileName, viewError, viewStatus, viewRow, selectedViewRow } = this.state

        return (
            <Modal show={showModal} onHide={toggleModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Create New Cube CSV</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* Cube Setting */}
                        <CubeSetting cubeName={cubeName} cubeError={cubeError} onCubeNameChange={this.onCubeNameChange} onCubeNameBlur={this.onCubeNameBlur} owners={owners} ownersError={ownersError} 
                                      onOwnersChange={this.onOwnersChange} />
                        <Form.Check type="checkbox" label="IsFlat" checked={isFlat} onChange={this.onIsFlatChange}/>
                        <hr/>

                        {/* Cube Type / Role List */}
                        <Form.Group>
                            <Form.Label>Role Row</Form.Label>
                            <Tab.Container defaultActiveKey="cubeType">
                            <Row>
                                <Col sm={2}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item> <Nav.Link eventKey="cubeType">Template</Nav.Link> </Nav.Item>
                                    <Nav.Item> <Nav.Link eventKey="roleList">CustomRole</Nav.Link> </Nav.Item>
                                </Nav>
                                </Col>
                                <Col sm={10}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="cubeType">
                                        <CubeTypePane scenario={scenario} onScenarioChange={this.onScenarioChange} scenarioList={ScenarioList} 
                                                      cubeType={cubeType} onTypeChange={this.onTypeChange} cubeTypeList={cubeTypeList} 
                                                      cubeArchitecture={cubeArchitecture} onArchitectureChange={this.onArchitectureChange} cubeArchitectureList={cubeArchitectureList}/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="roleList">
                                        <RoleListPane roleName={roleName} roleNameError={roleNameError} onRoleNameChange={this.onRoleNameChange} shortRoleName={shortRoleName} shortRoleNameError={shortRoleNameError} 
                                                      onShortRoleNameChange={this.onShortRoleNameChange} onAddRole={this.onAddRole} customRoleList={customRoleList} onDeleteRole={this.onDeleteRole}/>
                                    </Tab.Pane>
                                </Tab.Content>
                                </Col>
                            </Row>
                            </Tab.Container>
                        </Form.Group>

                        <hr/>

                        <OptionalFile isApp={isApp} onIsAppChange={this.onIsAppChange} isMixer={isMixer} onIsMixerChange={this.onIsMixerChange} />
                        
                        <hr/>

                        {/* Suggestion Row */} 
                        <Form.Group>
                            <Form.Label>Suggestion Row</Form.Label>
                            <Tab.Container defaultActiveKey="suggestionRow">
                            <Row>
                                <Col sm={2}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item> <Nav.Link eventKey="suggestionRow">Suggestion</Nav.Link> </Nav.Item>
                                        <Nav.Item> <Nav.Link eventKey="viewRow">View</Nav.Link> </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col sm={10}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="suggestionRow">
                                        <SuggestionRow suggestionColRowNames={suggestionColRowNames} suggestionColRowContents={suggestionColRowContents} selectedColumnRow={selectedColumnRow} onSelectColumnSuggestion={this.onSelectColumnSuggestion}
                                                       suggestionRoleRowNames={suggestionRoleRowNames} selectedRoleRow={selectedRoleRow} onSelectRoleSuggestion={this.onSelectRoleSuggestion} 
                                                       suggestionLogRowNames={suggestionLogRowNames} suggestionLogRowContents={suggestionLogRowContents} selectedLogRow={selectedLogRow} onSelectLogSuggestion={this.onSelectRoleSuggestion} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="viewRow">
                                        <ViewRow viewFileName={viewFileName} viewError={viewError} viewStatus={viewStatus} viewRow={viewRow} selectedViewRow={selectedViewRow} 
                                                 onGetRow={this.onGetViewRow} onSelectViewColumn={this.onSelectViewColumn} uploadView={this.uploadView} />
                                    </Tab.Pane>
                                </Tab.Content>
                                </Col>
                            </Row>
                            </Tab.Container>
                        </Form.Group>

                        
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={toggleModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={this.onCreate}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

class CubeTypePane extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { scenario, onScenarioChange, scenarioList, cubeType, onTypeChange, cubeTypeList, cubeArchitecture, onArchitectureChange, cubeArchitectureList } = this.props

        return (
            <Form.Group as={Row}>
                <Col sm={4}>
                    <Form.Label>Scenario</Form.Label>
                    <SelectWithList value={scenario} required="true" onChange={onScenarioChange} options={scenarioList}/>
                </Col>
                <Col sm={4}>
                    <Form.Label>Cube Type</Form.Label>
                    <SelectWithList value={cubeType} required="true" onChange={onTypeChange} options={cubeTypeList}/>
                </Col>
                <Col sm={4}>
                    <Form.Label>Cube Architecture</Form.Label>
                    <SelectWithList value={cubeArchitecture} required="true" onChange={onArchitectureChange} options={cubeArchitectureList}/>
                </Col>
            </Form.Group>
        )
    }
}

class RoleListPane extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { roleName, roleNameError, onRoleNameChange, shortRoleName, shortRoleNameError, onShortRoleNameChange, onAddRole, customRoleList, onDeleteRole } = this.props

        return(
            <React.Fragment>
                <Form.Group as={Row}>
                    <Col sm={6}>
                        <ValidationInput type="page" columnkey="roleName" placeholder="Role Name" value={roleName} error={roleNameError} onChange={onRoleNameChange}/>
                    </Col>
                    <Col sm={4}>
                        <ValidationInput type="page" columnkey="shortRoleName" placeholder="Short Role Name" value={shortRoleName} error={shortRoleNameError} onChange={onShortRoleNameChange}/>
                    </Col>
                    <Col sm={2}>
                    <Button onClick={onAddRole}> Submit </Button>
                    </Col>
                </Form.Group>
                <Form.Group>
                    {
                        customRoleList.map((key, index) => {
                            return (<Button variant="info" size='sm'>{key.ShortRoleName}<FontAwesomeIcon icon={faTimes} onClick={onDeleteRole.bind(this, index)} size='sm'/></Button>)
                        })
                    }
                </Form.Group>
            </React.Fragment>
        )
    }
}

class OptionalFile extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { isApp, onIsAppChange, isMixer, onIsMixerChange } = this.props

        return(
            <Form.Group>
                <Form.Label>Optional file</Form.Label>
                <Row>
                    <Col>
                        <Form.Check type="checkbox" label="AppId Name Translation File" checked={isApp} onChange={onIsAppChange}/>
                    </Col>
                    <Col>
                        <Form.Check type="checkbox" label="Mixer Configuration" checked={isMixer} onChange={onIsMixerChange}/>
                    </Col>
                </Row>
            </Form.Group>
        )
    }
}

class SuggestionRow extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { suggestionColRowNames, suggestionColRowContents, selectedColumnRow, onSelectColumnSuggestion,
                suggestionRoleRowNames, selectedRoleRow, onSelectRoleSuggestion, 
                suggestionLogRowNames, suggestionLogRowContents, selectedLogRow, onSelectLogSuggestion } = this.props

        return(
            <Form.Group>
                <Form.Label>Suggestion Row</Form.Label>
                <Row>
                    <SuggestionCol label="Column Defination" rowName={suggestionColRowNames} rowContent={suggestionColRowContents} selectedcolumn={selectedColumnRow} onSelectColumn={onSelectColumnSuggestion}/>
                    <SuggestionCol label="Role Table" rowName={suggestionRoleRowNames} selectedcolumn={selectedRoleRow} onSelectColumn={onSelectRoleSuggestion}/>
                    <SuggestionCol label="Log Table" rowName={suggestionLogRowNames} rowContent={suggestionLogRowContents} selectedcolumn={selectedLogRow} onSelectColumn={onSelectLogSuggestion}/>
                </Row>
            </Form.Group>
        )
    }
}

class SuggestionCol extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { rowName, rowContent, selectedcolumn, onSelectColumn, label } = this.props

        return(
            <Col>
                <Form.Label>{label}</Form.Label>
                {
                    rowContent?
                    rowName.map((key, index) => (
                        <OverlayTrigger key={key} placement="top"
                        overlay={ <Tooltip id={`tooltip-${key}`}> {rowContent[index]} </Tooltip> }>
                            <Form.Check label={key} type="checkbox" checked={selectedcolumn[index]} id={`suggestionRow-${key}`} onChange={onSelectColumn.bind(this, index)}/>
                        </OverlayTrigger>
                    ))
                    :
                    rowName.map((key, index) => (
                        <Form.Check label={key} type="checkbox" checked={selectedcolumn[index]} id={`suggestionRow-${key}`} onChange={onSelectColumn.bind(this, index)}/>
                    ))
                }
            </Col>
        )
    }
}

class ViewRow extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { viewFileName, viewError, viewStatus, viewRow, onGetRow, onSelectViewColumn, selectedViewRow, uploadView } = this.props

        return(
            <React.Fragment>
                <Form.Group as={Row}>
                    <Col sm={10}> <FileInput columnkey="viewFile" status={viewStatus} error={viewError} onChange={uploadView} label={viewFileName}/> </Col>
                    <Col sm={2}> <Button onClick={onGetRow}> Get </Button> </Col>
                </Form.Group>
                <Table responsive size="sm">
                    <thead>
                        <tr>
                            <th></th>
                            <th>ColumnInCosmos</th>
                            <th>ComosDataType</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        viewRow.map((row, index) => (
                        <tr key={`${row[0]}-viewrow`}>
                            <td><Form.Check checked={selectedViewRow[index]} onChange={onSelectViewColumn.bind(this, index)} /></td>
                            <td>{row[0]}</td>
                            <td>{row[1]}</td>
                        </tr>    
                        ))
                    }
                    </tbody>
                </Table>
            </React.Fragment>
            
        )
    }
}

export default AddNewCsvModal