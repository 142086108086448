import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import 'antd/dist/antd.css';
import './ServiceMetricsPage.css';
import titles from '../../Store/titles.js';
import { getPageTitle } from '../../Functions/PageTitleHelper';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav'
import ServiceQualityPage from './ServiceQualityPage';
import UsagePage from './UsagePage';
import { NotPermittedWarning } from '../PublicComponents/HelperComponents';

class ServiceMetricsPage extends Component {

    constructor(props) {
        super(props)

        this.tabList = [
            "ServiceQuality",
            "Usage",
        ]
        this.state = {
            activeKey: "ServiceQuality",
        }
    }

    componentDidMount() {
        document.title = getPageTitle(titles.SERVICE_METRICS, null);
        let params = new URLSearchParams(this.props.location.search);
        let tab = params.get('tab');
        if(this.tabList.includes(tab)) {
            this.setState({activeKey:tab})
        } else {
            this.onSelect('ServiceQuality')
        }
    }

    onSelect = key => {
        let params = new URLSearchParams();
        params.set('tab', key);
        this.props.history.push(window.location.pathname + "?" + params.toString());
        this.setState({activeKey: key})
    }

    render() {
        const { activeKey} = this.state
        const { isAdmin, isUserLoading } = this.props

        return (
            <Container fluid>
                {isAdmin? 
                <React.Fragment>
                    <Tab.Container activeKey={activeKey} onSelect={this.onSelect}>
                        <Nav variant="tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="ServiceQuality">Service Quality</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Usage">Usage</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="QualityInsight">Quality Insight</Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Tab.Content>
                            <Tab.Pane eventKey="ServiceQuality" mountOnEnter>
                                <ServiceQualityPage key="ServiceQuality"/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Usage" mountOnEnter>
                                <UsagePage key="Usage"/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="QualityInsight" mountOnEnter>
                                <div>
                                Please use debug account to visit <a href="https://ms.portal.azure.com/#@prdtrs01.onmicrosoft.com/dashboard/arm/subscriptions/5818ed0f-6cf0-4bda-9eb9-2771a5ab62f6/resourcegroups/dashboards/providers/microsoft.portal/dashboards/f3ee6f32-d098-468d-8880-06c76379629f">Application Insight Page</a>.
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </React.Fragment>
                 : isUserLoading? null: NotPermittedWarning("Only admin can access this page")
                }
            </Container>
        )
    }
}

export default withRouter(ServiceMetricsPage);
