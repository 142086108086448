
import { convertToHTML } from 'draft-convert'
import DOMPurify from 'dompurify'

export const convertContentToHTML = convertToHTML({
    styleToHTML: (style) => {
        if (style === 'STRIKETHROUGH') {
            return <span style={{textDecoration: 'line-through'}} />
        }

        if (style.startsWith('fontsize')) {
            return <span style={{fontSize: style.split('-')[1] + 'px'}} />
        }
    },
    blockToHTML: (block) => {
        if (block.type === 'blockquote') {
            return <blockquote style={{ borderLeft: '5px solid #f1f1f1', paddingLeft: '5px' }}/>
        }
    },
    entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
            return <a className="accessibleLink" href={entity.data.url} target={entity.data.targetOption || '_self'} rel="noopener">{originalText}</a>
        }
        return originalText
    }
})


export const createMarkup = (html) => {
    return  {
      __html: DOMPurify.sanitize(html, { ADD_ATTR: ['target'] })
    }
}