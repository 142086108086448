import React, { Component } from 'react'
import { Button, Dropdown, DropdownButton, Form, InputGroup, Row, Container, Spinner } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import PivotDropdownList from './PivotDropdownList';
import PivotDatepicker from './PivotDatepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import './PivotHeadPane.css';
import { handleClick } from '../../Dataservices/EventTrackingService'
import ProgressLoading from './ProgressLoading';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faCaretLeft);

const PercentileWarningOverlay = ({tab, percentile, children}) => {
    if((tab === "checkpoint" || tab === "dailymetrics") && percentile.toString().search(/[^\w.+]/) !== -1){
        return <OverlayTrigger  
            placement="top"
            overlay={
            <Popover id="popover-basic">
                <Popover.Content>
                    Checkpoints and metrics only support a single percentile
                </Popover.Content>
            </Popover> 
            }>
                {React.cloneElement(children, {onClick : null})}
            </OverlayTrigger>
    }
    else return children;
}

//Removing Top from UI as it is not currently functional
const disabled = true;
export default class PivotHeadPane extends Component {

    state =
    {
        autoGraph: true,        
        dominantBuildOptions: null,
        topTypeOptions: null,
        selectedPercentileList: "99",
        selectedBuildDominant: null,
        includeWeekends: true,
        topN: '',
        selectedTopOrder: "Ascending",
        selectedTopType: "None",
        refresh: 0,

        // default mapping from ctrlName to serverName
        // will be updated using this.props.mapping
        ctrlNameToServerName: {
            fromDate: "StartDate",
            toDate: "EndDate",
            selectedPercentileList: "Percentile",
            selectedBuildDominant: "TypeOfBuild",
            includeWeekends: "IncludeWeekends",
            topN: "TopN",
            selectedTopOrder: "TopOrder",
            selectedTopType: "TopType"
        },
        specialPivots : []
    };

    setWeekends = e => {
        this.setState({ includeWeekends: e.target.checked });
        this.props.changeHeadPanePivot(this.state.ctrlNameToServerName["includeWeekends"], e.target.checked ? 'true' : 'false');
    }

    setAutoGraph = e => {
        this.setState({ autoGraph: e.target.checked })
        this.props.changeAutoGraph(e.target.checked);
    }

    changeTopN = e => {
        this.setState({ topN: e.target.value })
        this.props.changeHeadPanePivot(this.state.ctrlNameToServerName["topN"], e.target.value.toString());
    }

    changePercentile = e => {
        this.setState({ selectedPercentileList: e.target.value })
        this.props.changeHeadPanePivot(this.state.ctrlNameToServerName["selectedPercentileList"], e.target.value);
    }

    changeTopType = type => {
        this.setState({ selectedTopType: type })
        this.props.changeHeadPanePivot(this.state.ctrlNameToServerName["selectedTopType"], type);
    }

    changeTopOrder = order => {
        this.setState({ selectedTopOrder: order })
        this.props.changeHeadPanePivot(this.state.ctrlNameToServerName["selectedTopOrder"], order);
    }

    changeTypeOfBuild = selectedBuildDominant => {
        this.setState({ selectedBuildDominant })
        this.props.changeHeadPanePivot(this.state.ctrlNameToServerName["selectedBuildDominant"], selectedBuildDominant);
    }

    // receive options
    static getDerivedStateFromProps(props, state) {
        if (state.dominantBuildOptions) {
            return null;
        } else if (props.urlDefaultParams && props.mapping && props.changeHeadPanePivot && props.changeAutoGraph) {

            // read metadata from props.mapping
            let dominantBuildOptions = [];
            let topTypeOptions = [];
            let ctrlNameToServerName = state.ctrlNameToServerName;
            let specialPivots = [];
            let selectedPercentileList = "99";
            let selectedBuildDominant = null;
            if (props.mapping.groupsList) {
                let pivots = props.mapping.groupsList[0].pivots;
                for (let k in pivots) {
                    let pivot = pivots[k];

                    if (pivot.ctrlName in ctrlNameToServerName) {
                        ctrlNameToServerName[pivot.ctrlName] = pivot.serverName;
                    }
                    else if (pivot.ctrlName.startsWith("o-")){
                        specialPivots.push(pivot);
                    }

                    if (pivot.ctrlName === "selectedBuildDominant") {
                        dominantBuildOptions = pivot.pivotValues.map(e => e.name)
                        selectedBuildDominant = pivot.defaultVal.toString();
                    } else if (pivot.ctrlName === "selectedTopType") {
                        topTypeOptions = pivot.pivotValues.map(e => e.name)
                    }
                    else if (pivot.ctrlName === 'selectedPercentileList'){
                        selectedPercentileList = pivot.defaultVal.toString();
                    }
                }
            }

            // set default values
            let updateState = {
                selectedPercentileList: selectedPercentileList,
                selectedBuildDominant: selectedBuildDominant,
                includeWeekends: true,
                topN: '',
                selectedTopOrder: "Ascending",
                selectedTopType: "None",
                dominantBuildOptions: dominantBuildOptions,
                topTypeOptions: topTypeOptions,
                ctrlNameToServerName: ctrlNameToServerName,
                specialPivots : specialPivots,
                refresh: state.refresh + 1
            }

            // merge default values from url
            updateState["autoGraph"] = props.urlDefaultParams["graphIt"];
            props.changeAutoGraph(updateState["autoGraph"]);

            for (let ctrlName in ctrlNameToServerName) {
                let serverName = ctrlNameToServerName[ctrlName];

                let newValue = props.urlDefaultParams[serverName];
                if (newValue != null) {
                    if (ctrlName === "includeWeekends") {
                        newValue = newValue === "true";
                    }
                    updateState[ctrlName] = newValue;
                }

                if (ctrlName === "topN" || ctrlName === "includeWeekends") {
                    props.changeHeadPanePivot(serverName, updateState[ctrlName].toString());
                } else {
                    props.changeHeadPanePivot(serverName, updateState[ctrlName]);
                }
            }

            return updateState;
        } else {
            return null;
        }
    }
    
    renderRow = (pivot, index) => {
        let defaultSelectedValues = this.props.getDefaultValues(pivot).defaultSelectedValues;
        
        let pivotValueList = null;
        let lightPivot = false;
        if (this.props.pivots && this.props.pivots[pivot.pivotName]) {
            pivotValueList = [];
            let array = this.props.pivots[pivot.pivotName];
            for (let i in array) {
                pivotValueList = pivotValueList.concat(array[i].map(e => ({ "label": (!e)?"(blank)":e, "value": e })));
            }
        }else {
            lightPivot = true;
        }

        return (
            <Row key={"pivotRow" + index}>
            <div className="chartingPanePivotLabel"><span>{pivot.pivotFriendlyName}</span></div>
            <div className="chartingPanePivotValue">
                    <PivotDropdownList
                        pivotName={pivot.pivotName}
                        cubeName={this.props.cubeName}
                        options={pivotValueList}
                        defaultValues={defaultSelectedValues}
                        onChange={e => this.props.onSelectChange(pivot.serverName, e)}
                        supportPrefix={pivot.selectionType !== "single" && this.props.supportPrefix}
                        isMulti={pivot.selectionType !== "single"}
                        enableSearch={pivot.enableSearch}
                        tooltip={pivot.tooltip}
                        addPivots={this.props.addPivots}
                        isLightPivot={lightPivot}
                    />
            </div>
            </Row>
        );
    }

    render() {
        let { isLoading, tab, isRawLogsEnabled, isInsightEnabled, isDynamicGeneratedPivotNull, isHourly, urlDefaultParams, changeChartingPaneTimezone, changeChartingPaneDateRange} = this.props;
        let {dominantBuildOptions, topTypeOptions, refresh} = this.state;

        return (
            <Container className="pivotHeadPane" style={{ marginBottom: "1em" }}>
                <Row className="chartingPaneBtnGroup">
                    <PercentileWarningOverlay tab={tab} percentile={this.state.selectedPercentileList}>
                        <Button variant="primary" onClick={this.props.isGraphing ? this.props.cancelGraphIt : this.props.graphIt} disabled={isDynamicGeneratedPivotNull}>
                        {this.props.isGraphing ? "Cancel Graphing" : "Graph It"}
                        {this.props.isGraphing && <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />}                                
                        </Button>                           
                    </PercentileWarningOverlay>             
                    {isRawLogsEnabled && <Button variant="primary" onClick={this.props.handleGetRawLogsButton} disabled={isLoading}>
                        Raw Logs
                    </Button>}
                    {isInsightEnabled && <PercentileWarningOverlay tab={tab} percentile={this.state.selectedPercentileList} disabled={isLoading}>
                        <Button variant="primary" onClick={this.props.handleInsightsButton}>
                            Insights
                        </Button>
                    </PercentileWarningOverlay>}    
                    <Button variant="light" style={{ position: 'Absolute', right: '0' }} onClick={this.props.hideChartingPane} disabled={isLoading}>
                        <FontAwesomeIcon style={{ color: 'grey'}} icon={faCaretLeft}/>
                    </Button>
                </Row>
                {this.props.isGraphing && this.props.isProgressLoad && <ProgressLoading progress={this.props.progress} progressTask={this.props.progressTask} updateTimeInterval={this.props.updateProgressTimeInterval} updatedTime={this.props.updatedTime}/>}         
                <Row>
                    <div className="chartingPanePivotLabel">
                        <label htmlFor="url-autograph">Url Autograph</label>
                    </div>
                    <div className="chartingPanePivotCheckbox">
                        <input type="checkbox" id="url-autograph" checked={this.state.autoGraph} onChange={this.setAutoGraph} onClick={() => { handleClick("Url Autograph", "pivotHeadPane", "analysis") }}/>
                    </div>
                </Row>
                <PivotDatepicker isHourly={isHourly} changeChartingPaneDateRange={changeChartingPaneDateRange} changeChartingPaneTimezone={changeChartingPaneTimezone} urlDefaultParams={urlDefaultParams} refresh={refresh}/>
                <Row>                
                    <div className="chartingPanePivotLabel">
                        <label htmlFor="weekends">Weekends</label>
                    </div>
                    <div className="chartingPanePivotCheckbox">
                        <input type="checkbox" id="weekends" checked={this.state.includeWeekends} onChange={this.setWeekends} onClick={() => { handleClick("Weekends", "pivotHeadPane", "analysis") }}/>
                    </div>
                </Row>
                { (dominantBuildOptions && dominantBuildOptions.length ) ?
                <Row>
                    <div className="chartingPanePivotLabel">Dominant Build</div>
                        <div className="chartingPanePivotValue" aria-label="Dominant Build" onClick={() => { handleClick("Dominant Build", "pivotHeadPane", "analysis") }}>

                        <DropdownButton
                            as={InputGroup.Append}
                            variant="outline-secondary"
                            title={this.state.selectedBuildDominant}
                        >
                            {
                                dominantBuildOptions?.map(e => (<Dropdown.Item key={e} onClick={this.changeTypeOfBuild.bind(this, e)}>{e}</Dropdown.Item>))
                            }
                        </DropdownButton>

                    </div>
                </Row> : null
                }
                <Row>
                    <div className="chartingPanePivotLabel">Percentile</div>
                    <div className="chartingPanePivotValue" onClick={() => { handleClick("Percentile", "pivotHeadPane", "analysis") }}>
                        <Form.Control aria-label="Percentile" type="text" value={this.state.selectedPercentileList} onChange={this.changePercentile} />
                    </div>
                </Row>
                {!disabled &&
                <Row>
                    <div className="chartingPanePivotTopLabel">Top</div>
                    <div className="chartingPanePivotTopValue">
                        <InputGroup>
                            <Form.Control type="number" min="0" max="100" value={this.state.topN} onChange={this.changeTopN} />
                            <DropdownButton
                                as={InputGroup.Append}
                                variant="outline-secondary"
                                title={this.state.selectedTopType}
                                className="dropDownButton"
                            >
                                {
                                    topTypeOptions?.map(e => (<Dropdown.Item key={e} onClick={this.changeTopType.bind(this, e)}>{e}</Dropdown.Item>))
                                }
                            </DropdownButton>
                            <DropdownButton
                                as={InputGroup.Append}
                                variant="outline-secondary"
                                title={this.state.selectedTopOrder}
                                className="dropDownButton"
                            >
                                <Dropdown.Item onClick={this.changeTopOrder.bind(this, 'Ascending')}>Ascending</Dropdown.Item>
                                <Dropdown.Item onClick={this.changeTopOrder.bind(this, 'Desc')}>Descending</Dropdown.Item>
                            </DropdownButton>
                        </InputGroup>
                    </div>
                </Row>
                }
                {this.state.specialPivots.map((pivot, index) => this.renderRow(pivot, index))}
            </Container>          
        )
    }
}
