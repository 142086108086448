import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
import './CopilotComponents.css';

export default function Botpanel(props) {

    return (
        <React.Fragment>
            <Row className='botpanel'>
                <Column className='paneltext' sm='1'>
                    <img alt="Copilot Logo" className="copiloticon" src="../Copilot32.ico" />
                </Column>
                <Column className='paneltext' sm='10'>
                    <h3>Copilot <Badge pill variant="light"> Preview </Badge> </h3>
                </Column>
                <Column sm='1'>
                    <div className="panelaction">
                        <Button size="sm" variant='outline-dark' aria-label="close chat" className='panelbutton' onClick={props.hideChat}><FontAwesomeIcon icon={faTimes} /></Button>
                        <Button size="sm" variant='outline-dark' aria-label="more actions" className='panelbutton' onClick={props.newChat}><FontAwesomeIcon icon={faEllipsisH} /></Button>
                    </div>
                </Column>
            </Row>
        </React.Fragment>
    )
}