import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {runWithMsal, msalApp, setUpGraphClient, loadUserInfo} from './Dataservices/AuthUtils';
import Dataservice from './Dataservices/DataService';
import CosmicBaseDataService from './Dataservices/CosmicBaseDataService';
import EvoBaseDataService from './Dataservices/EvoBaseDataService';
import config from "./config";

function render(user) {
  ReactDOM.render(
    <React.StrictMode>
      <App loginUser={user}/>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

function renderUnauthorizedPage(username) {
  ReactDOM.render(
    <h1>
      403 - {username} is unauthorized to access Perfpanel. Please email o365coreperf@microsoft.com to request access
    </h1>,
    document.getElementById('root')
  );
}

runWithMsal(access_token => {
    const account = msalApp.getAccount();
    if (account) {
        Dataservice.setAccessToken(access_token);
        CosmicBaseDataService.setAccessToken(access_token);
        EvoBaseDataService.setAccessToken(access_token);
    } else {
        config.frontendAuth = false;
    }

    const user = {
        id: account.accountIdentifier,
        displayName: account.name,
        userName: account.userName
    }

    if (account.userName.endsWith('@microsoft.com') 
        || account.userName.endsWith("@trs09.prod.exo.microsoft.scloud") 
        || account.userName.endsWith("@ntdev.microsoft.com") 
        || account.userName.endsWith("@winse.microsoft.com"))
    {
        render(user);
        setUpGraphClient();

        // If enable data saving, load user info first. If userinfo is not loaded, it will be loaded before sending out latency record.
        // Latency record is sent out in beforeunload event. It's not statble so we need to prevent too many operation in the listener.
        loadUserInfo();
    }
    else
    {
        renderUnauthorizedPage(account.username);
    }
},
(username) => renderUnauthorizedPage(username));

console.log("config: ");
console.log(config);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
