import DataService from './DataService'

const MaintenanceService = {
    getCubeTableList: () => {
        return DataService.get('MaintenanceService/CubeTableList')
    },
    getDiskMonitor: () => {
        return DataService.get('MaintenanceService/DiskMonitor')
    },
    getJobMonitor: () => {
        return DataService.get('MaintenanceService/JobMonitor')
    },
    getTrimmingTableList: () => {
        return DataService.get('MaintenanceService/TrimmingTableList')
    },
    getTrimmingConfig: () => {
        return DataService.get('MaintenanceService/TrimmingConfig')
    },
    getTrimmingSqlStatement: request => {
        return DataService.post('MaintenanceService/TrimmingSqlStatement', request)
    }
}

export default MaintenanceService