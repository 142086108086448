import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import EvoDataService from '../../Dataservices/EvoDataService';
import './CosmicMappings.css';

class ServiceLevelMappingTableRow extends Component {
    constructor(props) {
        super(props)

        this.state = {
            item: this.props.item,
            isEditing: false
        }
    }

    onSave = payload => {
        this.setState({ 
            item: payload,
            isEditing: false
        });
        
        EvoDataService.editServiceLevelMapping({...this.state.item, serviceName: this.props.service}).catch(err => {
            console.error(err);
            this.props.setError(err.message);
        })
    }

    onEdit = () => {
        this.setState({ isEditing: true});
    }

    onChangeLegend = (value, item) => {
        item.legend = value;
        this.setState({ item: item});
    }

    onChangeDescription = (value, item) => {
        item.description = value;
        this.setState({ item: item});
    }

    onSelect = (checked) => {
        if (checked) {
            this.props.addMappingToPromotionList(this.state.item);
        } else {
            this.props.removeMappingFromPromotionList(this.state.item);
        }
    }

    onPromotion = (item) => {
        item.isMappingInCommonTable = true;
        this.setState({item: item});
        EvoDataService.promoteServiceLevelMappingsToCommon([item]).then(res => {
            this.props.removeMappingFromPromotionList(this.state.item);
            this.props.setMappingPromoted(true);
        }).catch(err => {
            console.error(err);
            this.props.setError(err.message);
        })
    }

    areLegendAndDescriptionEmpty = (item) => {
        return (item.legend === '' || item.legend === null) && (item.description === '' || item.description === null);
    }

    render() {
        const { isEditing, item } = this.state
        let isPromotionDisabled = isEditing || item.isMappingInCommonTable || this.areLegendAndDescriptionEmpty(item);
        return (
            <tr key={item.keyName + item.keyType}>
                <td>
                    {isPromotionDisabled ? 
                        <OverlayTrigger placement="auto" overlay={<Tooltip>You can only promote mapping with non-empty legend or description that's not in the common mappings</Tooltip>}>
                            <span><Form.Check aria-label="Select measure or checkpoint" type="checkbox" style={{ pointerEvents: 'none' }} disabled={isPromotionDisabled} onChange={(e) => this.onSelect(e.target.checked)}></Form.Check></span>
                        </OverlayTrigger> :
                        <Form.Check aria-label="Select this checkpoint or measure" type="checkbox" disabled={isPromotionDisabled} onChange={(e) => this.onSelect(e.target.checked)}></Form.Check>
                    }
                </td>
                <td className="limitMinWidth">{item.keyName}</td>
                <td className="limitMinWidth">{item.keyType}</td>
                <td>{isEditing ? <InputGroup className="mb-3"><Form.Control value={item.legend} onChange={(event) => this.onChangeLegend(event.target.value, item)}/></InputGroup> : item.legend}</td>
                <td>{isEditing ? <InputGroup className="mb-3"><Form.Control value={item.description} onChange={(event) => this.onChangeDescription(event.target.value, item)}/></InputGroup> : item.description}</td>
                <td>
                    <Button variant="primary" onClick={isEditing ? this.onSave.bind(this, item) : this.onEdit.bind(this, item)}>{isEditing ? 'Save' : 'Edit'}</Button>
                    {isPromotionDisabled ? 
                        <OverlayTrigger placement="auto" overlay={<Tooltip>You can only promote mapping with non-empty legend or description that's not in the common mappings</Tooltip>}>
                            <span><Button variant="primary" className="promoteButton" style={{ pointerEvents: 'none' }} disabled={isPromotionDisabled} onClick={() => this.onPromotion(item)}>Promote</Button></span>
                        </OverlayTrigger> :
                        <OverlayTrigger placement="auto" overlay={<Tooltip>Promote your mapping to the common mappings!</Tooltip>}>
                            <Button variant="primary" className="promoteButton" disabled={isPromotionDisabled} onClick={() => this.onPromotion(item)}>Promote</Button>
                        </OverlayTrigger>
                    }   
                </td>
            </tr>
        );
    }
}
export default ServiceLevelMappingTableRow;