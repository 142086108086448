import React, { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { withRouter } from 'react-router'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faRedo, faSpinner}  from '@fortawesome/free-solid-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";
import DataService from '../../Dataservices/DataService'
import { cloneDate, addDaysToUTCDate, getDisplayDateAsLogicalDate, getLogicalDateAsDisplayDate } from '../../Functions/DateTimeHelper'
import PaginatedTable from '../PublicComponents/PaginatedTable'
import { SortOrderEnum } from '../../Functions/TableHelper'
import uuid from 'react-uuid'
import ReactDatePicker from '../ReactDatepicker';

export function RestatementPage() {

    library.add(faRedo, faSpinner);

    const[restatementList, setRestatementList] = useState([]);
    const[skipDayList, setSkipDayList] = useState([]);
    const[cubeList, setCubeList] = useState([]);
    const[error, setError] = useState();
    const[resInfo, setResInfo] = useState();
    const[isCubeLoading, setIsCubeLoading] = useState(true);
    const[isRestatementLoading, setIsRestatementLoading] = useState(true);
    const[isSkipDayListLoading, setIsSkipDayListLoading] = useState(true);
    const[selectedRestatementCube, setSelectedRestatementCube] = useState({Name: "Select a Cube", Id: -1});
    const[restatementDate, setRestatementDate] = useState(getLogicalDateAsDisplayDate(addDaysToUTCDate(new Date(), 0))); // Don't want the time, just the date
    const[restatementHour, setRestatementHour] = useState(0);
    const[selectedSkipCube, setSelectedSkipCube] = useState({Name: "Select a Cube", Id: -1});
    const[skipDateStart, setSkipDateStart] = useState(getLogicalDateAsDisplayDate(addDaysToUTCDate(new Date(), 0))); // Don't want the time, just the date
    const[skipHourStart, setSkipHourStart] = useState(0);
    const[skipDateEnd, setSkipDateEnd] = useState(getLogicalDateAsDisplayDate(addDaysToUTCDate(new Date(), 0))); // Don't want the time, just the date
    const[skipHourEnd, setSkipHourEnd] = useState(0);
    const[addRestatement, setAddRestatement] = useState(false);

    let restatementTableColumns = [
        ["RestatementId", "Id"],
        ["StagingConfigurationId", "Staging Id"], 
        ["DisplayName", "Cube"],
        ["RestatementDate", "Date"],
        ["RestatementCompletionTime", "Completed Date"]
    ];

    let daySkipTableColumns = [
        ["DaysToSkipId", "Id"],
        ["StagingConfigurationId", "Staging Id"],
        ["SkipDate", "Date"],
        ["IsCompleted", "Completed"],
        ["DisplayName", "Cube"],
        ["AddedBy", "Added By"]
    ];

    let getCubeInfo = () => {
        DataService.get('SkipADay/CubeList').then(res => {
            setCubeList(res.data);       
        }).catch(err => {
            setError('Error getting the cube list: ' + err.message);
            console.error(err);
        }).finally(() => {
            setIsCubeLoading(false);
        })
    }
    useEffect(getCubeInfo, []);

    let getRestatementList = () => {       
        DataService.get('SkipADay/Restatements').then(res => {         
            setRestatementList(res.data);
        }).catch(err => {
            setError('Error getting the restatement list: ' + err.message);
            console.error(err);
        }).finally(() => {
            document.getElementById('restatement-refresh').innerHTML = "Restatements refreshed";
            setIsRestatementLoading(false);         
        })
    }
    useEffect(getRestatementList, []);

    let getSkipDayList = () => {
        DataService.get('SkipADay/SkippedDayList').then(res => {
            setSkipDayList(res.data);
        }).catch(err => {
            setError('Error getting the skipped days list: ' + err.message);
            console.error(err);
        }).finally(() => {
            document.getElementById('skip-refresh').innerHTML = "Skip a day refreshed";
            setIsSkipDayListLoading(false);
        })
    }
    useEffect(getSkipDayList, []);

    let handleDoRestatementCheck = (e) => {
        setAddRestatement(e.target.checked);
    }

    let postRestatement = () => {
        
        var curDate = getDisplayDateAsLogicalDate(restatementDate, "UTC");

        if (!cubeList.find(x => x.StagingConfigurationId === selectedRestatementCube.Id))
        {
            setError('Could not find cube with staging config id: ' + selectedRestatementCube.Id);
            return;
        }

        if(selectedRestatementCube.IsHourly)
        {
            curDate.setUTCHours(restatementHour);
        }
        else
        {
            curDate.setUTCHours(0);
        }

        var RestatementRequest = {
            StagingConfigurationId: selectedRestatementCube.Id,
            DateTimeToRestate: curDate
        };

        DataService.post('SkipADay/AddRestatement', RestatementRequest).then(res=> {
            setResInfo("Successfully added restatement");
            getRestatementList();
            setError(null);
        }).catch(err => {
            console.error(err);
            setError('Failed to add restatement: ' + err.message);
        });
    }
    
    let fixDate = (date, hour, IsHourly) => {

        var curDate = getDisplayDateAsLogicalDate(date, "UTC");

        if(IsHourly)
        {
            curDate.setUTCHours(hour);   
        }
        else
        {
            curDate.setUTCHours(0);
        }

        return curDate;
    }

    let postSkip = () => {

        if (!cubeList.find(x => x.StagingConfigurationId === selectedSkipCube.Id))
        {
            setError('Could not find cube with staging config id: ' + selectedSkipCube.Id);
            return;
        }
        
        var dayToSkipRequest = {
            StagingConfigurationId: selectedSkipCube.Id,
            StartDateToSkip: fixDate(skipDateStart, skipHourStart, selectedSkipCube.IsHourly),
            EndDateToSkip: fixDate(skipDateEnd, skipHourEnd, selectedSkipCube.IsHourly),
            AddRestatement: addRestatement,
            IsHourly: selectedSkipCube.IsHourly
        };
        
        DataService.post('SkipADay/AddDayToSkip', dayToSkipRequest).then(res=> {
            setResInfo("Successfully added skip");
            getSkipDayList();
            setError(null);
        }).catch(err => {
            console.error(err);
            setError('Failed to add skip: ' + err.message);
        });
    }
   

    return (
        <Container>
            {error && <Alert variant='danger'>{error}</Alert>}
            {resInfo && <Alert variant='info'>{resInfo}</Alert>}          
            <Tabs defaultActiveKey="Restatements">
                <Tab key={uuid()} eventKey="Restatements" title="Restatements">                  
                    <Row>
                        <Col>                           
                            <Row className='p-3'>                               
                                <Col lg="3">
                                    Add new restatement for
                                </Col>
                                {isCubeLoading ? <FontAwesomeIcon icon={faSpinner}/> : <Col lg="3">
                                    <DropdownButton id="dropdown-restatement-cube" title={selectedRestatementCube.Name}>
                                        {cubeList.map((cube, index) => {
                                            return (
                                                <Dropdown.Item key={uuid()} onClick={() => setSelectedRestatementCube({Id:cube["StagingConfigurationId"], Name:cube["DisplayName"], IsHourly:cube["IsHourly"]})}>{cube["DisplayName"]}</Dropdown.Item>
                                            )
                                        })}
                                    </DropdownButton> 
                                </Col>}
                            </Row>
                            <Row className='p-3'>
                            <Col lg="3">
                                From:
                            </Col>
                                <Col lg="3"> 
                                    <ReactDatePicker 
                                        className="gray-border medium-width"
                                        value={restatementDate}
                                        format={"MM/dd/yyyy"}
                                        onChange={(date) => setRestatementDate(date)}                                                
                                    />
                                </Col>
                            </Row>
                            {selectedRestatementCube.IsHourly && <Row className='p-3'>
                                <Col lg="3">
                                    Hour:
                                </Col>
                                <Col lg="3">                                    
                                    <DropdownButton id="dropdown-restatement-hour" title={restatementHour}>
                                        {[...Array(24).keys()].map(hour => (
                                            <Dropdown.Item key={uuid()} onClick={() => setRestatementHour(hour)}>{hour}</Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                </Col>
                            </Row>}
                            <Row className='p-3'>
                                <Col lg="3" />                                
                                <Col lg="3">
                                    <Button onClick={postRestatement}>Add</Button>
                                </Col>
                                <Col lg="3">
                                    <Button onClick={getRestatementList}>Refresh <FontAwesomeIcon icon={faRedo} /></Button>  
                                    <div id="restatement-refresh" role="status" class="sr-only"></div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col> 
                            <PaginatedTable data={restatementList} tableColumns={restatementTableColumns} isLoading={isRestatementLoading} tableSort={{sortKey: 'RestatementId', sortOrder: SortOrderEnum.Descending}}  />
                        </Col>
                    </Row>
                </Tab>
                <Tab key={uuid()} eventKey="Skips" title="Skip a Day">
                    <Row>
                        <Col>
                            <Row className='p-3'>
                                <Col lg="3">
                                    Cube to skip: 
                                </Col>
                                {isCubeLoading ? <FontAwesomeIcon icon={faSpinner}/> : <Col lg="3">
                                    <DropdownButton id="dropdown-restatement-cube" title={selectedSkipCube.Name}>
                                        {cubeList.map((cube, index) => {
                                            return (
                                                <Dropdown.Item key={uuid()} onClick={() => setSelectedSkipCube({Id:cube["StagingConfigurationId"], Name:cube["DisplayName"], IsHourly:cube["IsHourly"]})}>{cube["DisplayName"]}</Dropdown.Item>
                                            )
                                        })}
                                    </DropdownButton> 
                                </Col>}
                            </Row>
                            <Row className='p-3'>
                                <Col lg="3">
                                    From:
                                </Col>
                                <Col lg="3">
                                    <ReactDatePicker    
                                        className="gray-border medium-width"
                                        value={skipDateStart}
                                        onChange={(date) => setSkipDateStart(date)}
                                    />
                                </Col>
                            </Row>
                            {selectedSkipCube.IsHourly && <Row className='p-3'>
                                <Col lg="3">
                                    Hour:
                                </Col>
                                <Col lg="3">                                    
                                    <DropdownButton id="dropdown-restatement-hour" title={skipHourStart}>
                                        {[...Array(24).keys()].map(hour => (
                                            <Dropdown.Item key={uuid()} onClick={() => setSkipHourStart(hour)}>{hour}</Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                </Col>
                            </Row>}
                            <Row className='p-3'>
                                <Col lg="3">
                                    To:
                                </Col>
                                <Col lg="3">
                                    <ReactDatePicker 
                                        className="gray-border medium-width"
                                        value={skipDateEnd}
                                        onChange={(date) => setSkipDateEnd(date)}
                                    />
                                </Col>
                            </Row>
                            {selectedSkipCube.IsHourly && <Row className='p-3'>
                                <Col lg="3">
                                    Hour:
                                </Col>
                                <Col lg="3">                                    
                                    <DropdownButton id="dropdown-restatement-hour" title={skipHourEnd}>
                                        {[...Array(24).keys()].map(hour => (
                                            <Dropdown.Item key={uuid()} onClick={() => setSkipHourEnd(hour)}>{hour}</Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                </Col>
                            </Row>}
                            <Row className='p-3'>
                                <Col lg="3">
                                    Add a Restatement?
                                </Col>
                                <Col lg="3">
                                    <Form.Check 
                                        type='checkbox'
                                        onChange={handleDoRestatementCheck}
                                    />
                                </Col>
                            </Row>
                            <Row className='p-3'>
                                <Col lg="3" />       
                                <Col lg="3">
                                    <Button onClick={postSkip}>Add</Button>
                                </Col>
                                <Col lg="3">
                                    <Button onClick={getSkipDayList}>Refresh <FontAwesomeIcon icon={faRedo} /></Button>
                                    <div id="skip-refresh" role="status" class="sr-only"></div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>                     
                            <PaginatedTable data={skipDayList} tableColumns={daySkipTableColumns} isLoading={isSkipDayListLoading} tableSort={{sortKey: 'DaysToSkipId', sortOrder: SortOrderEnum.Descending}} />       
                        </Col>
                    </Row>
                </Tab>
            </Tabs>
        </Container>
    )
}

export default withRouter(RestatementPage)