import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Alert from 'react-bootstrap/Alert'
import Dropdown from 'react-bootstrap/Dropdown'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CubeCsvTableRow from './CubeCsvTableRow'
import CubeCSVDataService from '../../Dataservices/CubeCSVDataService'
import { getValidator, validateWholeTable, } from '../../Functions/CubeCSVManagement/CubeCSVValidation'
import { parseDataFromManagement } from '../../Functions/CubeCSVManagement/CubeCSVProcessCSV'
import { LoadingOverlay } from '../PublicComponents/HelperComponents'
import { columnSchemaComment, logSchemeComment, roleSchemaComment, pageComment } from '../../Functions/CubeCSVManagement/CubeCSVConst'
import { faDownload, faChevronUp, faChevronDown, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import './CubeCsvComponents.css'
import { UploadSingleCSVModal } from './UploadCSVModal'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faDownload, faChevronUp, faChevronDown, faTrash, faPlus);

class CubeCsvTable extends Component {

    constructor(props) {
        super(props)

        const {dataHeader, dataContent, error, comment} = this.initTable(props.type, props.isFlat, props.data, props.comment)

        this.state = {
            // CSV properties
            initialTitle: props.title,
            title: props.title,

            // Shown data
            isFlat: props.isFlat,
            type: props.type,
            dataHeader: dataHeader,
            dataContent: dataContent,
            error: error,
            comment: comment,
            errorMessage: '',
            
            // Show modal setting
            loading: false,
            showUploadCsvModal: false,
            showMessageModal: false,
            show: true,
            Alertvisible: false,

            updateRowTime: new Date().getTime(),
            dataUpdateTime: null,
            initTable: this.initTable.bind(this),
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.title !== prevState.title || nextProps.dataUpdateTime !== prevState.dataUpdateTime) {
            const {dataHeader, dataContent, error, comment} = prevState.initTable(nextProps.type, nextProps.isFlat, nextProps.data, nextProps.comment)
            return {
                initialTitle: nextProps.initialTitle,
                title: nextProps.title,

                isFlat: nextProps.isFlat,
                type: nextProps.type,
                dataHeader: dataHeader,
                dataContent: dataContent,
                error: error,
                comment: comment,

                dataUpdateTime: nextProps.dataUpdateTime,
                updateRowTime: new Date().getTime(),
            }
        }

        return null
    }

    initTable = (type, isFlat, data, pcomment) => {
        // Initialize header, content and comment
        const {dataHeader, dataContent, error, comment} = parseDataFromManagement(type, isFlat, data, pcomment)

        // Initialize index data
        this.headerIndex = dataHeader.reduce((map, header, index) => {
            map[header.toLowerCase()] = index
            return map
        }, {})
        this.nextRowIndex = 0
        this.rowIndex = new Array()
        for(let i = 0; i < dataContent.length; i++) {
            this.rowIndex.push(this.nextRowIndex)
            this.nextRowIndex += 1
        }

        return {dataHeader:dataHeader, dataContent:dataContent, error:error, comment:comment}
    }

    toggleUploadNewCsvModal = () => {
        this.setState(prevState => {
            return {
                showUploadCsvModal: !prevState.showUploadCsvModal
            }
        })
    }

    uploadCSV = (data) => {
        this.setState({
            loading: true
        })

        const {type, isFlat} = this.props
        const pcomment = []
        const {dataHeader, dataContent, error, comment} = this.initTable(type, isFlat, data, pcomment)
        this.setState({
            dataHeader: dataHeader,
            dataContent: dataContent,
            error: error,
            comment: comment,

            updateRowTime: new Date().getTime(),
            loading: false,
        })
    }

    onTitleChange = (e) => {
        this.setState({
            title: e.target.value
        })
    }

    getEmptyRow = () => {
        const {dataHeader} = this.state
        return new Array(dataHeader.length).fill('')
    }

    getEmptyError = () => {
        const {dataHeader} = this.state
        return new Array(dataHeader.length).fill('')
    }

    getEmptyComment = () => {
        const {dataHeader} = this.state
        let result = []
        for(let i = 0; i < dataHeader.length; i++) {
            result.push({user:"", value:"", updateTime:""})
        }
        return result
    }

    onAddRow = (prevRank) => {
        let { dataContent, error, comment } = this.state
        dataContent.splice(prevRank, 0, this.getEmptyRow())
        error.splice(prevRank, 0, this.getEmptyError())
        comment.splice(prevRank, 0, this.getEmptyComment())
        this.rowIndex.splice(prevRank, 0, this.nextRowIndex)
        this.nextRowIndex += 1

        this.setState({
            dataContent: dataContent,
            error: error
        })
    }

    onDeleteRow = (rank) => {
        let { dataContent, error, comment } = this.state
        dataContent.splice(rank, 1)
        error.splice(rank, 1)
        comment.splice(rank, 1)
        this.rowIndex.splice(rank, 1)
        this.setState({
            dataContent: dataContent,
            error: error
        })
    }

    onMoveRow = (fromPos, toPos) => {
        let { dataContent, error, comment } = this.state
        let fromMoveData = dataContent[fromPos]
        dataContent[fromPos] = dataContent.splice(toPos, 1, fromMoveData)[0]
        error[fromPos] = error.splice(toPos, 1, error[fromPos])[0]
        comment[fromPos] = comment.splice(toPos, 1, comment[fromPos])[0]
        this.rowIndex[fromPos] = this.rowIndex.splice(toPos, 1, this.rowIndex[fromPos])[0]
        this.setState({
            dataContent: dataContent,
            error: error
        })
    }

    getContent = () => {
        const { dataHeader, dataContent, title } = this.state
        let csvRow = [dataHeader.join(",")]
        let hasEmpty = true
        for(let i = 0; i < dataHeader.length; i++) {
            if(dataContent[dataContent.length-1][i])
                hasEmpty = false 
        }
        const contentRow = hasEmpty?dataContent.slice(0, dataContent.length-1).map(row => (row.join(","))) : dataContent.map(row => (row.join(",")))
        csvRow = csvRow.concat(contentRow)
        return {
            title: title,
            data: csvRow.join("\n")
        }
        
    }

    getError = () => {
        let { error, dataContent, dataHeader, type} = this.state
        let result = false

        let hasEmpty = true
        for(let i = 0; i < dataHeader.length; i++) {
            if(dataContent[dataContent.length-1][i])
                hasEmpty = false 
        }

        for(let i = 0; i < dataContent.length-(hasEmpty?1:0); i++) {
            for(let j = 0; j < dataContent[i].length; j++) {
                const validator = getValidator(type, dataHeader[j])
                if (validator) {
                    const { isValid, errorMsg } = validator(dataContent[i][j], this.headerIndex, dataContent[i])
                    if (!isValid) {
                        error[i][j] = errorMsg
                        result = true
                    } else {
                        error[i][j] = ''
                    }
                }
            }
        }

        if(!result) {
            const { isValid} = validateWholeTable(dataHeader, dataContent, this.headerIndex, error)
            if(!isValid) {
                result = true
            }
        }
        if(result === true){
            this.setState({
                updateTime: new Date().getTime(),
                error: error,
                Alertvisible: true,
                updateRowTime: new Date().getTime(),
            }, 
                ()=> {window.setTimeout(()=>{this.setState({Alertvisible:false})},8000)
            });
        }
        return result
    }

    getComment = () => {
        const { comment } = this.state 
        const { csvId, loginUser } = this.props
        let result = []

        for(let i = 0; i < comment.length; i++) {
            for(let j = 0; j < comment[i].length; j++) {
                if(comment[i][j].value) {
                    result.push({
                        id: comment[i][j].id,
                        CSVId: csvId,
                        row: i,
                        column: j,
                        comment: comment[i][j].value,
                        updateUser: loginUser.userName,
                        updateTime: new Date()
                    })
                }
            }
        }
        return result
    }

    downloadCSV = (title, validation) => {
        const {cubeId, csvId} = this.props
        if(cubeId === -1) {
            this.setState({
                updateTime: new Date().getTime(),
                Alertvisible: true,
                errorMessage: "A new cube should be saved first before downloading file. Please click Draft/Validate/Download button in the front of the page to save.",
                updateRowTime: new Date().getTime(),
            }, 
                ()=> {window.setTimeout(()=>{this.setState({Alertvisible:false})},8000)
            });
            return;
        }
        if(validation) {
            if(this.getError() ) {
                return;
            }
        }
        this.saveCSV();
        let content = this.getContent().data;
        const blob = new Blob([content]);
        const fileDownloadUrl = URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = fileDownloadUrl;
        a.download = title+'.csv';
        a.click();
    }
    
    saveCSV = () => {
        const { type, title, cubeId, csvId } = this.state
        let request = {}
        request.CubeId = cubeId
        request.Name = title
        request.Id = csvId
        request.Content = this.getContent().data
        request.IsDeleted = false
        request.Comments = this.getComment()
        request.Type = type
        CubeCSVDataService.saveCSV(request).then(res =>{
            this.setState({
                csvId: res.data
            })
        })
    }

    getHeaderComment = (type, columnkey) => {
        if(type === "column") {
            return columnSchemaComment[columnkey.toLowerCase()]
        }else if(type === "role"){
            return roleSchemaComment[columnkey.toLowerCase()]
        }else if(type === "log"){
            return logSchemeComment[columnkey.toLowerCase()]
        }else if(type === "page"){
            return pageComment[columnkey]
        }
        return null
    }

    render() {
        const { title, dataHeader, dataContent, error, comment, type, updateRowTime, Alertvisible, errorMessage, showUploadCsvModal, loading } = this.state
        const { isFlat } = this.props
        if(dataContent.length == 0) {
            this.onAddRow(0)
        }
            
        return (
            <React.Fragment>
                <UploadSingleCSVModal showModal={showUploadCsvModal} toggleModal={this.toggleUploadNewCsvModal} onCreateCsv={this.uploadCSV}/>

                {loading? 
                <LoadingOverlay/>
                :<Row>
                    <Col>
                        <Alert show={Alertvisible} variant='danger'>{(errorMessage)?errorMessage:error}</Alert>

                        <div className="tableOperation">
                            <div style={{ width: '50%' }}>
                                <InputGroup >
                                    <Button onClick={this.downloadCSV.bind(this, title, true)}>
                                        Download &ensp;<FontAwesomeIcon icon={faDownload} />
                                    </Button>
                                    <Button variant="outline-primary" onClick={this.downloadCSV.bind(this, title, false)}>
                                        Download(no Validation)&ensp;<FontAwesomeIcon icon={faDownload} />
                                    </Button>
                                    <FormControl
                                        value={title} onChange={this.onTitleChange}
                                        disabled = "true"
                                    />
                                </InputGroup>
                            </div>
                            <Button variant="secondary" style={{float: 'right'}} onClick={this.toggleUploadNewCsvModal}> Upload </Button>
                        </div>
                        
                        <div className="tableWrapper">
                            <Table bordered hover className="tableFixHead">
                                    <thead>
                                        <tr>
                                            <th className='cubeCsvTableCell'></th>
                                            {dataHeader.map(s => 
                                            <OverlayTrigger
                                            key={`${s}-comment`}
                                            placement="top"
                                            overlay={
                                            <Tooltip id={`tooltip-${s}`}>
                                                {this.getHeaderComment(type, s)}
                                            </Tooltip>
                                            }>
                                                <th key={s} className='cubeCsvTableCell'>{s}</th>
                                            </OverlayTrigger>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataContent && error && dataContent.map((row, index) => (
                                            <tr key={`${this.rowIndex[index]}`}>
                                                <td className='cubeCsvTableCell'>
                                                    <ButtonGroup aria-label="csv-operations">
                                                        <DropdownButton as={ButtonGroup} variant="light" title={<FontAwesomeIcon icon={faPlus} />} id="bg-nested-dropdown">
                                                            <Dropdown.Item eventKey="1" onClick={this.onAddRow.bind(this, index)} >Insert Above</Dropdown.Item>
                                                            <Dropdown.Item eventKey="2" onClick={this.onAddRow.bind(this, index+1)} >Insert Below</Dropdown.Item>
                                                        </DropdownButton>
                                                        <Button variant="light" onClick={this.onDeleteRow.bind(this, index)}><FontAwesomeIcon icon={faTrash} /></Button>
                                                        {index !== 0 && <Button variant="light" onClick={this.onMoveRow.bind(this, index, index - 1)}><FontAwesomeIcon icon={faChevronUp} /></Button>}
                                                        {index !== dataContent.length - 1 && <Button variant="light" onClick={this.onMoveRow.bind(this, index, index + 1)}><FontAwesomeIcon icon={faChevronDown} /></Button>}
                                                    </ButtonGroup>
                                                </td>
                                                <CubeCsvTableRow 
                                                key={`${this.rowIndex[index]}-row`}
                                                id={this.rowIndex[index]}
                                                isFlat={isFlat}
                                                type={type}
                                                row={row} 
                                                error={error[index]}
                                                comment={comment[index]}
                                                dataHeader={dataHeader} 
                                                dataLength={dataContent.length}
                                                updateTime={updateRowTime}/>
                                            </tr>  
                                        ))}
                                    </tbody>
                            </Table>  
                        </div>
                    </Col>
                </Row>}
            </React.Fragment>
        )
    }
}

export default CubeCsvTable