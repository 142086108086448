import React, { useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import uuid from 'react-uuid'
import './ChartComponents.css'
import { pointStorageSuggestion } from '../../Functions/ChartHelpers'
import RulesDataTable from './RulesDataTable';
import DataService from './../../Dataservices/DataService';
import ChartCardRow from './ChartCardRow';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faTimesCircle, faLightbulb);

export function ChartCard (props) {

    const [filterType, setFilterType] = useState('default');
    const [filterMinPct, setFilterMinPct] = useState('0');
    const [isPinned, setIsPinned] = useState(false);
    const [showRecommendations, setShowRecommendations] = useState(false);

    if(props.isPinned !== isPinned){
        setIsPinned(props.isPinned);
    }
    let updateLegends = series => {
        let newLegendsData = {
            yTotals: {},
            displayLegends: {}
        };
        let { yTotals, displayLegends } = newLegendsData;
        series.forEach(s => {
            if (s.name !== 'Goal line' && s.visible === true) {
                s.data.forEach(point => {
                    if (!yTotals[point.name]) {
                        yTotals[point.name] = 0;
                    }
                    yTotals[point.name] += point.y;

                    if (!displayLegends[point.name]) {
                        displayLegends[point.name] = [];
                    }

                    displayLegends[point.name].push({
                        name: s.name,
                        xValue: point.name,
                        build: point.build,
                        y: point.y,
                        percentage: 0,
                        color: s.color
                    });
                })
            }
        });
        Object.keys(displayLegends).forEach(xValue => {
            let points = displayLegends[xValue];
            points.forEach(point => {
                let pointCalculation = ((point.y || 0) / yTotals[xValue])
                point.percentage = isNaN(pointCalculation) ? 0 : pointCalculation * 100
            });
        }); 
        return newLegendsData;
    };
    
    const legendsData = useMemo(() => updateLegends(props.series), [props.series]);

    let handleFilterTypeChange = event => {
        setFilterType(event.target.value);
    }

    let handleMinPctChange = event => {
        setFilterMinPct(event.target.value);
    }

    let renderSeriesInCard = (seriesPoint) => { //shows the legends in the card for each line
        if(seriesPoint && seriesPoint.series) {
            const { tab, chartDisplayType, showCurrentPoint, legendDescriptionMapping } = props;
            const {displayLegends, yTotals} = legendsData;
            const points = displayLegends[seriesPoint.name] || [];
            const yTotal = yTotals[seriesPoint.name] || 0;
            const currentPointPercentage = isNaN(seriesPoint.y / yTotal) ? 0 : (seriesPoint.y / yTotal) * 100;
            const currentPointDescription = legendDescriptionMapping[seriesPoint.series.name];
            const currentPointY = seriesPoint.y === null ? '' : seriesPoint.y.toLocaleString();
            let visibleSeries = [];
            points.forEach(point => {
                if(filterType === 'default' && point.percentage >= 1) {
                    visibleSeries.push(point);
                } else if (filterType === 'minPct' && point.percentage >= (parseFloat(filterMinPct) || 0)) {
                    visibleSeries.push(point);
                }
            });

            return (
                <React.Fragment>
                    {showCurrentPoint && seriesPoint.series.name !== 'Goal line' && <React.Fragment>
                        <div className="selectedPointDiv" style={{borderLeft: `5px solid ${seriesPoint.series.color}`}}>
                            {tab === 'checkpoint' || tab === 'checkpointPercentiles'?
                            <React.Fragment>
                                <span style={{color: seriesPoint.series.color}}>{'\u25CF'}</span>:
                                {currentPointPercentage >= 1 ? 
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>{seriesPoint.series.name}: {currentPointY} ({currentPointPercentage.toFixed(2)}%)</span> :                                
                                        <span style={{ fontWeight: 'bold' }}>{seriesPoint.series.name}: {currentPointY} {currentPointPercentage === 0 ? null : currentPointPercentage.toFixed(2) + "%" } </span>}             
                            </React.Fragment> :
                            <p className="activePoint">{<span style={{color: seriesPoint.series.color}}>{'\u25CF'}</span>}:
                            {seriesPoint.series.name}: {currentPointY}{pointStorageSuggestion(tab, seriesPoint)}</p>}
                            
                            {currentPointDescription && <p className="activePointItem"><span className="activePointItemKey">Description:</span> <span className="activePointItemValue">{currentPointDescription}</span></p>}
                        </div>
                    </React.Fragment>}
                    {visibleSeries.length > 0 && (tab === 'checkpoint' || tab === 'checkpointPercentiles') ? <span className="listitem">
                        <p style={{fontWeight: 'bold'}}>Total: {yTotal.toFixed(2)}</p>
                    </span> : null}
                    {visibleSeries.map(point => {
                        const yDisplay = point.y === null ? '' : point.y.toLocaleString()
                        const percentageDisplay = point.percentage.toFixed(2)
                        const description = legendDescriptionMapping[point.name]
                        const options = {
                            tab: tab,
                            yDisplay: yDisplay,
                            percentageDisplay: percentageDisplay,
                            description: description,
                            chartDisplayType: chartDisplayType,
                            point: point,
                            isSpecificSeriesExpertInsightsSupported: props.isSpecificSeriesExpertInsightsSupported,
                            launchSpecificDailyExpertInsights: launchSpecificDailyExpertInsights,

                        }
                        return (
                            <div className="listitem" key={uuid()}>
                                <ChartCardRow options={options}></ChartCardRow>
                            </div>
                        )
                    })}
                </React.Fragment>
            )
        }

        return null;
    }

    

    let handleInsightsNav = (e) => {
        e.preventDefault();

        var chartInfo = {
            StartDate: props.currentPoint.target.name,
            EndDate: props.currentPoint.target.name,
            Url: window.location.href
        };
        DataService.post(props.cube + "/chartIdforUrl", chartInfo).then(res => {
            console.log(res);
            window.open(window.location.origin + "/Insights?chartID=" + res.data, "_blank");
        }).catch(err => {
            console.log(`${err} - could not open insights for ${props.currentPoint.target.name}`);
        });
    }

    let launchSpecificDailyExpertInsights = (legend) => { //call line-specific expert insights handler if supported
        var chartInfo = {
            StartDate: props.currentPoint.target.name,
            EndDate: props.currentPoint.target.name,
            Url: window.location.href,
            Legend: legend,
            AllLegends: ""
        };

        if (props.tab === 'daily' && props.isSpecificSeriesExpertInsightsSupported) {
            DataService.post(props.cube + "/dailyUrlForLegend", chartInfo).then(res => { //get the dailyUrl for specific line
                //create request object
                let dailyUrl = res.data;
                if (dailyUrl && dailyUrl.length > 0) {
                    chartInfo.Url = dailyUrl;
                }
    
                DataService.post(props.cube + "/chartIdforUrl", chartInfo).then(res => { //get chartID
                    console.log(res);
                    window.open(window.location.origin + "/Insights?chartID=" + res.data, "_blank");
                }).catch(err => {
                    console.log(`${err} - could not open insights for ${chartInfo.StartDate}`);
                });
    
            }).catch(err => {
                console.log(`Got an error: ${err}`);
            });
        }
    }

    let shouldShowRuleAlert = () => { //if rules have been processed and recommendations exist
        return false;
        // Temporarily disabled until fixed
        /*
        let date = props.currentPoint.target.name;
        if (typeof props.processedRules[date] == "undefined") {
            return false;
        }
        let show = props.showRules[date] && props.processedRules[date].length > 0;
        return show;
        */
    };

    let renderCard = () => {
        const { currentPoint } = props;
        if(currentPoint && currentPoint.target) {
            let seriesPoint = currentPoint.target;
            return (
                <div>
                    {renderSeriesInCard(seriesPoint)}
                </div>
            );
        }
        return null;
    };

    const { mouseCoords, unpinCard, currentPoint, showChartCard, order, cardOffset, tab, showAddAnnotationForm, isAccessibilityEnabled } = props;
    let style = {};
    if (isAccessibilityEnabled) {
        style = order % 2 === 1 ? { position: 'absolute', left: (-330) + 'px', top: '0 px' } : { position: 'absolute', left: '20 px', top: '3 px' };
    }
    else if (!isPinned) {
        style = order % 2 === 1 ? { position: 'fixed', left: (mouseCoords.X - 330) + 'px', top: mouseCoords.Y + 'px' } : { position: 'fixed', left: mouseCoords.X + 20 + 'px', top: mouseCoords.Y + 3 + 'px' };
    }
    else {
        style = order % 2 === 1 ? {position: 'absolute', left: (cardOffset.X -330) + 'px', top: cardOffset.Y + 'px' } : {position: 'absolute', left: cardOffset.X + 20 + 'px', top: cardOffset.Y + 3 + 'px' };
    };
    return showChartCard && currentPoint ? (
        <Card
            style={style}
            className="chartCard"
            >
            <Card.Title style={cardHeaderStyle}>
                <React.Fragment>
                    {shouldShowRuleAlert() && 
                    <div onMouseEnter={e => {
                        setShowRecommendations(true);
                    }}
                    onMouseLeave={e => {
                        setShowRecommendations(false);
                    }}>
                        <div className="insightsAvailableContainer">
                            <FontAwesomeIcon icon={faLightbulb} className="insightsBulb"/>

                            {isPinned && <div className="unPinBtnWrapper topcorner">
                                <FontAwesomeIcon onClick={unpinCard} icon={faTimesCircle} className="unPinBtn"/>
                            </div>}
                        </div>
                        {shouldShowRuleAlert() && showRecommendations && isPinned && <RulesDataTable className="rulesDataTable" rules={props.processedRules[props.currentPoint.target.name]}></RulesDataTable>}
                    </div>
                    }

                    {isPinned && <div className="charCardHeaderContainer">
                        <Form.Control as="select" value={filterType} aria-label="Filter legend items" onChange={handleFilterTypeChange} className="charCardHeaderSelect">
                            <option value="default">Default (&gt;1%)</option>
                            <option value="minPct">Min %</option>
                        </Form.Control>
                        {filterType === 'minPct' && <Form.Control type='number' value={filterMinPct} aria-label="Min percent" onChange={handleMinPctChange} className="charCardHeaderInput"/>}
                        {tab && !props.isHourly && (tab === 'daily' || tab === 'usage' || tab === "checkpoint" || tab.includes("dailymetrics") || tab === "dailyHistogram" || tab === "ratio") && <Button variant="outline-secondary" onClick={showAddAnnotationForm} className="charCardHeaderButton">Note</Button>}
                        {!props.isInDashboard && !props.isHourly && (props.tab && (props.tab === 'daily' || props.tab === "checkpoint" || props.tab.includes("dailymetrics"))) && <Button variant="outline-secondary" onClick={handleInsightsNav} className="charCardHeaderButton">Insights</Button>}
                        {!shouldShowRuleAlert() && <div className="unPinBtnWrapper">
                            <FontAwesomeIcon onClick={unpinCard} icon={faTimesCircle} className="unPinBtn"/>
                        </div>}
                    </div>}
                        <strong>{currentPoint.target.name}</strong>
                        {!isPinned && <span style={{float:'right', marginLeft: '10px'}}>(Click on the chart to pin this card)</span>}
                    
                </React.Fragment>
            </Card.Title>
            <Card.Body style={{ padding: 0 }}>
                {renderCard()}
            </Card.Body>
        </Card>
    ) : null;
    
}

const cardHeaderStyle = {
    fontSize:'12px'
}

export default ChartCard