import DataService from './DataService'

const IcMDataService = {

    getServices: () => {
        return DataService.get('/IcM/getServices')
    },

    getTeams: (id) => {
        return DataService.get('/IcM/getTeams?id=' + id)
    },

    getIcmTeam: (id) => {
        return DataService.get('/IcM/getIcmTeam?icmConfigId=' + id)
    },

    addIcmConfig: (request) => {
        return DataService.post('/IcM/addIcMConfig', request)
    }

}

export default IcMDataService