import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import Select from 'react-select';
import UserSearchInput from '../PublicComponents/UserSearchInput'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import './Dashboard.css'
import IcMDataService from '../../Dataservices/IcMDataService'
import { library } from "@fortawesome/fontawesome-svg-core";
import RenderInformativeTooltip from '../RenderInformativeTooltip';
import IcMSelector from '../AlertingComponents/IcMSelector';


library.add(faQuestionCircle);

class DashboardAddEditBoardModal extends Component {
    constructor(props) {
        super(props)
        const { name, email, lookbackTime, isPrivate, owners, members, icmTeamInfo, icmServiceInfo, icmConfigId} = props
        this.state = {
            nameOrigin: name,
            emailOrigin: email,
            lookbackTimeOrigin: lookbackTime,
            ownersOrigin: owners,
            membersOrigin: members,
            isPrivateOrigin: isPrivate,
            icmConfigIdOrigin: icmConfigId,
            icmServiceInfoOrigin: icmServiceInfo,
            icmTeamInfoOrigin: icmTeamInfo,
            name,
            email,
            lookbackTime,
            isPrivate,
            owners,
            members,
            error: '',
            icmConfigId,
            icmServiceInfo,
            teamSelectMessage: 'Please select a service first',
            icmTeamInfo,
            serviceList: [],
            teamList: []
        }
    }

    componentDidMount() {
        this.populateServiveList()
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { email, lookbackTime, isCloning, isPrivate, owners, members, name, icmServiceInfo, icmTeamInfo, icmConfigId } = nextProps;
        let nameCloned = isCloning ? "Copy of " + name : name;
        if (prevState.nameOrigin !== nameCloned || prevState.emailOrigin !== email || prevState.lookbackTimeOrigin !== lookbackTime
            || prevState.ownersOrigin !== owners || prevState.membersOrigin !== members || prevState.isPrivateOrigin !== isPrivate || prevState.icmConfigIdOrigin !== icmConfigId ||
            prevState.icmServiceInfoOrigin !== icmServiceInfo) {
            return {
                nameOrigin: nameCloned,
                emailOrigin: email,
                lookbackTimeOrigin: lookbackTime,
                ownersOrigin: owners,
                membersOrigin: members,
                isPrivateOrigin: isPrivate,
                icmConfigIdOrigin: icmConfigId,
                icmServiceInfoOrigin: icmServiceInfo,
                icmTeamInfoOrigin: icmTeamInfo,
                name: nameCloned,
                email,
                lookbackTime,
                isPrivate,
                owners,
                members,
                icmConfigId,
                icmServiceInfo,
                icmTeamInfo
            }
        }
        return null
    }

    onChangeHandler = e => {
        const { name, value } = e.target
        this.setState({
           [name]: value
        })
    }

    onLookbackTimeChangeHandler = e => {
        const lookbackTime = e.target.value
        this.setState({
            lookbackTime
        })
    }

    onPrivateChangeHandler = () => {
        this.setState(prevState => {
            return {
                isPrivate: !prevState.isPrivate
            }
        })
    }

    onEmailChangeHandler = newVal => {
        this.setState({
            email: newVal
        })
    }

    onOwnerChangeHandler = newVal => {
        this.setState({
            owners: newVal.slice()
        })
    }

    onMemberChangeHandler = newVal => {
        this.setState({
            members: newVal
        })
    }

    setServiceInfo = (icmServiceInfo) => {
        this.setState({
            icmServiceInfo: icmServiceInfo,
            teamSelectMessage: 'Searching...',
            icmTeamInfo: null,
            teamList: [],
            icmConfigId: null
        });
        if (icmServiceInfo != null) {
            this.getTeamList(icmServiceInfo.serviceId)
        }
    }

    setTeamInfo = (icmTeamInfo) => {
        this.setState({
            icmTeamInfo: icmTeamInfo,
            icmConfigId: null
        });
    }

    getIcmConfigId = () => {
        const { icmServiceInfo, icmTeamInfo } = this.state
        let request = {};
        request.ServiceConnectorId = icmServiceInfo.value
        request.TeamName = icmTeamInfo.label
        request.RoutingId = icmTeamInfo.value
        return IcMDataService.addIcmConfig(request)
    }

    getTeamList = (id) => {
        let index = 0;
        let teamSelectListConstructor = [];
        IcMDataService.getTeams(id).then(res => {
            let uniqueArray = res.data.filter((object, index) => index === res.data.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object)))
            let allTeams = uniqueArray.map(a => a.Item2)
            const duplicates = new Set(allTeams.filter((item, index) => index !== allTeams.indexOf(item)));
            for (; index < uniqueArray.length; index++) {
                const series = uniqueArray[index];
                let routingId = series.Item1
                let teamName = series.Item2
                let nameWithId = teamName + ' (' + routingId + ')'
                teamSelectListConstructor.push({
                    value: routingId,
                    label: duplicates.has(teamName) ? nameWithId : teamName,
                });
            }
            this.setState({ teamList: teamSelectListConstructor })
        });
    }

    populateServiveList = () => {
        if(this.props.icmServices) {
            this.props.icmServices.then(serviceSelectList => {
                this.setState({
                    serviceList: serviceSelectList,
                    isLoadingServices: false
                })
            });
        }else {
            let index = 0;
            let serviceSelectList = [];
            const serviceQuery = IcMDataService.getServices().then(res => {
                for (; index < res.data.length; index++) {
                    const service = res.data[index];
                    serviceSelectList.push({
                        value: service.Id,
                        label: service.ServiceName,
                        serviceId: service.ServiceId,
                    });
                }
                this.setState({
                    serviceList: serviceSelectList,
                    isLoadingServices: false
                })
                return serviceSelectList
            });
            this.props.setIcmServices(serviceQuery)
        }
    }

    onSubmit = e => {
        e.preventDefault()
        const { onSubmit } = this.props
        const { name, owners, members, email, lookbackTime, isPrivate, icmConfigId, icmTeamInfo, icmServiceInfo } = this.state
        if (!owners || owners.length === 0) {
            this.setState({
                error: 'There must be at least one owner!'
            })
            return
        }
        if (icmTeamInfo != null) {
            let addIcmConfig = this.getIcmConfigId()
            addIcmConfig.then(res => {
                this.setState({
                    icmConfigId: res.data
                })
                onSubmit(name, owners, members, email, lookbackTime, isPrivate, res.data, icmTeamInfo, icmServiceInfo)

            })
        } else {
            onSubmit(name, owners, members, email, lookbackTime, isPrivate, icmConfigId, icmTeamInfo, icmServiceInfo)
        }
    }

    render() {
        const { showModal, toggleModal, isEditing, isCloning } = this.props
        const { name, email, lookbackTime, isPrivate, owners, members, error, serviceList, teamSelectMessage, teamList, icmServiceInfo, icmTeamInfo } = this.state

        return (
            <Modal show={showModal} onHide={toggleModal}>
                <Modal.Header closeButton>
                    {isEditing ? 'Edit Dashboard' : (isCloning ? 'Clone Dashboard' : 'Add New Dashboard')}
                </Modal.Header>
                <Modal.Body>
                    {error && <Alert variant='danger'>{error}</Alert>}
                    <Form onSubmit={this.onSubmit}>
                        <Form.Group>
                            <Form.Label>Name:   </Form.Label>
                            <Form.Control aria-label="Dashboard name" name="name" type="text" value={name} onChange={this.onChangeHandler}/>
                        </Form.Group>
                        <Form.Group aria-label="Owners">
                            <Form.Label>Owners:&ensp;</Form.Label>
                            <RenderInformativeTooltip field="owners" placement="right" message="Owners will have the permission to visit, edit and delete the dashboard and charts." />                            
                            <UserSearchInput name="owners" persons={owners} onChange={this.onOwnerChangeHandler}/>
                        </Form.Group>
                        <Form.Group aria-label="Members">
                            <Form.Label>Members:&ensp;</Form.Label>
                            <RenderInformativeTooltip field="members" placement="right" message="Members will have the permission to visit the dashboard even if it's private." />                            
                            <UserSearchInput name="members" persons={members} onChange={this.onMemberChangeHandler} />
                        </Form.Group>
                        <Form.Group aria-label="Send emails to">
                            <Form.Label>Send Emails To:&ensp;</Form.Label>
                            <RenderInformativeTooltip field="alerts" placement="right" message="Alerts are always sent to owners and members. People in this field will only receive alerts when the dashboard is public." />                             
                            <UserSearchInput name="email" persons={email} onChange={this.onEmailChangeHandler} />
                        </Form.Group>
                        <IcMSelector serviceList={serviceList} icmServiceInfo={icmServiceInfo} setServiceInfo={this.setServiceInfo} teamList={teamList} icmTeamInfo={icmTeamInfo} teamSelectMessage={teamSelectMessage} setTeamInfo={this.setTeamInfo}></IcMSelector>
                        <Form.Group aria-label="Lookback time">
                            <Form.Label>Lookback time:&ensp;</Form.Label>
                            <RenderInformativeTooltip field="lookback" placement="right" message="This indicates the number of days before today that the graphs will obtain data for." />
                            <Form.Control aria-label="Lookback options" value={lookbackTime} onChange={this.onLookbackTimeChangeHandler} as="select" name="lookbackTime">
                                {['15', '30', '60', '90'].map(
                                    (lookbackTimeIterator) =>
                                        <option value={lookbackTimeIterator} key={lookbackTimeIterator}>{lookbackTimeIterator} days</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <div className="makePrivate">
                                <input type="checkbox" id="make-private" name="isPrivate" checked={isPrivate} onChange={this.onPrivateChangeHandler}/>
                                <label htmlFor="make-private">Make it private&ensp;</label>
                                <RenderInformativeTooltip field="private" placement="right" message="The dashboard will be only visible to owners and members if it's private." />                               
                            </div>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        )
    }
}

export default DashboardAddEditBoardModal