import React, { Component } from 'react'
import uuid from 'react-uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import Pagination from 'react-bootstrap/Pagination'
import Alert from 'react-bootstrap/Alert';
import ApplicationPageService from '../../Dataservices/ApplicationPageService'
import ApplicationPageTableRow from './ApplicationPageTableRow'
import { SortOrderEnum, filterObject, compareString, getNextSortOrder, renderSortMark, getCurrentPageList, getPageNumbersToDisplay } from '../../Functions/TableHelper'
import { LoadingGif } from '../PublicComponents/HelperComponents';
import titles from '../../Store/titles.js'
import { getPageTitle } from '../../Functions/PageTitleHelper'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faRedo);

class Application extends Component {
    constructor(props) {
        super(props)

        this.applicationKeys = {
            None: 'None',
            AppId: 'AppId',
            Name: 'Name'
        }

        this.state = {
            isLoading: true,
            searchText: '',
            rawList: [],
            allList: [],
            displayList: [],
            sortKey: this.applicationKeys.None,
            sortOrder: SortOrderEnum.None,
            currentPage: 0,
            pagination: [],
            error : null
        }
    }

    componentDidMount() {
        this.getApplicationList()
        document.title = getPageTitle(titles.APPLICATION, null)
    }

    getApplicationList = () => {
        this.setState({
            isLoading: true,
            rawList: [],
            displayList: [],
            pagination: []
        })
        ApplicationPageService.getTranslation().then(res => {
            const { searchText, sortKey, sortOrder, currentPage } = this.state
            const raw = res.data.result
            let all = raw.filter(item => filterObject(item, searchText))
            if (sortKey !== this.applicationKeys.None) {
                all.sort(this.compareObject(sortKey, sortOrder === SortOrderEnum.Ascending))
            }
            this.setState({
                isLoading: false,
                rawList: raw,
                allList: all
            }, this.onClickPagination.bind(this, currentPage === 0 ? 1 : currentPage))
        }).catch(err => {
            console.error(err);
            this.setState({error: err.message});
        })
    }

    onChangeSearchText = e => {
        const text = e.target.value
        const { rawList, currentPage, sortKey, sortOrder } = this.state
        const all = rawList.filter(item => filterObject(item, text))
        if (sortKey !== this.applicationKeys.None) {
            all.sort(this.compareObject(sortKey, sortOrder === SortOrderEnum.Ascending))
        }

        this.setState({
            searchText: text,
            allList: all
        }, this.onClickPagination.bind(this, currentPage))
    }

    compareObject = (key, isAscending) => {
        return (obj1, obj2) => {
            return compareString(obj1[key], obj2[key], isAscending, true)
        }
    }

    onSort = key => {
        const { allList, sortKey, sortOrder, currentPage } = this.state
        const nextOrder = getNextSortOrder(sortOrder, key !== sortKey, true)
        const all = allList.sort(this.compareObject(key, nextOrder === SortOrderEnum.Ascending))
        const display = getCurrentPageList(all, currentPage)

        this.setState({
            allList: all,
            displayList: display,
            sortKey: key,
            sortOrder: nextOrder
        })
    }

    onClickPagination = clickedNum => {
        const { allList } = this.state
        const totalPage = Math.ceil(allList.length / 15)
        if(clickedNum > totalPage) {
            clickedNum = 1
        }
        const pages = getPageNumbersToDisplay(clickedNum, totalPage)

        this.setState({
            pagination: pages,
            displayList: getCurrentPageList(allList, clickedNum),
            currentPage: clickedNum
        })
    }

    setError = error => {
        this.setState({error : error.message});
    }

    render() {
        const { allList, displayList, isLoading, sortKey, sortOrder, currentPage, pagination } = this.state
        const totalPage = Math.ceil(allList.length / 15)
        return (
            <Container fluid className="MappingContainer">
                {this.state.error && <Alert variant='danger'>{this.state.error}</Alert>}
                <Row>
                    <Col className="Label">
                        <Button variant="primary" onClick={this.getApplicationList} disabled={isLoading} id="refreshBtn">
                            { isLoading && <span><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />&ensp;</span> }
                            Refresh Application Translations&ensp;
                            <FontAwesomeIcon icon={faRedo}/>
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group className="Label" controlId="mappingSearch">
                                <h3>Translations for Application IDs</h3>
                                <Form.Control type="text" placeholder="Search Translations" name="search" id="search" onChange={this.onChangeSearchText}/>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{overflowX: 'auto'}}>
                            <Table striped bordered hover size="sm" style={{textAlign: 'left'}}>
                                <thead>
                                    <tr>
                                        <th onClick={this.onSort.bind(this, this.applicationKeys.AppId)} className="tableHeader" id="identityHeader">
                                            {renderSortMark(sortKey, sortOrder, this.applicationKeys.AppId)}
                                            Application Identity
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.applicationKeys.Name)} className="tableHeader">
                                            {renderSortMark(sortKey, sortOrder, this.applicationKeys.Name)}
                                            Application Name
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    {displayList.map((item) => (
                                        <ApplicationPageTableRow identity={item.AppId} name={item.Name} key={uuid()} setError={this.setError} />
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        {isLoading && <LoadingGif/>}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Pagination style={{overflowX: 'auto'}}>
                            <Pagination.First disabled={currentPage === 1 || currentPage === 0 ? true : false} onClick={this.onClickPagination.bind(this, 1)}/>
                            <Pagination.Prev disabled={currentPage === 1 || currentPage === 0 ? true : false} onClick={this.onClickPagination.bind(this, currentPage - 1)} />
                            {pagination.map(num => num === 0 ? <Pagination.Ellipsis disabled key={uuid()}/> : <Pagination.Item key={uuid()} onClick={this.onClickPagination.bind(this, num)} active={currentPage === num ? true : false}>{num}</Pagination.Item>)}
                            <Pagination.Next disabled={currentPage === totalPage ? true : false} onClick={this.onClickPagination.bind(this, currentPage + 1)}  />
                            <Pagination.Last disabled={currentPage === totalPage ? true : false} onClick={this.onClickPagination.bind(this, totalPage)}/>
                        </Pagination>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Application