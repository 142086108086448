
import { useState, useEffect, useRef } from 'react';

export function useComponentShown(initial, showDropdown) {
    const [isComponentShown, setIsComponentShown] = useState(initial);
    const ref = useRef();

    const handleClickOutside = (event) => {
        if (ref.current && ref.current.contains(event.target)) {
            setIsComponentShown(true);
        } else {
            setIsComponentShown(false);
        }
    };

    const handleKeyUp = (event) => {
        if (event.key === 'Tab') {
            if (ref.current && ref.current.contains(document.activeElement)) {
                setIsComponentShown(true);
            } else {
                setIsComponentShown(false);
            }
        }
    };

    useEffect(() => {
        if (showDropdown) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keyup', handleKeyUp, true);
            return () => {
                document.removeEventListener('click', handleClickOutside, true);
                document.removeEventListener('keyup', handleKeyUp, true);
            };
        }
    });

    return { ref, isComponentShown };
}