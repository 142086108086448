export const CosmosDataTypeList = ["boolean", "double", "float", "int", "long", "string", ""]

export const SQLDataTypeList = ["bigint", "numeric", "bit", "smallint", "decimal", "smallmoney",
                                "int", "tinyint", "money", "float", "real", "date", "datetimeoffset",
                                "datetime2", "smalldatetime", "datetime", "time", "char", "varchar",
                                "text", "nchar", "nvarchar", "ntext", "binary", "varbinary", "image",
                                "cursor", "rowversion", "hierarchyid", "uniqueidentifier", "sql_variant",
                                "xml", "Spatial Geometry Types", "Spatial Geography Types", "table", "#N/A", ""]

export const SQLKeyword = ["ADD","CONSTRAINT", "ALTER", "COLUMN", "TABLE", "ALL", "AND", "ANY", "AS", "ASC", "DATABASE", "BACKUP", 
                           "BETWEEN", "CASE", "CHECK", "CREATE", "INDEX", "VIEW", "PROCEDURE", "REPLACE", "INDEX", "UNIQUE", "DEFAULT", 
                           "DELETE", "DESC", "DISTINCT", "DROP", "EXEC", "EXISTS", "FOREIGN", "KEY", "FROM", "JOIN", "FULL", "OUTER", "GROUP",
                           "BY", "HAVING", "IN", "INSERT", "INTO", "SELECT", "NULL", "IS", "NOT", "LEFT", "LIKE", "LIMIT", "RIGHT", "OR", "ORDER", 
                           "OUTER", "PRIMARY", "RIGHT", "ROWNUM", "TOP", "SET", "TRUNCATE", "UNION", "ALL", "UNIQUE", "UPDATE", "VALUES", "WHERE"]

export const BooleanList = ["TRUE", "FALSE", ""]

export const ClassList = ["Ring", "Build", "CheckpointKey", "MetricKey", "MetricValue", 
                          "CheckpointValue", "CheckpointDataError", "MetricDataError",
                          "Pivot", "Path", "Latency", "Date", "Hour", "PivotHashLookup", 
                          "PivotHashPrimaryLookup", "Samples", "LatencyBucketFloor", 
                          "LatencyBucketCeiling", "PivotHash", "DataAnomaly", "DimValue", "DimKey", ""]

export const ClassWithRoleList = ["CheckpointKey", "MetricKey", "MetricValue", "CheckpointValue", "CheckpointDataError", "MetricDataError"]

export const ClassWithNullRoleList = ["Pivot", "Path", "Latency", "Date", "Hour", "PivotHashLookup", 
                                      "PivotHashPrimaryLookup", "Samples", "LatencyBucketFloor", 
                                      "LatencyBucketCeiling", "PivotHash", "DataAnomaly", "DimValue", "DimKey"]

export const  CpAndMetricClass = ["CheckpointKey", "MetricKey", "CheckpointValue", "MetricValue"]

export const shortNameList = ["tab", "graphIt", "bpc", "sd", "ed", "pct", "w", "tn", "to", "tt", "type"]

export const GroupClass = ["Pivot", "Path", "Ring"]

export const RoleList = {
    CL: {
        name: "CL",
        regString: new RegExp("(client)|(cl_)"),
        friendlyName: "client"
    },
    FD: {
        name: "FD",
        regString: new RegExp("edge"),
        friendlyName: "edge"
    }, // Edge
    SFH: {
        name: "SFH",
        regString: new RegExp("search_((fh)|(first_hop))"),
        friendlyName: "search_fh_cafe"
    }, // search_fh_cafe
    SLH: {
        name: "SLH",
        regString: new RegExp("search_((lh)|(last_hop))"),
        friendlyName: "client"
    }, // search_lh_cafe
    SBE: {
        name: "SBE",
        regString: new RegExp("search_((be)|(backend))"),
        friendlyName: "search_backend"
    }, // search_backend,
    XFH: {
        name: "XFH",
        regString: new RegExp("xap_((fh)|(first_hop))"),
        friendlyName: "xap_fh_cafe"
    }, // xap_fh_cafe,
    XLH: {
        name: "XLH",
        regString: new RegExp("xap_((lh)|(last_hop))"),
        friendlyName: "xap_lh_cafe"
    }, // xap_lh_cafe,
    NFH1: {
        name: "NFH1",
        regString: null, //Remove due to password check
        friendlyName: "step1_Nuowo_fh_cafe"
    }, // step1_Nuowo_fh_cafe,
    NLH1: {
        name: "NLH1",
        regString: null, //Remove due to password check
        friendlyName: "step1_Nuowo_lh_cafe"
    }, // step1_Nuowo_lh_cafe,
    NBE1: {
        name: "NBE1",
        regString: null, //Remove due to password check
        friendlyName: "step1_Nuowo_backend"
    }, // step1_Nuowo_backend,
    IQ1: {
        name: "IQ1",
        regString: new RegExp("step1_iq"),
        friendlyName: "step1_iq"
    }, // step1_iq,
    NFH2: {
        name: "NFH2",
        regString: null, //Remove due to password check
        friendlyName: "step2_Nuowo_fh_cafe"
    }, // step2_Nuowo_fh_cafe,
    NLH2: {
        name: "NLH2",
        regString: null, //Remove due to password check
        friendlyName: "step2_Nuowo_lh_cafe"
    }, // step2_Nuowo_lh_cafe,
    NBE2: {
        name: "NBE2",
        regString: null, //Remove due to password check
        friendlyName: "step2_Nuowo_backend"
    }, // step2_Nuowo_backend,
    IQ2: {
        name: "IQ2",
        regString: new RegExp("step2_iq"),
        friendlyName: "step2_iq"
    }, // step2_iq,
    FC: {
        name: "FC",
        regString: new RegExp("((fh)|(first_hop))_cafe"),
        friendlyName: "firsthopcafe"
    }, // firsthopcafe,
    LC: {
        name: "LC",
        regString: new RegExp("((lh)|(last_hop))_cafe"),
        friendlyName: "lasthopcafe"
    }, // lasthopcafe,
    BE: {
        name: "BE",
        regString: new RegExp("backend"),
        friendlyName: "backend"
    }, // backend
    GWS: {
        name: "GWS",
        regString: new RegExp("gws"),
        friendlyName: "gws"
    }, // gws
}

export const RoleNameList = [RoleList.CL.name, RoleList.FD.name, RoleList.SFH.name, RoleList.SBE.name, RoleList.XFH.name, RoleList.XLH.name, 
                             RoleList.NFH1.name, RoleList.NLH1.name, RoleList.NBE1.name, RoleList.IQ1.name, RoleList.NFH2.name, RoleList.NLH2.name, 
                             RoleList.NBE2.name, RoleList.IQ2.name, RoleList.FC.name, RoleList.LC.name, RoleList.BE.name, RoleList.GWS.name, ""]

export const fileStatus = {
    Waiting : "Waiting", 
    Uploading: "Uploading", 
    Success: "Success",
    Fail: "Fail"
}

export const classType = {
    Ring: "Ring",
    Build: "Build", 
    CheckpointKey: "CheckpointKey", 
    MetricKey: "MetricKey", 
    MetricValue: "MetricValue", 
    CheckpointValue: "CheckpointValue", 
    CheckpointDataError: "CheckpointDataError", 
    MetricDataError: "MetricDataError",
    Pivot: "Pivot", 
    Path: "Path", 
    Latency: "Latency", 
    Date: "Date", 
    Hour: "Hour", 
    PivotHashLookup: "PivotHashLookup", 
    PivotHashPrimaryLookup: "PivotHashPrimaryLookup", 
    Samples: "Samples", 
    LatencyBucketFloor: "LatencyBucketFloor", 
    LatencyBucketCeiling: "LatencyBucketCeiling", 
    PivotHash: "PivotHash", 
    DataAnomaly: "DataAnomaly", 
    DimValue: "DimValue", 
    DimKey: "DimKey"
}

export const columnTableSchema = {
    ColumnInCosmos: "#Column in Cosmos".toLowerCase(),
    CosmosDataType: "CosmosDataType".toLowerCase(),
    Class: "Class".toLowerCase(),
    PresentInView: "PresentInView".toLowerCase(),
    IsDataFromCosmos: "IsDataFromCosmos".toLowerCase(),
    DimMap: "DimMap".toLowerCase(),
    Role: "Role".toLowerCase(),
    SQLTable: "SQLTable".toLowerCase(),
    SqlColumnName: "SqlColumnName".toLowerCase(),
    SqlDataType: "SqlDataType".toLowerCase(),
    FriendlyName: "FriendlyName".toLowerCase(),
    Dimshortname: "Dimshortname".toLowerCase(),
    FactTableColumnId: "FactTableColumnId".toLowerCase(),
    Idcolsize: "Idcolsize".toLowerCase(),
    SnowFlake: "Snow flake Table".toLowerCase(), 
    SnowFlakeId: "SnowFlakeId".toLowerCase(), 
    SnowFlakeAlias: "SnowFlakeAlias".toLowerCase(),
    SnowFlakeDimTableId: "SnowFlakeDimTableId".toLowerCase(),
    GroupName: "GroupName".toLowerCase(),
    IsGroupCollapsed: "IsGroupCollapsed".toLowerCase(),
    DefaultValueInUI: "DefaultValueInUI".toLowerCase(),
    IsHiddenInUI: "IsHiddenInUI".toLowerCase(),
    IsCalculatedColumn: "IsCalculatedColumn".toLowerCase(),
    CalculationFunction: "CalculationFunction".toLowerCase(),
    AdditionalDetails: "AdditionalDetails".toLowerCase(),
    GroupNum: "GroupNum".toLowerCase(),
    RowNumber: "RowNumber".toLowerCase(),
    RoleGapName: "RoleGapName".toLowerCase(),
    RTTcolumn: "RTT column".toLowerCase(),
    UrlName: "UrlName".toLowerCase(),
    RollupUrlName: "RollupUrlName".toLowerCase(),
    ValidatorRegexName: "ValidatorRegexName".toLowerCase(),
    ValidatorRegexValue: "ValidatorRegexValue".toLowerCase()
}

export const roleTableSchema = {
    Role1: "#Role1".toLowerCase(),
    Role2: "Role2".toLowerCase(),
    Alignment1: "Alignment1".toLowerCase(),
    Alignment2: "Alignment2".toLowerCase(),
    Fallback: "Fallback".toLowerCase()
}

export const logTableScheme = {
    Name: "#Name".toLowerCase(),
    FriendlyName: "FriendlyName".toLowerCase(),
    DefaultVal: "DefaultVal".toLowerCase()
}

export const columnSchemaComment = {
    [columnTableSchema.ColumnInCosmos]: "Column name in cosmos",
    [columnTableSchema.CosmosDataType]: "Data type in cosmos",
    [columnTableSchema.Class]: "Related type in meddileTier",
    [columnTableSchema.PresentInView]: "If this column should be included in input view",
    [columnTableSchema.IsDataFromCosmos]: "If this column is read from cosmos data stream",
    [columnTableSchema.DimMap]: "Dim map used to map app id, client id to friendly name",
    [columnTableSchema.Role]: "Role in path like beckend(BE), firsthopcafe（FC）",
    [columnTableSchema.SQLTable]: "Related sql table in sql server after staging",
    [columnTableSchema.SqlColumnName]: "Related column name in sql table",
    [columnTableSchema.SqlDataType]: "Type of sql column",
    [columnTableSchema.FriendlyName]: "Name shown in perfpanel (no need if not shown)",
    [columnTableSchema.Dimshortname]: "Short name used for join statement, it should be unique for each sql table (no need if flat)",
    [columnTableSchema.FactTableColumnId]: "Column id name in fact table (no need if flat)",
    [columnTableSchema.Idcolsize]: "Column id type in fact table (no need if flat)",
    [columnTableSchema.SnowFlake]: "", 
    [columnTableSchema.SnowFlakeId]: "", 
    [columnTableSchema.SnowFlakeAlias]: "",
    [columnTableSchema.SnowFlakeDimTableId]: "",
    [columnTableSchema.GroupName]: "Group's name shown in perfpanel",
    [columnTableSchema.IsGroupCollapsed]: "If the group should be collapsed in default",
    [columnTableSchema.DefaultValueInUI]: "Default value used in perfpanel",
    [columnTableSchema.IsHiddenInUI]: "If the column should be hidden in perfpanel. Empty means false.",
    [columnTableSchema.IsCalculatedColumn]: "If the column should be calculated. Empty means false.",
    [columnTableSchema.CalculationFunction]: "Calculation function when the column need to be calculated",
    [columnTableSchema.AdditionalDetails]: "Addition details",
    [columnTableSchema.GroupNum]: "The groups' order, the same group name's number should be the same, ascending order",
    [columnTableSchema.RowNumber]: "Columns' order in group",
    [columnTableSchema.RoleGapName]: "Role gap column name, like App_Start",
    [columnTableSchema.RTTcolumn]: "RTT column name, like RTT",
    [columnTableSchema.UrlName]: "Short name shown in url",
    [columnTableSchema.RollupUrlName]: "Rollup short name shown in url",
    [columnTableSchema.ValidatorRegexName]: "",
    [columnTableSchema.ValidatorRegexValue]: ""
}

export const roleSchemaComment = {
    [roleTableSchema.Role1]: "Role 1 in checkpoint merging",
    [roleTableSchema.Role2]: "Role 2 in checkpoint merging",
    [roleTableSchema.Alignment1]: "Checkpoint for role 1",
    [roleTableSchema.Alignment2]: "Checkpoint for role 2",
    [roleTableSchema.Fallback]: ""
}

export const logSchemeComment = {
    [logTableScheme.Name]: "",
    [logTableScheme.FriendlyName]: "",
    [logTableScheme.DefaultVal]: ""
}

export const pageComment = {
    ["cubeName"] : "Cube name, like OWS_FD2BE.",
    ["columnTableName"] : "File name of column definition csv file.", 
    ["roleTableName"] : "File name of role csv file.", 
    ["logTableName"] : "File name of log csv file.", 
    ["owners"] : "Owner list of the cube csv, should be seperated by ';'. ",
    ["columnTableFile"] : "Please upload column definition csv file.",
    ["roleTableFile"] : "Please upload role csv file.", 
    ["logTableFile"] : "Please upload log csv file.", 
    ["tableFile"] : "Please upload csv file.", 
    ["CSVFile"] : "Please upload csv file.", 
    ["roleName"] : "Role name in the request path, connected the word by '_', like first_hop_cafe",
    ["shortRoleName"] : "Abbreviation of the role name, like FC",
    ["viewFile"] : "Input view file used for this cube.",
}

export const listColumn = {
    [columnTableSchema.CosmosDataType]: CosmosDataTypeList,
    [columnTableSchema.Class]: ClassList,
    //[columnTableSchema.Role]: RoleNameList,
    [columnTableSchema.PresentInView]: BooleanList,
    [columnTableSchema.IsDataFromCosmos]: BooleanList,
    //[columnTableSchema.SqlDataType]: SQLDataTypeList,
    [columnTableSchema.IsGroupCollapsed]: BooleanList,
    [columnTableSchema.IsHiddenInUI]: BooleanList,
    [columnTableSchema.IsCalculatedColumn]: BooleanList
}

export const columnTableDefaultRowsCSVForTest = `#Column in Cosmos,CosmosDataType,Class,PresentInView,IsDataFromCosmos,DimMap,Role,SQLTable,SqlColumnName,SqlDataType,FriendlyName,Dimshortname,FactTableColumnId,Idcolsize,GroupName,IsGroupCollapsed,DefaultValueInUI,IsHiddenInUI,IsCalculatedColumn,CalculationFunction,AdditionalDetails,GroupNum,RowNumber,RoleGapName,RTT column,UrlName,RollupUrlName,ValidatorRegexName,ValidatorRegexValue
date,string,Date,TRUE,TRUE,,,[dbo].[FactTable],Date,date,Date,,,,,,,TRUE,FALSE,,,,,,,,,,
backend_build,string,Build,TRUE,TRUE,,BE,[dbo].[Dim_BackendBuild],BackendBuild,varchar(20),,build,BuildId,int,,,,TRUE,FALSE,,,,,,,,,,
backend_ring,string,Ring,TRUE,TRUE,,BE,[dbo].[Dim_BackendRing],BackendRing,varchar(10),Backend Ring,ring,RingId,tinyint,Request Details,FALSE,"""PROD""",FALSE,FALSE,,,1,1,,, r,rr,,
api_name,string,Pivot,TRUE,TRUE,,,[dbo].[Dim_ApiName],ApiName,varchar(256),API,api_name,APINameId,int,API Details,FALSE,,FALSE,FALSE,,,2,1,,, api,rapi,,
api_sub_type,string,Pivot,TRUE,TRUE,,,[dbo].[Dim_ApiSubType],ApiSubType,varchar(2048),API Sub Type,api_sub_type,APISubTypeId,int,API Details,FALSE,,FALSE,FALSE,,,2,2,,, apis,rapis,,
scenario_tag,string,Pivot,TRUE,TRUE,,,[dbo].[Dim_ScenarioTag],ScenarioTag,varchar(256),Tag,tag,TagId,int,API Details,FALSE,,FALSE,FALSE,,,2,3,,, st,rst,,
request_result,string,Pivot,TRUE,TRUE,,,[dbo].[Dim_RequestResult],RequestResult,varchar(50),Request Status,result,RequestStatusId,tinyint,Request Status,FALSE,"""Success"" ",FALSE,FALSE,,,3,2,,,rs,rst,,
http_status,string,Pivot,TRUE,TRUE,,,[dbo].[Dim_HttpStatus],HttpStatus,varchar(5),HTTP Status Code,http,HttpStatusId,smallint,Request Status,FALSE,"""200""| ""201""| ""202""| ""204""",FALSE,FALSE,,,3,1,,, s,rs,,
error_code,string,Pivot,TRUE,TRUE,,,[dbo].[Dim_ErrorCode],ErrorCode,varchar(250),Error Type,err,ErrorId,int,Request Status,FALSE,,FALSE,FALSE,,,3,3,,, ec,rec,,
is_retry,string,Pivot,TRUE,TRUE,,,[dbo].[Dim_IsRetry],IsRetry,varchar(10),Has Retry,retry,RetryId,tinyint,Request Status,FALSE,,FALSE,FALSE,,,3,4,,, hr,rhr,,
data_error,string,DataAnomaly,TRUE,TRUE,,,[dbo].[Dim_DataAnomalyType],DataAnomalyType,varchar(256),Data Anomaly,data_err,DataAnomalyId,int,Request Status,FALSE,,FALSE,FALSE,,,3,5,,, da,rda,,
path_info,string,Path,TRUE,TRUE,,,[dbo].[Dim_PathInfo],PathInfo,varchar(50),Request Path,path,PathId,smallint,Route Info,TRUE,,FALSE,FALSE,,,4,1,,, pt,rpt,,
frontdoor_type,string,Pivot,TRUE,TRUE,,,[dbo].[Dim_FrontDoorType],FrontDoorType,varchar(10),Front Door,fd_type,FrontDoorId,smallint,Route Info,TRUE,,FALSE,FALSE,,,4,2,,, fd,rfd,,
calculated_region,string,Pivot,TRUE,TRUE,,,[dbo].[Dim_CalculatedRegion],CalculatedRegion,varchar(50),Calculated Region,region,CalRegionId,smallint,Server Details,FALSE,,FALSE,FALSE,,,5,1,,, cr,rcr,,
sku_friendly_name,string,Pivot,TRUE,TRUE,,,[dbo].[Dim_SkuFriendlyName],SkuFriendlyName,varchar(128),Hardware SKU,sku_fname,SkuId,smallint,Server Details,FALSE,,FALSE,FALSE,,,5,2,,, sfn,rsfn,,
sku_type,string,Pivot,TRUE,TRUE,,,[dbo].[Dim_SkuDiskType],SkuDiskType,varchar(128),Hardware SKU Type,sku_type,SkuTypeId,smallint,Server Details,FALSE,,FALSE,FALSE,,,5,3,,, dt,rdt,,
request_size_friendly_name,string,Pivot,TRUE,TRUE,,,[dbo].[Dim_FriendlyRequestCeiling],FriendlyRequestCeiling,varchar(50),Request Size,request_fn,RequestId,tinyint,Message Size,TRUE,,FALSE,FALSE,,,6,1,,, rqs,rrqs,,
response_size_friendly_name,string,Pivot,TRUE,TRUE,,,[dbo].[Dim_FriendlyResponseCeiling],FriendlyResponseCeiling,varchar(50),Response Size,response_fn,ResponseId,tinyint,Message Size,TRUE,,FALSE,FALSE,,,6,2,,, rss,rrss,,
user_type,string,Pivot,TRUE,TRUE,,,[dbo].[Dim_IsConsumer],IsConsumer,varchar(50),User Type,user_type,UserTypeId,tinyint,User Details,TRUE,,FALSE,FALSE,,,7,1,,, ut,rut,,
recipient_type,string,Pivot,TRUE,TRUE,,,[dbo].[Dim_RecipientType],RecipientType,varchar(50),Recipient Type,recp_type,RecipientTypeId,tinyint,User Details,TRUE,,FALSE,FALSE,,,7,2,,, sat,rrt,,
bucket_floor,int,LatencyBucketFloor,TRUE,TRUE,,,[dbo].[Dim_LatencyBucket],BucketFloor,int,,lb,LatencyBucketId,tinyint,,,,TRUE,FALSE,,,,,,,,,,
bucket_ceiling,int,LatencyBucketCeiling,TRUE,TRUE,,,[dbo].[Dim_LatencyBucket],BucketCeiling,int,,lb,LatencyBucketId,tinyint,,,,TRUE,FALSE,,,,,,,,,,
edge_checkpoint_key,string,CheckpointKey,TRUE,TRUE,,FD,[dbo].[Dim_CheckpointKey],EdgeCheckpointKey,varchar(1024),edge,ec,EdgeCheckpointKeyId,int,,,,FALSE,FALSE,,,1000,1000,,,,,,
edge_checkpoint_value,string,CheckpointValue,TRUE,TRUE,,FD,[dbo].[FactTable],EdgeCheckpointValue,varchar(1024),edge,,,,,,,FALSE,FALSE,,,1000,1000,,,,,,
fh_cafe_checkpoint_key,string,CheckpointKey,TRUE,TRUE,,FC,[dbo].[Dim_CheckpointKey],FhCheckpointKey,varchar(1024),firsthopcafe,fc,FhCheckpointKeyId,int,,,,FALSE,FALSE,,,1000,1000,,,,,,
fh_cafe_checkpoint_value,string,CheckpointValue,TRUE,TRUE,,FC,[dbo].[FactTable],FhCheckpointValue,varchar(1024),firsthopcafe,,,,,,,FALSE,FALSE,,,1000,1000,,,,,,
lh_cafe_checkpoint_key,string,CheckpointKey,TRUE,TRUE,,LC,[dbo].[Dim_CheckpointKey],LhCheckpointKey,varchar(1024),lasthopcafe,lc,LhCheckpointKeyId,int,,,,FALSE,FALSE,,,1000,1000,,,,,,
lh_cafe_checkpoint_value,string,CheckpointValue,TRUE,TRUE,,LC,[dbo].[FactTable],LhCheckpointValue,varchar(1024),lasthopcafe,,,,,,,FALSE,FALSE,,,1000,1000,,,,,,
backend_checkpoint_key,string,CheckpointKey,TRUE,TRUE,,BE,[dbo].[Dim_CheckpointKey],BackendCheckpointKey,varchar(1024),backend,bc,BackendCheckpointKeyId,int,,,,FALSE,FALSE,,,1000,1000,,,,,,
backend_checkpoint_value,string,CheckpointValue,TRUE,TRUE,,BE,[dbo].[FactTable],BackendCheckpointValue,varchar(1024),backend,,,,,,,FALSE,FALSE,,,1000,1000,,,,,,
edge_correlated_metricKey,string,MetricKey,TRUE,TRUE,,FD,[dbo].[Dim_CorelatedMetricKey],EdgeCorelatedMetricKey,varchar(1024),edge,ecm,EdgeCorelatedMetricKeyId,int,,,,FALSE,FALSE,,,1000,1000,,,,,,
edge_correlated_metricValue,string,MetricValue,TRUE,TRUE,,FD,[dbo].[FactTable],EdgeCorelatedMetricValue,varchar(1024),edge,,,,,,,FALSE,FALSE,,,1000,1000,,,,,,
fh_cafe_correlated_metricKey,string,MetricKey,TRUE,TRUE,,FC,[dbo].[Dim_CorelatedMetricKey],FhCorelatedMetricKey,varchar(1024),firsthopcafe,fcm,FhCorelatedMetricKeyId,int,,,,FALSE,FALSE,,,1000,1000,,,,,,
fh_cafe_correlated_metricValue,string,MetricValue,TRUE,TRUE,,FC,[dbo].[FactTable],FhCorelatedMetricValue,varchar(1024),firsthopcafe,,,,,,,FALSE,FALSE,,,1000,1000,,,,,,
lh_cafe_correlated_metricKey,string,MetricKey,TRUE,TRUE,,LC,[dbo].[Dim_CorelatedMetricKey],LhCorelatedMetricKey,varchar(1024),lasthopcafe,lcm,LhCorelatedMetricKeyId,int,,,,FALSE,FALSE,,,1000,1000,,,,,,
lh_cafe_correlated_metricValue,string,MetricValue,TRUE,TRUE,,LC,[dbo].[FactTable],LhCorelatedMetricValue,varchar(1024),lasthopcafe,,,,,,,FALSE,FALSE,,,1000,1000,,,,,,
backend_correlated_metricKey,string,MetricKey,TRUE,TRUE,,BE,[dbo].[Dim_CorelatedMetricKey],BackendCorelatedMetricKey,varchar(1024),backend,bcm,BackendCorelatedMetricKeyId,int,,,,FALSE,FALSE,,,1000,1000,,,,,,
backend_correlated_metricValue,string,MetricValue,TRUE,TRUE,,BE,[dbo].[FactTable],BackendCorelatedMetricValue,varchar(1024),backend,,,,,,,FALSE,FALSE,,,1000,1000,,,,,,
samples,long,Samples,FALSE,TRUE,,,[dbo].[FactTable],Samples,bigint,,,,,,,,TRUE,FALSE,,,,,,,,,,
end_to_end_latency,long,Latency,TRUE,TRUE,,,[dbo].[FactTable],EndToEndLatency,bigint,,,,,,,,TRUE,FALSE,,,,,,,,,,
pivot_hash,long,PivotHash,FALSE,FALSE,,,[dbo].[FactTable],PivotHash,bigint,,,,,,,,TRUE,FALSE,,,,,,,,,,
edge_trace_id,string,PivotHashLookup,TRUE,TRUE,,,[dbo].[FactTable],EdgeTraceId,#N/A,,,,,,,,TRUE,FALSE,,,,,,,,,,
request_id,string,PivotHashPrimaryLookup,TRUE,TRUE,,,[dbo].[FactTable],RequestId,#N/A,,,,,,,,TRUE,FALSE,,,,,,,,,,
hour,string,Hour,TRUE,TRUE,,,,,#N/A,,,,,,,,TRUE,FALSE,,,,,,,,,,`

export const roleTableDefaultRowsCSVForTest = `#Role1,Role2,Alignment1,Alignment2,Fallback
FD,FC,FB_AS_TO_FD,WinHttp_HeadersAvailable,
FD,FC,LB_FD_TO_CL,IIS_BeginWrite_Last,
FD,LC,FB_AS_TO_FD,WinHttp_HeadersAvailable,
FD,LC,LB_FD_TO_CL,IIS_BeginWrite_Last,
FC,LC,WinHttp_HeadersAvailable,WinHttp_HeadersAvailable,
FC,LC,IIS_BeginWrite_Last,IIS_BeginWrite_Last,
FC,LC,WinHttp_DataAvailable_Last,WinHttp_DataAvailable_Last,
LC,BE,WinHttp_DataAvailable_Last,AERC,
LC,BE,IIS_BeginWrite_Last,ODR_LOSW,
LC,BE,WinHttp_HeadersAvailable,APSRH,
LC,BE,WinHttp_HeadersAvailable,AERC,fb`

export const logTableDefaultRowsCSVForTest = `#Name,FriendlyName,DefaultVal
OWS,OWS Log,TRUE
AD,AD Log,FALSE
RUM,RUM Log,FALSE
GLS,GLS Log,FALSE
CAFEOWA,Cafe Log,FALSE
EDGEPROD,Edge Exo Prod Log,FALSE
EDGEREST,Edge Exo Rest Log,FALSE
EDGEOUTLOOK,Edge Exo Outlook Log,FALSE
EDGEDEFAULT,Edge Default Log,FALSE`

export const columnTableDefaultRowsCSVNonFlat = `#Column in Cosmos,CosmosDataType,Class,PresentInView,IsDataFromCosmos,DimMap,Role,SQLTable,SqlColumnName,SqlDataType,FriendlyName,Dimshortname,FactTableColumnId,Idcolsize,GroupName,IsGroupCollapsed,DefaultValueInUI,IsHiddenInUI,IsCalculatedColumn,CalculationFunction,AdditionalDetails,GroupNum,RowNumber,RoleGapName,RTT column,UrlName,RollupUrlName,ValidatorRegexName,ValidatorRegexValue
date,string,Date,TRUE,TRUE,,,[dbo].[FactTable],Date,date,Date,,,,,,,TRUE,FALSE,,,,,,,,,,
backend_build,string,Build,TRUE,TRUE,,BE,[dbo].[Dim_BackendBuild],BackendBuild,varchar(20),,build,BuildId,int,,,,TRUE,FALSE,,,,,,,,,,
backend_ring,string,Ring,TRUE,TRUE,,BE,[dbo].[Dim_BackendRing],BackendRing,varchar(10),Backend Ring,ring,RingId,tinyint,Request Details,FALSE,"""PROD""",FALSE,FALSE,,,1,1,,, r,rr,,
backend_checkpoint_key,string,CheckpointKey,TRUE,TRUE,,BE,[dbo].[Dim_CheckpointKey],BackendCheckpointKey,varchar(1024),backend,bc,BackendCheckpointKeyId,int,,,,FALSE,FALSE,,,1000,1000,,,,,,
backend_checkpoint_value,string,CheckpointValue,TRUE,TRUE,,BE,[dbo].[FactTable],BackendCheckpointValue,varchar(1024),backend,,,,,,,FALSE,FALSE,,,1000,1000,,,,,,
backend_correlated_metricKey,string,MetricKey,TRUE,TRUE,,BE,[dbo].[Dim_CorelatedMetricKey],BackendCorelatedMetricKey,varchar(1024),backend,bcm,BackendCorelatedMetricKeyId,int,,,,FALSE,FALSE,,,1000,1000,,,,,,
backend_correlated_metricValue,string,MetricValue,TRUE,TRUE,,BE,[dbo].[FactTable],BackendCorelatedMetricValue,varchar(1024),backend,,,,,,,FALSE,FALSE,,,1000,1000,,,,,,
samples,long,Samples,FALSE,TRUE,,,[dbo].[FactTable],Samples,bigint,,,,,,,,TRUE,FALSE,,,,,,,,,,
end_to_end_latency,long,Latency,TRUE,TRUE,,,[dbo].[FactTable],EndToEndLatency,bigint,,,,,,,,TRUE,FALSE,,,,,,,,,,
pivot_hash,long,PivotHash,FALSE,FALSE,,,[dbo].[FactTable],PivotHash,bigint,,,,,,,,TRUE,FALSE,,,,,,,,,,
request_id,string,PivotHashPrimaryLookup,TRUE,TRUE,,,[dbo].[FactTable],RequestId,#N/A,,,,,,,,TRUE,FALSE,,,,,,,,,,
hour,string,Hour,TRUE,TRUE,,,,,#N/A,,,,,,,,TRUE,FALSE,,,,,,,,,,`

export const columnTableDefaultRowsCSVFlat = `#Column in Cosmos,CosmosDataType,Class,PresentInView,IsDataFromCosmos,DimMap,Role,SQLTable,SqlColumnName,SqlDataType,FriendlyName,Dimshortname,FactTableColumnId,Idcolsize,GroupName,IsGroupCollapsed,DefaultValueInUI,IsHiddenInUI,IsCalculatedColumn,CalculationFunction,AdditionalDetails,GroupNum,RowNumber,RoleGapName,RTT column,UrlName,RollupUrlName,ValidatorRegexName,ValidatorRegexValue
date,string,Date,TRUE,TRUE,,,[dbo].[FactTable],Date,date,Date,,,,,,,TRUE,FALSE,,,,,,,,,,
backend_build,string,Build,TRUE,TRUE,,BE,[dbo].[FactTable],BackendBuild,varchar(20),,,,,,,,TRUE,FALSE,,,,,,,,,,
backend_ring,string,Ring,TRUE,TRUE,,BE,[dbo].[FactTable],BackendRing,varchar(10),Backend Ring,,,,Request Details,FALSE,"""PROD""",FALSE,FALSE,,,1,1,,, r,rr,,
backend_checkpoint_key,string,CheckpointKey,TRUE,TRUE,,BE,[dbo].[FactTable],BackendCheckpointKey,varchar(1024),backend,,,,,,,FALSE,FALSE,,,1000,1000,,,,,,
backend_checkpoint_value,string,CheckpointValue,TRUE,TRUE,,BE,[dbo].[FactTable],BackendCheckpointValue,varchar(1024),backend,,,,,,,FALSE,FALSE,,,1000,1000,,,,,,
backend_correlated_metricKey,string,MetricKey,TRUE,TRUE,,BE,[dbo].[FactTable],BackendCorelatedMetricKey,varchar(1024),backend,,,,,,,FALSE,FALSE,,,1000,1000,,,,,,
backend_correlated_metricValue,string,MetricValue,TRUE,TRUE,,BE,[dbo].[FactTable],BackendCorelatedMetricValue,varchar(1024),backend,,,,,,,FALSE,FALSE,,,1000,1000,,,,,,
samples,long,Samples,FALSE,TRUE,,,[dbo].[FactTable],Samples,bigint,,,,,,,,TRUE,FALSE,,,,,,,,,,
end_to_end_latency,long,Latency,TRUE,TRUE,,,[dbo].[FactTable],EndToEndLatency,bigint,,,,,,,,TRUE,FALSE,,,,,,,,,,
pivot_hash,long,PivotHash,FALSE,FALSE,,,[dbo].[FactTable],PivotHash,bigint,,,,,,,,TRUE,FALSE,,,,,,,,,,
request_id,string,PivotHashPrimaryLookup,TRUE,TRUE,,,[dbo].[FactTable],RequestId,#N/A,,,,,,,,TRUE,FALSE,,,,,,,,,,
hour,string,Hour,TRUE,TRUE,,,,,#N/A,,,,,,,,TRUE,FALSE,,,,,,,,,,`

export const roleTableDefaultRowsCSV = `#Role1,Role2,Alignment1,Alignment2,Fallback`

export const logTableDefaultRowsCSV = `#Name,FriendlyName,DefaultVal`

export const appTableDefaultCSV = `#AppNameSQLColumn,AppIdSQLColumn,DimTableName` 

export const mixerTableDefaultCSV = `#MinimumNoOfSamples,NoOfPartitionsForCombiner,PartitionByColumnNames,RollupValue,IsDebugFlagString,PivotValueOccurancesFuncName,MakeCopiesFuncName` 

export const UploadType = {
    Cube: "Cube",
    Template: "Template"
}

export const UploadTypeList = [UploadType.Cube, UploadType.Template]

export const ScenarioList = ["Empty", "FD2BE", "Backend"]