import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import React, { Component, useState } from 'react';
import { withRouter } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import ExpertInsightsDataService from '../../Dataservices/ExpertInsightsDataService';
import RCADataService from '../../Dataservices/RCADataService';
import RCANegativeFeedBackModal from '../../Components/RCAComponents/RCANegativeFeedBackModal';
import RCAPositiveFeedBackModal from '../../Components/RCAComponents/RCAPositiveFeedBackModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { faThumbsDown, faThumbsUp, } from '../../../node_modules/@fortawesome/free-solid-svg-icons/index';
import uuid from 'react-uuid';
import { getCubeFromURL, getStartDateFromUrl, getEndDateFromUrl } from '../../Functions/UrlHelper';
import { convertStringToUTCDate } from '../../Functions/DateTimeHelper';
import { getPageTitle } from '../../Functions/PageTitleHelper';
import titles from '../../Store/titles.js';
import { library } from "@fortawesome/fontawesome-svg-core";
import ProgressiveChart from '../ChartComponents/ProgressiveChart';
import { ScenarioTags } from '../../Dataservices/ChartService';

library.add(faCaretDown, faCaretUp);

function CollapsibleRuleExplanation(props) {
    const [isOpen, setIsOpen] = useState(false);
    return <span onClick={() => setIsOpen(!isOpen)}>
        {props.rule.Name}
        <FontAwesomeIcon className="float-right" icon={isOpen ? faCaretUp : faCaretDown} />
        {isOpen && <Card body>{props.rule.Definition}</Card>}
    </span>;
}

export class Insights extends Component {

    constructor(props) {
        super(props);

        var params = new URLSearchParams(document.location.search);
        console.log(params);

        this.state = {
            chartId: params.get("chartID") || "0",
            alertId: params.get("alertID") || "0",
            isLoadingRules: true,
            isLoadingChart: true,
            ruleData: [],
            ruleHeaders: [{ name: "Rule", sort: "Name" }]
                .concat(
                    params.get("alertID") ?
                        [
                            { name: "Before Regression", sort: "SetARuleState" },
                            { name: "After Regression", sort: "SetBRuleState" }
                        ]
                        : [])
                .concat({ name: "Recommendation", sort: "Recommendation" }),
            gettingAnalysisError: null,
            gettingRCAError: null,
            gettingChartError: null,
            tabType: null,
            chartType: null,
            chartTitle: null,
            staticThreshold: null,
            sourceUrl: null,
            checkpointChart: null,
            metricsChart: null,
            dailyStartDate: null,
            dailyEndDate: null,
            hourlyStartDate: null,
            hourlyEndDate: null,
            isLoadingRCA: true,
            isLoadingRegressionInfo: true,
            requestRCAStatus: null,
            rcaResults: null,
            rcaLogId: null,
            legend: null,
            diff: null,
            regressionDate: null,
            showPositiveFeedbackModal: false,
            showNegativeFeedbackModal: false,
            showMessageModal: false,
            currentResultId: null,
            currentUser: null
        };


    }


    componentDidMount() {
        document.title = getPageTitle(titles.INSIGHTS, null);

        let id = this.state.alertId
        let isAlert = true

        if (id === "0") {
            id = this.state.chartId
            isAlert = false
        }

        RCADataService.getRCAStatus(id, isAlert).then(res => {
            if (res.data.IsSuccess) {
                this.setState({
                    isLoadingRCA: false,
                    isLoadingRegressionInfo: false,
                    requestRCAStatus: res.data.Status,
                    rcaResults: res.data.Results,
                    rcaLogId: res.data.LogId,
                    legend: res.data.Legend,
                    regressionDate: res.data.RegressionDate,
                    tabType: res.data.ChartType,
                    diff: res.data.Diff ?? 0,
                    gettingRCAError: null
                })
            } else {
                this.setState({
                    gettingRCAError: res.data.ErrorMessage
                })
                console.error(res.data.ErrorMessage)
            }
        }).catch(err => {
            console.error(err)
        });


        ExpertInsightsDataService.chart({ alertID: this.state.alertId, chartId: this.state.chartId }).then(res => {
            const dailyStartDate = convertStringToUTCDate(getStartDateFromUrl(res.data.url));
            const dailyEndDate = convertStringToUTCDate(getEndDateFromUrl(res.data.url));
            const hourlyStartDate = null; // placeholder for hourly cube support
            const hourlyEndDate = null; // placeholder for hourly cube support

            this.setState({
                chartType: res.data.type,
                chartTitle: res.data.chartTitle,
                staticThreshold: res.data.staticThreshold,
                sourceUrl: res.data.url,
                checkpointChart: res.data.checkpointurl,
                metricsChart: res.data.metricsurl,
                dailyStartDate: dailyStartDate,
                dailyEndDate: dailyEndDate,
                hourlyStartDate: hourlyStartDate,
                hourlyEndDate: hourlyEndDate,
            });
        }).then(() => {

            // if chart, do different call
            if (this.state.alertId === "0") {
                let cubeName = getCubeFromURL(this.state.sourceUrl);

                ExpertInsightsDataService.ruleResultForChart({ alertID: this.state.alertId, chartId: this.state.chartId }, cubeName).then(res => {
                    this.setState({ ruleData: res.data, isLoadingRules: false, isLoadingChart: false, gettingAnalysisError: null });
                }).catch(rulesErr => {
                    let error = rulesErr.response.data;
                    if (rulesErr.response && rulesErr.response.data && rulesErr.response.data.ExceptionMessage) {
                        error = rulesErr.response.data.ExceptionMessage;
                    }
                    this.setState({ isLoadingRules: false, isLoadingChart: false, gettingAnalysisError: error });
                })
            }
            else {
                //if alert do this call
                ExpertInsightsDataService.ruleResultForAlert({ alertID: this.state.alertId, chartId: this.state.chartId }).then(res => {
                    this.setState({ ruleData: res.data, isLoadingRules: false, isLoadingChart: false, gettingAnalysisError: null });
                }).catch(rulesErr => {
                    let error = rulesErr.response.data;
                    if (rulesErr.response && rulesErr.response.data && rulesErr.response.data.ExceptionMessage) {
                        error = rulesErr.response.data.ExceptionMessage;
                    }
                    this.setState({ isLoadingRules: false, isLoadingChart: false, gettingAnalysisError: error });
                })
            }
        }).catch(err => {
            console.log(err);
            this.setState({ gettingChartError: "Failed to get Chart", isLoadingChart: false, isLoadingRules: false });
        })
    }

    triggerRCARequest = () => {
        const { loginUser } = this.props
        this.setState({
            isLoadingRCA: true,
            showMessageModal: true
        });

        let request = {}
        request.Owner = loginUser.userName
        request.ChartId = this.state.chartId
        request.AlertId = this.state.alertId

        RCADataService.triggerRCA(request).then(res => {
            if (res.data.IsSuccess) {
                this.setState({
                    isLoadingRCA: false,
                    requestRCAStatus: "Running",
                    gettingRCAError: null
                });
            } else {
                console.error(res.data.ErrorMessage)
                this.setState({
                    isLoadingRCA: false,
                    gettingRCAError: res.data.ErrorMessage
                });
            }
        }).catch(err => {
            console.log(err)
        });
    }

    analysisPage = (url) => {
        window.open(url)
    }

    toggleAddPositiveFeedbackModal = (id) => {
        this.setState({
            currentResultId: id
        });
        this.setState(prevState => {
            return { showPositiveFeedbackModal: !prevState.showPositiveFeedbackModal }
        })
    }

    toggleAddNegativeFeedbackModal = (id) => {
        this.setState({
            currentResultId: id
        });
        this.setState(prevState => {
            return { showNegativeFeedbackModal: !prevState.showNegativeFeedbackModal }
        })
    }

    toggleMessageModal = () => { this.setState(prevState => { return { showMessageModal: !prevState.showMessageModal } }) }

    renderExpertInsightsPane() {
        return (
            <Col>
                {this.state.gettingAnalysisError && <Alert variant='danger'>
                    {this.state.gettingAnalysisError}
                </Alert>}
                <Card>
                    <Card.Header>Expert Insights</Card.Header>
                    {this.state.isLoadingRules && <div><img alt="gears.gif" src="gears.gif" style={{ marginTop: "5%", marginBottom: "5%" }} /></div>}
                    {this.state.chartId !== "0" && <Alert variant='warning'>Rules have been run with all pivots rolled up.</Alert>}
                    {this.state.ruleData && this.state.ruleData.length > 0 &&
                        <Table responsive>
                            <thead>
                                <tr key={uuid()}>
                                    {this.state.ruleHeaders.map(header => (
                                        <th key={uuid()}>{header.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.ruleData.map(rule => (
                                    <tr key={uuid()}>
                                        <td><CollapsibleRuleExplanation rule={rule} /></td>
                                        {this.state.alertId !== "0" && <><td>{rule.SetARuleState}</td><td>{rule.SetBRuleState}</td></>}
                                        <td>{rule.Recommendation}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    }
                    {!this.state.isLoadingRules && (!this.state.ruleData || this.state.ruleData.length < 1) &&
                        <Card.Body>No Expert Insights were identified for this scenario</Card.Body>
                    }
                </Card>
            </Col>
        );
    }

    renderRegressionInfo() {
        return (
            <Col>
                <Card>
                    <Card.Header>Regression Info</Card.Header>
                    {this.state.isLoadingRegressionInfo && <div><img alt="gears.gif" src="gears.gif" style={{ marginTop: "5%", marginBottom: "5%" }} /></div>}
                    {!this.state.isLoadingRegressionInfo && <Table>
                        <tr>
                            <td style={{ fontWeight: 'bold' }} >Chart Type</td>
                            <td>{this.state.tabType}</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: 'bold' }} >Legend</td>
                            <td>{this.state.legend}</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: 'bold' }} >Regression Date</td>
                            <td>{this.state.regressionDate}</td>
                        </tr>
                        {this.state.alertId != 0 && <tr>
                            <td style={{ fontWeight: 'bold' }} >Difference</td>
                            <td>{this.state.diff}</td>
                        </tr>}
                    </Table>}
                </Card>
            </Col>
        );
    }

    renderRCAPane() {
        const { loginUser } = this.props
        return (
            <Col>
                {this.state.gettingRCAError && <Alert variant='danger'>
                    {this.state.gettingRCAError}
                </Alert>}
                <RCAPositiveFeedBackModal showModal={this.state.showPositiveFeedbackModal} toggleModal={this.toggleAddPositiveFeedbackModal} loginUser={loginUser} resultId={this.state.currentResultId} />
                <RCANegativeFeedBackModal showModal={this.state.showNegativeFeedbackModal} toggleModal={this.toggleAddNegativeFeedbackModal} loginUser={loginUser} resultId={this.state.currentResultId} />
                {this.state.isLoadingRCA && <div className="overlay"><img alt="gears.gif" className="center" src="gears.gif" /></div>}
                <Card>
                    <Card.Header>Regression Cohort Analyzer</Card.Header>
                    {this.state.requestRCAStatus == "Ready" &&
                        <Card.Body>
                            <Card.Text>
                                Try our ML-based Regression Cohort Analyzer to get more insights and diagnose the regression quicker!
                            </Card.Text>
                            <Button variant="primary" onClick={this.triggerRCARequest}>
                                Trigger RCA
                            </Button>
                        </Card.Body>
                    }
                    {this.state.requestRCAStatus == "Finished" &&
                        <Card.Body>
                            <Card.Text>
                                The following pivot combinations are found to be highly relevent with this latency regression:
                            </Card.Text>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#Rank</th>
                                        <th>Pivot Combination</th>
                                        <th>Description</th>
                                        <th>Analysis Link</th>
                                        <th>Is It Helpful?</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.state.rcaResults.map((row, index) => (
                                        <tr
                                            key={index + 1}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <td component="th" scope="row">
                                                {index + 1}
                                            </td>
                                            <td style={{ whiteSpace: 'break-spaces' }} align="left">{row.Combination.replaceAll(";", "\r\n")}</td>
                                            <td>{row.Description}</td>
                                            <td><Button HorizontalAlign="Center" variant="primary" onClick={this.analysisPage.bind(this, row.AnalysisLink)}>
                                                Analysis
                                            </Button></td>
                                            <td><Button onClick={this.toggleAddPositiveFeedbackModal.bind(this, row.ResultId)} style={{ backgroundColor: "transparent", border: "none", width: "50%" }} ><FontAwesomeIcon icon={faThumbsUp} style={{ color: "gray" }} /></Button>
                                                <Button onClick={this.toggleAddNegativeFeedbackModal.bind(this, row.ResultId)} style={{ backgroundColor: "transparent", border: "none", width: "50%" }} ><FontAwesomeIcon icon={faThumbsDown} style={{ color: "gray" }} /></Button>
                                            </td>
                                        </tr>

                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    }
                    {this.state.requestRCAStatus == "NotAvailable" &&
                        <Card.Body>RCA is not available for this scenario</Card.Body>
                    }
                    {this.state.requestRCAStatus == "Running" &&
                        <Card.Body>The RCA request has been triggered successfully. Please note that this process could take a few minutes.
                            Once Completed, we will send you an email, and you can view the results here.</Card.Body>
                    }
                </Card>
            </Col>
        );
    }

    render() {
        const sourceChart = { url: this.state.sourceUrl, title: this.state.chartTitle, id: 0, alertList: { ChartId: 0, StaticAlerts: [], DynamicAlerts: [], GoalLines: [] } };
        const checkpointChart = { url: this.state.checkpointChart, title: "Checkpoint Chart", id: 1, alertList: { ChartId: 1, StaticAlerts: [], DynamicAlerts: [], GoalLines: [] } };
        const metricsChart = { url: this.state.metricsChart, title: "Metrics Chart", id: 2, alertList: { ChartId: 2, StaticAlerts: [], DynamicAlerts: [], GoalLines: [] } };
        const { cubes, loginUser } = this.props
        const { dailyStartDate, dailyEndDate, hourlyStartDate, hourlyEndDate, showMessageModal } = this.state;
        const containerStyle = { height: '450px', width: '100%' };
        return (
            <Container fluid>
                <Modal show={showMessageModal} onHide={this.toggleMessageModal}>
                    <Modal.Header closeButton>
                        <Modal.Title> RCA request received </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        We are processing you request and it will take a while. Please check the status later.
                    </Modal.Body>
                    <Button variant="primary" onClick={this.toggleMessageModal}> OK </Button>
                </Modal>
                <Row>
                    <h1 style={{ textAlign: 'center', width: '100%' }}>Insights</h1>
                    <hr style={{ textAlign: 'center', width: '100%' }}></hr>
                </Row>
                <Row>
                    <h2 style={{ textAlign: 'center', width: '100%' }}>
                        {this.state.chartType === "Regression" && <span style={{ color: '#ff0800' }}>
                            {this.state.chartType}
                        </span>}
                        {this.state.chartType === "Improvement" && <span style={{ color: '#008000' }}>
                            {this.state.chartType}
                        </span>}
                        {this.state.chartType !== "Regression" && this.state.chartType !== "Improvement" && <span>
                            {this.state.chartType}
                        </span>}
                    </h2>
                    <hr style={{ textAlign: 'center', width: '100%' }}></hr>
                </Row>
                <Row>
                    <Col style={{ width: "50%" }}>
                        {this.state.sourceUrl &&
                            <Row>
                                <div style={containerStyle}>
                                    <Button aria-label="Open chart in new tab" variant="light" onClick={() => window.open(this.state.sourceUrl)} className="insightPopout float-right"><FontAwesomeIcon icon={faShareSquare} /></Button>
                                    <ProgressiveChart
                                        cubes={cubes}
                                        chart={sourceChart}
                                        dailyStartDate={dailyStartDate}
                                        dailyEndDate={dailyEndDate}
                                        hourlyStartDate={hourlyStartDate}
                                        hourlyEndDate={hourlyEndDate}
                                        loginUser={loginUser}
                                        isInExpertInsight
                                        scenarioTags={ScenarioTags.Insights}
                                    />
                                </div>
                            </Row>}
                        {this.state.checkpointChart &&
                            <Row>
                                <div style={containerStyle}>
                                    <Button aria-label="Open chart in new tab" variant="light" onClick={() => window.open(this.state.checkpointChart)} className="insightPopout float-right"><FontAwesomeIcon icon={faShareSquare} /></Button>
                                    <ProgressiveChart
                                        cubes={cubes}
                                        chart={checkpointChart}
                                        dailyStartDate={dailyStartDate}
                                        dailyEndDate={dailyEndDate}
                                        hourlyStartDate={hourlyStartDate}
                                        hourlyEndDate={hourlyEndDate}
                                        loginUser={loginUser}
                                        isInExpertInsight
                                        scenarioTags={ScenarioTags.Insights}
                                    />
                                </div>
                            </Row>}
                        {this.state.metricsChart &&
                            <Row>
                                <div style={containerStyle}>
                                    <Button aria-label="Open chart in new tab" variant="light" onClick={() => window.open(this.state.metricsChart)} className="insightPopout float-right"><FontAwesomeIcon icon={faShareSquare} /></Button>
                                    <ProgressiveChart
                                        cubes={cubes}
                                        chart={metricsChart}
                                        dailyStartDate={dailyStartDate}
                                        dailyEndDate={dailyEndDate}
                                        hourlyStartDate={hourlyStartDate}
                                        hourlyEndDate={hourlyEndDate}
                                        loginUser={loginUser}
                                        isInExpertInsight
                                        scenarioTags={ScenarioTags.Insights}
                                    />
                                </div>
                            </Row>}
                    </Col>
                    <Col>
                        {this.renderRegressionInfo()}
                        <div class="p-4"></div>
                        {this.renderExpertInsightsPane()}
                        <div class="p-4"></div>
                        {this.renderRCAPane()}
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(Insights);