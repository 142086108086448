import React, { useState, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { ArrowSort16Regular } from '@fluentui/react-icons'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import Pagination from 'react-bootstrap/Pagination'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import uuid from 'react-uuid'
import './ChartComponents.css'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add( faCheckSquare, faSquare, faTimes );
const legendItemHeight = 19;

export function Legend(props) {

    //#region Initialization    
    let getInitialSortOrder = () => {
        let originalOrder = {};
        let index = 0;
        props.data.forEach(series => {originalOrder[series.name] = index++;})
        return originalOrder;
    }
    
    const [sortOrderObj, setSortOrderObject] = useState(getInitialSortOrder());
    const [initialSortOrder, setInitialSortOrder] = useState(getInitialSortOrder());
    const [legendFilterText, setLegendFilterText] = useState('');
    const [prevUpdatedTime, setPrevUpdatedTime] = useState(null);

    if (props.updatedTime !== prevUpdatedTime) {
        let sortOrder = getInitialSortOrder();
        setSortOrderObject(sortOrder);
        setInitialSortOrder(sortOrder);
        setPrevUpdatedTime(props.updatedTime);
    }
    //#endregion

    //#region Sorting
    let getChartLegendSums = (type) => {
        let seriesList = type === "self" ? props.data : props.alternateSortData.serverData;
        let seriesSums = [];
        seriesList.forEach(series => {
            let sum = 0;
            let seriesData = type === "self" ? series.data : series.Data;
            for (let i = 0; i < seriesData.length; i++){
                sum += seriesData[i].y;
            };
            seriesSums.push({ name: type === "self" ? series.name : series.Name, sum: sum });
        });

        let sortFunc = function(a,b) {
            if (a.sum < b.sum)
                return 1;
            if (a.sum > b.sum)
                return -1;
            return a.name.localeCompare(b.name);
        };

        seriesSums.sort(sortFunc);
        let seriesNames = {};
        for(let i = 0; i < seriesSums.length; i++) {
            seriesNames[seriesSums[i].name] = i;
        }
        return seriesNames;
    }

    let alphaSort = () => {
        let seriesNames = [];
        props.data.forEach(series => {seriesNames.push(series.name)})
        seriesNames.sort();
        let seriesLookup = {};
        for(let i = 0; i < seriesNames.length; i++) {
            seriesLookup[seriesNames[i]] = i;
        }
        setSortOrderObject(seriesLookup);
    }

    let defaultSort = () =>
    {
        setSortOrderObject(initialSortOrder);
    }

    let valueSort = () => {
        setSortOrderObject(getChartLegendSums("self"));
    }

    let alternateSort = () => {
        setSortOrderObject(getChartLegendSums("alternate"));
    }
    //#endregion

    //#region Filtering
    let filterLegend = (filter) => {
        filter = filter.toLowerCase();
        setLegendFilterText(filter);
    };
    //#endregion

    //#region Toggle series
    let toggleAll = () => {
        props.toggleAll(true, true);
    };

    let clearAll = () => {
        props.toggleAll(false, true);
    }

    let toggleSeries = (e, name) => {
        let ctrlKeyPressed = e.metaKey || e.ctrlKey;
        if(!ctrlKeyPressed){
            props.toggleAll(false, false);
        }
        props.toggleSingle(name);
    }
    //#endregion

    let renderSortBtn = React.forwardRef(({ children, onClick }, ref) => (
            <Button aria-label="Sort legend" variant="light" size="sm" ref={ref} onClick={e => {
                e.preventDefault()
                onClick(e)
            }}>
                {children}
            </Button>
        
    ));

    let legendItems = [...props.data].sort((a,b) => sortOrderObj[a.name] - sortOrderObj[b.name])
                                     .filter(item => ( (legendFilterText === '' || item.name.toLowerCase().indexOf(legendFilterText) !== -1 ) && item.name !== "Goal line")) 
                                     .map(item => {
                                        return (<div key={uuid()} tabIndex={0} onClick={(e) => {toggleSeries(e, item.name)}} onKeyDown={(e) => {if (e.key === 'Enter' || e.key === 'Space') toggleSeries(e, item.name)}} style={item.visible ? {color : '#333333', height: legendItemHeight + 'px'} : {color: '#CCCCCC', height: legendItemHeight + 'px'}}>
                                                    <span style={{color: item.color}}>{'\u25CF'}</span>{item.name}
                                                </div>)
                                        });

    return (
        <Container className="legendContainer">
            <Row style={{border: "1px solid #e5e5e5", borderRight: "none"}}>
                <InputGroup size="sm" className="legendControls">
                    <div className="legendFilterBox">
                        {legendFilterText && <FontAwesomeIcon icon={faTimes} className="legendFilterClear" onClick={filterLegend.bind(this, '')}/>}
                        <FormControl 
                            type="text" 
                            placeholder={props.isInDashboard ? "Filter" : "Filter Legends"}
                            className="legendFilterInput" 
                            value={legendFilterText} 
                            name="legendFilter"
                            onChange={(e) => filterLegend(e.target.value)}/>
                    </div>
                    
                    <InputGroup.Append>
                        <Dropdown>
                            <Dropdown.Toggle as={renderSortBtn} id="dropdown-custom-components" variant="light">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id={`tooltip-sort-series`}>
                                            Sort
                                        </Tooltip>
                                    }
                                >
                                    <ArrowSort16Regular />
                                </OverlayTrigger>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item variant="light" onClick={alphaSort}>Sort alphabetically</Dropdown.Item>
                                <Dropdown.Item variant="light" onClick={defaultSort}>Sort by default</Dropdown.Item>
                                <Dropdown.Item variant="light" onClick={valueSort}>Sort by value</Dropdown.Item>
                                {props.alternateSortData &&  <Dropdown.Item variant="light" onClick={alternateSort}>{props.tab === "usage" ? "Sort by latency" : "Sort by sample count"}</Dropdown.Item>}
                            </Dropdown.Menu>
                        </Dropdown>
                    
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-all-series`}>
                                    Select all series
                                </Tooltip>
                            }
                            >
                            <Button variant="light" aria-label="Select all series" onClick={toggleAll}><FontAwesomeIcon icon={faCheckSquare}/></Button>
                        </OverlayTrigger>
                        
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-none-series`}>
                                    Select none series
                                </Tooltip>
                            }
                            >
                            <Button variant="light" aria-label="Select none series" onClick={clearAll}><FontAwesomeIcon icon={faSquare}/></Button>
                        </OverlayTrigger>
                    </InputGroup.Append>
                </InputGroup>
            </Row>
            <InnerLegend legendItems={legendItems} />
        </Container>
    )
}

export function InnerLegend(props) {
    
    const scrollRef = useRef();
    const [scrollPos, setScrollPos] = useState(0);
    const pgHeight = 323; // Height of legend (set in the legendRow css class)
    const itemsPerPage = pgHeight/legendItemHeight;
    const length = props.legendItems ? props.legendItems.length : 0;
    const numPages = Math.ceil((length*legendItemHeight)/pgHeight);
    const numItemsToPad = itemsPerPage-(length%itemsPerPage);

    let changePage = (isForwards) => {
        let newPosition = isForwards ? scrollPos+pgHeight : scrollPos-pgHeight;
        if (scrollRef.current) 
            scrollRef.current.scrollTo({top: Math.floor(newPosition/pgHeight) * pgHeight});
    }
    
    return <>
            <Row className="legendRow" ref={scrollRef} onScroll={(e) => setScrollPos(Math.ceil(e.target.scrollTop))}>
                {props.legendItems && props.legendItems.concat(Array(numItemsToPad === itemsPerPage ? 0 : numItemsToPad).fill(<div>&zwnj;</div>))}
            </Row>
            {numPages > 1 && 
            <Pagination size="sm" style={{justifyContent : "center"}}>
                <Pagination.Prev onClick={() => changePage(false)}/>
                <li className="legendPageMarkers">&nbsp;{Math.floor(scrollPos/pgHeight)+1}/{numPages}&nbsp;</li>
                <Pagination.Next onClick={() => changePage(true)}/>
            </Pagination>}
           </>;
}


export default withRouter(React.memo(Legend));
