import React, { Fragment, useRef } from 'react'
import { Spinner, Row, FormControl, Jumbotron, Button, ButtonGroup, Form } from 'react-bootstrap';
import './EvoServiceRegistration.css';
import ReactDatePicker from '../ReactDatepicker';
import EditableSelect from '../PublicComponents/EditableSelect';
import DeleteModal from './DeleteModal';
import SectionStatus from './SectionStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {  addDaysToDate} from '../../Functions/DateTimeHelper';

function ServiceLog(props) {
    const { logDropdownList, dropdownSelected, dataAvailableFrom, lookBackPeriod, 
        isEditable, isLoading, isCreatingNewLog,
        onAddServiceLog, onServiceLogChange, onDeleteServiceLog, onSaveServiceLog, sectionStatus } = props;

    const minDateDataAvailable = useRef(addDaysToDate(new Date(), -14))

    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    const toggleDeleteModal = () => {
        setShowDeleteModal(!showDeleteModal);
    };

    const onDeleteServiceLogInternal = () => {
        toggleDeleteModal();
        onDeleteServiceLog();
    };

    const onLogNameChange = (e) => {
        onServiceLogChange(e.target.value, 'logName');
    };

    const onAvailableFromChange = (date) => {
        if (date.getTimezoneOffset() !== 0) {
            date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        }
        onServiceLogChange(date, 'availableFrom');
    };

    const onLookBackPeriodChange = (e) => {
        onServiceLogChange(e.target.value, 'lookBackPeriodInDays');
    };

    return (
        <Fragment>
            {isLoading ? <div>
                <Spinner animation="border" variant="primary"/>&emsp;Loading logs...
            </div>
            : <Fragment>
                {logDropdownList.length === 0 && !isLoading ? <Row>
                    <div className="input-rows">
                        <Jumbotron className="empty-prompt-Jumbotron">
                            <p>No Service Log Available</p>
                            {isEditable &&
                            <div className="empty-prompt-button-wrapper">
                                <Button variant="primary" onClick={onAddServiceLog.bind(this, 'Incoming')}><FontAwesomeIcon icon={faPlus} />&ensp;Add Incoming Log</Button>
                                <Button variant="primary" onClick={onAddServiceLog.bind(this, 'Outgoing')}><FontAwesomeIcon icon={faPlus} />&ensp;Add Outgoing Log</Button>
                                {/* We temporarily don't allow users to create customized logs. Will restore the +Customized button when we publish this feature. */}
                                {/* <Button variant="primary" onClick={onAddServiceLog.bind(this, 'Customized Log Name')}><FontAwesomeIcon icon={faPlus}/>&ensp;Add Customized Log</Button> */}
                            </div>}
                        </Jumbotron>
                    </div>
                </Row>
                : <Fragment>
                    <Row>
                        <div className="section-actions">
                            <span>Select Log</span>
                            {isEditable && <ButtonGroup>
                                {logDropdownList.filter(option => option === "Incoming" || option === "Incoming(Not saved)").length === 0 && <Button variant="outline-primary" onClick={onAddServiceLog.bind(this, 'Incoming')}><FontAwesomeIcon icon={faPlus}/>&ensp;Incoming</Button>}
                                {logDropdownList.filter(option => option === "Outgoing" || option === "Outgoing(Not saved)").length === 0 && <Button variant="outline-primary" onClick={onAddServiceLog.bind(this, 'Outgoing')}><FontAwesomeIcon icon={faPlus}/>&ensp;Outgoing</Button>}
                                {/* We temporarily don't allow users to create customized logs. Will restore the +Customized button when we publish this feature. */}
                                {/* <Button variant="outline-primary" onClick={onAddServiceLog.bind(this, 'Customized Log Name')}><FontAwesomeIcon icon={faPlus}/>&ensp;Customized</Button> */}
                            </ButtonGroup>}
                        </div>
                    </Row>
                    <Row>
                        <div className="input-rows">
                            <EditableSelect
                                className="editable-select"
                                value={dropdownSelected}
                                options={logDropdownList}
                                onChange={onServiceLogChange}
                                onChangePayload="SelectLog"
                                disableValueChange
                                />
                        </div>
                    </Row>

                    {isEditable && <Row>
                        <div className="section-actions">
                            <SectionStatus text={sectionStatus[0]} type={sectionStatus[1]}/>
                            <ButtonGroup>
                                <Button variant="outline-primary" onClick={toggleDeleteModal}><FontAwesomeIcon icon={faTrashAlt}/>&ensp;{isCreatingNewLog ? 'Discard' : 'Delete'}</Button>
                                <Button variant="outline-primary" onClick={onSaveServiceLog}><FontAwesomeIcon icon={faSave}/>&ensp;Save</Button>
                            </ButtonGroup>
                        </div>
                    </Row>}
                    
                    <Form>
                        <Row>
                            <Form.Group className="input-rows" controlId="logName">
                                <Form.Label>Log Name{isEditable && <span className="required-star">*</span>}</Form.Label>
                                <FormControl type="text" aria-required="true" value={dropdownSelected} onChange={onLogNameChange} disabled={dropdownSelected === 'Incoming' || dropdownSelected === 'Outgoing'} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group className="input-rows" controlId="dataAvailableFrom">
                                <Form.Label>Data Available From{isEditable && <span className="required-star">*</span>}</Form.Label>
                                <ReactDatePicker
                                    className={!isEditable ? 'DatePicker react-datepicker--disabled medium-width' : 'DatePicker medium-width'}           
                                    value={new Date(dataAvailableFrom)} 
                                    minDate={minDateDataAvailable.current}
                                    onChange={onAvailableFromChange}
                                    disabled={!isEditable}/>
                                <SectionStatus text={'Please select a date within the last 14 days from today'} type={'info'}/>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group className="input-rows" controlId="lookBackPeriod">
                                <Form.Label>Look Back Period{isEditable && <span className="required-star">*</span>}</Form.Label>
                                <FormControl type="number" aria-required="true" value={lookBackPeriod} onChange={onLookBackPeriodChange} min="1" step="1" max="7" disabled={!isEditable} />
                            </Form.Group>
                        </Row>
                    </Form>
                    
                    {showDeleteModal && <DeleteModal 
                        show={showDeleteModal} 
                        onYes={onDeleteServiceLogInternal} 
                        onNo={toggleDeleteModal}
                        title={isCreatingNewLog ? "Are you sure to discard?" : "Are you sure to delete?"}
                        prompt={isCreatingNewLog ? "The new log you are creating will be discarded." : `This action will delete log "${dropdownSelected}" and all of its definitions.`}/>}
                </Fragment>}
            </Fragment>}
        </Fragment>
    );
}

export default ServiceLog;