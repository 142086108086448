import React, { Component } from 'react'
import uuid from 'react-uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import Pagination from 'react-bootstrap/Pagination'
import Alert from 'react-bootstrap/Alert'
import { SortOrderEnum, filterObject, compareString, getNextSortOrder, renderSortMark, getCurrentPageList, getPageNumbersToDisplay } from '../../Functions/TableHelper'
import { LoadingGif } from '../PublicComponents/HelperComponents';
import CentralService from '../../Dataservices/CentralService';
import {formatTime} from '../../Functions/DateTimeHelper';
import './PipelineStatusPage.css'
import { Modal } from 'react-bootstrap'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faRedo);

class CommandHistory extends Component {

    constructor(props) {
        super(props)

        this.objectKeys = {
            None: 'None',
            ID: 'ID',
            Parameters: 'Parameters',
            Reason: 'Reason',
            RequestedBy: 'RequestedBy',
            ServerName: 'ServerName',
            Source: 'Source',
            Status: 'Status',
            TaskName: 'TaskName',
            Error: 'Error',
            RequestTime: 'RequestTime',
            UpdateTime: 'UpdateTime'
        }

        this.columns = [
            ["ID", this.objectKeys.ID],
            ["ServerName", this.objectKeys.ServerName],
            ["TaskName", this.objectKeys.TaskName],
            ["Status", this.objectKeys.Status],
            ["Parameters", this.objectKeys.Parameters],
            ["Reason", this.objectKeys.Reason],
            ["RequestedBy", this.objectKeys.RequestedBy],
            ["RequestTime", this.objectKeys.RequestTime],
            ["UpdateTime", this.objectKeys.UpdateTime],
        ]

        this.state = {
            isLoading: true,
            searchText: '',
            rawList: [],
            allList: [],
            displayList: [],
            totalPage: 0,
            sortKey: this.objectKeys.None,
            sortOrder: SortOrderEnum.None,
            currentPage: 0,
            pagination: [],
            error : null,
            updateTime: null,
            showModal: false,
            modalMessage: ''
        }
    }

    componentDidMount() {
        this.getCommandList()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.refreshCounter !== this.props.refreshCounter) {
            this.getCommandList();
        }
    }

    getCommandList = () => {
        this.setState({
            isLoading: true,
            displayList: [],
            pagination: []
        }, ()=>{
            CentralService.getCommands().then(res => {
                let { searchText, sortKey, sortOrder, currentPage } = this.state
                let raw = res.data
                let all = raw.filter(item => filterObject(item, searchText))
                if (sortKey !== this.objectKeys.None) {
                    all.sort(this.compareObject(sortKey, sortOrder === SortOrderEnum.Ascending))
                }
    
                this.setState({
                    isLoading: false,
                    rawList: raw,
                    allList: all,
                    updateTime: new Date()
                }, this.onClickPagination.bind(this, currentPage === 0 ? 1 : currentPage))
            }).catch(err => {
                console.error(err);
                this.setState({error: err.message});
            })
        })
    }

    onChangeSearchText = e => {
        const { sortKey, sortOrder, currentPage } = this.state
        let text = e.target.value
        let list = this.state.rawList.filter(item => filterObject(item, text))
        if (sortKey !== this.objectKeys.None && sortOrder !== SortOrderEnum.None) {
            list = list.sort(this.compareObject(sortKey, sortOrder === SortOrderEnum.Ascending))
        }
        this.setState({
            allList: list,
            searchText: text,
            totalPage: Math.ceil(list.length / 15)
        }, this.onClickPagination.bind(this, currentPage))
    }

    compareObject = (key, isAscending) => {
        return (obj1, obj2) => {
            if (key === 'ID') {
                if (obj1[key] < obj2[key]) {
                    return isAscending ? -1 : 1
                } else if (obj1[key] > obj2[key]) {
                    return isAscending ? 1 : -1
                } else {
                    return 0
                }
            } else {
                return compareString(obj1[key], obj2[key], isAscending, true)
            }
        }
    }

    onSort = key => {
        let { allList, sortKey, sortOrder, currentPage } = this.state
        let nextOrder = getNextSortOrder(sortOrder, key !== sortKey, true)
        let all = allList.sort(this.compareObject(key, nextOrder === SortOrderEnum.Ascending))
        const display = getCurrentPageList(all, currentPage)

        this.setState({
            allList: all,
            displayList: display,
            sortKey: key,
            sortOrder: nextOrder
        })
    }

    onClickPagination = clickedNum => {
        const { allList } = this.state
        const totalPage = Math.ceil(allList.length / 15)
        if(clickedNum > totalPage) {
            clickedNum = 1
        }
        const pages = getPageNumbersToDisplay(clickedNum, totalPage)

        this.setState({
            pagination: pages,
            displayList: getCurrentPageList(allList, clickedNum),
            currentPage: clickedNum
        })
    }

    setError = error => {
       this.setState({error: error.message});
    }

    showError = msg => {
        this.setState({showModal: true, modalMessage: msg})
    }

    onRefresh = () => {
        this.getCommandList();
        document.getElementById('command-history-refresh').innerHTML = "Command History Refreshed  " + (this.state.isLoading ? "loading" : "loaded");
    }

    render() {
        const { showModal,allList, displayList, isLoading, sortKey, sortOrder, currentPage, pagination } = this.state
        const totalPage = Math.ceil(allList.length / 15)
        return (
            <Container fluid className="MappingContainer">
                {this.state.error && <Alert variant='danger'>{this.state.error}</Alert>}
                <Row>
                    <Col className="Label">
                        <div style={{display:"flex", alignItems: "center"}}>
                            <Button onClick={this.onRefresh} disabled={this.state.isLoading}>
                                { this.state.isLoading && <span><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />&ensp;</span> }
                                Refresh Command History&ensp;
                                <FontAwesomeIcon icon={faRedo}/>
                            </Button>
                            <div id="command-history-refresh" role="status" class="sr-only"></div>
                            <span style={{ paddingLeft: 15 }} role="status">
                            Update time: {formatTime(this.state.updateTime)}
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control type="text" placeholder="Search Command" onChange={this.onChangeSearchText}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{ overflowX: 'auto' }} className='CommandHistoryTable' aria-live="polite" aria-atomic="true" aria-label={"Command History, " + (displayList ? displayList.length + " results found" : "")}>
                            <Table striped bordered hover size="sm" className="alignTextLeft" >
                            <thead>
                                    <tr>
                                        {
                                            this.columns.map(item=>{
                                                let friendly_name = item[0];
                                                let key_name = item[1];
                                                return (
                                                    <th key={key_name} onClick={this.onSort.bind(this, key_name)} scope="col">
                                                        {renderSortMark(sortKey, sortOrder, key_name)}
                                                        {friendly_name}
                                                    </th>
                                                )
                                            })
                                        }
                                        <th>Error</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayList && displayList.map(item => (
                                        <tr key={item.ID}>
                                            {
                                                this.columns.map(col=>{
                                                    let key_name = col[1];
                                                    return (
                                                        <td key={key_name}>{item[key_name]}</td>
                                                    )
                                                })
                                            }
                                            {
                                                item.Error ?
                                                <td style={{textAlign: 'center'}}><Button onClick={()=>this.showError(item.Error)}>Error</Button></td> :
                                                <td></td>
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        {isLoading && <LoadingGif/>}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Pagination style={{overflowX: 'auto'}}>
                            <Pagination.First disabled={currentPage === 1 || currentPage === 0 ? true : false} onClick={this.onClickPagination.bind(this, 1)}/>
                            <Pagination.Prev disabled={currentPage === 1 || currentPage === 0 ? true : false} onClick={this.onClickPagination.bind(this, currentPage - 1)} />
                            {pagination.map(num => num === 0 ? <Pagination.Ellipsis disabled key={uuid()}/> : <Pagination.Item key={uuid()} onClick={this.onClickPagination.bind(this, num)} active={currentPage === num ? true : false}>{num}</Pagination.Item>)}
                            <Pagination.Next disabled={currentPage === totalPage ? true : false} onClick={this.onClickPagination.bind(this, currentPage + 1)}  />
                            <Pagination.Last disabled={currentPage === totalPage ? true : false} onClick={this.onClickPagination.bind(this, totalPage)}/>
                        </Pagination>
                    </Col>
                </Row>
                <Modal show={showModal}>
                    <Modal.Header>
                        <Modal.Title>Error Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{wordBreak: 'break-word'}}>
                            {this.state.modalMessage}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.setState({showModal: false})}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </Container>
            
        )
    }
}

export default CommandHistory