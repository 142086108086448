import React, { Component } from 'react'
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { convertContentToHTML } from '../../Functions/RichTextHelpers'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

class AnnotationEditor extends Component {
    constructor(props) {
        super(props)

        this.maxLimitNum = 1000

        let content = null
        if (props.text) {
            const blocksFromHTML = convertFromHTML(props.text);
            content = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            )
        }
        
        this.state = {
            AddAnnotationError: '',
            editorState: content ? EditorState.createWithContent(content) : EditorState.createEmpty(),
            currentHTMLContent: '',
            showMaxLimitPrompt: false
        }
    }

    onEditorStateChange = (editorState) => {
        const { onChange } = this.props
        const plainText = editorState.getCurrentContent().getPlainText()
        const html = convertContentToHTML(editorState.getCurrentContent())
        onChange(html, plainText)
        if (plainText.length <= this.maxLimitNum) {
            this.setState({
                editorState,
                currentHTMLContent: html,
                showMaxLimitPrompt: false
            })
        }
    }    

    handleBeforeInput = () => {
        const { editorState } = this.state
        const length = editorState.getCurrentContent().getPlainText().length
    
        if (length > this.maxLimitNum - 1) {
            this.setState({
                showMaxLimitPrompt: true
            })
            return 'handled'
        }
    }
    
    handlePastedText = (pastedText) => {
        const { editorState } = this.state
        const length = editorState.getCurrentContent().getPlainText().length

        if (length + pastedText.length > this.maxLimitNum) {
            this.setState({
                showMaxLimitPrompt: true
            })
            return 'handled'
        }
    }

    render() {
        const { showMaxLimitPrompt, editorState } = this.state
        return (
            <React.Fragment>
                <Editor 
                    defaultEditorState={editorState}
                    toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'link', 'history'],
                        inline: { 
                            options: ['bold', 'italic', 'underline', 'strikethrough']
                        },
                        blockType: {
                            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote']
                        },
                        list: { inDropdown: false },
                        link: { inDropdown: false },
                        history: { inDropdown: false },
                    }}
                    handleBeforeInput={this.handleBeforeInput}
                    handlePastedText={this.handlePastedText}
                    onEditorStateChange={this.onEditorStateChange}/>
                    {showMaxLimitPrompt && <span style={{color: 'red'}}>The max word limit is {this.maxLimitNum}!</span>}
            </React.Fragment>
            
        )
    }
}

export default AnnotationEditor