import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { addDaysToUTCDate, formatUTCDateAsString, convertStringToUTCDate } from '../../Functions/DateTimeHelper';
import PerfPanelStatsService from '../../Dataservices/PerfPanelStatsService';
import ServiceMetricsChart from './ServiceMetricsChart';
import { ServiceMetricsDatePicker } from './ServiceMetricsDatepicker';
import { LoadingGif } from '../PublicComponents/HelperComponents';
import './ServiceMetricsPage.css';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faQuestionCircle);

class ServiceQualityPage extends Component {

    constructor(props) {
        super(props)

        let startDate = addDaysToUTCDate(new Date(), -30);
        startDate.setUTCHours(0, 0, 0);
        let endDate = addDaysToUTCDate(new Date(), -1);
        endDate.setUTCHours(23,59,59);

        let params = new URLSearchParams(this.props.location.search);
        const paramEndDate = params.get('endDate');
        if(paramEndDate) {
            endDate = convertStringToUTCDate(paramEndDate)
            endDate.setUTCHours(23,59,59);
            startDate = addDaysToUTCDate(endDate, -30);
            startDate.setUTCHours(0, 0, 0);
        }

        this.state = {
            startDate: startDate,
            endDate: endDate,
            data: [],
            isLoading: true,
            timezone: "UTC",
            overall: [], // overallItem {name: "", value: , trend: }
            showException: false,
            showInteractionTime: false,
            updatedTime: new Date().getTime(),
            error: '',
        }
    }

    componentDidMount() {
        this.getOverall();
    }

    selectDate = (date) => {
        let endDate = date;
        endDate.setUTCHours(23,59,59);
        let startDate = addDaysToUTCDate(endDate, -30);
        startDate.setUTCHours(0, 0, 0);
        this.setState({endDate: endDate, startDate: startDate, isLoading: true}, () => {
            let params = new URLSearchParams(this.props.location.search);
            params.set('endDate', formatUTCDateAsString(date));
            this.props.history.push(window.location.pathname + "?" + params.toString());
            this.getOverall();
        });
    }

    getOverall = () => {
        PerfPanelStatsService.getQualityOverall(formatUTCDateAsString(this.state.endDate)).then(res => {
            let data = res.data
            this.setState({
                overall: data.list,
                isLoading: false
            })
        }).catch(error => {
            this.setError(error.message)
        })
    }

    setError = (error) => { this.setState({error: error}); }

    onShowException = () => { this.setState(prevState => ({ showException: !prevState.showException })) }

    onShowInteractionTime = () => { this.setState(prevState => ({ showInteractionTime: !prevState.showInteractionTime })) }

    overallItem = () => {
        return (
        <React.Fragment>
            { this.state.overall.map(item => (
                    <OverallItem 
                        key={item.name}
                        overall={item}
                        increaseGreen={(item.name === "Availability" || item.name === "QoS")}
                        unit={(item.name === "Availability" || item.name === "QoS")? "%": "s"}
                        timeRange={30}
                    />
                )) }
        </React.Fragment>)
    }

    render() {
        const { isLoading, error, startDate, endDate } = this.state

        const headerStyle = isLoading?{ height: '150px' } : null

        return (
            <React.Fragment>
            {error && <Alert variant='danger'>{error}</Alert>}
            <Row style={{"flex-direction": "row-reverse"}}>
                <ServiceMetricsDatePicker label={"End Date: "} date={endDate} selectDate={this.selectDate}/>
            </Row>
            <Row className="usageHeader" style={headerStyle}>
                {isLoading ? <Col> <LoadingGif/> </Col> : this.overallItem() }
            </Row>
            <Row>
                <Col lg={6} className="usageChartContainer">
                    <ServiceMetricsChart key={"availability-chart"} 
                        startDate={startDate}
                        endDate={endDate}
                        setError={this.setError} 
                        title={"Availability"} 
                        chart={{
                            url:'PerfPanelStats/availability', 
                            breakdownUrl:'PerfPanelStats/availability-breakdown'
                            }} 
                        isLatency={false}
                        canOpenUrl={true}
                        openUrl={"https://ms.portal.azure.com/#@prdtrs01.onmicrosoft.com/resource/subscriptions/5818ed0f-6cf0-4bda-9eb9-2771a5ab62f6/resourceGroups/OFECubes/providers/microsoft.insights/components/perfpanel/failures"}
                        tab={"percentage"}
                    />
                </Col>
                <Col lg={6} className="usageChartContainer">
                    <ServiceMetricsChart key={"qos-chart"} 
                        startDate={startDate}
                        endDate={endDate}
                        setError={this.setError} 
                        title={"QoS"} 
                        chart={{url:'PerfPanelStats/qos', breakdownUrl:'PerfPanelStats/qos-breakdown'}} 
                        isLatency={false}
                        canOpenUrl={true}
                        openUrl={"https://ms.portal.azure.com/#@prdtrs01.onmicrosoft.com/resource/subscriptions/5818ed0f-6cf0-4bda-9eb9-2771a5ab62f6/resourceGroups/OFECubes/providers/microsoft.insights/components/perfpanel/performance"}
                        tab={"percentage"}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6} className="usageChartContainer">
                    <ServiceMetricsChart key={"page-latency-chart"} 
                        startDate={startDate}
                        endDate={endDate}
                        setError={this.setError} 
                        title={"Page Loading Time"} 
                        chart={{url:'PerfPanelStats/totalLatency',breakdownUrl:'PerfPanelStats/latency-breakdown'}} 
                        isLatency={true} 
                        tab={"daily"}
                    />
                </Col>
                <Col lg={6} className="usageChartContainer">
                    <ServiceMetricsChart key={"cube-latency-chart"} 
                        startDate={startDate}
                        endDate={endDate}
                        setError={this.setError} 
                        title={"Data Query Time"}
                        chart={{
                            baseQuery: 'perfPanelPerf/dataQueryOverrides', 
                            url:'https://perfpanel.azurewebsites.net/Analysis/perfPanelPerf?graphIt=true&tab=daily&un=%21"Unknown"%7C"Unauthorized""&sd=08%2F28%2F2022&ed=09%2F14%2F2022&pct=95',
                            breakdownUrl:'https://perfpanel.azurewebsites.net/Analysis/perfPanelPerf?graphIt=true&tab=daily&un=%21"Unknown"%7C"Unauthorized"&sd=08%2F28%2F2022&ed=09%2F14%2F2022&pct=95&rcn=false'
                            }} 
                        isLatency={true}
                        canOpenUrl={true}
                        tab={"daily"}
                    />
                </Col>
            </Row>
            </React.Fragment>
        )
    }
}

const tipsMap = {
    "Availability": "A ratio of the number of successful service requests to the total number of requests.", 
    "QoS": "A ratio of the number of success requests with latency < 10s to the total number of requests.",
    "PLT": "The time that the user should wait to get all the components loaded on the page.",
    "DQT": "The time that the user should wait to query the data from a cube for rendering the chart",
    "DAU": "Daily Active Users",
    "WAU": "Weekly Active Users",
    "MAU": "Monthly Active Users",
}

const overallNameMap = {
    "Availability": "Availability", 
    "QoS": "QoS",
    "PLT": "Page Loading Time(p95)",
    "DQT": "Data Query Time(p95)", 
}

export class OverallItem extends Component{
    constructor(props) {
        super(props)
    }
    
    render() {
        const {overall, increaseGreen, unit, timeRange} = this.props

        return (
            <Col>
                <p>
                    {overallNameMap[overall.name]??overall.name} &ensp; 
                    <OverlayTrigger
                        key={`overlay-${overall.name}`}
                        placement="right"
                        overlay={ <Tooltip id={`tooltip-${overall.name}`}> {tipsMap[overall.name]} </Tooltip>}>
                        <FontAwesomeIcon icon={faQuestionCircle} className="promptMark"/>
                    </OverlayTrigger>
                </p>
                <div classname='h1-style'>
                    {overall.value? (overall.value.toFixed(2) + unit) : <Spinner animation="border" />}
                </div>
                {timeRange? <p style={{"color": "#707070"}}>In last {timeRange} days</p>: null}
                {overall.trend? <Badge pill variant={!((overall.trend > 0) ^ increaseGreen) ? "success":"danger"}> {(overall.trend > 0?"+":"") + overall.trend.toFixed(2) + unit} </Badge> : null}
            </Col>
        )
    }
}

export default withRouter(ServiceQualityPage);