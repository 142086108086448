import axios from "axios";

let refreshOnExpiration = (res) => {
    if (res.status === 302) {
        window.location.reload();
        return;
    } else {
        return res;
    }
};

class DataServiceBase {
    constructor(baseUrl, frontendAuth) {
        this.baseUrl = baseUrl;
        this.frontendAuth = frontendAuth;
    }

    setAccessToken (token) {
        if (this.frontendAuth) {
            this.accessToken = token;
        }
    }

    get (url) {
        if (this.frontendAuth) {
            return axios.get(this.baseUrl + url, {
                headers: {
                    'Authorization': 'Bearer ' + this.accessToken
                }
            }).then(refreshOnExpiration);
        }
        else {
            return axios.get(this.baseUrl + url)
        }
    }

    post (url, data, cancelToken, scenarioTag) {
        if (this.frontendAuth) {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + this.accessToken,
                    'ScenarioTag': scenarioTag 
                }
            }

            if (cancelToken) {
                config.cancelToken = cancelToken
            }
            return axios.post(this.baseUrl + url, data, config).then(refreshOnExpiration);
        } else {
            return axios.post(this.baseUrl + url, data, {
                cancelToken: cancelToken
            })
        }
    }

    delete(url, data) {
        if (this.frontendAuth) {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + this.accessToken
                }
            }
            return axios.delete(this.baseUrl + url, config).then(refreshOnExpiration);
        } else {
            return axios.delete(this.baseUrl + url)
        }
    }

    put (url, data, cancelToken) {
        if (this.frontendAuth) {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + this.accessToken
                }
            }
            if (cancelToken) {
                config.cancelToken = cancelToken
            }
            return axios.put(this.baseUrl + url, data, config).then(refreshOnExpiration);
        } else {
            return axios.put(this.baseUrl + url, data, {
                cancelToken: cancelToken
            })
        }
    }
}

export default DataServiceBase;