import React, {Component} from 'react'
import Alert from 'react-bootstrap/Alert'
import DataService from '../../Dataservices/DataService'
import { ScenarioTags } from '../../Dataservices/ChartService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { convertLocalDateToUTCTime, replaceSingleParamforUrl, formatSampleCount, setAccessibility } from '../../Functions/MSRHelper'
import './MSR.css'
import { getCubeFromURL } from '../../Functions/UrlHelper'
import { library } from "@fortawesome/fontawesome-svg-core";
import MSRCard from './MSRCard'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import MSRCalloutModal from './MSRCalloutModal'
import MSRService from '../../Dataservices/MSRService'

library.add(faArrowUp, faArrowDown);

class MSRSummaryScenario extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: props.startDate,
            endDate: props.endDate,
            error: null,
            ringParamName: null,
            SummaryMsitScenarioData: {},
            SummaryMsitSampleData: {},
            SummaryProdScenarioData: {},
            SummaryProdSampleData: {},
            SummarySdfv2ScenarioData: {},
            SummarySdfv2SampleData: {},
            WeekCountsShownonMSR: props.WeekCountsShownonMSR,
            ringUsedforMSR: "%22PROD%22%7C%22WW%22%7C%22MSIT%22%7C%22SDFV2%22",
            isLoading: Array(props.WeekCountsShownonMSR).fill(true),
            showChartCard: false,
            showTableCard: false,
            showCalloutCard: false,
            showDeviationTableCard: false,
            cardPosition: { x: 0, y: 0 },
            cardPinnedPosition: { x: 0, y: 0 },
            cardChartData: {},
            cardLatencyData: {},
            cardCalloutData: {},
            latencyThreshold: null,
            cardPinned: false,
            cardUrl: null,
            showMSRCallout: false,
            currCalloutData: undefined
        }
    }
    
    componentDidMount() {
        this.getRingParamNameAndFetchFirst()
        if (Highcharts.charts.length > 0) {
            Highcharts.charts.forEach((chart) => {
              if (chart) chart.reflow();
            });
          }
    }

    componentDidUpdate(prevProps) {
        if (this.props.WeekCountsShownonMSR !== prevProps.WeekCountsShownonMSR) {
            this.getScenario(prevProps)
        }
      }

      
    getRingParamNameAndFetchFirst = () => {
        const appName = getCubeFromURL(this.props.Scenario.url);

        DataService.get(appName + "/RingParamName").then(res=>{
            this.setState({ringParamName : res.data})
            this.getScenario()
        }).catch(err => {
            console.error(err)
            return {}
        })
    }

    convertUrlIntoRequestObjects = (Scenario, startDate, endDate) => {
        const appName = getCubeFromURL(Scenario.url)
        const indexOfSeperator = this.state.ringParamName.indexOf('|')
        let currentScenarioUrl = Scenario.url
        currentScenarioUrl = replaceSingleParamforUrl(currentScenarioUrl, this.state.ringParamName.substr(0, indexOfSeperator), this.state.ringUsedforMSR)
        currentScenarioUrl = replaceSingleParamforUrl(currentScenarioUrl, this.state.ringParamName.substr(1 + indexOfSeperator), "false")
        currentScenarioUrl = replaceSingleParamforUrl(currentScenarioUrl, "pct", Scenario.GoalPercent)
        if (appName) {
            return {
                endpointUrl: appName + '/dataQueryOverrides',
                obj: {
                    Url: currentScenarioUrl,
                    StartDate: startDate,
                    EndDate: endDate
                }
            }
        }
        else
        {
            console.log(Scenario.url);
            return {};
        }
    };

    sortObjById = (obj) => {
        obj.sort((a, b) => {
            return parseInt(a.ID) - parseInt(b.ID);
        });
    }

    transformDailyDataToMSR = (LatencyData, SampleData, startDate) => {
        let SummaryretMsitLatencyData = this.state.SummaryMsitScenarioData
        let SummaryretMsitSampleData = this.state.SummaryMsitSampleData
        let SummaryretProdLatencyData = this.state.SummaryProdScenarioData
        let SummaryretProdSampleData = this.state.SummaryProdSampleData
        let SummaryretSdfv2LatencyData = this.state.SummarySdfv2ScenarioData
        let SummaryretSdfv2SampleData = this.state.SummarySdfv2SampleData

        for (let j = 0; j < LatencyData.length; j ++)
        {
            const startDateUTC = new Date(convertLocalDateToUTCTime(startDate)).toISOString().slice(0, 10)
            let Legend = LatencyData[j].Name
            if(Legend.indexOf('MSIT') > -1)
            {
                SummaryretMsitLatencyData[startDateUTC] = Math.floor(LatencyData[j].Data[0].y)
                SummaryretMsitSampleData[startDateUTC] = Math.floor(SampleData[j].Data[0].y)
            }
            else if(Legend.indexOf('PROD') > -1 || Legend.indexOf('WW') > -1)
            {
                SummaryretProdLatencyData[startDateUTC] = Math.floor(LatencyData[j].Data[0].y)
                SummaryretProdSampleData[startDateUTC] = Math.floor(SampleData[j].Data[0].y)
            }
            else if(Legend.indexOf('SDFV2') > -1)
            {
                SummaryretSdfv2LatencyData[startDateUTC] = Math.floor(LatencyData[j].Data[0].y)
                SummaryretSdfv2SampleData[startDateUTC] = Math.floor(SampleData[j].Data[0].y)
            }
        }

        this.setState({
            SummaryMsitScenarioData: this.sortObjKeys(SummaryretMsitLatencyData),
            SummaryMsitSampleData: this.sortObjKeys(SummaryretMsitSampleData),
            SummaryProdScenarioData: this.sortObjKeys(SummaryretProdLatencyData),
            SummaryProdSampleData: this.sortObjKeys(SummaryretProdSampleData),
            SummarySdfv2ScenarioData: this.sortObjKeys(SummaryretSdfv2LatencyData),
            SummarySdfv2SampleData: this.sortObjKeys(SummaryretSdfv2SampleData)
        });

    } 

    sortObjKeys = (unordered) => {
        return Object.keys(unordered)
            .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
            .reduce((obj, key) => {
            obj[key] = unordered[key];
            return obj;
            }, {});
    };
      

    getScenario = (prevProps) => { 

        let countsForAddedDates = this.props.WeekCountsShownonMSR;

        if (prevProps !== undefined) {
            if (parseInt(this.props.WeekCountsShownonMSR, 10) > parseInt(prevProps.WeekCountsShownonMSR, 10)) {
                countsForAddedDates = this.props.WeekCountsShownonMSR - prevProps.WeekCountsShownonMSR;
            }
        } else {
            countsForAddedDates = this.props.WeekCountsShownonMSR;
        }

        this.setState({ 
            isLoading: Array(countsForAddedDates).fill(true)
        })

        for (let i = 0; i < countsForAddedDates; i++) {
            this.getScenarioForDateRange(new Date(this.props.startDate.getTime() + 86400000 * 7 * i), new Date(this.props.startDate.getTime() + 86400000 * (7 * i + 3)), i)
        }
    }

    getScenarioForDateRange = (startDate, endDate, indexOfWeek) => {
        const scenario = this.props.Scenario
        
        const requestObj = this.convertUrlIntoRequestObjects(scenario, startDate, endDate)
        requestObj.obj.OverrideTab = "pivottable"

        if (requestObj.endpointUrl) {
            DataService.post(requestObj.endpointUrl, requestObj.obj, null, ScenarioTags.WeeklyMSR)
                .then(res => {
                    if (res.data.IsSuccess) {
                        this.transformDailyDataToMSR(res.data.LatencyDataSeries, res.data.SampleCountDataSeries, startDate)
                    } else {
                        console.error(res.data.RequestID);
                        console.error(res.data.RequestLog);
                        console.error(res.data.ErrorMessage);
                        this.setState({
                            error : res.data.ErrorMessage, 
                        });
                    }
                    this.setIsLoadingFalse(indexOfWeek)
            }).catch(err => {
                console.error(err);
                this.setState({
                    error : err
                });
                this.setIsLoadingFalse(indexOfWeek)
            })
        } else {
            this.setIsLoadingFalse(indexOfWeek)
        }
    }

    deleteCallout = (MSRCalloutId) => {
        const { cardCalloutData } = this.state

        let toDelete = null

        const newList = cardCalloutData.filter(s => {
            if (s.Id === MSRCalloutId) {
                toDelete = s
            }
            return s.Id !== MSRCalloutId
        })

        this.setState({ cardCalloutData: newList })
        this.props.onEditCallout(toDelete.ScenarioId, newList)

        toDelete.ScenarioId = null

        MSRService.deleteMSRCallout(toDelete).then().catch(err => {
            console.error(err);
            this.props.setError(err.message);
        })
    }

    editCallout = (UpdatedMSRCalloutData) => {
        const { cardCalloutData } = this.state;
        const newList = cardCalloutData.map(s => {
            if (s.Id === UpdatedMSRCalloutData.Id) {
                return UpdatedMSRCalloutData;
            }
            return s;
        });
        this.setState({ cardCalloutData: newList });
        this.props.onEditCallout(UpdatedMSRCalloutData.ScenarioId, newList)
    }    

    setIsLoadingFalse = indexOfWeek => {
        this.setState(prevState => {
            prevState.isLoading[indexOfWeek] = false
            return {
                isLoading: prevState.isLoading
            }
        })
    }

    hasLoadCompleteAll = () => {
        return this.state.isLoading.every(isLoading => !isLoading)
    }

    setError = error => {
        this.setState({error: error});
    }

    setCurrCalloutData = (currCalloutData) => {
        this.setState({currCalloutData : currCalloutData});
        this.onToggleMSRCalloutModal();
    }

    setCardPositionCentralCell = (element) => {
        const rect = element.getBoundingClientRect();
        const x = rect.left + rect.width / 2;
        const y = rect.top + rect.height / 2;
        this.setState({ cardPosition: { x, y } });
    }

    handleMouseEnter = (scenarioData, latencyThreshold, Scenario, currentRing, element) => {
        const indexOfSeperator = this.state.ringParamName.indexOf('|')
        let cardUrl = Scenario.url
        cardUrl = replaceSingleParamforUrl(cardUrl, this.state.ringParamName.substr(0, indexOfSeperator), '%22'+currentRing+'%22')
        cardUrl = replaceSingleParamforUrl(cardUrl, this.state.ringParamName.substr(1 + indexOfSeperator), "true")
        cardUrl = replaceSingleParamforUrl(cardUrl, "pct", Scenario.GoalPercent)
        this.setCardPositionCentralCell(element)
    
        this.unpinCard()
        this.setState({ showChartCard: true, cardChartData: scenarioData, latencyThreshold: latencyThreshold, cardUrl: cardUrl });
    }
      
    handleMouseMove = (event) => {
        const x = event.clientX;
        const y = event.clientY;
        this.setState({ cardPosition: { x, y } });
    }
    
    handleMouseLeave = () => {
        console.log('mouse leave high chart cell!')
        const{cardPinned} = this.state
        if(!cardPinned){
            this.setState({ showChartCard: false });
        }
    }

    handleMouseEnterTableCell = (scenarioData, latencyThreshold, showDeviationTableCard, element) => {
        this.setCardPositionCentralCell(element)
        this.unpinCard()
        this.setState({ showTableCard: true, cardLatencyData: scenarioData, latencyThreshold: latencyThreshold, showDeviationTableCard: showDeviationTableCard });
    }
    
    handleMouseLeaveTableCell = () => {
        this.setState({ showTableCard: false });
    }

    handleMouseEnterCalloutCell = (cardCalloutData, element) => {
        this.setCardPositionCentralCell(element)
        this.unpinCard()
        this.setState({ showCalloutCard: true, cardCalloutData: cardCalloutData});
    }
    
    handleMouseLeaveCalloutCell = () => {
        const{cardPinned} = this.state
        if(!cardPinned){
            this.setState({ showCalloutCard: false });
        }
    }

    handleMouseClick = () => {
        const {cardPosition} = this.state
        setAccessibility('-1');
        this.setState({cardPinned : true, cardPinnedPosition : cardPosition });
    }

    onToggleMSRCalloutModal = () => {
        this.setState(prevState => {
            return {
                showMSRCallout: !prevState.showMSRCallout
            }
        })
    }

    unpinCard = () => {
        setAccessibility('0');
        this.setState({cardPinned : false, showChartCard: false, showTableCard: false, showCalloutCard: false});
    }

    filterDataByCurrentStartDate = (data) => {
        const startDateTimestamp = new Date(convertLocalDateToUTCTime(this.props.startDate)).getTime();
        const endDateTimestamp = new Date(convertLocalDateToUTCTime(this.props.endDate)).getTime();

        return Object.keys(data)
          .reduce((acc, key) => {
            const date = new Date(key).getTime();
            if (date >= startDateTimestamp && date <= endDateTimestamp && data[key] !== null) {
              acc[key] = data[key];
            }
            return acc;
          }, {});
    }

    generateHighchart = (dataItem, latencyThreshold) => {
        const labels = Object.keys(dataItem).sort((a, b) => new Date(a) - new Date(b));
        const data = labels.map(label => [new Date(label).getTime(), dataItem[label]]);

        const options = {
            accessibility: {
                enabled: false
            },
            chart: {
                backgroundColor: null,
                width: 150,
                height: 45,
                margin: [10, 30, 10, 30],
                events: {
                    load: function() {
                      this.container.style.pointerEvents = 'none';
                    }
                  }                  
              },
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            tooltip: {
                enabled: false
              },
            xAxis: {
                visible: false,
                type: 'datetime'
            },
            yAxis: {
                min: Math.min(latencyThreshold, Math.min(...data.map(item => item[1]))),
                max: Math.max(latencyThreshold, Math.max(...data.map(item => item[1]))),
                labels: {
                    enabled: false
                },
                title: {
                    text: null
                },
                lineWidth: 0,
                tickLength: 0,
                gridLineWidth: 0,
                plotLines: [{
                    color: '#FF4500', // Orange threshold line
                    value: latencyThreshold
                }]
            },          
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            series: [{
                data: data,
                type: 'spline',
                color: '#000000',
                lineWidth: 1,
                marker: {
                  enabled: false
                },
                states: {
                    hover: {
                        enabled: false
                    }
                }        
              }]
              
        };

        return (
            <div style={{ width: '100%', height: '100%' }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        );
    }

    
    getAvg = (ScenarioData) => {
        const arr = Object.values(ScenarioData)
        const filteredArr = arr.filter(element => element !== null);
        const sum = filteredArr.reduce((acc, curr) => acc + curr, 0);
        const count = filteredArr.length;
        
        const avg = count === 0 ? 0 : sum / count

        return avg.toFixed(2)
    }

    renderScenarioData = (ScenarioData, SampleData, latencyThreshold, showCurrentRing, currentRing) => {
        const {Scenario, showAvgDailySamples, showLatencyThreshold} = this.props
        const avgLatency = this.getAvg(ScenarioData)
        const latestWeekAvgLatency = ScenarioData[Object.keys(ScenarioData).pop()]
        const deviationGoal = Math.round(100 * (latencyThreshold - latestWeekAvgLatency) / latencyThreshold).toFixed(2)

        return (
            showCurrentRing?
            <>
                {showLatencyThreshold ? <td className='noWordBreak'>{`${latencyThreshold} ms @P${Scenario.GoalPercent}`}</td> : null}

                {showAvgDailySamples ? 
                    (this.hasLoadCompleteAll() ?
                    <td className='noWordBreak'>{formatSampleCount(this.getAvg(SampleData))}</td> 
                    :
                    <td className='high-contrast-red noWordBreak'>getting data...</td>)
                :null
                }

                {this.hasLoadCompleteAll() ?
                    <td className='noWordBreak'
                    ref={el => (this[`${currentRing}WeekAvgDivElement`] = el)}
                    tabIndex={0}
                    onMouseEnter={() => this.handleMouseEnterTableCell(ScenarioData, latencyThreshold, false, this[`${currentRing}WeekAvgDivElement`])} 
                    onMouseMove={this.handleMouseMove} 
                    onMouseLeave={this.handleMouseLeaveTableCell}
                    onFocus={() => this.handleMouseEnterTableCell(ScenarioData, latencyThreshold, false, this[`${currentRing}WeekAvgDivElement`])}
                    onBlur={() => this.unpinCard()}
                    >
                        {   
                            Object.keys(ScenarioData).length <= 0 ? null : (
                                <>
                                {avgLatency}
                                </>
                            )
                        }
                    </td>
                    :
                    <td className='high-contrast-red noWordBreak'>getting data...</td>
                }

                {this.hasLoadCompleteAll() ? (
                    <td className='cellHighChart'>
                        <div
                            ref={el => (this[`${currentRing}DivElement`] = el)}
                            tabIndex={0}
                            onMouseEnter={() => this.handleMouseEnter(ScenarioData, latencyThreshold, Scenario, currentRing, this[`${currentRing}DivElement`])}
                            onMouseMove={this.handleMouseMove}
                            onMouseLeave={this.handleMouseLeave}
                            onClick={this.handleMouseClick}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    this.handleMouseClick();
                                }
                            }}
                            onFocus={() => this.handleMouseEnter(ScenarioData, latencyThreshold, Scenario, currentRing, this[`${currentRing}DivElement`])}
                        >
                            {this.generateHighchart(ScenarioData, latencyThreshold)}
                        </div>
                    </td>
                ) : (
                    <td className='high-contrast-red noWordBreak'>getting data...</td>
                )}

                {this.hasLoadCompleteAll() ?
                    <td className='noWordBreak'
                        ref={el => (this[`${currentRing}DeviationDivElement`] = el)} // Unique ref for each cell
                        tabIndex={0}
                        onMouseEnter={() => this.handleMouseEnterTableCell(ScenarioData, latencyThreshold, true, this[`${currentRing}DeviationDivElement`])}
                        onMouseMove={this.handleMouseMove} 
                        onMouseLeave={this.handleMouseLeaveTableCell}
                        onFocus={() => this.handleMouseEnterTableCell(ScenarioData, latencyThreshold, true, this[`${currentRing}DeviationDivElement`])}
                        onBlur={() => this.unpinCard()}
                        >
                        {   
                            Object.keys(ScenarioData).length <= 0 ? null : (
                                <>
                                {deviationGoal}%
                                {deviationGoal > 0 ? 
                                    <FontAwesomeIcon icon={faArrowUp} color="green"/> : 
                                    <FontAwesomeIcon icon={faArrowDown} color="red"/>
                                }
                                </>
                            )
                        }


                    </td> 
                    :
                    <td className='high-contrast-red noWordBreak'>getting data...</td>
                }

                {this.hasLoadCompleteAll() ?
                    <td className='noWordBreak' style={currentRing === 'PROD|WW' ? {} : {borderRight: "2px solid #111"}}>
                        {   
                            Object.keys(ScenarioData).length <= 0 ? null : (
                                <>
                                {latestWeekAvgLatency}
                                </>
                            )
                        }
                    </td>
                    :
                    <td className='high-contrast-red noWordBreak' style={currentRing === 'PROD|WW' ? {} : {borderRight: "2px solid #111"}}>getting data...</td>
                }
            </>
            :
            null
        );
    }
  
    render() {
        const {Scenario, showMsit, showProd, showSdfv2} = this.props
        const {showMSRCallout, currCalloutData, cardPinnedPosition, cardPinned, error, SummaryMsitSampleData, SummaryMsitScenarioData, SummaryProdSampleData, SummaryProdScenarioData, SummarySdfv2SampleData, SummarySdfv2ScenarioData, showChartCard, showTableCard, cardPosition, cardChartData, cardLatencyData, latencyThreshold, showDeviationTableCard, showCalloutCard, cardCalloutData, cardUrl} = this.state

        return (
            <React.Fragment>
                {error && <Alert variant='danger'>{error}</Alert>}

                <tr className="MSR" key={Scenario.title}>
                    <td className='noWordBreak'>{Scenario.title}</td>
                        {this.renderScenarioData(this.filterDataByCurrentStartDate(SummarySdfv2ScenarioData), this.filterDataByCurrentStartDate(SummarySdfv2SampleData), Scenario.Sdfv2LatencyThreshold, showSdfv2, "SDFV2")}
                        {this.renderScenarioData(this.filterDataByCurrentStartDate(SummaryMsitScenarioData), this.filterDataByCurrentStartDate(SummaryMsitSampleData), Scenario.MsitLatencyThreshold, showMsit, "MSIT")}
                        {this.renderScenarioData(this.filterDataByCurrentStartDate(SummaryProdScenarioData), this.filterDataByCurrentStartDate(SummaryProdSampleData), Scenario.ProdLatencyThreshold, showProd, "PROD|WW")}
                    <td className='noWordBreak'
                        ref={el => (this[`CalloutDivElement`] = el)}
                        tabIndex={0}
                        onMouseEnter={() => this.handleMouseEnterCalloutCell(Scenario.Callouts, this[`CalloutDivElement`])}
                        onMouseMove={this.handleMouseMove} 
                        onMouseLeave={this.handleMouseLeaveCalloutCell}
                        onClick={this.handleMouseClick}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                this.handleMouseClick();
                            }
                        }}
                        onFocus={() => this.handleMouseEnterCalloutCell(Scenario.Callouts, this[`CalloutDivElement`])}
                        onBlur={() => this.handleMouseLeaveCalloutCell()}
                        >
                            {Scenario.Callouts.length > 0 ? `${Scenario.Callouts.length} Weeks` : null}
                    </td>
                </tr>

                <MSRCard
                    showChartCard={showChartCard}
                    showTableCard={showTableCard}
                    showCalloutCard={showCalloutCard}
                    isPinned={cardPinned}
                    cardChartData={cardChartData}
                    cardUrl={cardUrl}
                    cardLatencyData={cardLatencyData}
                    latencyThreshold={latencyThreshold}
                    showDeviationTableCard={showDeviationTableCard}
                    cardCalloutData={cardCalloutData}
                    cardPinnedPosition={cardPinnedPosition}
                    unpinCard={this.unpinCard}
                    mouseCoords={cardPosition}
                    setCurrCalloutData={this.setCurrCalloutData}/>
                <MSRCalloutModal
                    showModalorNot={showMSRCallout}
                    toggleModal={this.onToggleMSRCalloutModal}
                    Scenario={Scenario}
                    setError={this.setError}
                    currCalloutData={currCalloutData}
                    onDelete={this.deleteCallout}
                    onSave={this.editCallout}/>
            </React.Fragment>
        );
    }

}

export default MSRSummaryScenario