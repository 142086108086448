import React, { Component } from 'react';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Alert from 'react-bootstrap/Alert'
import './PipelineStatusPage.css'
import { LoadingGif } from '../PublicComponents/HelperComponents';
import DiskMonitor from './DiskMonitor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BackupCubeTableList  from './BackupCubeTableList';
import MaintenanceJobMonitor from './MaintenanceJobMonitor';
import MaintenanceService from '../../Dataservices/MaintenanceService';
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import {formatTime} from '../../Functions/DateTimeHelper';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faRedo);

class MaintenanceMonitor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            diskData: null,
            cubeTableList: null,
            jobData: null,
            error: null,
            updateTime: null
        }
    }
    
    componentDidMount() {
        this.getData();
    }

    getData = () => {
        MaintenanceService.getCubeTableList().then(cubeRes => {
            this.setState({
                cubeTableList: cubeRes.data,
                isLoading: false
            })
        }).catch(err => {
            console.error(err)
            this.setState({error: err.message})
        })

        MaintenanceService.getDiskMonitor().then(diskRes => {
            this.setState({
                diskData: diskRes.data,
                isLoading: false
            })
        }).catch(err => {
            console.error(err)
            this.setState({error: err.message})
        })

        MaintenanceService.getJobMonitor().then(jobRes => {
            this.setState({
                jobData: jobRes.data,
                isLoading: false,
                updateTime: new Date()
            })
        }).catch(err => {
            console.error(err)
            this.setState({error: err.message})
        })
    }

    refresh = () => {
        this.setState({
            isLoading: true,
            diskData: null,
            cubeTableList: null,
            jobData: null,
        })

        this.getData();
        document.getElementById('maintenance-refresh').innerHTML = "Refreshed  " + (this.state.isLoading ? "loading" : "loaded");
    }

    render() {
        return (
            <div>
                {this.state.error && <Alert variant='danger'>{this.state.error}</Alert>}
                <Row style={{paddingTop: 10, paddingBottom: 15}}>
                    <Col className="Label">
                        <div style={{display:"flex", alignItems: "center"}}>
                            <Button onClick={this.refresh} disabled={this.state.isLoading}>
                                { this.state.isLoading && <span><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />&ensp;</span> }
                                Refresh Page&ensp;
                                <FontAwesomeIcon icon={faRedo}/>
                            </Button>
                            <div id="maintenance-refresh" role="status" class="sr-only"></div>
                            <span style={{ paddingLeft: 15 }} role="status">
                            Update time: {formatTime(this.state.updateTime)}
                            </span>
                        </div>
                    </Col>
                </Row>
                {this.state.isLoading 
                    ? <LoadingGif/>
                    : <div>
                        <Row>
                            <Col className="Label"><h2>Job Monitor</h2></Col>
                        </Row>
                        {
                            this.state.jobData !== null ?
                            <MaintenanceJobMonitor data={this.state.jobData}></MaintenanceJobMonitor> :
                            <div></div>
                        }
                        <div>
                            <hr/>
                            <Row>
                                <Col className="Label">
                                    <h2>Disk Monitor</h2>
                                </Col>
                            </Row>

                            {
                                (this.state.diskData !== null && this.state.diskData.length !==0) ?
                                <DiskMonitor data={this.state.diskData}></DiskMonitor> :
                                <div></div>
                            }
                        </div>
                        <div>
                            <hr/>
                            <Row>
                                <Col className="Label">
                                    <h2>Cube Backup Status</h2>
                                </Col>
                            </Row>
                            {
                                this.state.cubeTableList !== null ?
                                <BackupCubeTableList data={this.state.cubeTableList}></BackupCubeTableList> :
                                <div style={{fontSize: 30}} >loading...</div>
                            }
                        </div>
                    </div>}
            </div>
        )
    }
}

export default MaintenanceMonitor;
