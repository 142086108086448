import React, { Component } from 'react';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Select from 'react-select';
import Table from 'react-bootstrap/Table'
import { SortOrderEnum, compareString, getNextSortOrder, renderSortMark } from '../../Functions/TableHelper'
import './PipelineStatusPage.css'


class BackupCubeTableList extends Component {
    
    constructor(props) {
        super(props);

        let data = this.props.data;
        let tableDict = {};
        let cubeNames = [];

        for(let i in data) {
            let cubeName = data[i].DBName;
            data[i].DefaultKey = data[i].TableName;
            if (data[i].ExpireDays === 0) {
                data[i].ExpireDays = "Never";
            }
            if (data[i].TimeIntervalForBackup === 0) {
                data[i].TimeIntervalForBackup = "Once";
            }

            if (cubeName in tableDict) {
                tableDict[cubeName].push(data[i])
            } else {
                cubeNames.push(cubeName);
                tableDict[cubeName] = [data[i]]
            }
        }
        this.tableDict = tableDict;
        let selectedCubeName = cubeNames.length !== 0 ? cubeNames[0] : "";

        this.state = {
            table: tableDict[selectedCubeName],
            selectedCubeName: selectedCubeName,
            cubeNames: cubeNames,
            tableSort: {
                key: 'None',
                order: SortOrderEnum.None
            }
        }

        this.tableColumns = [
            ["Table Name", "TableName"], 
            ["Expire Days", "ExpireDays"],
            ["TimeInterval for Backup", "TimeIntervalForBackup"],
            ["Path", "Path"],
        ]

    }

    compareObject = (key, isAscending) => {
        return (obj1, obj2) => {
            if (key === "ExpireDays" || key === "TimeIntervalForBackup") {
                let num1 = isNaN(obj1[key]) ? 0 : obj1[key];
                let num2 = isNaN(obj2[key]) ? 0 : obj2[key];
                // compare numbers
                return isAscending ? num1 - num2 : num2 - num1
            } else {
                // compare strings
                return compareString(obj1[key], obj2[key], isAscending)
            }
        }
    }

    sortList = (list, key, order) => {
        if (order === SortOrderEnum.None) {
            return list.sort(this.compareObject("DefaultKey", true))
        } else {
            return list.sort(this.compareObject(key, order === SortOrderEnum.Ascending))
        }
    }


    onSort = (key) => {
        let { tableSort, table }  = this.state
        let nextOrder = getNextSortOrder(tableSort.order, tableSort.key !== key)
    
        tableSort = {
            key: key,
            order: nextOrder
        }
        
        let sortedList = this.sortList(table, key, nextOrder)
        this.setState({
            table: sortedList,
            tableSort: tableSort
        })
    }

    onChangeCube = cube => {
        this.setState({
            table: this.tableDict[cube.value],
            tableSort: {
                key: 'None',
                value: SortOrderEnum.None
            },
            selectedCubeName: cube.value
        })
    }

    renderTable = () => {
        let { tableSort, table } = this.state
        let columns = this.tableColumns;
        return (
            <div>
                <hr/>
                <Row>
                    <Col className="Label">
                        <div style={{ overflowX: 'auto', width: '100%' }} aria-live="polite" aria-atomic="true" aria-label={"Cube Backup Status, " + (table ? table.length + " results found" : "")}>>
                            <Table striped bordered hover className="statusTable">
                                <thead>
                                    <tr>
                                        {
                                            columns.map(item=>{
                                                let friendly_name = item[0];
                                                let key_name = item[1];
                                                return (
                                                    <th key={key_name} onClick={this.onSort.bind(this, key_name)} className="tableHeader" scope="col">
                                                        {renderSortMark(tableSort.key, tableSort.order, key_name)}
                                                        {friendly_name}
                                                    </th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {table && table.map(row => (
                                        <tr key={row.DefaultKey}>
                                            {
                                                columns.map(col=>{
                                                    let key_name = col[1];
                                                    return (
                                                        <td key={key_name}>{row[key_name]}</td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
  

    render(){
        const {selectedCubeName, cubeNames} = this.state;
        return (
        <div>
            <hr/>
            <div>
                <Row>
                    <div style={{"fontSize":17, "padding":8}}>Choose cube: </div>
                    <div style={{width: 300}}>
                    <Select
                        value={{label:selectedCubeName, value:selectedCubeName}}
                        options={cubeNames.map(e=>{return {label:e, value:e}})}
                        onChange={this.onChangeCube}
                    />
                    </div>
                </Row>
            </div>
            <div style={{width:"100%", "overflowX":"auto"}}>
                <div>{this.renderTable()}</div>
            </div>
        </div>
        )
    }
}

export default BackupCubeTableList;