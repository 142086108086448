import React, {Component} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ValidationInput, { FileInput, FileInputWithType, SelectWithList, CubeSetting } from './ValidationInput'
import { fileStatus, UploadTypeList, UploadType } from '../../Functions/CubeCSVManagement/CubeCSVConst'
import CubeCSVDataService from '../../Dataservices/CubeCSVDataService'
import { LoadingOverlay } from '../PublicComponents/HelperComponents'

class UploadCSVModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cubeName: '',
            cubeError: '',
            fileList: [], // [{file: '', fileName:'', fileError:'',fileStatus:''}]
            owners: props.loginUser.userName+';',
            ownersError: '',
            isFlat: false,
            uploadType: UploadType.Cube,
            modalStatus: fileStatus.Waiting,
            cubeType: 'Daily',
            cubeArchitecture: 'V4',
            cubeTypeList: [],
            cubeArchitectureList: [],
        }
    }

    componentDidMount() {
        CubeCSVDataService.getTemplateType().then(res => {
            this.setState({
                cubeTypeList: res.data.CubeType,
                cubeArchitectureList: res.data.CubeArchitecture,
            })
        })
    }

    initState = () => {
        this.setState({
            cubeName: '',
            cubeError: '',
            fileList: [], // [{file: '', fileName:'', fileError:'',fileStatus:''}]
            owners: this.props.loginUser.userName+';',
            ownersError: '',
            isFlat: false,
            uploadType: UploadType.Cube,
            modalStatus: fileStatus.Waiting,
            cubeType: 'Daily',
            cubeArchitecture: 'V4',
        })
    }

    validateCubeName = (cubeName) => {
        const { validateCube } = this.props
        const { isFlat } = this.state
        let err = validateCube(cubeName, isFlat)
        if(err) return err
        return this.validateTitle(cubeName)
    }

    validateTitle = (title) => {
        if (title && title.indexOf(' ') !== -1) { return 'File title cannot contain spaces!' }
        return ''
    }

    validateOwners = (owner) => {
        if (owner && owner.indexOf(' ') !== -1) { return 'Owners cannot contain spaces!' }
        return ''
    } 

    onCubeNameChange = (e) => { this.setState({ cubeName: e.target.value, cubeError: this.validateCubeName(e.target.value) }) }

    onFileChange = (index, e) => {
        let { fileList } = this.state
        const files = e.target.files
        if(files.length > 0) {
            fileList[index].fileName = files[0].name
            fileList[index].fileStatus = fileStatus.Uploading
            this.setState({  fileList: fileList })
            const model = this
            files[0].text().then(
                function(value){ 
                    fileList[index].file = value
                    fileList[index].fileStatus = fileStatus.Success
                    model.setState({ fileList: fileList }) 
                },
                function(error){ 
                    fileList[index].fileStatus = fileStatus.Fail
                    fileList[index].fileError = error.message
                    model.setState({ fileList: fileList }) 
                }
            )
        }
    }

    onIsFlatChange = (e) => { this.setState({ isFlat: e.target.checked }) }

    onOwnersChange = (e) => { this.setState({ owners: e.target.value, ownersError: this.validateOwners(e.target.value) }) }

    onTypeChange = (e) => { this.setState({ uploadType: e.target.value }) }

    onScenarioChange = (e) => { this.setState({ scenario: e.target.value}) }

    onArchitectureChange = (e) => { this.setState({ cubeArchitecture: e.target.value}) }

    onFileTypeChange = (index, e) => {
        let { fileList } = this.state
        fileList[index].type = e.target.value
        this.setState({ fileList: fileList })
     }

    addNewCSVFile = () => {
        let { fileList } = this.state
        fileList.push({file: '', fileName: 'CSV File', type: 'column', fileStatus: fileStatus.Waiting, fileError: ''})
        this.setState({ fileList: fileList })
    }

    onDeleteFile = (index) => {
        let { fileList } = this.state
        fileList.splice(index, 1)
        this.setState({ fileList: fileList })
    }

    onCreate= (show) => {
        let { cubeName, cubeError, fileList, logStatus, isFlat, owners, ownersError, uploadType, cubeType, cubeArchitecture } = this.state
        const { onCreateCsv, toggleModal } = this.props
        const fileNameEmptyError = 'Input cannot be empty!'
        const fileEmptyError = 'File need to be provided!'

        if (cubeError || ownersError || fileList.filter(x => {return x.fileError != ''}).length > 0)  return 
        if (!cubeName) { 
            this.setState({ cubeError: fileNameEmptyError }) 
            return
        }
        if(!owners) { 
            this.setState({ ownersError: fileNameEmptyError }) 
            return
        }
        if (fileList.filter(x => {return x.fileType === 'column' && x.fileStatus !== fileStatus.Success}).length > 0) { 
            this.setState({ columnStatus: fileStatus.Fail, columnError: fileEmptyError }) 
            return
        }
        if (fileList.filter(x => {return x.fileType === 'role' && x.fileStatus !== fileStatus.Success}).length > 0) { 
            this.setState({ roleStatus: fileStatus.Fail, roleError: fileEmptyError }) 
            return
        }
        if (fileList.filter(x => {return x.fileStatus === fileStatus.Uploading}).length > 0) return
        if (!cubeName || !owners || logStatus === fileStatus.Uploading) return 

        this.setState({ modalStatus: fileStatus.Uploading })
        onCreateCsv(cubeName, fileList, isFlat, owners, uploadType, cubeType, cubeArchitecture, show)
        toggleModal()
        this.initState()
    }                                                   

    render() {
        const { showModal, toggleModal } = this.props
        const { cubeName, cubeError, fileList, isFlat, owners, ownersError, uploadType, cubeType, cubeArchitecture, modalStatus, cubeTypeList, cubeArchitectureList } = this.state

        return (
            <Modal show={showModal} onHide={toggleModal}>
                {(modalStatus === fileStatus.Uploading) && <LoadingOverlay />}
                <Modal.Header closeButton>
                    <Modal.Title>Create New Cube CSV</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <CubeSetting cubeName={cubeName} cubeError={cubeError} onCubeNameChange={this.onCubeNameChange} owners={owners} ownersError={ownersError} onOwnersChange={this.onOwnersChange} />

                        <Form.Group as={Row}>
                            <Col sm={4}>
                                <Form.Label>Upload Type</Form.Label>
                                <SelectWithList value={uploadType} required="true" onChange={this.onTypeChange} options={UploadTypeList}/>
                            </Col>
                            <Col sm={4}>
                                <Form.Label>Cube Type</Form.Label>
                                <SelectWithList value={cubeType} required="true" onChange={this.onTypeChange} options={cubeTypeList}/>
                            </Col>
                            <Col sm={4}>
                                <Form.Label>Cube Architecture</Form.Label>
                                <SelectWithList value={cubeArchitecture} required="true" onChange={this.onArchitectureChange} options={cubeArchitectureList}/>
                            </Col>
                        </Form.Group>

                        <Form.Group>
                            <Form.Check type="checkbox" label="IsFlat" checked={isFlat} onChange={this.onIsFlatChange}/>
                        </Form.Group>
                        
                        <Form.Label>CSV File</Form.Label>
                        {
                            fileList.map((file, index) => (
                                <Form.Group>
                                <FileInputWithType
                                    type="page"
                                    columnkey="tableFile"
                                    label={file.fileName}
                                    error={file.fileError}
                                    onDelete={this.onDeleteFile.bind(this, index)}
                                    onTypeChange={this.onFileTypeChange.bind(this, index)}
                                    onChange={this.onFileChange.bind(this, index)}
                                    status={file.fileStatus}
                                />
                                </Form.Group>
                            ))
                        }

                        <Button variant="secondary" size="sm" onClick={this.addNewCSVFile} block>
                            Add new CSV
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={toggleModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={this.onCreate.bind(this, false)}>
                        Create
                    </Button>
                    <Button variant="primary" onClick={this.onCreate.bind(this, true)}>
                        Create And Show
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export class UploadSingleCSVModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fileName: 'Upload file...',
            file: '',
            status: fileStatus.Waiting,
            error: '',
            modalStatus: fileStatus.Waiting,
        }
    }

    onFileChange = (e) => {
        const files = e.target.files
        if(files.length > 0) {
            this.setState({ fileName: files[0].name, status: fileStatus.Uploading })
            const model = this
            files[0].text().then(
                function(value){ model.setState({ file: value, status: fileStatus.Success, }) },
                function(error){ model.setState({ status: fileStatus.Fail, error: error.message, }) }
            )
        }
    }

    initState = () => { this.setState({ fileName: 'Upload file...', file: '', status: fileStatus.Waiting, error: '', modalStatus: fileStatus.Waiting, }) }

    onCreate = () => {
        let { file, error, status} = this.state
        const { onCreateCsv, toggleModal } = this.props
        const fileEmptyError = 'File need to be provided!'

        if (error) return
        if (status !== fileStatus.Success) { this.setState({ status: fileStatus.Fail, error: fileEmptyError }); return; }

        this.setState({ modalStatus: fileStatus.Uploading })
        onCreateCsv(file)
        toggleModal()
        this.initState()
    }

    render() {
        const { showModal, toggleModal } = this.props
        const { fileName, status, error, modalStatus } = this.state

        return (
            <Modal show={showModal} onHide={toggleModal}>
                {(modalStatus === fileStatus.Uploading) && <LoadingOverlay />}
                <Modal.Header closeButton>
                    <Modal.Title>Upload CSV File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group >
                            <Form.Label>CSV File</Form.Label>
                            <FileInput 
                                type="page"
                                columnkey="CSVFile"
                                label={fileName}
                                error={error}
                                onChange={this.onFileChange}
                                status={status}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={toggleModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={this.onCreate}>
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default UploadCSVModal