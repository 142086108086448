import { Modal, Button } from 'react-bootstrap';

/**
 * A Yes/No modal dialog for confirming deletion of a service or service log.
 * title: The title of the modal dialog.
 * prompt: The prompt to display in the modal dialog.
 * onYes: The function to call when the Yes button is clicked.
 * onNo: The function to call when the No button is clicked.
 * show: A boolean indicating whether to show the modal dialog.
 */
function DeleteModal(props) {
    const { title, prompt, onYes, onNo, show } = props;

    return (
        <Modal show={show} onHide={onNo}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>{prompt}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onNo}>No</Button>
                <Button variant="primary" onClick={onYes}>Yes</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteModal;