export function convertLocalDateToUTCTime (localdate) {
    return localdate.getTime() + localdate.getTimezoneOffset() * 60000
}

export function replaceDateforUrlArray (dataList, urlParam, date) {
    var dateObj;
    if(date instanceof Date) {
        dateObj = date;
    } else {
        dateObj = new Date(date);
    }

    dateObj.setHours(0,0,0,0); //strip off the time
    var dateStr = dateObj.toLocaleDateString("en-US").replace(/[^ -~]/g,'');
    replaceParamsforUrlArray(dataList, urlParam, dateStr);
};

export function replaceParamsforUrlArray (dataList, urlParam, value) {
    for(var i = 0; i < dataList.length; i++) {
        var url = dataList[i].url;
        if(url) {
            dataList[i].url = replaceSingleParamforUrl(url, urlParam, value);
        }
    }
};

export function replaceSingleParamforUrl (url, urlParam, value) {
    var startIndex = url.indexOf("&" + urlParam + "=");

    if(startIndex === -1)
    {
        startIndex = url.indexOf("?" + urlParam + "=");
    }

    var newUrl;

    if(startIndex > -1) {
        startIndex = startIndex + 1;
        newUrl = url.substring(0, startIndex + urlParam.length + 1) + value;
        var endIndex = url.indexOf("&", startIndex + 1);

        if(endIndex > -1) {
            newUrl += url.substring(endIndex, url.length);
        }
    } else {
        newUrl = url + '&' + urlParam + '=' + value;
    }
    return newUrl;
}

export function formatSampleCount (currAvgDailySample) {
    let retAvgDailySample = currAvgDailySample

    if (currAvgDailySample > 1000 && currAvgDailySample <= 1000000)
    {
        retAvgDailySample = (currAvgDailySample  / 1000).toFixed(2) + 'K'
    }
    else if (currAvgDailySample > 1000000 && currAvgDailySample <= 1000000000)
    {
        retAvgDailySample = (currAvgDailySample  / 1000000).toFixed(2) + 'M'
    }
    else if (currAvgDailySample > 1000000000)
    {
        retAvgDailySample = (currAvgDailySample  / 1000000000).toFixed(2) + 'B'
    }

    return retAvgDailySample 
}

export function getBackgroundColorByLatency (latency, latencyThreshold) {
    const BLACK = "#000000";
    const RED = "#ff0909";
    const YELLOW = "#ffff05";
    const LIGHT_GREEN = "#92d050";
    const DARK_GREEN = "#00b050";
    const DEFAULT_COLOR = "#c8e6c9";
  
    if (latency > latencyThreshold * 1.6) {
        return BLACK;
      } else if (latency > latencyThreshold * 1.3) {
        return RED;
      } else if (latency > latencyThreshold) {
        return YELLOW;
      } else if (latency === latencyThreshold) {
        return LIGHT_GREEN;
      } else if (latency < latencyThreshold) {
        return DARK_GREEN;
      } else {
        return DEFAULT_COLOR;
    }
}

export function setAccessibility(tabIndex) {
    const focusableElements = document.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="' + tabIndex + '"])'
    );
    const focusableArray = Array.from(focusableElements);
    focusableArray.forEach(element => {
        element.setAttribute('tabindex', tabIndex);
    });
}