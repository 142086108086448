import React, {Component} from 'react'
import Table from 'react-bootstrap/Table'
import './MSR.css'
import Col from 'react-bootstrap/Col'
import { LoadingGif } from '../PublicComponents/HelperComponents';
import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import MSRSummaryScenario from './MSRSummaryScenario'
import Select from 'react-select';
import MSRService from '../../Dataservices/MSRService'
import moment from 'moment';
import ReactDatePicker from '../ReactDatepicker';

const MSRIdForCoreScenarios = 29

class MSRSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLatencyThreshold: true,
            showAvgDailySamples: true,
            weeks: 12,
            showCard: true,
            scenarioList: [],
            selectedRings: ["MSIT", "PROD|WW"],
            error : null,
            isInfoLoading: false,
            endDate: new Date()
        }
    }

    componentDidMount() {
        this.setState({
            scenarioList: [],
            isInfoLoading: true,
            error: ''
        })

        MSRService.getMSRInfo(MSRIdForCoreScenarios)
        .then(res =>{
            this.setState({
                scenarioList: res.data.charts
            })
        })
        .catch(err => {
            console.error(err)
            this.setError(err.message)
            this.setState({ isInfoLoading: false })
        })
    }

    setLatencyThreshold = e => {
        this.setState({ showLatencyThreshold: e.target.checked });
    }

    setAvgDailySamples = e => {
        this.setState({ showAvgDailySamples: e.target.checked });
    }

    setRing = selectedOptions => {
        const selectedRings = selectedOptions ? selectedOptions.map(option => option.value) : [];
        this.setState({ selectedRings });
    }

    setWeeks = e => {
        const currSelectText = e.target.options[e.target.selectedIndex].text
        this.setState({ weeks: currSelectText});
    }

    onEditCallout = (ScenarioId, newCalloutList) => {
        debugger;
        const{ scenarioList } = this.state
        const newScenarioList = scenarioList.map(s => {
            if (s.id === ScenarioId) {
                s.Callouts = newCalloutList
            }
            return s;
        });
        this.setState({ scenarioList: newScenarioList });
    }

    getMSRHeader = () => {
        const { showLatencyThreshold, showAvgDailySamples, selectedRings, weeks } = this.state
        const showMsit = selectedRings.includes('MSIT')
        const showProd = selectedRings.includes('PROD|WW')
        const showSdfv2 = selectedRings.includes('SDFV2')
        const ringCountsShownonMSR = showProd + showMsit + showSdfv2

        return (
            <thead>
                <tr>
                    <th className="tableHeader" rowspan="2">
                        Scenario&nbsp;&nbsp;Name
                    </th>    
                    {showSdfv2?
                        <th className="tableHeader" style={{borderRight: "2px solid #111"}} colspan={4 + showLatencyThreshold + showAvgDailySamples}> 
                            SDFV2
                        </th>
                        :
                        null
                    }                             
                    {showMsit?
                        <th className="tableHeader" style={{borderRight: "2px solid #111"}} colspan={4 + showLatencyThreshold + showAvgDailySamples}> 
                            MSIT
                        </th>
                        :
                        null
                    }
                    {showProd?
                        <th className="tableHeader" colspan={4 + showLatencyThreshold + showAvgDailySamples}> 
                            PROD|WW
                        </th>
                        :
                        null
                    }
                    <th className="tableHeader" rowspan="2">
                        MSR Callout
                    </th>
                </tr>
                <tr>
                    {Array(ringCountsShownonMSR).fill(1).map((el, i) =>
                        <>
                            {showLatencyThreshold?
                                <th className="tableHeader">
                                    Latency Goal
                                </th>
                                :
                                null
                            }
                            {showAvgDailySamples?
                                <th className="tableHeader">
                                    Avg Daily Samples
                                </th>
                                :
                                null
                            }
                            <th className="tableHeader">
                                {weeks} Weeks Avg
                            </th>
                            <th className="tableHeader">
                                {weeks} Weeks Trend
                            </th>
                            <th className="tableHeader">
                                % Deviation from Goal
                            </th>
                            <th className="tableHeader" style={i === ringCountsShownonMSR - 1 ? {} : {borderRight: "2px solid #111"}}>
                                Current Week Avg
                            </th>
                        </>
                    )}
                </tr>
            </thead>
        )
    }

    setError = error => {
        this.setState({error: error.message});
    }

    getStartDate = () => {
        const weeksWithFullDays = this.state.weeks === null ? 3 : this.state.weeks - 1;
        const FRIDAY = 5;
        const DAYS_IN_WEEK = 7;
        const MILLISECONDS_IN_DAY = 86400000;
        const WEEKLY_MSR_ADJUSTMENT_DAYS = weeksWithFullDays * DAYS_IN_WEEK + 4; // 4 presentated as days count through Monday to Thursday

        const currentDate = this.state.endDate;

        const daysToPreviousFriday = currentDate.getDay() < FRIDAY ?
            (DAYS_IN_WEEK - FRIDAY + currentDate.getDay()) :
            (currentDate.getDay() - FRIDAY);

        const startDateforWeeklyMsr = new Date(currentDate.getTime() - (WEEKLY_MSR_ADJUSTMENT_DAYS + daysToPreviousFriday) * MILLISECONDS_IN_DAY);
        return startDateforWeeklyMsr
    }

    isValidSelectedDate = (current) => {
        const now = moment();
        if (current) {
            const currentMoment = moment(current);
            const isAfterToday = currentMoment.isAfter(now, 'day');
            const isThursday = currentMoment.weekday() === 4;
            return !(isAfterToday || !isThursday);
        }
        return true;
    }

    render() {
        const { showLatencyThreshold, showAvgDailySamples, weeks, selectedRings, scenarioList, endDate } = this.state
        const showMsit = selectedRings.includes('MSIT')
        const showProd = selectedRings.includes('PROD|WW')
        const showSdfv2 = selectedRings.includes('SDFV2')
        const startDate = this.getStartDate()
        const momentUpdate = require('moment');

        return (
            scenarioList === null ? null :
            <Container fluid>
                {this.state.error && <Alert variant='danger'>{this.state.error}</Alert>}
                <Row>
                    <Col xs={12} lg={12}><h1 style={{paddingTop: '20px'}}>MSR Summary for {momentUpdate(startDate).utc().format('MM/DD/YYYY')} - {momentUpdate(endDate).utc().format('MM/DD/YYYY')}</h1></Col>
                </Row>

                <Row>
                    <Col className="chartingPanePivotCheckbox">
                        <div style={{ display: 'flex', paddingBottom: '10px' }}>
                            <React.Fragment>
                                    <Select
                                        options={[
                                            { value: 'SDFV2', label: 'SDFV2' },
                                            { value: 'MSIT', label: 'MSIT' },
                                            { value: 'PROD|WW', label: 'PROD|WW' },
                                        ]}
                                        defaultValue={[
                                            { value: 'MSIT', label: 'MSIT' },
                                            { value: 'PROD|WW', label: 'PROD|WW' },
                                        ]}
                                        isMulti
                                        onChange={this.setRing}
                                        aria-label="Select Ring"
                                        styles={{
                                            control: (provided) => ({
                                            ...provided,
                                            width: 300,
                                            }),
                                            menu: (provided) => ({
                                            ...provided,
                                            width: 300,
                                            })
                                        }}
                                        />
                                </React.Fragment>
                                <React.Fragment>
                                    <div style={{ display: 'flex', alignItems: 'center'}}>
                                        <label htmlFor="weeks" style={{ marginRight: '10px', marginLeft: '10px', marginTop: '10px' }}>Weeks</label>
                                        <select id="weeks" value={weeks} onChange={this.setWeeks} style={{ height: '20px' }}>
                                        {Array.from({ length: 24 }, (_, i) => i + 1).map((week) => (
                                            <option key={week} value={week}>
                                            {week}
                                            </option>
                                        ))}
                                        </select>
                                    </div>
                                </React.Fragment>

                                <React.Fragment>
                                    <label htmlFor="endDate" style={{ marginRight: '10px', marginLeft: '10px', marginTop: '10px' }}>End Date</label>
                                    
                                    <ReactDatePicker
                                        id="endDate"
                                        className="gray-border short-width"
                                        value={this.state.endDate}
                                        onChange={(date) => this.setState({ endDate: date })}
                                        filterDate={this.isValidSelectedDate}
                                        isTodayDisabled={!this.isValidSelectedDate(new Date())}                                       
                                    />
                                </React.Fragment>

                                <React.Fragment>
                                    <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                    <input type="checkbox" id="showLatencyThreshold" checked={showLatencyThreshold} onClick={this.setLatencyThreshold}/>
                                    <label htmlFor="showLatencyThreshold" style={{ display: 'flex', paddingTop: '10px' }}>Show Latency Goal</label>
                                </React.Fragment>
                                <React.Fragment>
                                    <label htmlFor="weekends">&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                    <input type="checkbox" id="showAvgDailySamples" checked={showAvgDailySamples} onClick={this.setAvgDailySamples}/>
                                    <label htmlFor="showAvgDailySamples" style={{ display: 'flex', paddingTop: '10px' }}>Show Avg Daily Samples</label>
                                </React.Fragment>
                            </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="MSRTable">
                            {!this.state.isInfoLoading ? 
                                <Col><LoadingGif /></Col> 
                                : 
                                <Table striped bordered hover>
                                    {this.getMSRHeader()}
                                    <tbody>
                                        {scenarioList.map(Scenario =>(
                                            <MSRSummaryScenario
                                            Scenario={Scenario}
                                            WeekCountsShownonMSR={weeks}
                                            startDate={this.getStartDate()}
                                            endDate={this.state.endDate}
                                            showLatencyThreshold={showLatencyThreshold}
                                            showAvgDailySamples={showAvgDailySamples}
                                            showMsit={showMsit}
                                            showProd={showProd}
                                            showSdfv2={showSdfv2}
                                            onEditCallout={this.onEditCallout}/>
                                        ))}
                                    </tbody>
                                </Table>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default MSRSummary