import React, { Component } from 'react'
import Chart from '../Chart'
import DataService from '../../Dataservices/DataService'
import PercentileTable from '../PublicComponents/PercentileTable'
import axios from "axios"
import { getTabFromUrl, getCubeFromURL, getIsDeprecated, getIsHourly, getPercentilesFromUrl, getMetricsFromUrl, getTimezoneFromUrl, getStandardizedUrl, replaceGraphIt, replaceDate, getIncludeWeekendsFromUrl, getChartTypeFromUrl, getChartTypeFromIndex, getIsEvo, getEvoServiceAndLogNameFromUrl, getEvoServiceAndLogIdFromUrl } from '../../Functions/UrlHelper'
import { getPercentileTableData } from '../../Functions/TableHelper'
import { TabXValueMap, transformLatencyDataToCumulative, transformLatencyDataToDate, fillInDates, isThisDayIncluded, emptyLatencySeries, concatData, mergeCheckpointData } from '../../Functions/ChartHelpers'
import SharedCharts from '../SharedCharts'
import { handleGraph } from '../../Dataservices/EventTrackingService'
import { formatUTCDateAsString, convertStringToUTCDate, addDaysToUTCDate, getDateConvertedFromUTCToTimezone, dateTimeCmp, formatUTCDateAsDashString } from '../../Functions/DateTimeHelper'
import { DashboardLoadingOverlay } from '../PublicComponents/HelperComponents';
import EvoDataService from '../../Dataservices/EvoDataService';

class ProgressiveChart extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dailyStartDate: props.dailyStartDate,
            dailyEndDate: props.dailyEndDate,
            hourlyStartDate: props.hourlyStartDate,
            hourlyEndDate: props.hourlyEndDate,
            isLoading: true,
            isSaving: false,
            cubeName: '',
            tab: null,
            tableError: '',
            chartError: [],
            error: null,
            updatedTime: new Date().getTime(),
            updatedGoallineTime: new Date().getTime(),
            updatedAlertTime: new Date().getTime(),
            refreshTime: props.refreshTime,
            chart: props.chart,
            missingDates: -1,
        }

        this.dataQuerySource = axios.CancelToken.source();

        this.data = {
            charts: [{
                serverData: [],
                rawData: [],
                chartType: 'Line',
                errorMessage: ''
            }],
            isTable: false,
            isHourly: false,
            timezone: "UTC",
            tableData: {},
            renderStartTime: null,
            renderEndTime: null,
            startDate: null,
            endDate: null,
        }
    }

    componentDidMount() {
        const { dailyStartDate, dailyEndDate, hourlyStartDate, hourlyEndDate } = this.state
        if ((dailyStartDate && dailyEndDate) || (hourlyStartDate && hourlyEndDate)) this.drawChart()
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (dateTimeCmp(prevState.dailyStartDate, nextProps.dailyStartDate) || dateTimeCmp(prevState.dailyEndDate, nextProps.dailyEndDate) ||
            dateTimeCmp(prevState.hourlyStartDate, nextProps.hourlyStartDate) || dateTimeCmp(prevState.hourlyEndDate, nextProps.hourlyEndDate) ||
            (prevState.chart && nextProps.chart && prevState.chart.url !== nextProps.chart.url)) {
            return {
                hourlyStartDate: nextProps.hourlyStartDate,
                hourlyEndDate: nextProps.hourlyEndDate,
                dailyStartDate: nextProps.dailyStartDate,
                dailyEndDate: nextProps.dailyEndDate,
                chart: nextProps.chart
            }
        }
        return null
    }

    componentDidUpdate(prevProps) {
        const { dailyStartDate, dailyEndDate, hourlyStartDate, hourlyEndDate, chart, refreshTime, cubes } = this.props
        if (dateTimeCmp(dailyStartDate, prevProps.dailyStartDate) || dateTimeCmp(dailyEndDate, prevProps.dailyEndDate) ||
            dateTimeCmp(hourlyStartDate, prevProps.hourlyStartDate) || dateTimeCmp(hourlyEndDate, prevProps.hourlyEndDate) ||
            (chart && prevProps.chart && chart.url !== prevProps.chart.url) || refreshTime !== prevProps.refreshTime || cubes !== prevProps.cubes) {
            this.drawChart()
        }
        this.data.renderEndTime = new Date().getTime();
    }

    parseUrlToState = (url) => {
        const appName = getCubeFromURL(url);
        const metrics = getMetricsFromUrl(url);
        const tabFromUrl = getTabFromUrl(url);
        const timezone = getTimezoneFromUrl(url);
        let chartTypeString = getChartTypeFromUrl(url);
        const chartType = chartTypeString ? chartTypeString.split("|").map(chartTypeIndex => (getChartTypeFromIndex(chartTypeIndex))) : [];
        if (appName) {
            this.setState({ tab: tabFromUrl, cubeName: appName });
            return { metrics, appName, tabFromUrl, timezone, chartType };
        }
        else {
            console.error('Invalid url: ', url)
            return null
        }
    }

    getIsProgressiveLoading = (appName, tab, isHourly) => {
        return (appName !== "perfPanelPerf") && (!isHourly) && (tab === "checkpoint" || tab === "usage" || tab === "dailymetrics" || tab === "daily") // todo : tab === "ratio"
    }

    getEvoMapping = (serviceId, serviceLogId) => {
        return new Promise((resolve, reject) => {
            const mappingKey = serviceId + "_" + serviceLogId + "_" + "Mapping";
            const mappingValue = this.props.evoServicesMapping[mappingKey];
            if (mappingValue) { 
                mappingValue.then(mapping => {
                    resolve(mapping);
                }).catch(error => {
                    console.error(error);
                    this.showChartErrorMessage(error)
                    reject(error);
                })
            } else {
                const mappingQuery = EvoDataService.getEvoServiceConfiguration().then(res => {
                    resolve(res.data);
                    return res.data;
                }).catch(error => {
                    console.error(error);
                    this.showChartErrorMessage(error)
                    reject(error);
                })
                this.props.evoServicesMapping[mappingKey] = mappingQuery;
            }
        })
    }

    getEvoPivot = (serviceId, serviceLogId, fromDate, toDate) => {
        return new Promise((resolve, reject) => {
            const pivotKey = serviceId + "_" + serviceLogId + "_" + "Pivot" + fromDate + '_' + toDate;
            const pivotValue = this.props.evoServicesMapping[pivotKey];
            if (pivotValue) { 
                pivotValue.then(pivot => {
                    resolve(pivot);
                }).catch(error => {
                    console.error(error);
                    this.showChartErrorMessage(error)
                    reject(error);
                })
            } else {
                const pivotQuery = EvoDataService.evoPivots(serviceId, serviceLogId, fromDate, toDate).then(res => {
                    resolve(res.data);
                    return res.data;
                }).catch(error => {
                    console.error(error);
                    this.showChartErrorMessage(error)
                    reject(error);
                })
                this.props.evoServicesMapping[pivotKey] = pivotQuery;
            }
        })
    }

    getMaxDate = (appName, tab, isHourly, includeWeekends, startDate, endDate) => {
        return new Promise((resolve, reject) => {
            const cube = this.props.cubes.find(cube => cube.AppName === appName);
            if (cube && cube.maxDate) {
                cube.maxDate.then(maxDate => {
                    endDate = maxDate < endDate ? maxDate : endDate;
                    this.updateDataWithEmptyData(tab, isHourly, startDate, endDate, includeWeekends);
                    this.data.endDate = endDate;
                    resolve(endDate);
                }).catch(error => {
                    console.error(error);
                    if (axios.isCancel(error)) {
                        this.setState({ isLoading: false })
                    } else {
                        this.showChartErrorMessage(error)
                    }
                    reject(error);
                })
            } else {
                const maxDateQuery = DataService.post(appName + '/MaxDate', null, this.dataQuerySource.token).then(res => {
                    const maxDate = convertStringToUTCDate(JSON.parse(res.data).MaxDateNormalized);
                    endDate = maxDate < endDate ? maxDate : endDate;
                    this.updateDataWithEmptyData(tab, isHourly, startDate, endDate, includeWeekends);
                    this.data.endDate = endDate;
                    resolve(endDate);
                    return maxDate;
                }).catch(error => {
                    console.error(error);
                    if (axios.isCancel(error)) {
                        this.setState({ isLoading: false })
                    } else {
                        this.showChartErrorMessage(error)
                    }
                    reject(error);
                })
                if (cube) cube.maxDate = maxDateQuery;
            }
        })
    }

    progressUpdateChartState = (data, error) => {
        if (this.props.setData) this.props.setData(this.data);
        this.setState({
            chartError: data.map(() => ''),
            isLoading: false,
            updatedTime: new Date().getTime(),
            updatedGoallineTime: new Date().getTime(),
            error: error,
        })
    }

    queryCacheData = (appName, tab, isHourly, includeWeekends, url, startDate, endDate, hourlyStartDate, hourlyEndDate, chartType, metrics) => {
        const requestObj = {
            endpointUrl: appName + '/dataQueryOverrides',
            obj: {
                Url: url,
                StartDate: startDate, // todo: support hourly
                EndDate: endDate, // todo: support hourly
                IsHourly: isHourly,
                CacheOnly: true,
            }
        };

        return new Promise((resolve, reject) => {
            DataService.post(requestObj.endpointUrl, requestObj.obj, this.dataQuerySource.token, this.props.scenarioTags).then(res => {
                if (res.data.IsSuccess) {
                    const data = this.updateProgressiveData(res, tab, isHourly, includeWeekends, startDate, endDate, chartType, url, metrics);

                    this.data.charts = data;
                    this.data.isHourly = isHourly;
                    this.progressUpdateChartState(data, null);

                    let dateList = [];
                    if (tab === 'checkpoint' && res.data.AdditionalLatencyDataSeries) {
                        res.data.AdditionalLatencyDataSeries.map(series => series.Data.map(data => {
                            if (!dateList.includes(data.name) && data.y != null) {
                                dateList.push(data.name);
                            }
                        }));
                    } else {
                        res.data.LatencyDataSeries.map(series => series.Data.map(data => {
                            if (!dateList.includes(data.name) && data.y != null) {
                                dateList.push(data.name);
                            }
                        }));
                    }
                    resolve(dateList);
                } else {
                    this.setErrorMessage(res.data.ErrorMessage);
                    console.error(res.data.RequestID);
                    console.error(res.data.RequestLog);
                    console.error(res.data.ErrorMessage);
                    reject(res.data.ErrorMessage);
                }
            }).catch(error => {
                console.error(error);
                if (axios.isCancel(error)) {
                    this.setState({ isLoading: false })
                } else {
                    this.showChartErrorMessage(error)
                }
                reject(error);
            })
        })
    }

    queryNonCachedData = (appName, tab, isHourly, includeWeekends, url, startDate, endDate, chartType, missingDates, metrics) => {
        this.setState({ missingDates: missingDates.length });
        if (missingDates.length > 0) {
            const queryDate = missingDates.pop();
            const requestObj = {
                endpointUrl: appName + '/dataQueryOverrides',
                obj: {
                    Url: url,
                    StartDate: queryDate, // todo: support hourly
                    EndDate: queryDate, // todo: support hourly
                    IsHourly: isHourly,
                    CacheOnly: false,
                }
            };

            DataService.post(requestObj.endpointUrl, requestObj.obj, this.dataQuerySource.token, this.props.scenarioTags).then(res => {
                if (res.data.IsSuccess) {
                    if((res.data.LatencyDataSeries && res.data.LatencyDataSeries.length !== 0) || 
                       (res.data.AdditionalLatencyDataSeries && res.data.AdditionalLatencyDataSeries.length !== 0) || 
                       (res.data.SampleCountDataSeries && res.data.SampleCountDataSeries.length !== 0)) {
                        const data = this.updateProgressiveData(res, tab, isHourly, includeWeekends, startDate, endDate, chartType, url, metrics);
                        this.data.charts = data;
                        this.progressUpdateChartState(data, null);
                    }
                    this.queryNonCachedData(appName, tab, isHourly, includeWeekends, url, startDate, endDate, chartType, missingDates, metrics);
                } else {
                    this.setErrorMessage(res.data.ErrorMessage);
                    console.error(res.data.RequestID);
                    console.error(res.data.RequestLog);
                    console.error(res.data.ErrorMessage);
                }
            }).catch(error => {
                console.error(error)
                if (axios.isCancel(error)) {
                    this.setState({ isLoading: false })
                } else {
                    this.showChartErrorMessage(error)
                }
            })
        } else {
            // clear chart in data 
            this.data.charts.map(chart => {
                chart.serverData = chart.serverData.filter(x => (x.ID !== "0"));
            })
            this.progressUpdateChartState(this.data.charts, null);
        }
    }

    getMissingDates = (dateList, startDate, endDate, isHourly, includeWeekends) => {
        const missingDates = [];
        for (let currentDate = startDate; currentDate <= endDate; currentDate = addDaysToUTCDate(currentDate, 1)) {
            if (!isThisDayIncluded(currentDate, includeWeekends)) {
                continue;
            }
            if (!dateList.includes(formatUTCDateAsString(currentDate))) {
                missingDates.push(currentDate);
            }
        }
        return missingDates;
    }

    reInitializeChart = (tab, isHourly, startDate, endDate, includeWeekends) => {
        this.data = {
            ...this.data,
            charts: [{
                serverData: [],
                rawData: [],
                chartType: 'Line',
                errorMessage: ''
            }],
            startDate: null,
            endDate: null,
            missingDates: -1,
        }
        if (this.dataQuerySource) {
            this.dataQuerySource.cancel()
            this.dataQuerySource = axios.CancelToken.source()
        }
        this.updateDataWithEmptyData(tab, isHourly, startDate, endDate, includeWeekends);
    }

    updateDataWithEmptyData = (tab, isHourly, startDate, endDate, includeWeekends) => {
        const emptyDataSeries = fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, false);
        const data = [
            {
                id: "0",
                serverData: this.data.charts.length > 0 && this.data.charts[0].serverData ? concatData(emptyDataSeries, this.data.charts[0].serverData, startDate, endDate, isHourly, includeWeekends, tab !== 'usage') : emptyDataSeries,
                rawData: [],
                chartType: tab === 'checkpoint' ? 'StackedArea' : 'Line',
                xAxisType: "datetime"
            }
        ]
        this.data.charts = data;
        this.data.tableData = {};
        this.data.isTable = false;
        this.data.isLoading = false;
        this.data.errorMessage = '';
        this.progressUpdateChartState(data, null);
    }

    formatAndRefreshUrl = (chart, isHourly) => {
        const { hourlyStartDate, hourlyEndDate, dailyStartDate, dailyEndDate, refreshChartUrl } = this.props;
        const tz = getTimezoneFromUrl(chart.url);
        let newUrl = getStandardizedUrl(chart.url);
        newUrl = replaceGraphIt(newUrl);
        newUrl = replaceDate(newUrl, "sd", isHourly ? getDateConvertedFromUTCToTimezone(hourlyStartDate, tz) : dailyStartDate, isHourly);
        newUrl = replaceDate(newUrl, "ed", isHourly ? getDateConvertedFromUTCToTimezone(hourlyEndDate, tz) : dailyEndDate, isHourly);
        if (refreshChartUrl) refreshChartUrl(chart.id, newUrl);
        return newUrl;
    }

    // todo fill in missing date
    updateProgressiveData = (res, tabFromUrl, isHourly, includeWeekends, startDate, endDate, chartType, url, metrics) => {
        if (!res.data.LatencyDataSeries) {
            return [{
                serverData: [],
            }];
        }

        this.data.isTable = false

        res.data.SampleCountDataSeries?.forEach(series => this.formatSeries(series, metrics));
        res.data.LatencyDataSeries.forEach(series => this.formatSeries(series, metrics));
        res.data.AdditionalLatencyDataSeries?.forEach(series => this.formatSeries(series, metrics));

        let data = []
        endDate = this.data.endDate ? this.data.endDate : endDate;
        switch (tabFromUrl) {
            case 'checkpoint':
                if (res.data.AdditionalLatencyDataSeries) {
                    const dataSeries = fillInDates(transformLatencyDataToDate([...res.data.AdditionalLatencyDataSeries].sort((a, b)=>(parseInt(b.ID) - parseInt(a.ID)))), isHourly, includeWeekends, true);
                    data = [
                        {
                            id: "0",
                            serverData: this.data.charts.length > 0 && this.data.charts[0].serverData && this.data.charts[0].serverData.length > 0 ? mergeCheckpointData(this.data.charts[0].serverData, dataSeries, startDate, endDate, isHourly, includeWeekends, true) : dataSeries,
                            rawData: [...res.data.AdditionalLatencyDataSeries].sort((a, b)=>(parseInt(b.ID) - parseInt(a.ID))),
                            chartType: chartType.length > 0 && chartType[0] ? chartType[0] : 'StackedArea',
                            xAxisType: "datetime"
                        }
                    ]
                } else {
                    data = [
                        {
                            id: "0",
                            serverData: this.data.charts.length > 0 && this.data.charts[0].serverData && this.data.charts[0].serverData.length > 0 ? this.data.charts[0].serverData : [],
                            rawData: this.data.charts.length > 0 && this.data.charts[0].rawData ? this.data.charts[0].serverData : [],
                            chartType: chartType.length > 0 && chartType[0] ? chartType[0] : 'StackedArea',
                            xAxisType: "datetime"
                        }
                    ]
                }
                break
            case 'dailymetrics':
                const dataLatencySeries = fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries.filter(value => {
                    return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() === (".T");
                })), isHourly, includeWeekends, true)
                const dataCountSeries = fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries.filter(value => {
                    return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() === (".C");
                })), isHourly, includeWeekends, true)
                const dataRatioSeries = fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries.filter(value => {
                    return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() === (".R");
                })), isHourly, includeWeekends, true)
                const dataOtherSeries = fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries.filter(value => {
                    return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() !== (".R") && value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() !== (".C") && value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() !== (".T");
                })), isHourly, includeWeekends, true)
                data = [
                    {
                        id: "0",
                        serverData: this.data.charts.length > 0 && this.data.charts[0].serverData && this.data.charts[0].serverData.length > 0 ? concatData(this.data.charts[0].serverData, dataLatencySeries, startDate, endDate, isHourly, includeWeekends, true) : dataLatencySeries,
                        rawData: res.data.LatencyDataSeries,
                        chartType: chartType.length > 0 && chartType[0] ? chartType[0] : 'Line',
                        xAxisType: "datetime"
                    },
                    {
                        id: "1",
                        serverData: this.data.charts.length > 1 && this.data.charts[1].serverData && this.data.charts[1].serverData.length > 0 ? concatData(this.data.charts[1].serverData, dataCountSeries, startDate, endDate, isHourly, includeWeekends, true) : dataCountSeries,
                        rawData: res.data.LatencyDataSeries,
                        chartType: chartType.length > 1 && chartType[1] ? chartType[1] : 'Line',
                        xAxisType: "datetime"
                    },
                    {
                        id: "2",
                        serverData: this.data.charts.length > 2 && this.data.charts[2].serverData && this.data.charts[2].serverData.length > 0 ? concatData(this.data.charts[2].serverData, dataRatioSeries, startDate, endDate, isHourly, includeWeekends, true) : dataRatioSeries,
                        rawData: res.data.LatencyDataSeries,
                        chartType: chartType.length > 2 && chartType[2] ? chartType[2] : 'Line',
                        xAxisType: "datetime"
                    },
                    {
                        id: "3",
                        serverData: this.data.charts.length > 3 && this.data.charts[3].serverData && this.data.charts[3].serverData.length > 0 ? concatData(this.data.charts[3].serverData, dataOtherSeries, startDate, endDate, isHourly, includeWeekends, true) : dataOtherSeries,
                        rawData: res.data.LatencyDataSeries,
                        chartType: chartType.length > 3 && chartType[3] ? chartType[3] : 'Line',
                        xAxisType: "datetime"
                    }
                ]
                break
            case 'metricPercentiles':
                data = [
                    {
                        id: "0",
                        serverData: res.data.LatencyDataSeries.filter(value => {
                            return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() === (".T");
                        }),
                        rawData: res.data.LatencyDataSeries,
                        chartType: chartType.length > 0 && chartType[0] ? chartType[0] : 'Line',
                        xAxisType: "category"
                    },
                    {
                        id: "1",
                        serverData: res.data.LatencyDataSeries.filter(value => {
                            return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() === (".C");
                        }),
                        rawData: res.data.LatencyDataSeries,
                        chartType: chartType.length > 1 && chartType[1] ? chartType[1] : 'Line',
                        xAxisType: "category"
                    },
                    {
                        id: "2",
                        serverData: res.data.LatencyDataSeries.filter(value => {
                            return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() === (".R");
                        }),
                        rawData: res.data.LatencyDataSeries,
                        chartType: chartType.length > 2 && chartType[2] ? chartType[2] : 'Line',
                        xAxisType: "category"
                    },
                    {
                        id: "3",
                        serverData: res.data.LatencyDataSeries.filter(value => {
                            return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() !== (".R") && value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() !== (".C") && value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() !== (".T");
                        }),
                        rawData: res.data.LatencyDataSeries,
                        chartType: chartType.length > 3 && chartType[3] ? chartType[3] : 'Line',
                        xAxisType: "category"
                    }
                ]
                break
            case 'cumulativeHistogram':
                data = [
                    {
                        id: "0",
                        serverData: transformLatencyDataToCumulative(JSON.parse(JSON.stringify(res.data.LatencyDataSeries))),
                        chartType: chartType.length > 0 && chartType[0] ? chartType[0] : 'Line',
                        xAxisType: "category"
                    }
                ]
                break
            case 'pivottable':
            case 'table':
                this.data.isTable = true
                const percentiles = getPercentilesFromUrl(url);
                let cols = percentiles.map(e => {
                    if (e === "AVG") {
                        return "-1"
                    } else {
                        return e.trim()
                    }
                })
                let latencyHeader = percentiles.map(e => {
                    let p = parseInt(e)
                    if (isNaN(p)) {
                        return e
                    } else {
                        return e + "%"
                    }
                })
                let table = getPercentileTableData(res.data, cols)
                this.data.tableData.table = table
                this.data.tableData.latencyHeader = latencyHeader
                this.setErrorMessage('')
                break
            case 'histogram':
            case 'percentiles':
                data = [
                    {
                        id: "0",
                        serverData: res.data.LatencyDataSeries.concat(res.data.AdditionalLatencyDataSeries).filter(arr => arr !== null),
                        chartType: chartType.length > 0 && chartType[0] ? chartType[0] : 'Line',
                        xAxisType: "category"
                    }
                ]
                break
            case 'dailyHistogram':
                data = [
                    {
                        id: "0",
                        serverData: fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries.concat(res.data.AdditionalLatencyDataSeries).filter(arr => arr !== null)), isHourly, includeWeekends, true),
                        chartType: chartType.length > 0 && chartType[0] ? chartType[0] : 'StackedArea',
                        xAxisType: "datetime"
                    }
                ]
                break
            case 'checkpointPercentiles':
                data = [
                    {
                        id: "0",
                        serverData: [...res.data.AdditionalLatencyDataSeries].reverse(),
                        chartType: chartType.length > 0 && chartType[0] ? chartType[0] : 'StackedArea',
                        xAxisType: "category"
                    }
                ]
                break
            case 'usage':
                const usageSeries = fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries.concat(res.data.AdditionalLatencyDataSeries).filter(arr => arr !== null)), isHourly, includeWeekends, false);
                data = [
                    {
                        id: "0",
                        serverData: this.data.charts.length > 0 && this.data.charts[0].serverData && this.data.charts[0].serverData.length > 0 ? concatData(this.data.charts[0].serverData, usageSeries, startDate, endDate, isHourly, includeWeekends, false) : usageSeries,
                        chartType: chartType.length > 0 && chartType[0] ? chartType[0] : 'Line',
                        xAxisType: "datetime"
                    }
                ]
                break
            default:
                const defaultSeries = fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries.concat(res.data.AdditionalLatencyDataSeries).filter(arr => arr !== null)), isHourly, includeWeekends, true);
                data = [
                    {
                        id: "0",
                        serverData: this.data.charts.length > 0 && this.data.charts[0].serverData && this.data.charts[0].serverData.length > 0 ? concatData(this.data.charts[0].serverData, defaultSeries, startDate, endDate, isHourly, includeWeekends, true) : defaultSeries,
                        chartType: chartType.length > 0 && chartType[0] ? chartType[0] : 'Line',
                        xAxisType: "datetime"
                    }
                ]
                break
        }
        return data
    }

    showChartErrorMessage = (err) => {
        let errorMessage = err.response && err.response.data ? err.response.data : err;
        if (err.response && err.response.status === 403) {
            this.setErrorMessage(err.response.data)
        } else {
            this.setErrorMessage("Something is wrong when getting this chart. If you see this error constantly or refresh doesn't work, please send an email to o365coreperf@microsoft.com with the following details:" + JSON.stringify(errorMessage))
        }
    }

    getSnapshotBeforeUpdate() {
        this.data.renderStartTime = new Date().getTime();
        //getSnapshotBeforeUpdate() should have a return value
        return null;
    }

    setErrorMessage = message => {
        let { isTable, charts } = this.data
        if (isTable) {
            this.setState({ tableError: message })
        } else {
            this.setState({
                chartError: charts.map(() => message)
            })
        }
        if (message) {
            console.error(message);
        }
        this.setState({ isLoading: false });
        if (this.props.setErrorMessage) this.props.setErrorMessage(message);
    }

    formatSeries = (series, metrics) => {
        if (!series.Name) {
            series.Name = "(blank)";
        }
        if (metrics && !metrics.includes(series.Name)) {
            series.Hide = true;
        }
    }

    drawChart = chartData => {
        this.setState({ isLoading: true })
        const chart = chartData || this.props.chart
        const { hourlyStartDate, hourlyEndDate, dailyStartDate, dailyEndDate } = this.state
        const { setCsvData, cubes, evoServices } = this.props

        if(!cubes || cubes.length === 0) {
            return // wait for cubes to be loaded
        }

        let { metrics, appName, tabFromUrl, timezone, chartType } = this.parseUrlToState(getStandardizedUrl(chart.url));
        const isDeprecated = getIsDeprecated(chart.url, cubes, evoServices);

        if (!isDeprecated && appName && tabFromUrl) {
            var startTime = new Date().getTime();
            const isHourly = getIsHourly(chart.url, cubes);
            const includeWeekends = getIncludeWeekendsFromUrl(chart.url);
            const url = this.formatAndRefreshUrl(chart, isHourly);
            const isEvo = getIsEvo(chart.url);

            if (!isEvo && this.getIsProgressiveLoading(appName, tabFromUrl, isHourly)) {
                const startDate = convertStringToUTCDate(formatUTCDateAsString(dailyStartDate));
                let endDate = convertStringToUTCDate(formatUTCDateAsString(dailyEndDate));

                this.reInitializeChart(tabFromUrl, isHourly, startDate, endDate, includeWeekends);

                const maxDateQuery = this.getMaxDate(appName, tabFromUrl, isHourly, includeWeekends, startDate, endDate);
                const cacheQuery = this.queryCacheData(appName, tabFromUrl, isHourly, includeWeekends, url, startDate, endDate, hourlyStartDate, hourlyEndDate, chartType, metrics);

                Promise.all([maxDateQuery, cacheQuery])
                    .then(values => {
                        endDate = values[0];
                        const dateList = values[1];
                        const missingDates = this.getMissingDates(dateList, startDate, endDate, isHourly, includeWeekends);
                        this.queryNonCachedData(appName, tabFromUrl, isHourly, includeWeekends, url, startDate, dailyEndDate, chartType, missingDates, metrics);
                    })
                    .catch(error => {
                        console.error(error)
                        if (axios.isCancel(error)) {
                            this.setState({ isLoading: false });
                        } else {
                            this.showChartErrorMessage(error);
                        }
                    });
            } else {
                const [evoServiceId, evoLogId] = isEvo? getEvoServiceAndLogIdFromUrl(url) : [-1, -1];
                const [evoServiceName, evoLogName] = isEvo? getEvoServiceAndLogNameFromUrl(url, evoServices) : ['', ''];
                const formatStartDate = isEvo ? formatUTCDateAsDashString(dailyStartDate) : formatUTCDateAsString(isHourly ? hourlyStartDate : dailyStartDate, isHourly);
                const formatEndDate = isEvo ? formatUTCDateAsDashString(dailyEndDate) : formatUTCDateAsString(isHourly ? hourlyEndDate : dailyEndDate, isHourly);
                const pivotFunction = isEvo? this.getEvoPivot(evoServiceId, evoLogId, formatStartDate, formatEndDate) : new Promise((resolve) => {resolve(null)});

                pivotFunction
                    .then(pivot => {
                    let queryFunction;
                    if(isEvo) {
                        if(!pivot || !pivot.pivotGroup)
                        {
                            EvoDataService.getEvoNearestAvailableDate(evoServiceId, evoLogId, formatStartDate, formatEndDate)
                                .then(res => {
                                    this.setErrorMessage(`Service ${evoServiceName} Log ${evoLogName} does not have any data during this date range. Nearest available date ${res.data}`);
                                }).catch( (err) => this.setErrorMessage("Hit an error when getting nearestAvailableDate: " + err) );
                            return;                          
                        }

                        const requestObj = {
                            url: url,
                            serviceId: evoServiceId,
                            serviceLogId: evoLogId,
                            startDate: formatStartDate,
                            endDate: formatEndDate,
                        };
                        
                        queryFunction = EvoDataService.evoLatencyQueryByUrl(requestObj);
                    } else {
                        const requestObj = {
                            endpointUrl: appName + '/dataQueryOverrides',
                            obj: {
                                Url: url,
                                StartDate: formatStartDate,
                                EndDate: formatEndDate,
                                ...(tabFromUrl === "cumulativeHistogram" && {
                                    OverrideTab: "histogram"
                                }),
                                IsHourly: isHourly
                            }
                        };
                        queryFunction = DataService.post(requestObj.endpointUrl, requestObj.obj, null, this.props.scenarioTags);
                    }

                    queryFunction.then(res => {
                        if (res.data.IsSuccess) {
                            console.log(res.data.RequestLog);
                            this.setErrorMessage('')

                            const data = this.updateProgressiveData(res, tabFromUrl, isHourly, includeWeekends, dailyStartDate, dailyEndDate, chartType, url, metrics);

                            this.data.charts = data;
                            this.data.isHourly = isHourly;
                            this.data.timezone = timezone;
                            this.setState({
                                chartError: data.map(() => ''),
                                isLoading: false,
                                updatedTime: new Date().getTime(),
                                error: null,
                                missingDates: 0,
                            })
                            if (this.props.setData) this.props.setData(this.data);
                            if (setCsvData) setCsvData(chart.title, TabXValueMap[tabFromUrl], chart.id, this.data)

                            let graphTime = new Date().getTime() - startTime;
                            let renderTime = this.data.renderEndTime - this.data.renderStartTime;
                            handleGraph(this.state.cubeName, tabFromUrl, "dashboard", graphTime, renderTime, chart.title, this.props.dashboardInfo);
                        } else {
                            this.setErrorMessage(res.data.ErrorMessage)
                            console.error(res.data.RequestID)
                            console.error(res.data.RequestLog)
                            console.error(res.data.ErrorMessage)
                        }
                    }).catch(err => {
                        this.showChartErrorMessage(err)
                        console.error(err)
                    })
                });
            }
        } else {
            if (isDeprecated) {
                this.setErrorMessage('This cube is deprecated. Please remove it from your dashboard. If the cube has been upgraded to new cube, you can change the chart to use the new cube. If you need help, please contact support o365cppponcall@microsoft.com.')
            }
            else {
                this.setErrorMessage('Invalid url')
            }
        }
    }

    render() {
        const { isLoading, tab, tableError, chartError, updatedTime, updatedGoallineTime, missingDates } = this.state
        const { chart, loginUser, isInDashboard, isInExpertInsight } = this.props
        const { isTable, tableData, charts, isHourly, timezone } = this.data
        const metricTabs = ['dailymetricslatency', 'dailymetricscount', 'dailymetricsratio', 'dailymetricsother']
        const metricPercentileTabs = ['metricspercentileslatency', 'metricspercentilescount', 'metricspercentilesratio', 'metricspercentilessother']

        let newCharts = null
        if (isTable) {
            newCharts = <PercentileTable tableData={tableData} errorMessage={tableError} title={chart.title} />
        } else if (tab === 'dailymetrics' || tab === 'metricPercentiles') {
            let chartDataList = this.data.charts.map((chartData, index) => {
                return ({
                    serverData: chartData.serverData,
                    rawData: chartData.rawData,
                    title: index === 0 ? chart.title : '',
                    chartType: chartData.chartType,
                    xAxisType: chartData.xAxisType,
                    loading: isLoading
                });
            });
            newCharts = [<SharedCharts key={"0"}
                loginUser={loginUser}
                isHourly={isHourly}
                timezone={timezone}
                chartData={chartDataList}
                tabs={tab === 'dailymetrics' ? metricTabs : metricPercentileTabs}
                cube={this.state.cubeName}
                updatedTime={updatedTime}
                isInDashboard={isInDashboard}
                isInExpertInsight={isInExpertInsight}
                missingDates={missingDates}
                chartInfo={chart}
                updatedGoallineTime={updatedGoallineTime}
            />];
        } else {
            newCharts = charts.map((chartData, index) => {

                return (
                    <Chart key={chartData.id}
                        loginUser={loginUser}
                        isHourly={isHourly}
                        timezone={timezone}
                        options={{
                            serverData: chartData.serverData,
                            rawData: chartData.rawData,
                            title: index === 0 ? chart.title : '',
                            chartType: chartData.chartType,
                            xAxisType: chartData.xAxisType,
                            loading: isLoading
                        }}
                        cube={this.state.cubeName}
                        chartInfo={chart}
                        tab={tab}
                        errorMessage={chartError[index]}
                        isInDashboard={isInDashboard}
                        isInExpertInsight={isInExpertInsight}
                        missingDates={missingDates}
                        updatedTime={updatedTime}
                        updatedGoallineTime={updatedGoallineTime}
                        disableAnnotation={true}
                    />
                )
            })
        }

        const chartStyle = tab && (tab === 'dailymetrics' || tab === 'metricPercentiles') ? { height: '425px', overflowY: 'auto' } : { height: '425px' }
        return (
            <React.Fragment>
                {isLoading && <h3>{chart.title}</h3>}
                {isLoading ? <DashboardLoadingOverlay /> :
                    <div style={chartStyle}>
                        {newCharts}
                    </div>}
            </React.Fragment>
        );
    }
}

export default ProgressiveChart;
