import './PipelineStatusPage.css'

import React, { Component } from 'react';
import { SortOrderEnum, compareString, filterObject, getNextSortOrder, renderSortMark } from '../../Functions/TableHelper'

import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Form from 'react-bootstrap/Form'
import { LoadingGif } from '../PublicComponents/HelperComponents';
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import {formatTime} from '../../Functions/DateTimeHelper';
import { library } from "@fortawesome/fontawesome-svg-core";
import EvoDataService from '../../Dataservices/EvoDataService';

library.add(faRedo );

class CosmicJobStatus extends Component {

    constructor(props) {
        super(props);

        this.data = [] 

        this.statusObjectKeys = {
            None: 'None',
            ServiceName: 'ServiceName',
            ServiceLogType: 'ServiceLogType',
            DataSourceAccessInfo: 'DataSourceAccessInfo',
            DataDate: 'DataDate',
            ProcessByDate: 'ProcessByDate',
            ErrorCount: 'ErrorCount',
            Status: 'Status',
            Details: 'Details',
            GeneratedSelectStatement: 'GeneratedSelectStatement',
            LastUpdatedTime: 'LastUpdatedTime'
        }

        this.tableColumns = [
            ["Service Name", this.statusObjectKeys.ServiceName],
            ["Service LogType", this.statusObjectKeys.ServiceLogType],
            ["Data Source", this.statusObjectKeys.DataSourceAccessInfo],
            ["DataDate", this.statusObjectKeys.DataDate],
            ["Status", this.statusObjectKeys.Status],
            ["Error Count", this.statusObjectKeys.ErrorCount],
            ["Details", this.statusObjectKeys.Details],
            ["Update Time", this.statusObjectKeys.LastUpdatedTime]
        ]

        this.state = {
            tableSort: {
                key: this.statusObjectKeys.None,
                order: this.statusObjectKeys.None
            },
            selectedID: null,
            isLoading: true,
            error: null,
            updateTime: null,
            showJson: null
        }
    }

    componentDidMount() {
        EvoDataService.getJobStatus().then(res => {
            this.updateFromRawdata(res.data)
        }).catch(err => {
            console.error(err)
            this.setState({
                error: err.message
            })
        })
    }

    updateFromRawdata = (table) => {
        let data = table;
        for (let i in data) {
            data[i].DefaultKey = i;

            // get date string
            data[i].DataDate = data[i].DataDate.slice(0, 10);
            data[i].ProcessByDate = data[i].ProcessByDate.slice(0, 10);
        }

        this.data = data;
        this.setState({
            showJson: null,
            selectedID: null,
            searchText: '',
            table: data,
            isLoading: false,
            updateTime: new Date()
        })
    }

    compareObject = (key, isAscending) => {
        return (obj1, obj2) => {
            if (key === this.statusObjectKeys.ErrorCount) {
                if (obj1[key] > obj2[key]) {
                    return 1;
                } else if (obj1[key] < obj2[key]) {
                    return -1;
                } else {
                    return 0;
                }
            } else {
                // compare strings
                return compareString(obj1[key], obj2[key], isAscending)
            }
        }
    }

    sortList = (list, key, order) => {
        if (order === SortOrderEnum.None) {
            return list.sort(this.compareObject("DefaultKey", true))
        } else {
            return list.sort(this.compareObject(key, order === SortOrderEnum.Ascending))
        }
    }

    onChangeSearchText = e => {
        this.setState({
            searchText: e.target.value
        }, this.onSearch)
    }

    onSearch = () => {
        const {searchText} = this.state;
        let table = [];
        if (searchText.trim() === '') {
            table = this.data;
        } else {
            table = this.data.filter(x => filterObject(x, searchText, [
                this.statusObjectKeys.ServiceName,
                this.statusObjectKeys.ServiceLogType,
                this.statusObjectKeys.Status,
                this.statusObjectKeys.Details
            ]))
        }
        this.setState({
            table: table,
        })
    }

    onSort = (key) => {
        let { tableSort, table }  = this.state
        let nextOrder = getNextSortOrder(tableSort.order, tableSort.key !== key)
    
        tableSort = {
            key: key,
            order: nextOrder
        };
        
        let sortedList = this.sortList(table, key, nextOrder)
        this.setState({
            table: sortedList,
            tableSort: tableSort
        })
    }

    onChangeSearchText = e => {
        this.setState({
            searchText: e.target.value
        }, this.onSearch)
    }

    refresh = () => {
        this.setState({isLoading: true});
        EvoDataService.getJobStatus().then(res => {
            this.updateFromRawdata(res.data)
            this.setState({
                error: null
            })
        }).catch(err => {
            console.error(err)
            this.setState({
                error: err.message
            })
        })
        document.getElementById('cosmic-job-refresh').innerHTML = "Cosmic Jobs refreshed " + (this.state.isLoading ? "loading" : "loaded");
    }

    onRowClick = item => {
        this.setState({selectedID: item.DefaultKey, showJson: item})
    }

    renderTable() {
        let { tableSort, table, selectedID } = this.state
        let columns = this.tableColumns;
        return (        
            <Row>
                <Col className="Label">
                    <div style={{ overflowX: 'auto', width: '100%' }} className='CentralServiceMonitorTable' aria-live="polite" aria-atomic="true" aria-label={(table ? table.length + " results found" : "")}>
                        <Table striped bordered hover className="statusTable">
                            <thead>
                                <tr>
                                    {
                                        columns.map(item=>{
                                            let friendly_name = item[0];
                                            let key_name = item[1];
                                            return (
                                                <th key={key_name} onClick={this.onSort.bind(this, key_name)} className="tableHeader" scope="col">
                                                    {renderSortMark(tableSort.key, tableSort.order, key_name)}
                                                    {friendly_name}
                                                </th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {table && table.map(status => (
                                <tr key={status.DefaultKey} className={status.DefaultKey === selectedID ? 'SelectedRow' : 'HoverRow'}
                                    onClick={()=>this.onRowClick(status)}>
                                    {
                                        columns.map(item => {
                                            let key_name = item[1];
                                            return (
                                                <td key={key_name}>{status[key_name]}</td>
                                            )
                                        })
                                    }
                                </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Col>
                </Row>
        )
    }

    render() {
        let {showJson, error, isLoading, updateTime} = this.state;
        let json = null;
        if (showJson) {
            json = JSON.stringify(showJson, null, 2);
        }
        return (
            <div>
                {error && <Alert variant='danger'>{error}</Alert>}
                <Row>
                    <Col className="Label">
                        <div style={{display:"flex",alignItems: "center", marginTop: 10}}>
                            <Button onClick={this.refresh}>
                                { isLoading && <span><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />&ensp;</span> }
                                Refresh&ensp;
                                <FontAwesomeIcon icon={faRedo}/>
                            </Button>
                            <div id="cosmic-job-refresh" role="status" class="sr-only"></div>
                            <span style={{ paddingLeft: 15 }} role="status">
                                Update time: {formatTime(updateTime)}
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group className="Label" controlId="centralServiceSearch">
                                <Form.Control style={{marginTop: 20}} type="text" placeholder="Search" onChange={this.onChangeSearchText}/>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                {
                    isLoading ? <LoadingGif></LoadingGif> : this.renderTable()
                }
                {
                    json &&
                    <Row style={{marginTop: 20}}>
                        <Col>
                            <pre style={{textAlign: 'left', border: '1px solid gray', minHeight: 20}}>
                            {
                                json
                            }
                            </pre>
                        </Col>
                    </Row>
                }   
            </div>
        )
    }
}

export default CosmicJobStatus;
