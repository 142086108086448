import React, {Component} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import UserSearchInput from '../PublicComponents/UserSearchInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import Tooltip from 'react-bootstrap/Tooltip'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faQuestionCircle);


class AddAccessModal extends Component {
    constructor(props) {
        super(props)
        const { users } = props

        this.state = {
            users: []
        }
    }

    onUsersChangeHandler = newVal => {
        this.setState({
            users: newVal.slice()
        })
    }

    addRole = () => {
        const { onSubmit, toggleModal } = this.props
        const { users } = this.state
        onSubmit(users)
        toggleModal()
    }

    render() {
        const { toggleModal, showModal } = this.props
        const { users } = this.state

        return (
            <Modal show={showModal} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Grant Access To Users</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Users:&ensp;</Form.Label>
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Tooltip id={`tooltip-members`}>
                                        Grant CSVBuilder asccess to the users.
                                    </Tooltip>}>
                                <FontAwesomeIcon icon={faQuestionCircle} className="promptMark"/>
                            </OverlayTrigger>
                            <UserSearchInput name="user" persons={users} onChange={this.onUsersChangeHandler} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.addRole}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default AddAccessModal