import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import DashboardService from '../../Dataservices/DashboardService'
import { setGraphItToTrue } from '../../Functions/UrlHelper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretDown, faTrash, faPlusCircle, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faCaretRight, faCaretDown, faTrash, faPlusCircle, faCaretUp);

class DashboardEditChartModal extends Component {

    constructor(props) {
        super(props)

        const { chart, isNewChart, cubes } = props

        if (isNewChart) {
            this.state = {
                title: '',
                url: '',
                showUrlParser: false,
            }
        } else {
            this.state = {
                title: chart.title,
                url: chart.url,
                showUrlParser: false,
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { chart } = nextProps;
        if (prevState.chart && prevState.chart.url !== chart.url) {
            return {
                url: chart.url
            }
        }
        return null
    }

    onTitleChange = e => { this.setState({ title: e.target.value }) }

    onUrlChange = e => { 
        const url = e.target.value
        this.setState({ url: url })
    }

    setUrl = (url) => {
        this.setState({url: url})
    }

    onSave = () => {
        const { chart, toggleModal, onSave, isNewChart, dashboardId, order, setError } = this.props
        const { title, url } = this.state

        toggleModal()

        this.setState({
            url: setGraphItToTrue(url)
        })
        
        const payload = {
            ...chart,
            title: title,
            url: url,
        }

        if (isNewChart) {
            payload.dataSuiteId = dashboardId
            payload.order = order
            onSave(payload)
            DashboardService.addChart(payload).then(res => {
                payload.id = res.data.id
                this.setState({
                    title: '',
                    url: '',
                })
            }).catch(err => {
                console.error(err)
                setError(err.message);
            })
        } else {
            payload.id = chart.id
            payload.dataSuiteId = chart.dataSuiteId
            payload.order = chart.order
            onSave(payload)
            DashboardService.editChart(payload).then().catch(err => {
                console.error(err)
                setError(err.message);
            })
        }
    }

    onCancel = () => {
        const { toggleModal, chart, isNewChart } = this.props
        toggleModal()
        if (isNewChart) {
            this.setState({
                title: '',
                url: '',
            })
        } else {
            this.setState({
                title: chart.title,
                url: chart.url,
            })
        }
        
    }

    onDelete = chartId => {
        const {  toggleModal, onDelete } = this.props
        toggleModal()
        onDelete(chartId)
    }

    onShowUrlParser = () => { this.setState(prevState => ({ showUrlParser: !prevState.showUrlParser })) }

    render () {
        const { showModal, chart, isNewChart } = this.props
        const { title, url, showUrlParser } = this.state
        return (
            <Modal show={showModal} onHide={this.onCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>{ isNewChart ? 'Add New Chart' : 'Edit Chart'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} controlId="title">
                            <Form.Label column sm="3">Title</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" value={title} onChange={this.onTitleChange}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="url">
                            <Form.Label column sm="3">Url</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" value={url} onChange={this.onUrlChange}/>
                            </Col>
                        </Form.Group>

                        <Button variant="light" aria-label="Url Params" aria-expanded={showUrlParser ? "true" : "false"} onClick={this.onShowUrlParser}>
                            <FontAwesomeIcon icon={showUrlParser ? faCaretUp : faCaretDown} />
                            <b>Url Params</b>
                        </Button> 

                        { showUrlParser && <UrlParser url={url} setUrl={this.setUrl}/> }

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {!isNewChart && <div className="alertClearBtn">
                        <Button variant="danger" id="clearBtn" onClick={this.onDelete.bind(this, chart.id)}>
                            Delete
                        </Button>
                    </div>}
                    <Button variant="primary" id="saveBtn" onClick={this.onSave}>
                        Save
                    </Button>
                    <Button variant="warning" id="cancelBtn" onClick={this.onCancel}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

class UrlParser extends Component {
    constructor(props) {
        super(props)

        this.exceptParam = ["graphIt","sd","ed"]

        const url = props.url ? new URL(props.url) : null
        this.state = {
            originUrl: props.url,
            url: url
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.url && nextProps.url !== prevState.originUrl) {
            const url = new URL(nextProps.url)
            return {
                originUrl: nextProps.url,
                url: url
            }
        }
        return null
    }

    onChangeKey = (key, e) => {
        const newKey = e.target.value
        const { url } = this.state
        const { setUrl } = this.props

        const value = url.searchParams.get(key)
        url.searchParams.delete(key)
        url.searchParams.set(newKey, value)
        this.setState({url: url})
        setUrl(url.href)
    }

    onChangeValue = (key, e) => {
        const value = e.target.value
        const { url } = this.state
        const { setUrl } = this.props

        url.searchParams.set(key, value)
        this.setState({url: url})
        setUrl(url.href)
    }

    onDeleteParam = (key) => {
        const { url } = this.state
        const { setUrl } = this.props

        url.searchParams.delete(key)
        this.setState({url: url})
        setUrl(url.href)
    }

    onAddParam = () => {
        const { url } = this.state

        url.searchParams.set('', '')
        this.setState({url: url})
    }

    getUrlParams = (urlparam) => {
        let result = [] 
        for(let param of urlparam.entries()) {
            if(!this.exceptParam.includes(param[0])) {
                result.push(
                    <tr>
                        <td>
                            <Button variant="light" size="sm" aria-label="Delete Param" onClick={this.onDeleteParam.bind(this, param[0])}><FontAwesomeIcon icon={faTrash} /></Button>
                        </td>
                        <td><Form.Control type="text" required={true} value={param[0]} style={{"border": "1px #ddd", "margin": "0", "padding": "0.2rem", "white-space": "nowrap"}} 
                            onChange={this.onChangeKey.bind(this, param[0])}/> </td>
                        <td>
                            <Form.Control type="text" required={true} value={param[1]} style={{"border": "1px #ddd", "margin": "0", "padding": "0.2rem", "white-space": "nowrap"}} 
                            onChange={this.onChangeValue.bind(this, param[0])}/> 
                        </td>
                    </tr>
                )
            }
        }
        return result
    }

    render() {
        const { url } = this.state

        return (
            <Table bordered hover size="sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {url ? this.getUrlParams(url.searchParams) : ''}
                </tbody>
                <tr>
                    <td colSpan={3} >
                        <Button variant="light" size="sm" aria-label="Add Url Param" onClick={this.onAddParam} block><FontAwesomeIcon icon={faPlusCircle}/></Button>
                    </td>
                </tr>
            </Table>
        )
    }
}

export default DashboardEditChartModal