import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faTimes);

const gearsGifAltText = "A spinning gear to indicate something is loading";

export function ValidationWarning(props){
    return  <span style={{color: '#dc3545' }}>
                <FontAwesomeIcon icon={faTimes} style={{ marginTop: '4px'}}/>
                &ensp;{props.text}
            </span>;
}

export function DashboardLoadingOverlay(){
    return <div className="dashboardloadingoverlay"><CenteredLoadingGif/></div>;
}

export function LoadingOverlay(){
    return <div className="overlay"><CenteredLoadingGif/></div>;
}

export function LoadingGif(){
    return <img alt={gearsGifAltText} src="../gears.gif"/>
}

export function CenteredLoadingGif(){
    return <img alt={gearsGifAltText} className="center" src="../gears.gif"/>
}

export function NotPermittedWarning(message){
    return  <Row><Col><h1 style={{margin: '50px'}}>{message || "You don't have access permission to this page"}</h1></Col></Row>;
}