import React from 'react';
import AnnotationEditorModal from './AnnotationEditorModal'
import AnnotationDataService from '../../Dataservices/AnnotationDataService'
import { getDateFromName } from '../../Functions/ChartHelpers'

function AddAnnotationModal(props) {
    const { showModal, toggleModal, currentPoint, tab, cube, onAddAnnotation, url } = props

    const addAnnotation = (html) => {
        let postAddAnnotationRequest = {
            Date: getDateFromName(currentPoint.name),
            Cube: cube,
            Tab: tab,
            AnnotationText: html,
            Legend: currentPoint.series.name,
            url: url
        }

        toggleModal()
        return AnnotationDataService.postAddAnnotationsForChart(postAddAnnotationRequest)
        .then(res => {
            onAddAnnotation(res.data, currentPoint)
            return res
        })
    }

    return (
        <AnnotationEditorModal title={'Add a Note'} showModal={showModal} toggleModal={toggleModal} onSave={addAnnotation} />
    )
}


export default AddAnnotationModal