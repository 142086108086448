import DataService from './DataService'

const ExpertInsightsDataService = {
    chart: (chartRequestObject) => {
        return DataService.post('AlertAnalysis/chart', chartRequestObject)
    },
    ruleResultForAlert: (analysisRequestObject) => {
        return DataService.post('AlertAnalysis/ruleResultForAlert', analysisRequestObject)
    },
    ruleResultForChart: (analysisRequestObject, cubeName) => {
        return DataService.post(cubeName + '/ruleAnalysisResultForChart', analysisRequestObject)
    },
    ruleResultForInChartRules: (analysisRequestObject, cubeName) => {
        return DataService.post(cubeName + '/ruleResultsForInChartRuleAnalysis', analysisRequestObject)
    },
    urlForLegend: (cube, chartInfo) => {
            DataService.post(cube + "/dailyUrlForLegend", chartInfo).then(res => {
            console.log(res);
            return res.data;
        }).catch(err => {
            console.log(err);
            return;
        });
    },
    refreshRules: (cube) => {
        return DataService.post(cube + '/refreshRules', null)
    }
}

export default ExpertInsightsDataService