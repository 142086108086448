
    export function getCsvData (chartTitle, firstColumnHeader, chartId, data) {
        var csvData = {}
        if (data.isTable) {
            if (data.tableData.table) {
                let csv = [['Legend']]
                csv[0] = csv[0].concat(data.tableData.latencyHeader, ['Samples'])
    
                data.tableData.table.forEach(row => {
                    csv.push([].concat([row.legend], row.latency, row.samples.map(sample => sample.split(',').join(''))))
                })
                
                csvData[chartId] = {
                    fileName: chartTitle,
                    data: csv,
                    id: chartId
                }
            }
        } else {
            let filenameAppend = ['-Metric Latency', '-Metric Count', '-Metric Ratio', '-Metric Other']
        
            const csvList = data.charts.filter((chart, index) => {
                if (data.charts.length > 1) {
                    chart.append = filenameAppend[index]
                } else {
                    chart.append = ''
                }
                return chart.serverData.length !== 0
            }).map(chart => {
                let csv = [[firstColumnHeader]]

                chart.serverData.forEach((line, index) => {
                    csv[0].push(line.Name)

                    if (index === 0) {
                        line.Data.forEach(dot => {
                            csv.push([dot.name])
                        }) 
                    }

                    line.Data.forEach((dot, i) => {
                        csv[i + 1].push(dot.y)
                    })
                })

                return {
                    fileName: chartTitle + chart.append,
                    data: csv,
                    id: chartId
                }
            })

            csvList.forEach(file => {
                csvData[file.id] = file
            })
        }
        return csvData;
    }

    export function downloadCsv (csvData) {

        let link = document.createElement("a")
        Object.keys(csvData).forEach(key => {
            const data = csvData[key].data
            const csvStr = "data:text/csv;charset=utf-8," + data.map(row => row.join(',')).join('\n')

            link.setAttribute("href", csvStr)
            link.setAttribute("download", csvData[key].fileName + '.csv')
            document.body.appendChild(link)
            link.click()
        })

        document.body.removeChild(link)
    }