import { columnTableSchema, columnTableDefaultRowsCSVFlat, columnTableDefaultRowsCSVNonFlat, roleTableDefaultRowsCSV, logTableDefaultRowsCSV, classType } from './CubeCSVConst'
import { AutoFill } from './CubeCSVAutofill'

function preProcessCSV(data) {
    data = changeRowTerminator(data)
    return data.trim()
}

function changeRowTerminator(data) {
    if(data.indexOf('\r\n') >= 0) {
        return data.replace('\r\n', '\n')
    }
    return data
}

export function parseDataFromCSV(data, type, isFlat) {

    let defaultData = preProcessCSV(data)

    if (!defaultData || defaultData.length === 0) {
        if (type === "column") {
            if(isFlat) defaultData = columnTableDefaultRowsCSVFlat
            else defaultData = columnTableDefaultRowsCSVNonFlat
        } else if (type === "role") {
            defaultData = roleTableDefaultRowsCSV
        } else if (type === "log") {
            defaultData = logTableDefaultRowsCSV
        }
    }

    const dataRow = defaultData.split("\n")
    const dataHeader = dataRow[0].split(",")
    const dataContent = dataRow.slice(1, dataRow.length).map(row => (row.split(",")))

    const len = dataHeader.length
    for(let ind = 0; ind < dataContent.length; ind++) {
        if(dataContent[ind].length !== len) {
            return {
                isValid: false,
                errorMsg: "Row " + ind + " has different number of column with header.",
                dataHeader: [],
                dataContent: []
            }
        }
    }

    return {
        isValid: true,
        errorMsg: "",
        dataHeader: dataHeader,
        dataContent: dataContent
    }
}

export function parseDataFromManagement(type, isFlat, data, propsComment){
    const {dataHeader, dataContent} = parseDataFromCSV(data, type, isFlat)
    let error = dataContent.map(row => (
        new Array(row.length).fill('')
    ))
    let comment = dataContent.map(row => {
        let result = []
        for(let i = 0; i < dataHeader.length; i++) {
            result.push({user:"", value:"", updateTime:""})
        }
        return result
    })
    for(let i = 0; i < propsComment.length; i++) {
        comment[propsComment[i].Row][propsComment[i].Column] = {
            id: propsComment[i].Id,
            user: propsComment[i].UpdateUser, 
            value: propsComment[i].Comment, 
            updateTime: propsComment[i].UpdateTime
        }
    }
    return {
        dataHeader: dataHeader, 
        dataContent: dataContent,
        error: error,
        comment: comment
    }
}

export function getHeaderString(data) {
    data = preProcessCSV(data)
    const dataRow = data.split("\n")
    if(dataRow.length > 0) return dataRow[0].slice(1, dataRow[0].length)
    else return ''
}

export function getHeaderAndIndex(data) {
    const { dataHeader } = parseDataFromManagement('column', false, data, []) // Only want to get header here, param isFlat and comment is not used.

    const headerIndex = dataHeader.reduce((map, header, index) => {
        map[header.toLowerCase()] = index
        return map
    }, {})

    return {header: dataHeader, headerIndex: headerIndex}
}

export function generateRowWithHeader(row, header) {
    if(!header || !row || row.length > header.length) return row
    let result = header.map(()=>(''))
    for(let i = 0; i < row.length; i++) result[i] = row[i]
    return result
}

export function joinTemplateWithSuggestion(template, suggestionData) {
    template = preProcessCSV(template)
    suggestionData = preProcessCSV(suggestionData)
    const suggestRowName = suggestionData.split("\n").map(row => (row.split(',')[0].trim()))
    const dataRow = template.split("\n")
    for(let i = 1; i < dataRow.length; i++) {
        if(suggestRowName.includes(dataRow[0])) 
            dataRow.splice(i, 1)
    }
    return dataRow[0] + (suggestionData?('\n' + suggestionData):'') + '\n' + dataRow.slice(1, dataRow.length).join('\n')
}

export function joinTemplateWithView(template, viewData) {
    template = preProcessCSV(template)
    viewData = preProcessCSV(viewData)
    const dataRow = template.split("\n")
    return dataRow[0] + (viewData?('\n' + viewData):'') 
}

export function addNewRoleToTemplate(template, roleList, isFlat) {
    template = preProcessCSV(template)
    console.log(template)
    const dataRow = template.split("\n")
    const header = dataRow[0].split(",")
    const headerIndex = header.reduce((map, header, index) => {
        map[header.toLowerCase()] = index
        return map
    }, {})
    
    for(let i = 0; i < roleList.length; i++) {
        // Add checkpoint key
        let row = new Array(header.length).fill('')
        row[headerIndex[columnTableSchema.ColumnInCosmos]] = roleList[i].RoleName + '_checkpoint_key'
        row[headerIndex[columnTableSchema.Class]] = classType.CheckpointKey
        row[headerIndex[columnTableSchema.Role]] = roleList[i].ShortRoleName 
        row = AutoFill(row, header, headerIndex, isFlat) 
        template = template + '\n' + row.join(',')
        // Add checkpoint value
        row = new Array(header.length).fill('')
        row[headerIndex[columnTableSchema.ColumnInCosmos]] = roleList[i].RoleName + '_checkpoint_value'
        row[headerIndex[columnTableSchema.Class]] = classType.CheckpointValue
        row[headerIndex[columnTableSchema.Role]] = roleList[i].ShortRoleName 
        row = AutoFill(row, header, headerIndex, isFlat) 
        template = template + '\n' + row.join(',')
        // Add checkpoint key
        row = new Array(header.length).fill('')
        row[headerIndex[columnTableSchema.ColumnInCosmos]] = roleList[i].RoleName + '_correlated_metricKey'
        row[headerIndex[columnTableSchema.Class]] = classType.MetricKey
        row[headerIndex[columnTableSchema.Role]] = roleList[i].ShortRoleName 
        row = AutoFill(row, header, headerIndex, isFlat) 
        template = template + '\n' + row.join(',')
        // Add checkpoint key
        row = new Array(header.length).fill('')
        row[headerIndex[columnTableSchema.ColumnInCosmos]] = roleList[i].RoleName + '_correlated_metricValue'
        row[headerIndex[columnTableSchema.Class]] = classType.MetricValue
        row[headerIndex[columnTableSchema.Role]] = roleList[i].ShortRoleName 
        row = AutoFill(row, header, headerIndex, isFlat) 
        template = template + '\n' + row.join(',')
    }
    return template
}

export function AutoGetRowFromView(viewContent) {
    const viewReg = new RegExp('create\\s+view\\s+\\S+\\s+schema\\s+\\((?<schema>[^)]+)\\)', 'i')
    const result = viewReg.exec(viewContent).groups.schema

    if(result){
        return {
            message: "Success",
            schema: ParseViewSchema(result),
        }
    }else{
        return {
            message: "Can not read view schema from file, please check the view content.",
            schema: null
        }
    }
}

function ParseViewSchema(viewSchemaString) {
    let result = [];
    const schemaList = viewSchemaString.split(',');

    for(const schemaContent of schemaList) {
        var content = schemaContent.split('//')[0].trim().split(':');
        if (content.length == 2)
        {
            result.push([content[0].trim(), content[1].trim().split('?')[0]])
        }
    }

    return result
}