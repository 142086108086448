import React from 'react'
import { Spinner, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './EvoServiceRegistration.css'

function ServiceList(props) {
    const { serviceList, isLoading, onSelectService, selectedServiceIdndex, onAddService } = props;

    const [statusOpenMapping, setStatusOpenMapping] = React.useState({
        Active: true,
        Deprecated: false
    });

    const handleStatusClick = (status) => {
        const newStatusOpenMapping = {...statusOpenMapping};
        newStatusOpenMapping[status] = !newStatusOpenMapping[status];
        setStatusOpenMapping(newStatusOpenMapping);
    };

    return (
        <div id="service-list-container">
            <h2 className="service-list-header">
                Services
                <Button variant="primary" onClick={onAddService}><FontAwesomeIcon icon={faPlus}/>&ensp;Create</Button>
            </h2>
            {isLoading ? 
            <div className="service-list-loading">
                <Spinner animation="border" variant="primary" role="status"/>&emsp;Loading services...
            </div>
            : <div className="service-list-accordin">
                {Object.keys(statusOpenMapping).map((status) =>( 
                    <React.Fragment key={status}>
                        <button onClick={handleStatusClick.bind(this, status)} className="service-list-accordin-toggle">
                            <h3>{status}</h3>
                            {statusOpenMapping[status] ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                        </button>
                            {statusOpenMapping[status] && <div className="list-group list-group-flush">
                                {serviceList.map((service, index) => {
                                    return service.status === status ? <button 
                                        type="button" 
                                        role="listitem" 
                                        className={selectedServiceIdndex === index ? "list-group-item list-group-item-action service-list-item service-list-item-active" : "list-group-item list-group-item-action service-list-item"}
                                        key={service.serviceId} 
                                        onClick={onSelectService.bind(this, index)}>
                                            {service.serviceName}
                                    </button> : null
                                })}
                            </div>}
                    </React.Fragment>
                ))}
            </div>}
        </div>
    );
}

export default ServiceList;
