import React, {Component} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

class DashboardMoveChartModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orginalOrder: -1,
            order: props.orginalOrder
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.orginalOrder !== prevState.orginalOrder){
            return {
                orginalOrder: nextProps.orginalOrder,
                order: nextProps.orginalOrder
            }
        }
        return null
    }

    onOrderChange = e => { 
        this.setState({ 
            order: parseInt(e.target.value)
        }) 
    }

    render() {
        const { showModal, toggleModal, onMoveChart, maxOrder, orginalOrder } = this.props
        const { order } = this.state

        return (
            <Modal show={showModal} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Enter new position</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control aria-label="New position" type="number" value={order} onChange={this.onOrderChange} min={0} max={maxOrder}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={onMoveChart.bind(this, orginalOrder, order)}>
                        OK
                    </Button>
                    <Button variant="light" onClick={toggleModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default DashboardMoveChartModal