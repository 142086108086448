import DataService from './DataService'

const AlertingService = {
    changeAlert: payload => {
        return DataService.post('Alerting/changeAlert', payload)
    },
    getOriginator: (dataSuite) => {
        return DataService.get('Alerting/getOriginator?dataSuite=' + dataSuite)
    }
}
export default AlertingService