import React, { useState } from 'react';
import { Chart } from './Chart'
import { withRouter } from "react-router-dom"

function SharedCharts(props) {
    let { chartData, tabs, cube, updatedTime, chartInfo, isInDashboard, loginUser, onChangeChartType, updatedGoallineTime, isInExpertInsight, missingDates, isChartingPaneHidden} = props;

    const [showRules, setShowRules] = useState(new Proxy({}, {
        get: (target, name) => name in target ? target[name] : false
    }));
    const [processedRules, setProcessedRules] = useState({});

    return (
        <React.Fragment>
            {chartData.map((e, i) => {
                return <Chart isChartingPaneHidden={isChartingPaneHidden} loginUser={loginUser} key={tabs[i]} options={e} tab={tabs[i]} cube={cube} isHourly={props.isHourly} timezone={props.timezone} updatedTime={updatedTime} chartInfo={chartInfo} isInDashboard={isInDashboard} isInExpertInsight={isInExpertInsight} missingDates={missingDates} location={props.location} liftedShowRules={[showRules, setShowRules]} liftedProcessedRules={[processedRules, setProcessedRules]} onChangeChartType={onChangeChartType ? onChangeChartType.bind(this, i) : null} updatedGoallineTime={updatedGoallineTime} />
            })}
        </React.Fragment>
    )
}

export default withRouter(SharedCharts);