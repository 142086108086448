import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faLightbulb, faEdit } from '@fortawesome/free-solid-svg-icons';
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { library } from "@fortawesome/fontawesome-svg-core";
import { getBackgroundColorByLatency, setAccessibility } from '../../Functions/MSRHelper';
import DataService from '../../Dataservices/DataService';
import { getCubeFromURL } from '../../Functions/UrlHelper'
import Button from 'react-bootstrap/Button'
import './MSR.css'

library.add(faTimesCircle, faLightbulb, faEdit);

export function MSRCard (props) {

    const [isPinned, setIsPinned] = useState(false);
    const [options, setOptions] = useState(null);

    if(props.isPinned !== isPinned){
        setIsPinned(props.isPinned);
    }

    useEffect(() => {
        const { cardChartData, latencyThreshold, cardUrl } = props;
        const categories = [];
        const seriesData = [];
    
        for (const key of Object.keys(cardChartData)) {
            categories.push(key);
            seriesData.push(cardChartData[key]);
        }
        
        setOptions({
            tooltip: {
                useHTML: true,
                stickOnContact: true,
                formatter: function() {
                    // Removing existing event listeners
                    const existingTooltip = document.querySelector('.my-tooltip');
                    if (existingTooltip) {
                        existingTooltip.removeEventListener('click', function() {
                            Highcharts.charts[0].tooltip.hide = function() {};
                        });
                        const existingExpertButton = document.querySelector('.expert-insights-button');
                        if (existingExpertButton) {
                            existingExpertButton.removeEventListener('click', handleInsightsNav);
                        }
                    }
                    
                    setTimeout(() => {
                        // Check if the chart object and tooltip are defined
                        if (Highcharts.charts[0] && Highcharts.charts[0].tooltip) {
                          const newTooltip = document.querySelector('.my-tooltip');
                          newTooltip.addEventListener('click', function() {
                            Highcharts.charts[0].tooltip.hide = function() {};
                          });
                          
                          const expertButton = document.querySelector('.expert-insights-button');
                          const graphItButton = document.querySelector('.graph-it');
                          if (expertButton) {
                            expertButton.addEventListener('click', (e) => {
                              e.stopPropagation();
                              handleInsightsNav(e, cardUrl, this.point.category);
                            });

                            expertButton.addEventListener('keydown', (e) => {
                                if (e.key === 'Enter') {
                                    e.stopPropagation();
                                    handleInsightsNav(e, cardUrl, this.point.category);
                                }
                            });
                          }

                          if (graphItButton) {
                            graphItButton.focus();
                            graphItButton.addEventListener('click', (e) => {
                                e.stopPropagation();
                                handleGraphItNav(e, cardUrl);
                            });

                            graphItButton.addEventListener('keydown', (e) => {
                                if (e.key === 'Enter') {
                                    e.stopPropagation();
                                    handleGraphItNav(e, cardUrl);
                                }

                                if (e.key === ' ') {
                                    e.preventDefault();
                                    expertButton.focus();
                                }
                            });
                          }
                        }
                    }, 10);
                
                    return `
                        <div class="my-tooltip" role="tooltip" tabindex="0">
                            <button class="btn btn-outline-secondary graph-it">Graph it</button>
                            <button class="btn btn-outline-secondary expert-insights-button" role="button" aria-label="Insights" tabindex="0">
                                Insights
                            </button>
                            <table>
                                <tr><td style="padding:0"><b>Date: ${this.point.category}</b></td></tr>
                                <tr><td style="padding:0"><b>Avg Weekly Latency: ${this.point.y.toFixed(2)} ms</b></td></tr>
                            </table>
                        </div>`;
                },
                shared: false,
            },        
            chart: {
                type: 'column',
                height: 300,
            },
            title: {
                text: 'Weekly Latency'
            },
            xAxis: {
                categories: categories,
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Latency(ms)'
                },
                plotLines: [{
                    color: '#FF0000', // Red threshold line
                    width: 2,
                    value: latencyThreshold
                }]
            },
            series: [{
                name: 'weekly latency',
                color: "#00008B",
                type: 'line',
                data: seriesData,
            }]
        });        
        }, [props.cardChartData]);
       

    let handleInsightsNav = (e, cardUrl, startDate) => {
        e.preventDefault();
        var chartInfo = {
            StartDate: (new Date(startDate)).toISOString(),
            EndDate: (new Date(new Date(startDate).getTime() + 86400000 * 3)).toISOString(),
            Url: cardUrl,
            Legend: "All-Up"
        };
        DataService.post(getCubeFromURL(cardUrl) + "/chartIdforUrl", chartInfo).then(res => {
            console.log(res);
            window.open(window.location.origin + "/Insights?chartID=" + res.data, "_blank");
        }).catch(err => {
            console.log(`${err} - could not open insights for current scenario`);
        });
    };

    let handleGraphItNav = (e, cardUrl) => {
        e.preventDefault();
        window.open(cardUrl);
    };

    let renderChartCard = () => {

        const { mouseCoords, cardPinnedPosition, unpinCard } = props;
        let style = {};
        if (!isPinned) {
            style = { position: 'fixed', left: (mouseCoords.x + 20) + 'px', top: mouseCoords.y + 'px', zIndex: 9999 };
        }
        else {
            style = { position: 'fixed', left: (cardPinnedPosition.x + 20) + 'px', top: cardPinnedPosition.y + 'px', zIndex: 9999 };
        };

        return (
            <Card style={style}>
                <Card.Title style={{fontSize:'12px'}}>
                    <React.Fragment>
                        {
                            isPinned ? (
                                <FontAwesomeIcon 
                                tabIndex={0}
                                onClick={unpinCard} 
                                icon={faTimesCircle} 
                                className="unpin-icon"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        unpinCard()
                                    }
                                }}
                                onFocus={() => {
                                    setAccessibility('0')
                                }}
                                /> 
                            ) : (
                                <span style={{float:'right', marginLeft: '10px'}}>(Click on the chart to pin this card)</span>
                            )
                        }
                    </React.Fragment>
              </Card.Title>
                <Card.Body style={{ padding: 0 }}>
                    <HighchartsReact highcharts={Highcharts} options={options} />
                </Card.Body>
            </Card>
        );
    };

    let renderTableCard = () => {

        const { mouseCoords, cardLatencyData, latencyThreshold, showDeviationTableCard } = props;
        
        const tableStyle = {
            margin: '0px',
            padding: '0px'
        };

        const cardTitleStyle = {
            fontSize: '18px',
            textAlign: 'center'
        };
    
        return (
            Object.keys(cardLatencyData).length === 0 ? null : 
            <Card style={{ 
                position: 'fixed', 
                left: (mouseCoords.x + 20) + 'px', 
                top: mouseCoords.y + 'px', 
                padding: '0px', 
                margin: '0px',
                zIndex: 9999
            }}>
                <Card.Title style={cardTitleStyle}>
                    <React.Fragment>
                        Weekly Avg
                    </React.Fragment>
                </Card.Title>
                <Card.Body style={{ padding: '0px' }}>
                    <table style={tableStyle}>
                        <tbody>
                            {Object.keys(cardLatencyData).map((key, index) => (
                            <tr key={index} style={{'background-color': getBackgroundColorByLatency(cardLatencyData[key], latencyThreshold), 'color': (cardLatencyData[key] > latencyThreshold * 1.6 ? "#fff" : "#000")}}>
                                <td>{key}</td>
                                <td>{!showDeviationTableCard ? cardLatencyData[key] : (Math.round(100 * (latencyThreshold - cardLatencyData[key]) / latencyThreshold))}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
            </Card>
        );
    };

    let renderCalloutCard = () => {

        const { mouseCoords, cardCalloutData, setCurrCalloutData, unpinCard, cardPinnedPosition } = props;

        let style = {};
        if (!isPinned) {
            style = { position: 'fixed', left: (mouseCoords.x + 20) + 'px', top: mouseCoords.y + 'px', padding: '0px', margin: '0px', zIndex: 9999 };
        }
        else {
            style = { position: 'fixed', left: (cardPinnedPosition.x + 20) + 'px', top: cardPinnedPosition.y + 'px', padding: '0px', margin: '0px', zIndex: 9999 };
        };

        return (
            cardCalloutData.length === 0 ? null : 
            <Card style={style}>
              <Card.Title style={{fontSize:'12px'}}>
                    <React.Fragment>
                        {
                            isPinned ? (
                                <FontAwesomeIcon 
                                tabIndex={0}
                                onClick={unpinCard} 
                                icon={faTimesCircle} 
                                className="unpin-icon"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        unpinCard()
                                    }
                                }}
                                onFocus={() => {
                                    setAccessibility('0')
                                }}
                                /> 
                            ) : (
                                <span style={{float:'right', marginLeft: '10px'}}>(Click on the chart to pin this card)</span>
                            )
                        }
                    </React.Fragment>
              </Card.Title>
              <Card.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th style={{ fontWeight: 'bold' }}>Week</th>
                      <th style={{ fontWeight: 'bold' }}>Owner</th>
                      <th style={{ fontWeight: 'bold' }}>Callouts</th>
                      <th style={{ fontWeight: 'bold' }}>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cardCalloutData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.MSRWeek}</td>
                        <td>{item.Owner}</td>
                        <td>{item.Callout}</td>
                        <td>
                            <Button aria-label="Edit scenario" variant="light" onClick={() => setCurrCalloutData(item)}><FontAwesomeIcon icon={faEdit} /></Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          );
    };
    
    const { showChartCard, showTableCard, showCalloutCard } = props;
    
    return showChartCard ? renderChartCard() : (showTableCard ? renderTableCard() : (showCalloutCard ? renderCalloutCard() : null));
    
}

export default MSRCard