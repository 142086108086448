import React, { Component } from 'react';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import DataService from '../../Dataservices/DataService'
import { SortOrderEnum, filterObject, compareString, getNextSortOrder, renderSortMark } from '../../Functions/TableHelper'
import './PipelineStatusPage.css'
import { LoadingGif } from '../PublicComponents/HelperComponents';

class StagingStatus extends Component {

    constructor(props) {
        super(props);

        this.statusObjectKeys = {
            None: 'None',
            AverageTime: 'AverageTime',
            BucketNo: 'BucketNo',
            Cube: 'Cube',
            CubeId: 'CubeId',
            Date: 'Date',
            Disk: 'Disk',
            Id: 'Id',
            IsMigrating: 'IsMigrating',
            IsNormalizing: 'IsNormalizing',
            MigrationErrorTimeOut: 'MigrationErrorTimeOut',
            MigrationStatus: 'MigrationStatus',
            NormalizationErrorTimeOut: 'NormalizationErrorTimeOut',
            NormalizationStatus: 'NormalizationStatus',
            PriorityOrder: 'PriorityOrder',
            RestatementType: 'RestatementType',
            Server: 'Server',
            TimeElapsed: 'TimeElapsed',
            StagingServer: 'StagingServer'
        }

        this.serverTableKeys = [
            this.statusObjectKeys.Cube, 
            this.statusObjectKeys.Date, 
            this.statusObjectKeys.MigrationStatus, 
            this.statusObjectKeys.NormalizationStatus, 
            this.statusObjectKeys.RestatementType, 
            this.statusObjectKeys.MigrationErrorTimeOut, 
            this.statusObjectKeys.NormalizationErrorTimeOut, 
            this.statusObjectKeys.Server, 
            this.statusObjectKeys.Disk, 
            this.statusObjectKeys.StagingServer,
            this.statusObjectKeys.PriorityOrder, 
            this.statusObjectKeys.AverageTime
        ]
        
        this.noneServerTableKeys = [
            this.statusObjectKeys.Cube, 
            this.statusObjectKeys.Date, 
            this.statusObjectKeys.RestatementType, 
            this.statusObjectKeys.MigrationErrorTimeOut, 
            this.statusObjectKeys.NormalizationErrorTimeOut, 
            this.statusObjectKeys.Server, 
            this.statusObjectKeys.Disk, 
            this.statusObjectKeys.StagingServer,
            this.statusObjectKeys.PriorityOrder, 
            this.statusObjectKeys.TimeElapsed, 
            this.statusObjectKeys.AverageTime
        ]

        this.state = {
            isLoading: true,
            allList: [],
            searchText: '',
            tableList: [],
            tableTitles: [],
            tableSort: [],
            error : null
        }
    }

    componentDidMount() {
        DataService.get('JobStatus/stagingstatus').then(res => {
            this.setState({
                isLoading: false,
                allList: res.data
            }, this.init)
        }).catch(err => {
            console.error(err)
            this.setState({error: err.message})
        })
    }

    init = () => {
        let { allList } = this.state
        
        let servers = allList.map(status => status.Server)
        let tableTitles = [...new Set(servers)]
        tableTitles.unshift('Currently Migrating')
        tableTitles.unshift('Currently Normalizing')

        let sort = []
        for (let i = 0; i < tableTitles.length; i++) {
            sort.push({
                key: this.statusObjectKeys.None,
                order: this.statusObjectKeys.None
            })
        }

        let normalizing = allList.filter(status => status.IsNormalizing === 'TRUE')
        let migrating = allList.filter(status => status.IsMigrating === 'TRUE')

        let serverList = tableTitles.slice(2).map(server => allList.filter(status => status.IsMigrating === 'FALSE' && status.IsNormalizing === 'FALSE' && status.Server === server))
        let seperateList = [normalizing, migrating, ...serverList]

        this.setState({
            tableTitles: tableTitles,
            tableList: seperateList,
            tableSort: sort
        })
    }

    getSeperateList = () => {   
        let { allList, tableTitles, searchText, tableSort } = this.state

        let normalizing = allList.filter(status => status.IsNormalizing === 'TRUE' && filterObject(status, searchText, this.noneServerTableKeys))
        let migrating = allList.filter(status => status.IsMigrating === 'TRUE' && filterObject(status, searchText, this.noneServerTableKeys))

        let serverList = tableTitles.slice(2).map(server => allList.filter(status => status.IsMigrating === 'FALSE' && status.IsNormalizing === 'FALSE' && status.Server === server && filterObject(status, searchText, this.serverTableKeys)))
        let seperateList = [normalizing, migrating, ...serverList]

        tableSort.forEach((sort, index) => {
            if (sort.key !== this.statusObjectKeys.None && sort.order !== SortOrderEnum.None) {
                seperateList[index].sort(this.compareObject(sort.key, sort.order === SortOrderEnum.Ascending))
            }
        })

        this.setState({
            tableList: seperateList
        })
    }

    compareObject = (key, isAscending) => {
        return (obj1, obj2) => {
            if (key === this.statusObjectKeys.Date || key === this.statusObjectKeys.MigrationErrorTimeOut || key === this.statusObjectKeys.NormalizationErrorTimeOut) {
                // compare dates  "12/9/2020"  "12/11/2020 12:18:51 AM"
                let date1 =Date.parse(obj1[key])
                let date2 = Date.parse(obj2[key])

                // Date values may contain 'None'. Make None values always show last when sorting
                if (isNaN(date1)) return 1
                if (isNaN(date2)) return -1

                return isAscending ? date1 - date2 : date2 - date1
            } else if (key === this.statusObjectKeys.Disk || key === this.statusObjectKeys.PriorityOrder) {
                // compare numbers
                return isAscending ? obj1[key] - obj2[key] : obj2[key] - obj1[key]
            } else if (key === this.statusObjectKeys.TimeElapsed || key === this.statusObjectKeys.AverageTime) {
                // compare times "05:26:00"
                let parts1 = obj1[key].split(':')
                let parts2 = obj2[key].split(':')
                let nums1 = parts1.map(num => parseInt(num))
                let nums2 = parts2.map(num => parseInt(num))
                let seconds1 = nums1.reduce((prev, curr) => {
                    return prev * 60 + curr
                })

                let seconds2 = nums2.reduce((prev, curr) => {
                    return prev * 60 + curr
                })

                return isAscending ? seconds1 - seconds2 : seconds2 - seconds1
            } else {
                // compare strings
                return compareString(obj1[key], obj2[key], isAscending)
            }
        }
    }

    sortList = (list, key, order, tableIndex) => {
        if (order === SortOrderEnum.None) {
            let { allList, searchText, tableTitles } = this.state
            let originList = []

            if (tableIndex === 0) {
                originList = allList.filter(status => status.IsNormalizing === 'TRUE' && filterObject(status, searchText, this.noneServerTableKeys))
            } else if (tableIndex === 1) {
                originList = allList.filter(status => status.IsMigrating === 'TRUE' && filterObject(status, searchText, this.noneServerTableKeys))
            } else {
                originList = allList.filter(status => status.IsMigrating === 'FALSE' && status.IsNormalizing === 'FALSE' && status.Server === tableTitles[tableIndex] && filterObject(status, searchText, this.serverTableKeys))
            }
            return originList
        } else {
            return list.sort(this.compareObject(key, order === SortOrderEnum.Ascending))
        }
    }

    onSort = (key, tableIndex) => {
        let { tableSort, tableList }  = this.state
        let nextOrder = getNextSortOrder(tableSort[tableIndex].order, tableSort[tableIndex].key !== key)
    
        tableSort.splice(tableIndex, 1, {
            key: key,
            order: nextOrder
        })
        
        let sortedList = this.sortList(tableList[tableIndex], key, nextOrder, tableIndex)
        tableList.splice(tableIndex, 1, sortedList)

        this.setState({
            tableList: tableList,
            tableSort: tableSort
        })
    }

    onChangeSearchText = e => {
        this.setState({
            searchText: e.target.value
        }, this.getSeperateList)
    }

    getLocalTimeString = (dateToConvert) => {

        let date = new Date(dateToConvert);
        
        if (isNaN(date))
        {
            return "None";
        }
        else if (date < new Date())
        {
            return "Expired";
        }
        else
        {
            return date.toLocaleString([], {
                dateStyle: 'short',
                timeStyle: 'long',
              });
        }
    }

    renderNoneServerTable = (title, tableIndex) => {
        let { tableSort } = this.state
        return (
            <div>
                <hr/>
                <Row>
                    <Col className="Label">
                        <h2>{title}</h2>
                        <div style={{ overflowX: 'auto', width: '100%' }} aria-live="polite" aria-atomic="true" aria-label={title + " " + (this.state.tableList[tableIndex] ? this.state.tableList[tableIndex].length + " results found" : "")}>
                            <Table striped bordered hover className="statusTable">
                                <thead>
                                    <tr>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.Cube, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.Cube)}
                                            Cube
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.Date, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.Date)}
                                            Date
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.RestatementType, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.RestatementType)}
                                            Restatement Type
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.MigrationErrorTimeOut, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.MigrationErrorTimeOut)}
                                            Migration Error TimeOut
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.NormalizationErrorTimeOut, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.NormalizationErrorTimeOut)}
                                            Normalization Error TimeOut
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.Server, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.Server)}
                                            Server
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.Disk, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.Disk)}
                                            Disk
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.StagingServer, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.StagingServer)}
                                            Staging Server
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.PriorityOrder, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.PriorityOrder)}
                                            Priority Order
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.TimeElapsed, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.TimeElapsed)}
                                            Time Elapsed
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.AverageTime, tableIndex)} className="tableHeader" scope="col">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.AverageTime)}
                                            Estimated Time
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tableList[tableIndex] && this.state.tableList[tableIndex].map(status => (
                                        <tr key={status.Id}>
                                            <td>{status.Cube}</td>
                                            <td>{status.Date}</td>
                                            <td>{status.RestatementType}</td>
                                            <td>{this.getLocalTimeString(status.MigrationErrorTimeOut)}</td>
                                            <td>{this.getLocalTimeString(status.NormalizationErrorTimeOut)}</td>
                                            <td>{status.Server}</td>
                                            <td>{status.Disk}</td>
                                            <td>{status.StagingServer}</td>
                                            <td>{status.PriorityOrder}</td>
                                            <td>{status.TimeElapsed}</td>
                                            <td>{status.AverageTime}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    renderServerTable = (title, tableIndex) => {
        let { tableSort, tableList } = this.state
        return tableIndex < tableList.length && tableList[tableIndex].length > 0 ? (
            <div>
                <hr/>
                <Row>
                    <Col className="Label">
                        <h2>{title}</h2>
                        <div style={{ overflowX: 'auto', width: '100%' }} aria-live="polite" aria-atomic="true" aria-label={title + " " + (this.state.tableList[tableIndex] ? this.state.tableList[tableIndex].length + " results found" : "")}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.Cube, tableIndex)} className="tableHeader">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.Cube)}
                                            Cube
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.Date, tableIndex)} className="tableHeader">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.Date)}
                                            Date
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.MigrationStatus, tableIndex)} className="tableHeader">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.MigrationStatus)}
                                            Migration Status
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.NormalizationStatus, tableIndex)} className="tableHeader">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.NormalizationStatus)}
                                            Normalization Status
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.RestatementType, tableIndex)} className="tableHeader">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.RestatementType)}
                                            Restatement Type
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.MigrationErrorTimeOut, tableIndex)} className="tableHeader">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.MigrationErrorTimeOut)}
                                            Migration Error TimeOut
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.NormalizationErrorTimeOut, tableIndex)} className="tableHeader">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.NormalizationErrorTimeOut)}
                                            Normalization Error TimeOut
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.Server, tableIndex)} className="tableHeader">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.Server)}
                                            Server
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.Disk, tableIndex)} className="tableHeader">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.Disk)}
                                            Disk
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.StagingServer, tableIndex)} className="tableHeader">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.StagingServer)}
                                            Staging Server
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.PriorityOrder, tableIndex)} className="tableHeader">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.PriorityOrder)}
                                            Priority Order
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.statusObjectKeys.AverageTime, tableIndex)} className="tableHeader">
                                            {renderSortMark(tableSort[tableIndex].key, tableSort[tableIndex].order, this.statusObjectKeys.AverageTime)}
                                            Estimated Time Remaining
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tableList[tableIndex].map(status => (
                                        <tr key={status.Id}>
                                            <td>{status.Cube}</td>
                                            <td>{status.Date}</td>
                                            <td>{status.MigrationStatus}</td>
                                            <td>{status.NormalizationStatus}</td>
                                            <td>{status.RestatementType}</td>
                                            <td>{this.getLocalTimeString(status.MigrationErrorTimeOut)}</td>
                                            <td>{this.getLocalTimeString(status.NormalizationErrorTimeOut)}</td>
                                            <td>{status.Server}</td>
                                            <td>{status.Disk}</td>
                                            <td>{status.StagingServer}</td>
                                            <td>{status.PriorityOrder}</td>
                                            <td>{status.AverageTime}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </div>
        ) : null
    }

    render() {
        return (
            <div>
                {this.state.isLoading 
                    ? <LoadingGif/>
                    : <div>
                        {this.state.error && <Alert variant='danger'>{this.state.error}</Alert>}
                        <Row>
                            <Col>
                                <Form>
                                    <Form.Group className="Label" controlId="stagingStatusSearch">
                                        <h1>Current Staging Status</h1>
                                        <Form.Control type="text" placeholder="Search Staging Status" onChange={this.onChangeSearchText}/>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        
                        {this.state.tableTitles.map((title, index) => <div key={title}>{index <= 1 ? this.renderNoneServerTable(title, index) : this.renderServerTable('Currently Waiting on ' + title, index)}</div>)}
                    </div>}
            </div>
        )
    }
}

export default StagingStatus;
