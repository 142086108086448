import React, { Component } from 'react';
import { ProgressBar, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge'
import './PivotHeadPane.css';

const targetMilliSecond = 2500;

export default class ProgressLoading extends Component {

    constructor(props) {
        super(props);

        let ongoingTask = [];
        if(this.props.progressTask) ongoingTask.push(this.props.progressTask);
        this.state = {
            updatedTime: props.updatedTime,
            estimateTime: -1, 
            ongoingTask: ongoingTask,
            loadingDay: 0,
            exceedEstimated: false,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.updatedTime !== prevProps.updatedTime) {
            let ongoingTask = prevState.ongoingTask;
            let estimateTime = prevState.estimateTime;
            let exceedEstimated = prevState.exceedEstimated;
            let loadingDay = Object.values(this.props.progress).filter(v => (v)).length;
            if(this.props.progress.length === 0) {
                ongoingTask = []
                estimateTime = -1
                exceedEstimated = false
            }
            if(this.props.progressTask && this.props.progressTask !== ongoingTask[ongoingTask.length-1]) {
                ongoingTask.push(this.props.progressTask);
                if(ongoingTask.length > 2) ongoingTask = ongoingTask.slice(1);
            }
            // when a data query without cache finished, update estimate time
            if(ongoingTask.length > 1 && (ongoingTask[0].includes("Query data") || ongoingTask[1].includes("Query data"))) {
                const totalDay = Object.keys(this.props.progress).length;
                const usingTime = this.props.updatedTime - prevProps.updatedTime;
                // If new days's data are loaded, estimate time can be updated
                if(loadingDay > prevState.loadingDay) {
                    // Remaining load time is estimated by the last finished query 
                    // estimate time = (last query time)/(last query date range) * (remaining date range)
                    estimateTime = usingTime/(loadingDay - prevState.loadingDay) * (totalDay - loadingDay);

                    // We would like to update the chart at least every target second. If the time interval is too big to reponse in 5 second, we will decrease the time interval.
                    // If query with current time interval can be finished in 5 second, we will increase time interval to fetch more data in one query. 
                    // currents seconds            target seconds(5 sec)
                    // ------------------   =     ---------------------
                    // current day count            target count (x)
                    let timeInterval = targetMilliSecond * (loadingDay - prevState.loadingDay) / usingTime; 
                    timeInterval = timeInterval >= 1 ? timeInterval : 1;
                    this.props.updateTimeInterval(timeInterval);

                    clearInterval(this.exceedTimer);
                    this.exceedTimer = setInterval(this.showExceed, estimateTime > targetMilliSecond ? estimateTime : targetMilliSecond);
                    exceedEstimated = false;
                }
            } 
            this.setState({
                ongoingTask: ongoingTask,
                estimateTime: estimateTime,
                loadingDay: loadingDay,
                exceedEstimated: exceedEstimated
            })
        }
    }

    showExceed = () => {
        clearInterval(this.exceedTimer);
        this.setState({exceedEstimated: true});
    }

    render() {
        const { estimateTime, ongoingTask, exceedEstimated } = this.state

        const totalDay = Object.keys(this.props.progress).length;
        const loadingDay = Object.values(this.props.progress).filter(v => (v)).length;

        // If total day is 0, it means that the max date haven't been loaded. Will set initial progress to 0. 
        // If total day is not 0, it means that the progress array is ready. Will calculate the progress according to loading days.
        const graphProcess = (ongoingTask[ongoingTask.length-1] !== "Get max query date" && totalDay !== 0 && (loadingDay*100/totalDay) )? parseInt(loadingDay*100/totalDay) : 0;

        return (
            <React.Fragment>
                <div role="status">
                <ProgressBar animated striped variant="success" now={graphProcess} label={graphProcess + "%"} id="estimatedTime" />                
                    <Row>                         
                        <div className="chartingPanePivotLabel">
                            <label htmlFor="estimatedTime">Estimated Waiting Time</label>
                        </div>
                        <div className="chartingPanePivotValue">
                            <label htmlFor="estimatedTime">
                                { estimateTime < 0 ? "Loading " : ( estimateTime < 5000 ? "< 5s " : (estimateTime/1000).toFixed(2) + "s ")} 
                                { exceedEstimated && <Badge variant="info">Need more time, is still graphing</Badge> }
                            </label>
                        </div>                      
                    </Row>
                    {ongoingTask.length > 0 &&
                    <Row>
                        <Card body style={{"width":"100%", "marginRight":"10px", "textAlign":"left"}}>
                        {ongoingTask.length > 1 && <p>{ongoingTask[0]} <font style={{ color: "green" }}> {" finished"} </font> </p> }
                        {ongoingTask.length > 0 && <p>{ongoingTask[ongoingTask.length - 1]} <font style={{ color: "red" }}> {" ongoing"} </font> </p> }
                        </Card>
                    </Row>}
                </div>
            </React.Fragment>
        )
    }
}