import React, { Component } from 'react'
import CsvCellInput from './CsvCellInput'
import { getValidator } from '../../Functions/CubeCSVManagement/CubeCSVValidation'
import { AutoFill } from '../../Functions/CubeCSVManagement/CubeCSVAutofill'
import './CubeCsvComponents.css'
import CubeCSVDataService from '../../Dataservices/CubeCSVDataService'

class CubeCsvTableRow extends Component {

    constructor(props) {
        super(props)

        this.isAutofill = true
        if(props.row && props.row.length > 0 && props.row[0] === '') this.isAutofill = false

        this.state = {
            isFlat: props.isFlat,
            type: props.type,
            row : props.row,
            error : props.error, 
            comment: props.comment,
            dataHeader : props.dataHeader,
            headerIndex : props.dataHeader.reduce((map, header, index) => {
                map[header.toLowerCase()] = index
                return map
            }, {}),
            updateTime: new Date().getTime(),
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.updateTime > prevState.updateTime || nextProps.dataUpdateTime !== prevState.dataUpdateTime) {
            return {
                row: nextProps.row,
                error: nextProps.error,
                updateTime: nextProps.updateTime,
                dataUpdateTime: nextProps.dataUpdateTime,
            }
        }
        return null
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.id !== this.props.id || nextState.updateTime !== this.state.updateTime) {
            return true
        }
        return false
    }

    getContent = () => {
        const {row, error} = this.state
        return {
            row: row,
            error: error
        }
    }

    onContentChange = (key, e) => {
        let { row, error, dataHeader, headerIndex, type } = this.state
        const content = e.target.value
        const validator = getValidator(type, dataHeader[key])

        // TO DO: some validator requires more than 1 argument
        if (validator) {
            const { isValid, errorMsg } = validator(content, headerIndex, row)

            if (!isValid && error) {
                error[key] = errorMsg
            } else if(error) {
                error[key] = ''
            }
        }
        
        row[key] = content
        this.setState({ 
            error: error,
            row: row,
            updateTime: new Date().getTime()
        })
    }

    autofill = () => {
        let {row, dataHeader} = this.state
        const {isFlat} = this.props
        return CubeCSVDataService.getPivot(row[0], dataHeader.join(',').replace('#',''), isFlat)

    }

    validateRow = (key) =>{
        let {row, error, dataHeader, headerIndex, type} = this.state
        for(let i = 0; i < row.length; i++) if(i !== key){
            const validator = getValidator(type, dataHeader[i])
            if (validator) {
                const { isValid, errorMsg } = validator(row[i], headerIndex, row)

                if (!isValid && error) {
                    error[i] = errorMsg
                } else if(error) {
                    error[i] = ''
                }
            }
        }
        this.setState({ 
            error: error,
            row: row,
            updateTime: new Date().getTime()
        })
    }

    onContentBlur = (key, e) => {
        let {row, error, dataHeader, headerIndex, type} = this.state
        const {isFlat} = this.props
        if(key === 0) this.isAutofill = false
        if(error[key]) return
        if(type !== "column") return 
        if(this.isAutofill) return
        let nrow = null
        let autofillRow = this.autofill()
        autofillRow.then(res =>{
            const pivotInfo = res.data
            if(!pivotInfo){
                nrow = AutoFill(row, dataHeader, headerIndex, isFlat)
                if(nrow === null) return
                for(let i = 0; i < row.length; i++)
                    row[i] = nrow[i]
                this.setState({
                    error: error,
                    row: row,
                    updateTime: new Date().getTime()
                })
                this.isAutofill = true
            }else{
                this.isAutofill = true
                nrow = pivotInfo.split(',')
                if(nrow == null) return
                for(let i = 0; i < row.length; i++)
                    row[i] = nrow[i]
                this.setState({
                    error: error,
                    row: row,
                    updateTime: new Date().getTime()
                 })
            }
            this.validateRow(key)
        })
        
    }

    render() {
        const {row, dataHeader, error, comment, type, updateTime} = this.state
        return (
            <React.Fragment>
                {row.map((value,ind) => (
                    <td key={`${this.props.id}-${ind}`} className="cubeCsvTableCell">
                        <CsvCellInput
                        key={`${this.props.id}-${ind}-cellinput`}
                        column={ind}
                        value={value}
                        type={type}
                        columnkey={dataHeader[ind].toLowerCase()}
                        error={error && error[ind]}
                        comment={comment[ind]}
                        updateTime={updateTime}
                        onChange={this.onContentChange}
                        onBlur={this.onContentBlur}/>
                    </td>
                ))}
            </React.Fragment>
        )
    }
}

export default CubeCsvTableRow