
import React, { useState, useRef, useEffect, forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { handleClick } from '../../Dataservices/EventTrackingService';
import { addDaysToUTCDate, getDisplayDateAsLogicalDate, getLogicalDateAsDisplayDate } from '../../Functions/DateTimeHelper';
import { library } from "@fortawesome/fontawesome-svg-core";
import ReactDatePicker from '../ReactDatepicker';

library.add(faPen, faCalendar);

/*
    PLEASE READ THIS COMMENT BEFORE CHANGING OR USING ANYTHING IN THIS COMPONENT.
    Throughout PerfPanel, we handle dates with the assumption that the the given date is in UTC.
    However, the datepicker we use will always display the given date in the user's timezone.
    Ex: given date is "2021-01-01 00:00". Assume it is UTC.
        If the user is in Redmond, browser will display the date as "2020-12-31 17:00"
        If the user is in Suzhou, browser will display the date as "2021-01-01 8:00"
    Therefore, in order to display the date as UTC to the user,
    we must add an offset equivalent to offset between the user's timezone and UTC.
    **THIS RESULTS IN A LOGICALLY INCORRECT TIME**,
    so you must convert the date back to the intended UTC time before using.
    There is a helper function already written that will do this for you, getDisplayDateAsLogicalDate.
    See safeChangeDates for an example of how to use it.
    For the opposite behavior (UTC-date-to-displayed-date instead of displayed-date-to-UTC),
    use getLogicalDateAsDisplayDate.
    See the hourlyStartDate's initialization for an example of its use.
    PLEASE KEEP THIS BEHAVIOR ISOLATED TO THIS COMPONENT and do any needed conversions in this component,
    not elsewhere.
*/

// Helper functions and constants
const getDefaultStartDate = (isHourly) => isHourly ? getLogicalDateAsDisplayDate(addDaysToUTCDate(new Date(), -2)): addDaysToUTCDate(new Date(), -30);
const getDefaultEndDate = (isHourly) => isHourly ?  getLogicalDateAsDisplayDate(addDaysToUTCDate(new Date(), 0)) : addDaysToUTCDate(new Date(), 0);
const DefaultTimezone = "UTC";

export function ServiceMetricsDateRangePicker(props) {

    // State
    const [hourlyStartDate, setHourlyStartDate] = useState(props.startDate?getLogicalDateAsDisplayDate(props.startDate):getDefaultStartDate(false));
    const [hourlyEndDate, setHourlyEndDate] = useState(props.endDate?getLogicalDateAsDisplayDate(props.endDate):getDefaultEndDate(false));
    const [showPicker, setShowPicker] = useState(false);
    const [listening, setListening] = useState(false);

    // Refs
    const datepickerRef = useRef(null);
    const captureClicks = useRef(false);
    const resetClicks = useRef(false);

    let safeChangeDates = () => {
        props.selectDate({
            hourlyStartDate : getDisplayDateAsLogicalDate(hourlyStartDate, DefaultTimezone),
            hourlyEndDate : getDisplayDateAsLogicalDate(hourlyEndDate, DefaultTimezone)
        });
        fullPickerReset();
    };

    /* 
     *  This effect closes the datepicker on outside click.
     *  There are three cases that are covered:
     *  One, the case where the click was within the picker itself (ex: someone clicks on "Start Date").
     *      This case is handled by the datepickerRef. If the click target was on the dom subtree attached at the dom node associated with the datepickerRef,
     *      We just return (do nothing).
     *  Two, the case where the click is outside the picker. We close the picker by setting setShowPicker to false.
     *  Three, the case where the click is on datepicker dropdown (that appears when we click on a date).
     *      This datepicker is *not* part of the dom subtree associated with the datepicker, so without special handling, 
     *      clicking within that dropdown will close the entire picker.
     *      Ant-datepicker also does not expose a ref to the dropdown, so this is an admittedly hacky workaround.
     *      The workaround uses two variables, captureClicks and resetClicks.
     *      captureClicks is set to true when the datepicker dropdown is opened, and indicates that clicks should not trigger a close of showPicker.
     *      resetClicks is set to true when the datepicker dropdown is closed, and indicates that captureClicks should be set to false,
     *      and clicks should now go through the normal logic (close datepicker if click was not on the datepicker dom subtree).
     *      The reason why two separate variables are needed, instead of just setting captureClicks to false on dropdown close, is so that clicks on the dropdown
     *      that trigger a dropdown close (such as clicking on a day in the calendar), don't trigger a close of the entire picker.
     *      This workaround has the side-effect in that it doesn't distinguish between types of clicks when the dropdown is open, so a click outside the datepicker
     *      will only trigger a close of the dropdown, not a close of the entire picker as might be expected. So two outside clicks are needed to close the datepicker fully.
     *      Fixing this is a TODO for the future.
     */

    useEffect( () => {
      if (listening || !datepickerRef.current) return;
      setListening(true);
      [`click`, `touchstart`].forEach((type) => {
        document.addEventListener(type, (evt) => {
          if (datepickerRef.current && datepickerRef.current.contains(evt.target)) return;
          if(captureClicks.current && resetClicks.current){
              // The click occured while a datepicker dropdown was open,
              // and triggered a close. Reset capture + reset variables,
              // but don't close showPicker yet
              captureClicks.current = false;
              resetClicks.current = false;
          }
          else if(captureClicks.current && !resetClicks.current){
              // The click occured while a datepicker dropdown was open,
              // and did not trigger a close. Ignore the click
              return;
          }
          else if(!captureClicks.current && resetClicks.current){
              // This should never happen, but in case it does,
              // reset to the default state (everything set to false)
              fullPickerReset();
          }
          else {
              // The click was not on the datepicker and the datepicker dropdown was closed. Close ShowPicker
              setShowPicker(false);
          }
        });
      });
    });

    function DatepickerRow(label, isHourly, value, onChange) {
        return(
            <div className="usageDatepickerRow" aria-label={label} onClick={() => { handleClick(label, "menu", "servicemetrics") }}>
                <span style={{ width: "20%" }}>{label}&nbsp;</span>
                <ReactDatePicker
                    style={{ width: "80%" }}
                    className="gray-border"
                    value={value}
                    showTime={isHourly}
                    onChange={(date) => onChange(date)}
                    onCalendarOpen={() => captureClicks.current = true}
                    onCalendarClose={() => resetClicks.current = true}
                />    
            </div>
        )
    }

    const fullPickerReset = (shouldShowPicker = false) => {
        setShowPicker(shouldShowPicker);
        captureClicks.current = false;
        resetClicks.current = false; 
    }
            
    function DatepickerPopover() {
        return (
            <div className="usageDatepicker">
                {DatepickerRow("Start", false, hourlyStartDate, setHourlyStartDate)}
                {DatepickerRow("End", false, hourlyEndDate, setHourlyEndDate)}
                <Button style={{ display: "flex", margin: "5px" }} variant="primary" onClick={safeChangeDates}>Graph it&emsp;<FontAwesomeIcon icon={faPen} /></Button>
            </div>
        );
    }

    return (<div ref={datepickerRef}>
                <Button variant="light" style={{ display: "flex", justifyContent: "flex-end"}} aria-label="Calendar" onClick={() => {fullPickerReset(!showPicker)}}> <FontAwesomeIcon icon={faCalendar} /> </Button>
                {showPicker && <DatepickerPopover/>}
            </div>);
}

export function ServiceMetricsDatePicker(props) {
    // State
    const [date, setDate] = useState(props.date?getLogicalDateAsDisplayDate(props.date):getDefaultEndDate(false));

    useEffect( () => { 
        setDate(getLogicalDateAsDisplayDate(props.date));
    }, [props.date]);

    let safeChangeDate = newDate => {
        setDate(newDate);
        props.selectDate(getDisplayDateAsLogicalDate(newDate, DefaultTimezone));
    };

    function DatepickerRow(label, isHourly, value, onChange) {
        return (
            <div onClick={() => { handleClick(label, "menu", "servicemetrics") }}>
                <span style={{width: "40%"}}>{label}&nbsp;</span>
                <ReactDatePicker
                    className="gray-border medium-width"
                    value={value}
                    showTime={isHourly}
                    onChange={(newDate) => onChange(newDate)}
                />
            </div>
        );
    }

    return DatepickerRow(props.label, props.isHourly, date, safeChangeDate)
}