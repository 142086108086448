import React, {Component} from 'react';
import uuid from 'react-uuid';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import EvoDataService from '../../Dataservices/EvoDataService';
import ServiceLevelMappingTableRow from './ServiceLevelMappingTableRow';
import './CosmicMappings.css';
import { SortOrderEnum, filterObject, compareString, getNextSortOrder, renderSortMark, getCurrentPageList, getPageNumbersToDisplay } from '../../Functions/TableHelper'

class ServiceLevelMappings extends Component {

    constructor(props) {
        super(props)

        this.mappingKeys = {
            None: 'None',
            Description: 'description',
            Legend: 'legend',
            KeyName: 'keyName',
            KeyType: 'keyType'
        }

        this.state = {
            isLoading: true,
            searchText: '',
            rawList: [],
            allList: [],
            displayList: [],
            totalPage: 0,
            sortKey: this.mappingKeys.None,
            sortOrder: SortOrderEnum.None,
            currentPage: 0,
            pagination: [],
            selectedMappings: {},
            useCommon: null
        }
    }

    componentDidMount() {
        this.getMappingList()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mappingPromoted === false && this.props.mappingPromoted === true) {
            this.getMappingList();
            this.props.setMappingPromoted(false);
        }

        if (prevProps.service !== this.props.service) {
            this.getMappingList();
        }
    }

    getMappingList = () => {
        this.setState({
            isLoading: true,
            displayList: [],
            pagination: []
        })

        EvoDataService.doesServiceUseCommonMapping(this.props.service).then(res => {
            this.setState({useCommon: res.data});
        }).catch(err => {
            console.error(err);
            this.props.setError(err.message);
        })

        EvoDataService.getServiceLevelMappings(this.props.service).then(res => {
            let { searchText, sortKey, sortOrder, currentPage } = this.state
            let raw = res.data;
            let all = raw.filter(item => filterObject(item, searchText))
            if (sortKey !== this.mappingKeys.None) {
                all.sort(this.compareObject(sortKey, sortOrder === SortOrderEnum.Ascending))
            }

            this.setState({
                isLoading: false,
                rawList: raw,
                allList: all,
            }, this.onClickPagination.bind(this, currentPage === 0 ? 1 : currentPage))
        }).catch(err => {
            console.error(err);
            this.props.setError(err.message);
        })
    }

    onChangeSearchText = e => {
        const { sortKey, sortOrder, currentPage } = this.state
        let text = e.target.value
        let list = this.state.rawList.filter(item => filterObject(item, text))
        if (sortKey !== this.mappingKeys.None && sortOrder !== SortOrderEnum.None) {
            list = list.sort(this.compareObject(sortKey, sortOrder === SortOrderEnum.Ascending))
        }
        this.setState({
            allList: list,
            searchText: text,
            totalPage: Math.ceil(list.length / 15)
        }, this.onClickPagination.bind(this, currentPage))
    }

    compareObject = (key, isAscending) => {
        return (obj1, obj2) => {
            return compareString(obj1[key], obj2[key], isAscending, true)
        }
    }

    onSort = key => {
        let { allList, sortKey, sortOrder, currentPage } = this.state
        let nextOrder = getNextSortOrder(sortOrder, key !== sortKey, true)
        let all = allList.sort(this.compareObject(key, nextOrder === SortOrderEnum.Ascending))
        const display = getCurrentPageList(all, currentPage)

        this.setState({
            allList: all,
            displayList: display,
            sortKey: key,
            sortOrder: nextOrder
        })
    }

    onClickPagination = clickedNum => {
        const { allList } = this.state
        const totalPage = Math.ceil(allList.length / 15)
        if(clickedNum > totalPage) {
            clickedNum = 1
        }
        const pages = getPageNumbersToDisplay(clickedNum, totalPage)

        this.setState({
            pagination: pages,
            displayList: getCurrentPageList(allList, clickedNum),
            currentPage: clickedNum
        })
    }

    handleUseCommonChange = (checked) => {
        EvoDataService.modifyServiceUseCommon({serviceName: this.props.service, useCommon: checked}).then(
            () => this.setState({useCommon: checked})
        ).catch(err =>{
            console.error(err);
            this.props.setError(err.message);
        })
    }

    promoteMappings = () => {
        let requestObj = Object.values(this.state.selectedMappings).map(mapping => {
            delete mapping['isMappingInCommonTable'];
            return mapping;
        })

        EvoDataService.promoteServiceLevelMappingsToCommon(requestObj).then(res => {
            this.setState({selectedMappings: {}});
            this.props.setMappingPromoted(true);
        }).catch(err => {
            console.error(err);
            this.props.setError(err.message);
        })
    }

    addMappingToPromotionList = (mapping) => {
        let dictKeyName = mapping.keyName + mapping.keyType;
        let dupObj = JSON.parse(JSON.stringify(this.state.selectedMappings));
        dupObj[dictKeyName] = mapping;
        this.setState({selectedMappings: dupObj});
    }

    removeMappingFromPromotionList = (mapping) => {
        let dictKeyName = mapping.keyName + mapping.keyType;
        let dupObj = JSON.parse(JSON.stringify(this.state.selectedMappings));
        if (dictKeyName in dupObj) {
            delete dupObj[dictKeyName];
            this.setState({selectedMappings: dupObj});
        }
    }

    render() {
        const { allList, displayList, isLoading, sortKey, sortOrder, currentPage, pagination, selectedMappings, useCommon } = this.state
        const totalPage = Math.ceil(allList.length / 15)
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        {useCommon !== null && 
                        <OverlayTrigger placement="auto" overlay={<Tooltip>Opting in for common mappings will fill in empty legend and descriptions from common mapping table during query time</Tooltip>}>
                            <Form.Check aria-label="Opt in for common mappings" type="checkbox" label="Opt in for common mappings" onChange={(e) => this.handleUseCommonChange(e.target.checked)} checked={useCommon}/>
                        </OverlayTrigger>}
                    </Col>
                    <Col>
                        <Button variant="primary" disabled={Object.keys(selectedMappings).length === 0} onClick={() => this.promoteMappings()}>Promote Selected</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group className="Label" controlId="mappingSearch">
                                <h3>Legend Mappings for Measures and Checkpoints</h3>
                                <Form.Control type="text" placeholder="Search Mappings" onChange={this.onChangeSearchText}/>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{overflowX: 'auto'}}>
                            <Table striped bordered hover size="sm" className="alignTextLeft">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th onClick={this.onSort.bind(this, this.mappingKeys.KeyName)} className="tableHeader">
                                            {renderSortMark(sortKey, sortOrder, this.mappingKeys.KeyName)}
                                            Key
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.mappingKeys.KeyType)} className="tableHeader">
                                            {renderSortMark(sortKey, sortOrder, this.mappingKeys.KeyType)}
                                            Type
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.mappingKeys.Legend)} className="tableHeader">
                                            {renderSortMark(sortKey, sortOrder, this.mappingKeys.Legend)}
                                            Legend
                                        </th>
                                        <th onClick={this.onSort.bind(this, this.mappingKeys.Description)} className="tableHeader">
                                            {renderSortMark(sortKey, sortOrder, this.mappingKeys.Description)}
                                            Description
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    {displayList.map((item) => (
                                        <ServiceLevelMappingTableRow 
                                            key={item.keyName} 
                                            item={item} 
                                            service={this.props.service} 
                                            setError={this.props.setError}
                                            addMappingToPromotionList={this.addMappingToPromotionList}
                                            removeMappingFromPromotionList={this.removeMappingFromPromotionList}
                                            setMappingPromoted={this.props.setMappingPromoted}/>  
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        {isLoading && <img alt="" src="../gears.gif"/>}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Pagination style={{overflowX: 'auto'}}>
                            <Pagination.First disabled={currentPage === 1 || currentPage === 0 ? true : false} onClick={this.onClickPagination.bind(this, 1)}/>
                            <Pagination.Prev disabled={currentPage === 1 || currentPage === 0 ? true : false} onClick={this.onClickPagination.bind(this, currentPage - 1)} />
                            {pagination.map(num => num === 0 ? <Pagination.Ellipsis disabled key={uuid()}/> : <Pagination.Item key={uuid()} onClick={this.onClickPagination.bind(this, num)} active={currentPage === num ? true : false}>{num}</Pagination.Item>)}
                            <Pagination.Next disabled={currentPage === totalPage ? true : false} onClick={this.onClickPagination.bind(this, currentPage + 1)}  />
                            <Pagination.Last disabled={currentPage === totalPage ? true : false} onClick={this.onClickPagination.bind(this, totalPage)}/>
                        </Pagination>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default ServiceLevelMappings;