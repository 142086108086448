import React, { Component } from 'react';

import CentralServiceTab from './CentralServiceTab';
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import EventLogPage from './EventLogPage';
import MaintenanceMonitor from './MaintenanceMonitor';
import PipelineStatus from './PipelineStatus';
import RestatementPage from './RestatementPage';
import CosmicJobStatus from './CosmicJobStatus';
import EvoQuarantinedTask from './EvoQuarantinedTask';
import Row from 'react-bootstrap/Row'
import StagingStatus from './StagingStatus';
import TrimmingMonitor from './TrimmingMonitor';
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { getPageTitle } from '../../Functions/PageTitleHelper'
import titles from '../../Store/titles.js';
import { withRouter } from 'react-router-dom';
import EvoJobStatus from './EvoJobStatus.js';

class PipelineStatusPage extends Component {

    constructor(props) {
        super(props)

        this.tabList = [
            "pipeline",
            "staging",
            "maintenance",
            "centralService",
            "eventlog",
            "trimming",
            "cosmic",
            "evo",
            "quarantine"
        ]

        this.tabToTitleMapping = {
            "pipeline": titles.PIPELINE_STATUS,
            "staging": titles.STAGING_STATUS,
            "maintenance": titles.MAINTENANCE_SERVICE,
            "centralService": titles.CENTRAL_SERVICE,
            "eventlog": titles.EVENT_LOG,
            "trimming": titles.TRIMMING_MONITOR,
            "cosmic": titles.COSMIC_JOBS,
            "restatementPage": titles.RESTATEMENTS,
            "evo": titles.EVO_JOBS,
            "quarantine": titles.EVO_QUARANTINED_TASK
        }

        this.state = {
            key: 'pipeline'
        }
    }

    componentDidMount() {
        let params = new URLSearchParams(this.props.location.search);
        let tab = params.get('tab');
        if (this.tabList.includes(tab)) {
            this.setState({ key: tab })
        } else {
            this.onSelect('pipeline')
        }
    }

    onSelect = key => {
        this.props.history.push(`${window.location.pathname}?tab=${key}`);
        this.setState({ key })
    }

    render() {
        let isAdmin = this.props.isAdmin;
        document.title = getPageTitle(this.tabToTitleMapping[this.state.key]);
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Tabs activeKey={this.state.key}
                            style={{ marginBottom: '15px' }}
                            onSelect={this.onSelect}>
                            <Tab eventKey="pipeline" title="Pipeline Status">
                                <PipelineStatus />
                            </Tab>
                            <Tab eventKey="staging" title="Staging Status" disabled={!isAdmin}>
                                <StagingStatus />
                            </Tab>
                            <Tab eventKey="maintenance" title="Maintenance Service Monitor" disabled={!isAdmin}>
                                <MaintenanceMonitor />
                            </Tab>
                            <Tab eventKey="centralService" title="Central Service" disabled={!isAdmin}>
                                <CentralServiceTab />
                            </Tab>
                            <Tab eventKey="eventlog" title="Event Log" disabled={!isAdmin}>
                                <EventLogPage />
                            </Tab>
                            <Tab eventKey="restatementPage" title="Restatements" disabled={!isAdmin}>
                                <RestatementPage />
                            </Tab>
                            <Tab eventKey="trimming" title="Trimming Monitor" disabled={!isAdmin}>
                                <TrimmingMonitor />
                            </Tab>
                            <Tab eventKey="cosmic" title="Cosmic Jobs" disabled={!isAdmin}>
                                <CosmicJobStatus />
                            </Tab>
                            <Tab eventKey="evo" title="Evo Jobs" disabled={!isAdmin}>
                                <EvoJobStatus />
                            </Tab>
                            <Tab eventKey="quarantine" title="Evo Quarantined Jobs" disabled={!isAdmin}>
                                <EvoQuarantinedTask />
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(PipelineStatusPage);